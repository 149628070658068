<template>
    <div class="login five-bg">
        <!--    验证码主体-->
       <!-- <img class="banner" src="http://qn.yiquan.com.cn/web/spring2021/banner-five.jpg"/> -->
       <img class="banner" src="https://qn.yiquan.com.cn/web/202401/banner.jpg"/>
        <div class="container">
            <!-- 路由对应组件的显示 -->
            <div class="source">
                <div class="title">
                    <p>注：本公众号服务内容仅对已签约央企员工开放，凭邀请码或扫描员工购车二维码进入.</p>
                </div>
                <form class="container" @submit.prevent="submitCustCode">
<!--                    <img class="title-img" src="/images/login-title1-five.png"/>-->
                    <img class="title-img" src="http://api.yiquan.com.cn/web/spring2021/yaoqingma.png"/>
                    <img class="title-wh" src="https://qn.yiquan.com.cn/web/common/icon-wh.png" @click="infoShow=true"/>
                    <input class="submit-content" type="content" placeholder="请填写邀请码" v-model="tokenFormObj.token" @blur="clearTop"/>
                    <div class="warn-info">
                        <span v-if="warnLv == 1">请输入邀请码</span>
                        <span v-else-if="warnLv == 2">邀请码错误</span>
                    </div>
                    <div class="login-btn" @click="login()">
                        <p>已有账户，直接登陆</p>
                    </div>
                    <van-button class="submit-btn five-btn" round type="info" size="large" native-type="submit">确认</van-button>
                </form>
            </div>
        </div>
        <div class="alert-container" >
            <!--    注册/登陆弹窗-->
            <van-overlay :show="logShow">
                <div class="wrapper" @click.stop>
                    <div class="log-block">
                        <div class="close-btn" @click="clickClose()" v-if="logType==1">x</div>
                        <div class="title">
                             <p v-if="logType == 1">绑定账号</p>
                            <p v-else>快捷注册</p>
                        </div>
                        <form class="submit-container" @submit.prevent="logForm">
                            <div class="form-input mobile-container">
                                <input placeholder="请输入手机号" v-model="logFormObj.mobile" maxlength="11" @blur="clearTop"/>
                                <img src="/images/lab_m.png">
                                <div class="mobile-btn five-btn" @click="getMobileToken()">{{tokenBtnMsg}}</div>
                            </div>
                            <div class="form-input pwd-container">
                                <input placeholder="请输入验证码" v-model="logFormObj.token" @blur="clearTop" maxlength="6"/>
                                <img src="/images/lab_c.png">
                            </div>
                            <div class="warn-info">
                                <span v-if="alertWarnLv == 1">请输入正确的手机号</span>
                                <span v-else-if="alertWarnLv == 2">验证码错误</span>
                                <span v-else-if="alertWarnLv == 3">{{alertWarnContent}}</span>
                            </div>

                            <van-button class="submit-btn  five-btn" round type="info" size="large" native-type="submit">
                                <span v-if="logType == 1">登陆</span>
                                <span v-else>确认</span>
                            </van-button>
                        </form>
                    </div>
                </div>
            </van-overlay>
            <!--    问号弹窗-->
            <van-overlay :show="infoShow" @click="infoShow=false">
                <div class="wrapper">
                    <div class="info-block" @click.stop>
                        <div class="close-btn" @click="infoShow=false">x</div>
                        <img class="title-img" src="/images/login-alert-banner.png"/>
                        <section class="info-content">
                            <article>
                                <h3>1、什么是邀请码和邀请二维码？</h3>
                                <p>员工购车服务平台仅对协议企业员工开放，需凭邀请码进入，邀请码是您的单位身份识别码，以便我们为您展示相应政策和提供相应服务。</p>
                            </article>
                            <article>
                                <h3>2、去哪里获得邀请码和二维码？</h3>
                                <p>内部通知或宣传材料上有相应邀请码或邀请二维码。您也可以通过相关负责部门或已注册同事哪里获得。</p>
                            </article>
                            <footer>
                                <div class="mobile">
                                    <span class="mobile-content">客服电话: 400-000-2787</span>
                                    <span class="click-btn" @click="callTel">点击咨询</span>
                                </div>
                            </footer>
                        </section>
                    </div>
                </div>
            </van-overlay>
        </div>

        <van-overlay :show="loadShow"  class="overlay-div">
            <van-loading type="spinner"  class="load"/>
        </van-overlay>

    </div>
</template>

<script>
    import vue from 'vue';
    import {Button, Overlay, Loading} from 'vant';
    vue.use(Button).use(Overlay).use(Loading);
    export default {
        name: 'login',
        data() {
            return {
                userType: 0, //用户状态 0：未绑定企业 1：已绑定企业
                logShow: false,
                infoShow: false,
                loadShow : true,
                logType: 1,  //绑定账号：1|注册账号：2
                warnLv : 0,  //提示等级 0:无提示 1：提示填入邀请码 2：提示填入正确的邀请码
                alertWarnLv: 0, //提示等级 0:无提示 1：请输入正确的手机号 2：验证码错误 3：其他
                alertWarnContent : '',
                logFormObj: {
                    mobile: '',
                    token: ''
                },
                tokenFormObj: {
                    token: ''
                },
                tokenBtnMsg : '获取验证码',
                deadline : 60,
                btnDisable : false,
                btnDisable2 : false
            }
        },
        created: function () {
            let query = this.$route.query;
            //重新获取用户信息状态
            this.$post('/getUserInfoByOpenId', {
                openId: this.$store.getters['user/getOpenId']
            }).then((res) => {
                if (res.code == 200) {
                    let data = res.data;
                    let custId = data.custId;
                    let userId = data.userId;

                    //更新数据
                    this.$store.commit('user/setUserId', userId);
                    this.$store.commit('user/setCustId', custId);

                    this.$data.loadShow = false;
                    let urlType = this.$store.getters['local/getType'];
                    let url = this.$store.getters['local/getUrl'];
                    if(urlType == 7){
                        //特殊跳转
                        this.$router.push({path : url }).catch((e) => {
                            this.$router.push({path : url });
                        });
                        return false;
                    }

                    if(custId == 0){
                        //判断是否无企业id-不动
                        if(query.custInfo != null){
                            //有邀请用的企业信息，并且是无企业-查询是否有该企业
                            let custId = query.custInfo;
                            this.$post('/hasCust', {custId : custId}).then((res)=>{
                                if(res.code == 200){
                                    let code = res.data.code;
                                    //直接绑定宝武
                                    this.$post('/userBindCust', {
                                        openId : this.$store.getters['user/getOpenId'],
                                        code :  code,
                                    }).then((res) => {
                                        if(res.code == 200){
                                            this.$store.commit('user/setCustId', 1058);
                                            this.$data.userType = 1;
                                            this.$data.logType = 2;
                                            this.$data.logShow = true;
                                        }
                                    });
                                }
                            })
                        }
                    }else if(userId == 0){
                        //判断是否无用户id-修改用户状态未已绑定企业
                        window.console.log(this.$store.getters['local/getType']);
                        this.$data.userType = 1;
                        this.$data.logType = 2;
                        this.$data.logShow = true;
                    }else{
                        //都有，那么请你去index主页候命
                        if(urlType == 3){
                            this.$router.push({path: "/personal"});
                        }else if(urlType == 2){
                            this.$router.push({path: "/sp-index"});
                        }else if(urlType == 9){
                            this.$router.push({path: this.$store.getters['local/getUrl']});
                        }else{
                            this.$router.push({path: "/index"});
                        }
                    }
                } else {
                    alert(res.msg);
                }
            });
        },
        methods: {
            /**
             * 弹窗绑定账号
             */
            login: function () {
                this.showTypeAlert(1);
            },
            /**
             * 提交邀请码并弹出注册账号
             * @returns {boolean}
             */
            submitCustCode : function (){
                let code = this.$data.tokenFormObj.token;
                if(code == ''){
                    this.$data.warnLv = 1;
                    return false
                }
                //请求后端判断邀请码是否正确
                this.$data.logShow = false;
                this.$post('/userBindCust', {
                    openId : this.$store.getters['user/getOpenId'],
                    code :  code,
                }).then((res) => {
                    if(res.code == 200){
                        this.$data.userType = 1;
                        this.$data.logType = 2;
                        this.$data.logShow = true;
                    }else{
                        this.$data.warnLv = 2;
                    }
                });
            },
            /**
             * 获取手机验证码
             */
            getMobileToken: function () {
                if(this.btnDisable){
                    return false;
                }
                this.btnDisable = true;
                let mobile = this.logFormObj.mobile;
                if(this.isPhone(mobile)){
                    this.alertWarnLv = 0;
                    this.$post('/userGetRand', {
                        openId : this.$store.getters['user/getOpenId'],
                        mobile :  mobile,
                    }).then((res) => {
                        if(res.code == 200){
                            //循环倒计时
                            this.tokenBtnMsg = this.deadline + 's';
                            this.int_btnClock = window.setInterval(() => {
                                if(this.deadline <= 0){
                                    window.clearInterval(this.int_btnClock);
                                    this.tokenBtnMsg = '获取验证码';
                                    this.deadline = 60;
                                    this.btnDisable = false;
                                    return;
                                }
                                this.deadline--;
                                this.tokenBtnMsg = this.deadline + 's';
                            },1000);
                        }else{
                            this.btnDisable = false;
                            this.alertWarnLv = 3;
                            this.alertWarnContent = res.msg;
                        }
                    }).catch(e => {
                        window.console.log(e);
                        this.btnDisable = false;
                        this.alertWarnLv = 3;
                        this.alertWarnContent = '网路异常请稍后重试';
                    });
                }else{
                    this.alertWarnLv = 1;
                    this.btnDisable = false;
                }
                return false
            },
            /**
             *  绑定注册/登陆
             * @returns {boolean}
             */
            logForm: function () {
                if(this.btnDisable2){
                    return false;
                }
                this.btnDisable2 = true;

                let url = '';
                if(this.logType == 1){
                    //继承绑定账号
                    url = '/inheritAccount';
                }else if(this.logType == 2){
                    //绑定手机
                    url = '/userBindMobile';
                }else{
                    return false;
                }
                //请求绑定
                let mobile = this.logFormObj.mobile;
                let code = this.logFormObj.token;

                if(this.isPhone(mobile)){
                    //判断验证码
                    if(code == '' || typeof code == 'undefined'){
                        this.alertWarnLv = 3;
                        this.alertWarnContent = '请输入验证码';
                        this.btnDisable2 = false;
                        return false;
                    }

                    this.alertWarnLv = 0;
                    this.$post(url, {
                        openId : this.$store.getters['user/getOpenId'],
                        code : code,
                        mobile :  mobile,
                    }).then((res) => {
                        if(res.code == 200){
                            //都有，那么请你去index主页候命
                            let urlType = this.$store.getters['local/getType'];
                            window.console.log(urlType);
                            if(urlType == 3){
                                window.location.href = "/personal";
                            }else if(urlType == 2){
                                window.location.href = "/sp-index";
                            }else if(urlType == 9){
                                window.location.href = this.$store.getters['local/getUrl'];
                            }else{
                                window.location.href = "/index";
                            }
                        }else{
                            this.alertWarnLv = 3;
                            this.alertWarnContent = res.msg;
                            this.btnDisable2 = false;
                        }
                    }).catch(e => {
                        this.alertWarnLv = 3;
                        this.alertWarnContent = '网路异常请稍后重试';
                        this.btnDisable2 = false;
                    });
                }else{
                    this.alertWarnLv = 1;
                    this.btnDisable = false;
                }
                return false;
            },
            /**
             * 跳过该步骤(直接跳转到首页)
             */
            jumpLog: function () {
                let data = {
                    'appId' :this.$wechat.appId,
                    'openId' : this.$store.getters['user/getOpenId'],
                };
                let that= this;
                this.$post('/getToken', data).then((res) => {
                    if(res.code == 200){
                        let token = res.data.token;
                        this.$store.state.user.token = token;
                        this.$store.commit('user/setToken', token);
                        let type = this.$store.getters['local/getType'];
                        if(type == 2){
                            that.$router.push({path :  '/sp-index'});
                        }if(type == 9){
                            let url = this.$store.getters['local/getUrl'];
                            that.$router.push({path :  url}).catch((e)=>{
                                that.$router.push({path :  url})
                            });
                        }else{
                            that.$router.push({path :  '/index'}).catch(
                                (error) => {
                                    window.console.log(error);
                                    that.$router.push({path :  '/index'});
                                }
                            );
                        }
                    }else{
                        window.console.log('验证失败');
                    }
                });
            },
            /**
             * 根据需求弹出不同的状态登陆/注册窗口
             * @param type
             */
            showTypeAlert: function (type) {
                this.$data.logType = type;
                this.$data.logShow = true
            },
            /**
             * ，关闭弹窗
             */
            clickClose: function (){
                this.logShow=false;
                this.logFormObj={
                    mobile: '',
                    token: ''
                };
            },
            // 检查字符串是否为合法手机号码
            isPhone: function (str) {
                let reg = /^(1[3-9][0-9])[0-9]{8}$/;
                if (reg.test(str)) {
                    // console.log('手机号码格式输入正确');
                    return true;
                } else {
                    // console.log('请输入正确格式的手机号码');
                    return false;
                }
            },
            // 清空
            clearTop: function(){
                setTimeout(function(){
                    var scrollHeight = document.documentElement.scrollTop || document.body.scrollTop || 0;
                    window.scrollTo(0, Math.max(scrollHeight - 1, 0));
                }, 100);
            },
            //
            callTel(){
                window.location.href = 'tel://4000002787';
            }
        },
    }
</script>

<style>
    .alert-container{
        display: flex;
    }
    .wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        padding: 0 45px;
    }

    .block {
        width: 100%;
        background-color: #fff;
    }

    /*邀请码主体*/
    .login {
        background: #e5e5e5;
        height: 100%;
    }

    .login > .banner {
        width: 100%;
        display: inherit;
    }

    .login > .container {
        width: 100%;
        padding: 0 45px;
        /* padding-top: 40px; */
        box-sizing: border-box;
    }

    .source {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        border-radius: 25px;
    }

    .source .title {
        /*font-family: "PingFangSC-Medium";*/
        width: 100%;
        background: url("https://qn.yiquan.com.cn/web/common/yz_top_bg.png") no-repeat;
        background-size: 100% 100%;
        font-size: 24px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 1px;
        color: #f93204;
        height: 160px;
        padding: 50px 0;
    }

    .source .title p {
        line-height: 34px;
        padding: 0 54px;
        text-align: left;
    }

    .source .container {
        width: 100%;
        padding: 50px 58px;
        padding-top: 20px;
        box-sizing: border-box;
        background: #fff;
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
        position: relative;
    }

    .source .title-img {
        width: 230px;
        padding-bottom: 20px;
        box-sizing: content-box;
    }

    .source .title-wh {
        position: absolute;
        width: 20px;
        height: 20px;
        padding-left: 8px;
        box-sizing: content-box;
    }

    .source .submit-content {
        width: 550px;
        height: 100px;
        line-height: 100px;
        border-radius: 10px;
        border: solid 1px #bfbfbf;
        padding-left: 63px;
        /*font-family: "PingFangSC-Medium";*/
        font-size: 30px;
        font-weight: normal;
        font-stretch: normal;
    }

    .source .submit-content::-webkit-input-placeholder {
        color: #999;
        letter-spacing: 1px;
    }

    .source .warn-info{
        text-align: left;
        font-size: 26px;
        color: #f93204;
        height: 50px;
        line-height: 50px;
        padding-left: 60px;
    }

    .source .container .login-btn {
        padding-bottom: 60px;
        float: right;
    }

    .source .container .login-btn:after {
        clear: both;
    }

    .source .container .login-btn > p {
        border-bottom: 1px solid #bfbfbf;
        padding: 0 24px;
        padding-bottom: 8px;
        /*font-family: "PingFangSC-Medium";*/
        font-size: 26px;
        font-weight: normal;
        font-stretch: normal;
        color: #8c8c8c;
        display: block;
    }

    /*问号弹窗主体*/
    .info-block {
        width: 100%;
        position: relative;
    }

    .info-block .close-btn {
        position: absolute;
        right: 24px;
        top: 24px;
        width: 36px;
        height: 36px;
        color: #fff;
    }

    .info-block > img {
        width: 100%;
        display: block;
    }

    .info-block .info-content {
        background: #fff;
        width: 100%;
        padding: 0 55px;
        padding-bottom: 30px;
        text-align: left;
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;
    }

    .info-block .info-content h3 {
        padding-top: 23px;
        padding-bottom: 20px;
        /*font-family: "PingFangSC-Bold";*/
        font-size: 30px;
        font-weight: normal;
        font-stretch: normal;
        color: #565656;
    }

    .info-block .info-content p {
        /*font-family: "PingFangSC-Medium";*/
        font-size: 26px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 40px;
        letter-spacing: 1px;
        color: #919191;
    }

    .info-block .info-content .mobile {
        border-radius: 10px;
        border: solid 1px #bfbfbf;
        height: 68px;
        width: 98%;
        margin: 0 auto;
        margin-top: 36px;
        padding: 0 24px;
        text-align: left;
        line-height: 68px;
        font-size: 24px;
        /*font-family: "PingFangSC-Medium";*/
        letter-spacing: 1px;
    }

    .info-block .info-content .mobile-content {
        color: #919191;
    }

    .info-block .info-content .click-btn {
        color: #1b8fef;
        float: right;
    }

    /*注册/登陆弹窗主体*/
    .log-block {
        width: 100%;
        position: relative;
        background: #fff;
        border-radius: 25px;
        padding: 64px 54px;
        padding-bottom: 35px;
    }

    .log-block .close-btn {
        position: absolute;
        right: 24px;
        top: 24px;
        width: 36px;
        height: 36px;
        color: #000;
    }

    .log-block .title {
        /*font-family: "PingFangSC-Bold";*/
        font-size: 48px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 1px;
        color: #333333;
        text-align: left;
    }

    .log-block .submit-container {
        margin-top: 75px;
    }

    .log-block .submit-container .form-input {
        border-bottom: 1px solid #e3e3e3;
        padding: 40px 0;
        padding-left: 100px;
        position: relative;
        font-size: 30px;
        color: #4a4a4a;
    }

    .log-block .submit-container .form-input img {
        width: 33px;
        height: 44px;
        position: absolute;
        margin: auto 0;
        top: 0;
        bottom: 0;
        left: 34px;
    }

    .log-block .submit-container .form-input input {
        border: none;
        height: 44px;
        line-height: normal;
        line-height: 44px;
        width: 250px;
        margin: auto 0;
        display: block;
    }

    .log-block .submit-container .form-input input::-webkit-input-placeholder {
        color: #999;
    }

    .log-block .submit-container .form-input input.mobile-container {
        position: relative;
    }

    .log-block .submit-container .form-input .mobile-btn {
        position: absolute;
        /*font-family: "PingFangSC-Medium";*/
        color: #1b8fef;
        top: 0;
        bottom: 0;
        margin: auto 0;
        right: 0;
        height: 53px;
        border-radius: 10px;
        border: solid 1px #1b8fef;
        text-align: center;
        line-height: 62px;
        font-size: 22px;
        padding: 4px 25px;
        box-sizing: content-box;
        min-width: 110px;
    }

    .log-block .submit-container .form-input .mobile-btn::before {
        border-radius: 15px;
    }

    .log-block .submit-container .submit-btn {
        margin-top: 60px;
    }

    .log-block .submit-container .warn-info{
        text-align: left;
        font-size: 26px;
        color: #f93204;
        height: 50px;
        line-height: 50px;
        padding-left: 60px;
    }

    .log-block .jump-btn {
        margin-top: 25px;
        /*font-family: "PingFangSC-Medium";*/
        font-size: 24px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 1px;
        color: #999999;
    }

    .log-block .jump-btn span {
        padding: 20px 20px 0 20px;
        display: inline-block;
        float: right;
    }
    .overlay-div{
        position: fixed;
    }
    .overlay-div .load{
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
    .overlay-div .load .van-loading__spinner{
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
    /*five*/
    .login.five-bg{
        /* background: url("http://qn.yiquan.com.cn/web/spring2021/login-five-bg.jpg") no-repeat fixed top; */
        background: url("http://qn.yiquan.com.cn/web/202106/7M-shouye1.jpg") no-repeat fixed top;
        background-size:100%;
    }
    .login .container  .submit-btn.five-btn{
        background-image: linear-gradient(-51deg,
        #ff4200 0%,
        #ff2727 100%),
        linear-gradient(
                #1b8fef,
                #1b8fef);
        background-blend-mode: normal,
        normal;
        box-shadow: 0px 11px 40px 0px rgba(229, 26, 4, 0.3);
        border: 1px solid #ff4200;
    }

    .log-block .submit-container .submit-btn.five-btn {
        background-image: linear-gradient(-51deg,
        #ff4200 0%,
        #ff2727 100%),
        linear-gradient(
                #1b8fef,
                #1b8fef);
        background-blend-mode: normal,
        normal;
        box-shadow: 0px 11px 40px 0px rgba(229, 26, 4, 0.3);
        border: 1px solid #ff4200;
    }

    .log-block .submit-container .form-input .mobile-btn.five-btn{
        border: solid 1px #ff4200;
        color: #ff4200;
    }
</style>
