<template>
    <div class="home">
        <div class="loginframe">
            <div class="loginbg"></div>
            <div class="login">
                <div id="errorMsg-email" class="erro_email" style="display:none"><label id="email-error" class="error" for="email">请输入正确的邮箱或手机号码</label></div>
                <div id="errorMsg-password" class="erro_email" style="display:none"><label id="email-error" class="error" for="email">密码必须大于6</label></div>
                <div class="logo"><img src="http://api.yiquan.com.cn/static/index/demo_logo.png"></div>
                <div class="code"><img src="http://api.yiquan.com.cn/static/index/code_02.jpg"></div>
            </div>
        </div>
        <div class="bottomframe">
            <div class="bottom_left">
                <p class="size-3" style="color:#fff">一圈</p>
                版权所有 2016-2022  未经同意请勿转载<br>
                <a style="color:#a1a1a1" href="https://beian.miit.gov.cn/">沪ICP备15043757号</a><br>
                COPYRIGHT 2009. 一圈 CO.,LTD,CHINA ALL RIGHT RESERVED.<br>
                <a style="color:#a1a1a1" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502011932">公安部备案号：31011502011932</a>
            </div>
            <div class="bottom_middle">
                <div class="about">
                    <p size="3" style="color:#fff;">关注我们</p>
                    <div class="code-img">
                        <img src="http://api.yiquan.com.cn/static/index/code_02.jpg">
                    </div>
                    <!-- <a href="#" class="icon_sc">收藏网站</a> -->
                </div>
            </div>
            <div class="bottom_right">
                <p class="size-3">全国统一客服热线：</p>
                <span class="telphone"><img src="http://api.yiquan.com.cn/static/index/demo_05.png">400-000-2787</span><br>
                <p class="size-3">客服邮箱：</p>
                <p class="size-2">service@yiquan.com.cn</p>
            </div>
        </div>
        <link rel="stylesheet" href="http://api.yiquan.com.cn/static/index/css/index.css" type="text/css">
    </div>
</template>

<script>
    import vue from 'vue';
    export default {
        name: 'home',
        data() {
            return {
            }
        },
        created: function () {
        },
        methods: {
        },
    }
</script>
<style>
    .home .login{
        width: auto;
        background: transparent;
    }
</style>
