<template>
    <div class="litter-cardetail">
        <div class="top-bar">
            <div class="bar-item" v-for="(item, index) in list" :key="index" @click="navToBar(index)">
                <span v-if="barIndex == index" class="active">{{item.NAME}}</span>
                <span v-else>{{item.NAME}}</span>
            </div>
        </div>
        <div class="container" :class="padbottom">
            <div class="item" v-for="(item, index) in list" :key="index" :id="index">
                <label v-if="item.TYPE == 1">
                    <img class="item-img" :src="item.content">
                </label>
                <label v-else>
                    <div :id="index+'content'"></div>
<!--                    {{item.content}}-->
                </label>
            </div>
        </div>

        <div class="bottom-order-btn" v-if="info.JUMPSTATUS == 1" style="transform: translateZ(0);-webkit-transform: translateZ(0);">
            <div class="order-btn" @click="alertLocalPop">价格查询</div>
        </div>

        <van-popup v-model="localAlert" position="bottom" safe-area-inset-bottom>
            <div class="local-alert">
                <p class="alert-title">请选择城市</p>
                <div class="cell-container area-cell" :class="hasLocalCss">
                    <van-cell is-link @click="showLocalPopup" :value="localVal"/>
                    <img src="/images/icon_area.png">
                </div>
                <div class="bottom-order-btn bottom-order-btn-2" style="transform: translateZ(0);-webkit-transform: translateZ(0);">
                    <div class="order-btn" @click="alertOrder">确定</div>
                </div>
            </div>
        </van-popup>

        <van-popup v-model="showPicker" position="bottom" :close-on-click-overlay="false" safe-area-inset-bottom>
            <van-picker
                    title="请选择所在城市"
                    show-toolbar
                    :columns="columns"
                    value-key = "content"
                    @change="onLocalChange"
                    @cancel="showPicker = false"
                    @confirm="onLocalConfirm"
            ></van-picker>
        </van-popup>

        <van-overlay :show="loadShow" class="overlay-div">
            <van-loading type="spinner" class="load"/>
        </van-overlay>
    </div>
</template>
<script>
    import vue from 'vue';
    import {Button, Dialog } from 'vant';

    vue.use(Button, Dialog);
    let citys = {};
    export default {
        name: 'litter-cardetail',
        created: function(){
            this.loadShow = true;
            let query = this.$route.query;
            if (typeof query.id == 'undefined') {
                this.$router.push({
                    path : '/litter/topf'
                });
            }
            let booksCarId = query.id;
            this.$post('/booksCarDetail', {
                'openId' : this.$store.getters['user/getOpenId'],
                'booksCarId' : booksCarId,
            }).then((res)=> {
                if(res.code == 200){
                    let data = res.data;
                    this.list = data['detail'];
                    this.info = data['info'];
                    if(data['info']['JUMPSTATUS'] == 1){
                        this.padbottom = 'padbottom';
                    }
                }
            }).then((res) => {
                let list = this.list;
                let len = list.length;
                if(len <= 0){
                    Dialog.alert({
                        message: '暂无相关车系信息'
                    }).then(() => {
                        // on close
                        this.$router.push({
                            path : '/books/index'
                        });
                    });
                    return false;
                }
                for (var i = 0; i < len ; i++){
                    if(list[i]['TYPE'] == 2){
                        $('#'+i+'content').html(list[i]['content']);
                    }
                }
            }).then((res) => {
                $('.tb2-lb').click(function(e){
                let ids = e.target.dataset.ids;
                let dom = $('.'+ids);
                if(dom.hasClass('zk')){
                    $('.'+ids).removeClass('zk');
                }else {
                    $('.'+ids).addClass('zk');
                }
            });
            });

            //获取城市信息
            this.$post('/getCityList', {
            }).then((res) => {
                if(res.code == 200){
                    citys = res.data;
                    this.citys = citys;
                    this.columns = [
                        {
                            values: citys,
                            className: 'column1',
                        },
                        {
                            values: citys[0]['list'],
                            className: 'column2',
                        }
                    ];

                    let brandLoacl = this.$store.getters['local/getBrandLocal'];
                    if(brandLoacl.length > 0){
                        let brandKey = this.$store.getters['local/getBrandKey'];
                        this.onLocalConfirm(JSON.parse(brandLoacl), JSON.parse(brandKey));
                    }
                }else{
                    Dialog.alert({
                        message: res.msg
                    }).then(() => {
                        // on close
                    });
                }
            }).catch(
                (e) =>{
                    window.console.log(e);
                }
            );

            //临时处理获取token
            let data = {
                'appId' :this.$wechat.appId,
                'openId' : this.$store.getters['user/getOpenId'],
            };
            this.$post('/getToken', data).then((res) => {
                if(res.code == 200){
                    let token = res.data.token;
                    this.$store.commit('user/setToken', token);
                    this.loadShow = false;
                }else{
                    window.console.log('验证失败');
                }
            });
        },
        mounted: function(){
            $('.tb2-lb').click(function(e){
                let ids = e.target.dataset.ids;
                let dom = $('.'+ids);
                if(dom.hasClass('zk')){
                    $('.'+ids).removeClass('zk');
                }else {
                    $('.'+ids).addClass('zk');
                }
            });
            window.addEventListener('scroll', this.scrollTop);

            window.addEventListener('onload', this.winOnLoad);
        },
        data() {
            return {
                loadShow : false,
                barIndex : 0,
                info : {
                    'JUMPSTATUS' : 0,
                },
                list : [],
                scrollTimer : '',
                padbottom : '',
                showPicker : false,
                localAlert : false,
                citys : citys,
                columns: [
                    {
                        values: Object.keys(citys),
                    },
                    {
                        values: '',
                        className: 'column2',
                        defaultIndex: 2
                    }
                ],
                localVal : "请选择您所在城市",
                localVals : [],
                hasLocalCss : '',
            }
        },
        methods: {
            scrollTop(){
                var winPos = $(window).scrollTop(); //屏幕位置
                let len = this.list.length;
                clearTimeout(this.scrollTimer);
                this.scrollTimer = setTimeout(() => {
                    // todo something scroll end
                    //变量scrollTop是滚动条滚动时，距离顶部的距离
                    var scrollTop = document.documentElement.scrollTop||document.body.scrollTop;
                    //变量windowHeight是可视区的高度
                    var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
                    //变量scrollHeight是滚动条的总高度
                    var scrollHeight = document.documentElement.scrollHeight||document.body.scrollHeight;
                    //滚动条到底部的条件
                    if(scrollTop+windowHeight==scrollHeight){
                        //写后台加载数据的函数
                        this.barIndex = len - 1;
                    }else{
                        if(len > 0){
                            let index = 0;
                            for (var i =0; i < len; i++){
                                let high = $('#'+i).offset().top;
                                if(winPos > high-56){
                                    index = i;
                                }else{
                                    break;
                                }
                            }
                            this.barIndex = index;
                        }
                    }
                }, 320);
            },
            alertOrder(){
                // this.$store.commit('local/setProvinceId', 25);
                // this.$store.commit('local/setCityId', 2501);
                // this.$store.commit('local/setLocal', '上海-上海市');
                if(this.localVals.length <= 0 || (typeof this.localVals[0]['id'] == 'undefined') || (typeof this.localVals[1]['id'] == 'undefined')){
                    Dialog.alert({
                        message: '请选择所在城市'
                    }).then(() => {
                        // on close
                    });
                    return false;
                }
                let provinceId = this.localVals[0]['id'];
                let cityId = this.localVals[1]['id'];

                this.$store.commit('local/setProvinceId', provinceId);
                this.$store.commit('local/setCityId', cityId);
                this.$store.commit('local/setLocal', this.localVal);

                this.$store.commit('user/setFirst2', true);//过度处理

                // window.location.href = '/model?markId='+ this.info.MARKID +'&type='+ this.info.TYPENAME+'&source=book' ;
                window.location.href = '/model?markId='+ this.info.MARKID +'&type='+ this.info.TYPENAME+'&source=book&alert=TYalert2';

                return false;
            },
            /**
             * 地址选择变化
             */
            onLocalChange(picker) {
                let indexs = picker.getIndexes();
                picker.setColumnValues(1, this.citys[indexs[0]]['list']);
            },
            /**
             * 地址选择确认
             */
            onLocalConfirm(value, key){
                this.localVal = value[0]['content'] + '-' + value[1]['content'];
                this.localVals = value;
                let valStr = JSON.stringify(value);
                this.$store.commit('local/setBrandLocal', valStr);
                this.$store.commit('local/setBrandKey', JSON.stringify(key));
                this.hasLocalCss = "hasLocalCss";
                this.$data.showPicker = false;
            },
            /**
             * 展示地址底部弹窗
             */
            showLocalPopup(){
                this.$data.showPicker = true;
            },
            alertLocalPop(){
                this.$data.localAlert = true;
            },
            navToBar(index){
                let item = $('#'+index);
                $("html, body").animate({
                    scrollTop: item.offset().top-55
                }, {duration: 200,easing: "swing"});
                setTimeout(() => {
                    // todo something scroll end
                    clearTimeout(this.scrollTimer);
                    this.barIndex = index;
                }, 300);
            }
        }
    }
</script>

<style>
    /*主体*/
    .litter-cardetail {
        background: #d4d4d4;
        width: 100%;
        overflow-x: hidden;
        position: relative;
        padding-top: 100px;
    }

    .litter-cardetail .top-bar{
        z-index: 2;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100px;
        line-height: 100px;
        background: #fff;
        color: #999999;
        font-size: 30px;
        text-align: left;
        white-space: nowrap;
        overflow-x: scroll;
        overflow-scrolling: touch;
        border-bottom: 1px solid #d4d4d4;
    }

    .litter-cardetail .top-bar .bar-item{
        display: inline-block;
        padding: 0 20px;
    }

    .litter-cardetail .top-bar .bar-item:first-child{
        padding-left: 30px;
    }

    .litter-cardetail .top-bar .bar-item:last-child{
        padding-right: 30px;
    }

    ::-webkit-scrollbar{
    display: none;
        /* background-color:transparent; */
    }

    .litter-cardetail .top-bar .bar-item .active{
        color: #333333;
        font-weight: bold;
    }

    .litter-cardetail .container,litter-cardetail .container .item{
        width: 100%;
    }

    .litter-cardetail .container.padbottom{
        padding-bottom: 110px;
    }

    .litter-cardetail .container .item-img{
        width: 100%;
    }

    .litter-cardetail .bottom-order-btn{
        z-index: 20;
        width: 100%;
        height: 100px;
        line-height: 100px;
        font-size: 30px;
        position: fixed;
        bottom: 0;
        left: 0;
        text-align: center;
        color: #fff;
        background-image: linear-gradient(90deg,
        #ff5236 0%,
        #ff1700 100%),
        linear-gradient(
                #ff292d,
                #ff292d);
    }
    .litter-cardetail .local-alert{
        background: #fff;
        padding-top: 34px;
    }

    .litter-cardetail .local-alert .alert-title{
        font-size: 30px;
        letter-spacing: 3px;
        color: #282828;
    }

    .litter-cardetail .local-alert .cell-container{
        height: 100px;
        margin-top: 88px;
        box-sizing: content-box;
        position: relative;
        padding: 0 30px;
    }
    .litter-cardetail .local-alert .area-cell{
        border-bottom: none;
    }
    .litter-cardetail .local-alert .van-cell{
        height: 100px;
        padding-left: 100px;
        border: solid 1px #d2d2d2;
    }
    .litter-cardetail .local-alert .van-cell .van-cell__value{
        color: #979797;
        margin: auto;
    }
    .litter-cardetail .local-alert .van-cell__right-icon{
        margin: auto;
    }
    .litter-cardetail .local-alert .hasLocalCss .van-cell__value{
        color: #000;
    }
    .litter-cardetail .local-alert .cell-container img{
        position: absolute;
        left: 68px;
        top: 0;
        bottom: 0;
        margin: auto 0;
        width: 40px;
        height: 40px;
    }
    .litter-cardetail .local-alert .bottom-order-btn-2{
        margin-top: 99px;
        position: relative;
    }
</style>
