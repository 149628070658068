import Vue from 'vue'
import Router from 'vue-router'

import login from '@/pages/index/login.vue'
import index2 from '@/pages/index/index.vue'
import brandList from '@/pages/car/brand.vue'
import carSeries from '@/pages/car/series.vue'
import carSeries2 from '@/pages/car/series2.vue'
import carScreen from '@/pages/car/screen.vue'
import carModel from '@/pages/car/model.vue'
import carDetail from '@/pages/car/detail.vue'
import car360 from '@/pages/car/360car.vue'
import orderSelection from '@/pages/order/selection.vue'
import orderConfirm from '@/pages/order/confirm.vue'
import author from '@/pages/index/author.vue'
import orderList from '@/pages/order/list.vue'
import orderDetail from '@/pages/order/detail.vue'
import orderLoan from '@/pages/order/loan.vue'
import personal from '@/pages/personal/index.vue'
import personalInfo from '@/pages/personal/info.vue'
import custinfo from '@/pages/personal/custinfo.vue'
import cardFile from '@/pages/personal/cardfile.vue'
import feedback from '@/pages/personal/feedback.vue'
import coupon from '@/pages/personal/coupon.vue'
import SPCarLocal from '@/pages/spcar/local.vue'
import SPCarSeries from '@/pages/spcar/series.vue'
import SPCarDetail from '@/pages/spcar/detail.vue'
import blank from '@/pages/act/blank.vue'
import clearPath from '@/pages/index/clearPath.vue'
//=====支付订单================================
import payInfo from '@/pages/order/pay.vue'
import deposit from '@/pages/order/deposit.vue'

//=====活动页面================================
import hd20201111vl from '@/pages/act/20201111vl.vue' //双11微蓝活动
import hd20201231xny from '@/pages/act/20201231xny.vue' //新能源活动
import hd20210309yd from '@/pages/act/20210309yd.vue' //中国移动领500红包活动
import hd20210716hd from '@/pages/act/20210716hd.vue' //开播红包活动
import livehd from '@/pages/act/livehd.vue' //开播红包活动
import act20221012 from '@/pages/act/20221012.vue' //22年10中远活动
import act20221013 from '@/pages/act/20221013.vue' //22年10中远活动-2
import act20230508 from '@/pages/act/20230508.vue' //23年试驾
import act20230923 from '@/pages/act/20230923.vue' //23年9月活动
import act20240402 from '@/pages/act/20240402.vue' //24年4月活动
import act20240619 from '@/pages/act/20240619.vue' //24年6月活动

//=====临时页面 无需验证=========================
import dy202010 from '@/pages/act/20201030.vue' //双11 活动宣传页
import mini20201111vl from '@/pages/act/20201111vl2.vue' //小程序 双11活动
import dy20201119 from '@/pages/act/20201119.vue' //双11活动结束页面

//=====电子书===================================
import booksIndex from '@/pages/books/index.vue'
import booksCarList from '@/pages/books/list.vue'
import booksCarDetail from '@/pages/books/detail.vue'
import booksIndex2 from '@/pages/books/index2.vue'
import booksIndex3 from '@/pages/books/index3.vue' //保利集团
import neocar from '@/pages/books/neocar.vue' //新能源
//=====书信=====================================
import pflitter from '@/pages/litter/pflitter'
import pflitterList from '@/pages/litter/list.vue'
import pflitterDetail from '@/pages/litter/detail.vue'
import glitter from '@/pages/litter/glitter'
import elitter from '@/pages/litter/eight.vue'
//======优惠券==================================
import testCoupon from '@/pages/coupon/20200410' //测试领取页面
import c20230206 from '@/pages/coupon/20230206' //230206 限时福利 2000元购车红包
//======PC======================================
import pcHome from '@/pages/index/home.vue'
import jump from '@/pages/index/jump.vue'

Vue.use(Router);

export default new Router({
    routes: [
        {
            path: '/home',
            name: 'home',
            component: pcHome,
            children: [
                {path: '*', name: 'home', component: pcHome}
            ],
            beforeEnter: (to, from, next) => {
                //动态修改title
                document.getElementById('titleId').innerHTML = '一圈';
                next();
            }
        },
        {
            path: '/index',
            name: 'index',
            component: index2,
            children: [
                {path: '*', name: 'brand', component: index2}
            ],
            beforeEnter: (to, from, next) => {
                //动态修改title
                document.getElementById('titleId').innerHTML = '一圈';
                next();
            }
        },
        {
            path: '/series',
            name: 'series',
            component: carSeries2,
            children: [
                {path : '*', name : 'series'}
            ],
            beforeEnter: (to, from, next) => {
                //动态修改title
                document.getElementById('titleId').innerHTML = '车系';
                next();
            }
        },
        {
            path: '/carScreen',
            name: 'carScreen',
            component: carScreen,
            children: [
                {path : '*', name : 'carScreen'}
            ],
            beforeEnter: (to, from, next) => {
                //动态修改title
                document.getElementById('titleId').innerHTML = '车系';
                next();
            }
        },
        {
            path: '/model',
            name: 'model',
            component: carModel,
            children: [
                {path : '*', name : 'model'}
            ],
            beforeEnter: (to, from, next) => {
                //动态修改title
                document.getElementById('titleId').innerHTML = '车型';
                next();
            }
        },
        {
            path: '/detail',
            name: 'detail',
            component: carDetail,
            children: [
                {path : '*', name : 'detail'}
            ],
            beforeEnter: (to, from, next) => {
                //动态修改title
                document.getElementById('titleId').innerHTML = '车辆详情';
                next();
            }
        },
        {
            path: '/selection',
            name: 'selection',
            component: orderSelection,
            children: [
                {path: '*', name: 'brand'}
            ],
            beforeEnter: (to, from, next) => {
                //动态修改title
                document.getElementById('titleId').innerHTML = '详情选择';
                next();
            }
        },
        {
            path: '/confirm',
            name: 'confirm',
            component: orderConfirm,
            children: [
                {path: '*', name: 'confirm'}
            ],
            beforeEnter: (to, from, next) => {
                //动态修改title
                document.getElementById('titleId').innerHTML = '填写申请资料';
                next();
            }
        },
        //订单
        {
            path: '/orderList',
            name: 'orderList',
            component: orderList,
            children: [
                {path: '*', name: 'orderList'}
            ],
            beforeEnter: (to, from, next) => {
                //动态修改title
                document.getElementById('titleId').innerHTML = '我的订单';
                next();
            }
        },
        {
            path: '/deposit',
            name: 'deposit',
            component: deposit,
            children: [
                {path: '*', name: 'deposit'}
            ],
            beforeEnter: (to, from, next) => {
                //动态修改title
                document.getElementById('titleId').innerHTML = '已支付定金';
                next();
            }
        },
        {
            path: '/orderDetail',
            name: 'orderDetail',
            component: orderDetail,
            children: [
                {path: '*', name: 'orderDetail'}
            ],
            beforeEnter: (to, from, next) => {
                //动态修改title
                document.getElementById('titleId').innerHTML = '订单详情';
                next();
            }
        },
        {
            path: '/orderLoan',
            name: 'orderLoan',
            component: orderLoan,
            children: [
                {path: '*', name: 'orderLoan'}
            ],
            beforeEnter: (to, from, next) => {
                //动态修改title
                document.getElementById('titleId').innerHTML = '贷款方案';
                next();
            }
        },
        //商品订单

        {
            path: '/payInfo',
            name: 'pay',
            component: payInfo,
            children: [
                {path: '*', name: 'payInfo'}
            ],
            beforeEnter: (to, from, next) => {
                //动态修改title
                document.getElementById('titleId').innerHTML = '订单详情';
                next();
            }
        },
        //个人中心
        {
            path: '/personal',
            name: 'personal',
            component: personal,
            children: [
                {path: '*', name: 'personal'}
            ],
            beforeEnter: (to, from, next) => {
                //动态修改title
                document.getElementById('titleId').innerHTML = '个人中心';
                next();
            }
        },
        {
            path: '/userinfo',
            name: 'personalInfo',
            component: personalInfo,
            children: [
                {path: '*', name: 'personalInfo'}
            ],
            beforeEnter: (to, from, next) => {
                //动态修改title
                document.getElementById('titleId').innerHTML = '个人信息';
                next();
            }
        },
        {
            path: '/custinfo',
            name: 'custinfo',
            component: custinfo,
            children: [
                {path: '*', name: 'custinfo'}
            ],
            beforeEnter: (to, from, next) => {
                //动态修改title
                document.getElementById('titleId').innerHTML = '客户属性';
                next();
            }
        },
        {
            path: '/cardfile',
            name: 'cardfile',
            component: cardFile,
            children: [
                {path: '*', name: 'cardfile'}
            ],
            beforeEnter: (to, from, next) => {
                //动态修改title
                document.getElementById('titleId').innerHTML = '个人信息';
                next();
            }
        },
        {
            path: '/feedback',
            name: 'feedback',
            component: feedback,
            children: [
                {path: '*', name: 'feedback'}
            ],
            beforeEnter: (to, from, next) => {
                //动态修改title
                document.getElementById('titleId').innerHTML = '意见反馈';
                next();
            }
        },
        //优惠券
        {
            path: '/coupon',
            name: 'coupon',
            component: coupon,
            children: [
                {path: '*', name: 'coupon'}
            ],
            beforeEnter: (to, from, next) => {
                //动态修改title
                document.getElementById('titleId').innerHTML = '我的优惠券';
                next();
            }
        },
        //特价车
        {
            path: '/sp-index',
            name: 'sp-index',
            component: SPCarLocal,
            children: [
                {path: '*', name: 'sp-index'}
            ],
            beforeEnter: (to, from, next) => {
                //动态修改title
                document.getElementById('titleId').innerHTML = '特价车';
                next();
            }
        },
        {
            path: '/sp-series',
            name: 'sp-series',
            component: SPCarSeries,
            children: [
                {path: '*', name: 'sp-series'}
            ],
            beforeEnter: (to, from, next) => {
                //动态修改title
                document.getElementById('titleId').innerHTML = '特价车';
                next();
            }
        },
        {
            path: '/sp-detail',
            name: 'sp-detail',
            component: SPCarDetail,
            children: [
                {path: '*', name: 'sp-detail'}
            ],
            beforeEnter: (to, from, next) => {
                //动态修改title
                document.getElementById('titleId').innerHTML = '特价车';
                next();
            }
        },
        {
            path: '/login',
            name: 'login',
            component: login,
            children: [
                {path: '*'}
            ],
        },
        {
            path: '/author',
            name: 'author',
            component: author,
            children: [
                {path: '*'}
            ],
        },
        //中间夹层页（识别身份用）
        {
            path: '/middle',
            name: 'middle',
            component: blank,
            children: [
                {path : '*'}
            ],
        },
		{
            path: '/car/car360', //360度看车
            name: 'car360',
            component: car360,
                beforeEnter: (to, from, next) => {
                //动态修改title
                document.getElementById('titleId').innerHTML = '360度看车';
                next();
            }
		},
		//=====活动页面================================
		{
			path: '/act/20201111vl',  //双11 微蓝活动
			name: 'hd20201111vl',
			component: hd20201111vl,
			beforeEnter: (to, from, next) => {
				//动态修改title
				document.getElementById('titleId').innerHTML = '双11活动';
				next();
			}
		},
		{
			path: '/act/20201231xny', //新能源活动
			name: 'hd20201231xny',
			component: hd20201231xny,
			beforeEnter: (to, from, next) => {
				//动态修改title
				document.getElementById('titleId').innerHTML = '新能源';
				next();
			}
		},
		{
			path: '/act/hd20210309yd',
			name: 'hd20210309yd',
			component: hd20210309yd,
			beforeEnter: (to, from, next) => {
				//动态修改title
				document.getElementById('titleId').innerHTML = '中国移动领红包活动';
				next();
			}
		},
		{
			path: '/act/hd20210716hd',
			name: 'hd20210716hd',
			component: hd20210716hd,
			beforeEnter: (to, from, next) => {
				//动态修改title
				document.getElementById('titleId').innerHTML = '领红包活动';
				next();
			}
		},
		{
			path: '/act/livehd',
			name: 'livehd',
			component: livehd,
			beforeEnter: (to, from, next) => {
				//动态修改title
				document.getElementById('titleId').innerHTML = '直播间';
				next();
			}
		},
		{
			path: '/act/mini20201111vl', //小程序 双11活动
			name: 'mini20201111vl',
			component: mini20201111vl,
			beforeEnter: (to, from, next) => {
				//动态修改title
				document.getElementById('titleId').innerHTML = '双11活动';
				next();
			}
		},
		{
			path: '/act/dy202010', //双11 活动宣传页
			name: 'dy202010',
			component: dy202010,
			beforeEnter: (to, from, next) => {
				//动态修改title
				document.getElementById('titleId').innerHTML = '双十一新能源特别优惠活动';
				next();
			}
		},
		{
			path: '/act/dy20201119',
			name: 'dy20201119',
			component: dy20201119,
			beforeEnter: (to, from, next) => {
				//动态修改title
				document.getElementById('titleId').innerHTML = '双十一';
				next();
			}
		},
        {
            path: '/pact20221012',
            name: 'act20221012',
            component: act20221012,
            beforeEnter: (to, from, next) => {
                //动态修改title
                document.getElementById('titleId').innerHTML = '中远重工车展活动';
                next();
            }
        },
        {
            path: '/pact20221013',
            name: 'act20221013',
            component: act20221013,
            beforeEnter: (to, from, next) => {
                //动态修改title
                document.getElementById('titleId').innerHTML = '中远重工车展活动';
                next();
            }
        },
        {
            path: '/pact20230508',
            name: 'act20230508',
            component: act20230508,
            beforeEnter: (to, from, next) => {
                //动态修改title
                document.getElementById('titleId').innerHTML = '试驾有礼';
                next();
            }
        },
        {
            path: '/pact20230923',
            name: 'act20230923',
            component: act20230923,
            beforeEnter: (to, from, next) => {
                //动态修改title
                document.getElementById('titleId').innerHTML = '中国远洋海运员工购车全国巡回路演活动';
                next();
            }
        },
        {
            path: '/pact20240402',
            name: 'act20240402',
            component: act20240402,
            beforeEnter: (to, from, next) => {
                //动态修改title
                document.getElementById('titleId').innerHTML = '小米优先试驾活动';
                next();
            }
        },
        {
            path: '/pact20240619',
            name: 'act20240619',
            component: act20240619,
            beforeEnter: (to, from, next) => {
                //动态修改title
                document.getElementById('titleId').innerHTML = '中远海运员工优惠购车活动';
                next();
            }
        },
        //=======电子书========================================
        {
            path: '/books/index',
            name: 'booksIndex',
            component: booksIndex,
            beforeEnter: (to, from, next) => {
                //动态修改title
                document.getElementById('titleId').innerHTML = '上汽通用产品手册';
                next();
            }
        },
        {
            path: '/books/carlist',
            name: 'booksCarList',
            component: booksCarList,
            beforeEnter: (to, from, next) => {
                //动态修改title
                document.getElementById('titleId').innerHTML = '上汽通用产品手册';
                next();
            }
        },
        {
            path: '/books/cardetail',
            name: 'booksCarDetail',
            component: booksCarDetail,
            beforeEnter: (to, from, next) => {
                //动态修改title
                document.getElementById('titleId').innerHTML = '上汽通用产品手册';
                next();
            }
        },
        {
            path: '/books/index2',
            name: 'booksIndex2',
            component: booksIndex2,
            beforeEnter: (to, from, next) => {
                //动态修改title
                document.getElementById('titleId').innerHTML = '上汽通用产品手册';
                next();
            }
            },
        {
            path: '/books/index3',
            name: 'booksIndex3',
            component: booksIndex3,
            beforeEnter: (to, from, next) => {
                //动态修改title
                document.getElementById('titleId').innerHTML = '上汽通用产品手册';
                next();
            }
        },
        {
            path: '/books/neocar',
            name: 'neocar',
            component: neocar,
            beforeEnter: (to, from, next) => {
                //动态修改title
                document.getElementById('titleId').innerHTML = '新能源汽车';
                next();
            }
        },
		
        //=======信========================================
        {
            path : '/litter/topf',
            name: 'pflitter',
            component: pflitter,
            beforeEnter: (to, from, next) => {
                //动态修改title
                next();
            }
        },
        {
            path : '/litter/carlist',
            name: 'pflitter',
            component: pflitterList,
            beforeEnter: (to, from, next) => {
                //动态修改title
                document.getElementById('titleId').innerHTML = '上汽通用产品手册';
                next();
            }
        },
        {
            path : '/litter/cardetail',
            name: 'pflitter',
            component: pflitterDetail,
            beforeEnter: (to, from, next) => {
                //动态修改title
                document.getElementById('titleId').innerHTML = '上汽通用产品手册';
                next();
            }
        },
        {
            path : '/litter/gratitude',
            name: 'glitter',
            component: glitter,
            beforeEnter: (to, from, next) => {
                //动态修改title
                document.getElementById('titleId').innerHTML = '五周年感恩回馈';
                next();
            }
        },
        {
            path : '/litter/fiveth',
            name: 'elitter',
            component: elitter,
            beforeEnter: (to, from, next) => {
                //动态修改title
                document.getElementById('titleId').innerHTML = '欢迎信';
                next();
            }
        },
        //=======优惠券========================================
        {
            path : '/act/getTestCoupon',
            name: 'testCoupon',
            component: testCoupon,
            beforeEnter: (to, from, next) => {
                //动态修改title
                document.getElementById('titleId').innerHTML = '欢迎信';
                next();
            }
        },
        {
            path : '/act/20230206t',
            name: '20230206t',
            component: c20230206,
            beforeEnter: (to, from, next) => {
                //动态修改title
                document.getElementById('titleId').innerHTML = '限时福利';
                next();
            }
        },

        //=======跳转========================================
        {
            path: '/t/:code',
            name: 'jump',
            component: jump,
            beforeEnter: (to, from, next) => {
                //动态修改title
                document.getElementById('titleId').innerHTML = '一圈';
                next();
            }
        },{
            path: '/eRhBd',
            name: 'clearPath',
            component: clearPath,
            beforeEnter: (to, from, next) => {
                //动态修改title
                document.getElementById('titleId').innerHTML = '一圈';
                next();
            }
        },
        {path: '*', redirect: '/index'}
    ],
    mode: "history",
    base: process.env.VUE_APP_SRC,
})