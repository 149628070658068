<template class="act221012">
    <div class="con" id="act221012">
        <div class="bg">
            <img class="img" src="https://qn.yiquan.com.cn/act/20221012/1019/page-1.jpg" />
        </div>
        <div class="bg">
            <img class="img" src="https://qn.yiquan.com.cn/act/20221012/1019/page-2.jpg" />
            <!--提交订单弹窗-->
            <div class="popup-box">
                <label  v-if="pageType == 1">
                    <img  class="img" src="https://qn.yiquan.com.cn/act/20221012/alert-bg.png" />
                    <div class="inpu-form">
                    <div class="item-input-item">
                        <div class="right-box right-box-2">
                            <input v-model="orderFormObj.name" :rules="[{ required: true, message: '姓名' }]" placeholder="姓名">
                        </div>
                    </div>
                    <div class="item-input-item">
                        <div class="right-box right-box-2">
                            <input  type="number" v-model="orderFormObj.mobile" :rules="[{ required: true, message: '手机号' }]" placeholder="电话">
                        </div>
                    </div>
                </div>
                </label>
                <img v-else class="img" src="https://qn.yiquan.com.cn/act/20221012/1019/alert2.png" />
                <div class="right-box">
                    <img v-if="pageType == 1" class="img" src="https://qn.yiquan.com.cn/act/20221012/bt.png"  @click="submitInfo"/>
                    <img v-else class="img" src="https://qn.yiquan.com.cn/act/20221012/bt-2.png"  @click="changeTo1"/>
                </div>
            </div>
        </div>
        <div class="bg">
            <img class="img" src="https://qn.yiquan.com.cn/act/20221012/1019/page-3.jpg" />
        </div>
        <div class="bg">
            <img class="img" src="https://qn.yiquan.com.cn/act/20221012/1019/page-4.jpg" />
        </div>
    </div>
</template>

<script>
    import vue from 'vue';
    import {
        Button,
        Dialog,
        List,
        Popup,
        Field,
        Toast,
        AddressEdit
    } from 'vant';

    vue.use(Button, Dialog, Popup, List, Field, Toast, AddressEdit);
    export default {
        name: 'act221012',
        data() {
            return {
                orderFormObj: {
                    name: '',
                    mobile : ''
                },
                showPicker: true,
                buttonLock : false,
                pageType : 1
            }
        },
        created() {},
        methods: {
            /**
             * 清空
             */
            clearTop: function() {
                setTimeout(function() {
                    var scrollHeight = document.documentElement.scrollTop || document.body.scrollTop || 0;
                    window.scrollTo(0, Math.max(scrollHeight - 1, 0));
                }, 100);
            },
            // 提交信息
            submitInfo() {
                let that = this;
                if (that.btnDisable) {
                    return false;
                }
                that.btnDisable = true;
                let name = that.orderFormObj.name;
                let mobile = that.orderFormObj.mobile;
                if (name.length == 0 || name.length > 6) {
                    Dialog.alert({
                        message: "请输入姓名！"
                    });
                    that.btnDisable = false;
                    return false;
                } else if (mobile.length != 11) {
                    Dialog.alert({
                        message: "请正确输入手机号！"
                    });
                    that.btnDisable = false;
                    return false;
                }

                // 提交收货信息  partyId：正式服3 测试服5
                that.$post("/submitYxParty", {
                    openId: that.$store.getters['user/getOpenId'],
                    NAME: name,
                    MOBILE: mobile,
                }).then((res) => {
                    if (res.code == 200) {
                        that.btnDisable = false;

                        that.pageType = 2;
                    } else {
                        that.btnDisable = false;
                        Dialog.alert({
                            message: res.msg
                        });
                    }
                })
            },
            //改变状态
            changeTo1(){
                this.$router.push(
                    {
                        path: '/pact20221013',
                    })
            }
        },
    }
</script>

<style>
    /*展示内容*/
    #act221012{
    }

    #act221012 .bg{
        width: 100%;
        position: relative;
    }

    #act221012 .bg img{
        width: 100%;
    }

    #act221012 .bg .popup-box{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        padding: 0 70px;
        padding-top: 350px;
    }

    #act221012 .bg .popup-box .inpu-form{
        position: absolute;
        top: 550px;
        left: 0;
        right: 0;
        margin: auto;
        padding: 0 70px;
    }

    #act221012 .right-box{
        margin: auto;
        margin-top: 42px;
        padding: 0 120px;
    }

    #act221012 .right-box-2{
        margin-top: 40px;
        padding: 0 74px;
    }

    #act221012 .right-box input{
        width: 100%;
        border: none;
        background-color: #f4f4f4;
        border-radius: 6px;
        line-height: 75px;
        padding: 0 30px;
        font-size: 28px;
    }

    #act221012 .right-box input::-webkit-input-placeholder{
        letter-spacing: 8px;
    }
</style>
