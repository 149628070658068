import {baseUrl, imgUrl} from "./env"
import axios from 'axios';
import store from "../store";
import md5 from 'js-md5';

axios.default.timeout = 10000;//设置请求时间
axios.defaults.baseURL = baseUrl;//设置默认接口地址
axios.defaults.withCredentials = true;

/**
 * 封装get方法
 */
export function fetch(url, params = {}) {
    return new Promise((resolve, reject) => {
        this.$axios.get(url, {
            params: params
        })
            .then(response => {
                resolve(response.data)
            })
            .catch(err => {
                reject(err)
            })
    })
}
//刷新token
export function refreshToken(url, params = {}) {
	return new Promise((resolve, reject) => {
		let data = {
			'appId' : this.$wechat.appId,
			'openId' : store.getters['user/getOpenId'],
		};
		let params = {
			'params' : JSON.stringify(data)
		};
		axios.post(url, params, {
			headers: {
				"Content-Type": "application/json;charset=utf-8",
			},
		}).then(res => {
			if(res.data.code == 200){
				let token = res.data.data.token;
				store.commit('user/setToken', token);
			}else{
				window.console.log('验证失败');
			}
			resolve(res.data)
		}).catch(err => {
			return err;
		});
	})
}
/**
 * 封装post方法
 */
export function post(url, data = {}) {
    return new Promise((resolve, reject) => {
        let timestamp = Date.parse(new Date())/1000;
        let token = store.getters['user/getToken'];
        token = token == null? '' : token;
        data['appId'] = this.$wechat.appId;
        data = JSON.stringify(data);
        let params = {
            'openId' : store.getters['user/getOpenId'],
            'params' : data,
            'timestamp' : timestamp,
            'sign' : md5(md5(data)+timestamp+token+'yiquan')
        };

        this.$axios.post(url, params)
            .then(response => {
                if(response.data.code == 404){
                    let data = {
                        'appId' :this.$wechat.appId,
                        'openId' : store.getters['user/getOpenId'],
                    };
                    data = JSON.stringify(data);
                    let params = {
                        'params' : data
                    };
                    this.$axios.post('/getToken', params).then((res) => {
                        if(res.data.code == 200){
                            let token = res.data.data.token;
                            this.$store.commit('user/setToken', token);
                            if(this.$store.getters['local/getType'] == 3){
                                window.location.href = '/personal';
                            }else if(this.$store.getters['local/getType'] == 2){
                                window.location.href = '/sp-index';
                            }else{
                                window.location.href = '/index';
                            }
                        }else{
                            window.console.log('验证失败');
                        }
                    })
                    return;
                }else if(response.data.code == 401 && (url != '/getToken' && url != '/submitOrder' && url != '/userBindCust' && url != '/userGetRand' && url != '/inheritAccount' && url != '/userBindMobile')){
                    //只要报错并且是非获取token接口报错，清空本地用户数据 @TODO
                    this.$store.commit('user/setUserId', 0);
                    this.$store.commit('user/setCustId', 101088);
                    this.$store.commit('user/setOpenId', '');
                }
                resolve(response.data)
            }, err => {
                reject(err)
                errorPost({'errorMessage' : err.stack , 'scriptURI' : url + '?'+ JSON.stringify(data)});
            })
    })
}

/**
 * 封装图片上传方法
 * @param url
 * @param data 请求内容
 * @param file 图片文件
 * @returns {Promise<any>}
 */
export function uploadFile(url, data = {}, file){
    return new Promise((resolve, reject) => {
        //添加图片到form对象
        let form = new FormData();
        form.append('file', file, file.name);
        //其他数据
        form.append('params', JSON.stringify(data));
        // window.console.log(form.get('file')); //FormData私有类对象，访问不到，可以通过get判断值是否传进去
        let config = {
            headers:{'Content-Type':'application/x-www-form-urlencoded'}
        };  //添加请求头
        this.$axios.post(url, form, config)
            .then(response => {
                response.data['baseUrl'] = imgUrl;
                resolve(response.data);
            }, err => {
                reject(err)
            })
    })
}

/**
 * 强行传输错误到后台
 * @param data
 * @param fn
 */
export function errorPost(data, fn) {
    // var url = 'https://api.yiquan.com.cn/page_error';
    // var xhr = new XMLHttpRequest();
    // var fd =new FormData();
    // for(var key in data){
    //     fd.append(key, data[key])
    // }
    // xhr.open("POST", url);
    // xhr.send(fd);
}
