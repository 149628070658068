<template>
	<div class="wrap wrap-210330">
		<!-- <img class="hdImg" src="/images/act/hd/velite-bg.jpg" alt=""> -->
		<!-- <img class="img" src="http://qn.yiquan.com.cn/act/20210329/5M-index-top-bg2.jpg" alt=""> -->
		<img class="img" src="https://qn.yiquan.com.cn/web/202/22N-index-top-bg2.jpg" alt="">
		<div class="page3 pos_rel">
			<img class="img" src="http://qn.yiquan.com.cn/act/20210329/5M-index-bottom-bg2.jpg" alt="">
			<div class="xny-carbtn">
				<img class="xny-carbtn1" src="http://qn.yiquan.com.cn/act/20210329/4M-index-con-car1.png" @click="click(2)" alt="">
				<img class="xny-carbtn1" src="http://qn.yiquan.com.cn/act/20210329/4M-index-con-car2.png" @click="click(1)" alt="">
				<img class="xny-carbtn2" src="http://qn.yiquan.com.cn/act/20210329/4M-index-con-car3.png" @click="click(3)" alt="">
				<!-- /images/act/xny/index-con-car3.png -->
			</div>
		</div>
		<!-- <div class="footerBtn" @click="click()">立即参与</div> -->
		<!-- 弹窗 -->
		<van-popup v-model="logShow" class="popupTC" :close-on-click-overlay='false' >
		    <div class="wrapper wrapper-alert zyq-hd-wrapper" @click.stop >
		        <div class="log-block six-block zyq-hd-block">
		            <div class="close-btn close-btn2" @click="clickClose()">x</div> 
		            <div class="title">
		                <p>填写注册信息</p>
		            </div>
		            <form class="submit-container zyq-submit-container" @submit.prevent="submitCustCode">
		                <div class="form-input mobile-container zyq-form-input" v-if="!mobileType">
		                    <input placeholder="邀请码" v-model="logFormObj.code" @blur="clearTop"/>
		                    <!-- <img src="/images/act/six/six-act-u.png"> -->
		                </div>
		                <div class="form-input mobile-container zyq-form-input">
		                    <input placeholder="手机号" v-model="logFormObj.mobile" maxlength="11" @blur="clearTop"/>
		                    <!-- <img src="/images/lab_m.png"> -->
		                </div>
		                <div class="form-input pwd-container zyq-form-input border0">
		                    <div class="zyq-yzm-box">
		                        <input placeholder="验证码" class="yzm-input" v-model="logFormObj.token" @blur="clearTop" maxlength="6"/>
		                    </div>
		                    
		                    <!-- <img src="/images/lab_c-2.png"> -->
		                    <div class="mobile-btn yzm-btn hd-yzm-ben" @click="getMobileToken()">{{tokenBtnMsg}}</div>
		                </div>
		                <div class="warn-info zyq-warn-info">
		                    <span v-if="alertWarnLv == 1">请输入正确的手机号</span>
		                    <span v-else-if="alertWarnLv == 2">验证码错误</span>
		                    <span v-else-if="alertWarnLv == 3">{{alertWarnContent}}</span>
		                    <span v-else-if="alertWarnLv == 4">邀请码错误</span>
		                    <span v-else-if="alertWarnLv == 5">您已参加本次活动！</span>
		                    <span v-else-if="alertWarnLv == 6">请输入姓名</span>
		                </div>
		                <!-- <div class="zyq-color-red">注：收到您的预约信息后会有销售主动联系您；</div> -->
		
		                <van-button class="zyq-submit-btn" round type="info" size="large" native-type="submit">
		                    <span>确认</span>
		                </van-button>
		            </form>
		        </div>
		    </div>
		</van-popup>
		<van-popup v-model="showPicker" position="bottom" :close-on-click-overlay="false" safe-area-inset-bottom>
		    <van-picker
		            title="请选择所在城市"
		            show-toolbar
		            :columns="columns"
		            value-key = "content"
		            @change="onLocalChange"
		            @confirm="onLocalConfirm"
		    ></van-picker>
		</van-popup>
	</div>
</template>

<script>
	import vue from 'vue';
	import {Button, Dialog, List, Popup, Field, Toast} from 'vant';
	
	vue.use(Button, Dialog, Popup, List, Field, Toast);
	let citys = {};
	export default {
		name: 'vlhd',
		data() {
			return {
				id: '',
				logShow: false,
				tokenBtnMsg: '获取验证码',
				deadline: 60,
				btnDisable: false,
				showPicker: false, //地区弹窗
				logFormObj: {
					code: '',
					mobile: '',
					token: ''
				},
				alertWarnLv: 0, //提示等级 0:无提示 1：请输入正确的手机号 2：验证码错误 3：其他 4：邀请码错误 5:您已参加 6.姓名
				alertWarnContent: "",  //其他提示信息
				citys : citys,
				columns: [
				    {
				        values: Object.keys(citys),
				    },
				    {
				        values: '',
				        className: 'column2',
				        defaultIndex: 2
				    }
				],
				localVal : "请选择所在城市",
				localVals : [],
				code: "",
				yhqshow: false,           //优惠券弹窗
				mobileType: false,
				Type: true,
			}
		},
		created() {
			let that = this;
			wx.ready(function () {
				wx.checkJsApi({
					jsApiList: [
						'getLocation'
					],
					success: function (res) {
						if (res.checkResult.getLocation == false) {
							alert('你的微信版本太低，不支持微信JS接口，请升级到最新的微信版本！');
							return;
						}
					}
				}); 
				wx.error(function(res){
					console.log("接口调取失败")
				});
				wx.getLocation({
					success: function (res) {
						var lat = res.latitude;
						var lon = res.longitude;
						// console.log(JSON.stringify(res));
						var key = "25ZBZ-OIU6D-5CL4J-HQTJM-QITQO-YPFYM";
							// o6KgywD65MUvDIL7pX9G6jLHUhnI
						that.getCoderInfo(lat,lon);
					},
					cancel: function (res) {
						console.log('用户拒绝授权获取地理位置');
						//当无法获取当前位置是执行 弹窗城市地区
						that.$data.showPicker = true;
					}
				});
			});
			let openid = that.$store.getters['user/getOpenId'];
			let path = this.$route.path;
			let query  = this.$route.query.code;
			this.$data.code = this.$route.query.code;
			let length = window.location.href;
			//获取城市信息
			this.$post('/getCityList', {
			}).then((res) => {
			    if(res.code == 200){
			        citys = res.data;
			        this.citys = citys;
			        this.columns = [
			            {
			                values: citys,
			                className: 'column1',
			            },
						{
							values: citys[0]['list'],
							className: 'column2',
						}
			        ];
			
			        let brandLoacl = this.$store.getters['local/getBrandLocal'];
			        if(brandLoacl.length > 0){
			            let brandKey = this.$store.getters['local/getBrandKey'];
			            this.onLocalConfirm(JSON.parse(brandLoacl), JSON.parse(brandKey));
			        }
			    }else{
			        Dialog.alert({
			            message: res.msg
			        }).then(() => {
			            // on close
			        });
			    }
			}).catch(
			    (e) =>{
			        window.console.log(e);
			    }
			);
			//判断是否登录 并且未领取优惠券
			let userId = this.$store.getters['user/getUserId'];
			let custId = this.$store.getters['user/getCustId'];
			if(openid != '' && userId != 0) {
				// this.isstCoupon2();  //判断是否获取优惠券
				this.Type = false;
			}
			
		},
		methods: {
			//关闭优惠券弹窗
			closePopup() {
				this.yhqshow = false;
				let type = this.Type;
				if(!type) {

				}else {
					let e = this.$data.id;
					this.navToL(e);
				}
				// this.obtainCoupon();
			},
			//通过code 获取用户openid
			getUserInfoByCode(code) {
				var appid = this.$wechat.appId;
				this.$post('/getUserInfoByCode', {
					code: code,
					appId: appid
				}).then((res) => {
					console.log(res);
					let data = res.data;
				    if(res.code == 200){
				        //去除后if内跳转无法生效
						let that = this;

                        let data =res.data;
                        let custId = data.custId;
                        let userId = data.userId;
                        //更新数据
                        that.$store.commit('user/setOpenId', data.openid);
                        that.$store.commit('user/setUserId', userId);
                        that.$store.commit('user/setCustId', custId);
                        let urlType = this.$store.getters['local/getType'];
						let url = this.$store.getters['local/getUrl'];
						console.log(custId, userId);
						if(custId == 0 && userId == 0) {
							// that.logShow = true;
						}
						else {
							// that.isstCoupon();   //判断是否有优惠券
						}
						that.getToken();  //获取token
						state =JSON.parse(window.atob(state));
                        if(urlType == 7){
                            //特殊跳转
                            this.$store.commit('user/setFirst', false);
                            this.$router.push({
                                path : url ,
                                query : state
                            }).catch((e) => {
                                this.$router.push({
                                    path : url ,
                                    query : state
                                });
                            });
                            return false;
						}
				    }else{
				        // Dialog.alert({
				        //     message: res.msg
				        // }).then(() => {
				        //     // on close
				        // });
				    }
				}).catch(
				    (e) =>{
				        window.console.log(e);
				    }
				);
			},
			//通过经纬度获取位置信息
			getCoderInfo: function(lat,lon) {
				this.$post('/getCoderInfo', {
					location: lat+','+lon
				}).then((res) => {
					let that = this;
					let data = res.data;
				    if(res.code == 200){
				        let province = data.address_component.province;
						let city = data.address_component.city;
						let district = data.address_component.district;

						let provinces = ['上海市','北京市','天津市'];
                        let citys = ['上海市','北京市','天津市','杭州市','宁波市','温州市','湖州市','嘉兴市','绍兴市','金华市','衢州市','舟山市','台州市','丽水市','重庆市'];
						for(var i =0;i<provinces.length;i++) {
							if(province == provinces[i]) {
								province = province.split("市")[0];
								break;
							}
							else {
								province = province.split("省")[0];
							}
						}
						
						for(var v = 0; v < citys.length; v++) {
							if(city == citys[v]) {
								break;
							}else {
								city = city.split("市")[0];
							}
						}
						that.localVal = province+"-"+city;
						that.getLocalId(city);
					}else{
				        Dialog.alert({
				            message: res.msg
				        }).then(() => {
				            // on close
				        });
					}
				}).catch(
				    (e) =>{
						//当无法获取当前位置是执行 弹窗城市地区
						this.$data.showPicker = true;
				        window.console.log(e);
				    }
				);
			},
			//获取地址匹配Id信息
			getLocalId(cityName) {
				this.$post('/getLocalId', {
					cityName: cityName
				}).then((res) => {
					let data = res.data;
					if(res.code == 200){
						//http://192.168.20.190:8080/act/20201111vl
						if(data.provincesId == 0 || data.cityId == 0) {
							this.$data.showPicker = true;
							return false;
						}
						let provinceId = data.provincesId;
						let cityId = data.cityId;
						let loc = this.citys;
						let localVal = this.localVal;
						let xhprovince = 0;
						let xhcity = 0;
						let valStr = [];
						let brandkey = [];
						for(var i = 0; i < loc.length; i++) {
							if(provinceId == loc[i].id) {
								// valStr = JSON.stringify(loc[i]);
								valStr = loc[i];
								xhprovince = i;
							}
						}
						let localVals = [];
						localVals.push(valStr);
						for(var o = 0; o < valStr["list"].length; o++) {
							if(cityId == valStr["list"][o].id) {
								localVals.push(valStr["list"][o]);
								xhcity = o;
							}
						}
						
						brandkey = [xhprovince, xhcity];
						this.$store.commit('local/setProvinceId', provinceId);
						this.$store.commit('local/setCityId', cityId);
						this.$store.commit('local/setLocal', localVal);
						this.$store.commit('local/setBrandLocal', JSON.stringify(localVals));
						this.$store.commit('local/setBrandKey', JSON.stringify(brandkey));
					}else{
						Dialog.alert({
							message: res.msg
						}).then(() => {
							// on close
						});
					}
				}).catch(
					(e) =>{
						//当无法获取当前位置是执行 弹窗城市地区
						this.$data.showPicker = true;
						window.console.log(e);
					}
				);
			},
			/**
			 * 地址选择变化
			 */
			onLocalChange(picker) {
			    let indexs = picker.getIndexes();
			    picker.setColumnValues(1, this.citys[indexs[0]]['list']);
			},
			/**
			 * 地址选择确认
			 */
			onLocalConfirm(value, key){
			    this.localVal = value[0]['content'] + '-' + value[1]['content'];
				this.localVals = value;
			    let valStr = JSON.stringify(value);
			    this.$store.commit('local/setBrandLocal', valStr);
			    this.$store.commit('local/setBrandKey', JSON.stringify(key));
			    this.hasLocalCss = "hasLocalCss";
				this.$data.showPicker = false;

				let provinceId = this.localVals[0]['id'];
                let cityId = this.localVals[1]['id'];

                this.$store.commit('local/setProvinceId', provinceId);
                this.$store.commit('local/setCityId', cityId);
                this.$store.commit('local/setLocal', this.localVal);
			},
			//获取token
			getToken() {
				let data = {
                    'appId' :this.$wechat.appId,
                    'openId' : this.$store.getters['user/getOpenId'],
                };
                let that= this;
                this.$post('/getToken', data).then((res) => {
                    if(res.code == 200){
                        let token = res.data.token;
                        this.$store.state.user.token = token;
                        this.$store.commit('user/setToken', token);
                        let type = this.$store.getters['local/getType'];
                        
                    }else{
                        window.console.log('验证失败');
                    }
                });
			},
			//点击车辆图片
			click:function(e) {
				this.$data.id = e;
				let openId = this.$store.getters['user/getOpenId'];
				let userId = this.$store.getters['user/getUserId'];
                let custId = this.$store.getters['user/getCustId'];
				//判断是否有openid 和userId、custId
				
				if(openId != '' && userId == 0) {
					if(custId != 0) {
						//弹出登录弹窗
						this.mobileType = true;
					}
					//弹出登录弹窗
					this.logShow = true;
				}
				else {
					// this.isstCoupon();
					// let e = this.$data.id;
					this.getCarModelNum(e);
					// this.navToL(e);
				}
			},
			// 新增方法-2021-7-7
			getCarModelNum(e) {
				let provinceId = this.$store.getters['local/getProvinceId'];
				let cityId = this.$store.getters['local/getCityId'];
				let custId = this.$store.getters['user/getCustId'];
				let userId = this.$store.getters['user/getUserId'];
				if ((typeof provinceId == 'undefined' || provinceId == 0 || provinceId == null) || (typeof cityId == 'undefined' || cityId == 0 || cityId == null)) {
				    this.$router.replace({
				        path: '/index'
				    });
				    return false;
				}
				let name = "";
				let markId = "";
				if(e == 1) {
					name = "微蓝6";
					markId = 1047;
				}else if(e == 2) {
					name = "微蓝6 混动";
					markId = 1047;
				}else {
					name = "畅巡";
					markId = 1048;
				}
				
				this.$post('/getCarModelNum', {
					// openId : this.$store.getters['user/getOpenId'],
					markId: markId,
					provinceId: provinceId,
					cityId: cityId,
					custId: custId,
					userId: userId,
					typeName: name
				}).then((res) => {
					let that = this;
					console.log(res);
					if(res.code == 200){
						if(res.data.num == 0) {
							this.$store.commit('local/setProvinceId', 65);
							this.$store.commit('local/setCityId', 6508);
							this.$store.commit('local/setLocal', "广东-深圳");
							setTimeout(function() {
								that.navToL(e);
							},400);
						}else {
							that.navToL(e);
						}
					}else{
						window.console.log('失败');
					}
				});
			},
			/**
			 * 提交邀请码
			 * @returns {boolean}
			 */
			submitCustCode : function (){
				let id = this.id;
			    let code = this.$data.logFormObj.code;
				let mobile = this.logFormObj.mobile;
				let token = this.logFormObj.token;
				console.log(code, mobile, token);
				
				if(mobile == ''){
				    this.alertWarnLv = 1;
				    return false
				}
				if(token == ''){
				    this.alertWarnLv = 2;
				    return false
				}
				//http://test.yiquanvip.com/act/20201111vl
				let mobileType = this.mobileType;
				// console.log(mobileType);
				if(mobileType) {
					let id = this.id;
					// console.log("走这里");
					this.submitBingMobile(id);
					return false;
				}

			    if(code == ''){
			        this.alertWarnLv = 4;
			        return false
			    }
			    //请求后端判断邀请码是否正确
				// this.$data.logShow = false;
					this.$post('/userBindCust', {
			        openId : this.$store.getters['user/getOpenId'],
			        code :  code,
					}).then((res) => {
						let that = this;
						if(res.code == 200){
							that.$data.alertWarnLv = 0;
							// that.$data.alertWarnContent = res.msg;
							let id = this.id;
							this.mobileType = true;

							that.submitBingMobile();
						}else{
							that.$data.alertWarnLv = 3;
							that.$data.alertWarnContent = res.msg;
						}
					});
			    
			},
			/**
			 *  提交手机号
			 * @returns {boolean}
			 */
			submitBingMobile: function () {
			    //请求绑定
				// let code = this.logFormObj.code;
				let mobile = this.logFormObj.mobile;
				let token = this.logFormObj.token;
				let url = '/userBindMobile';  //注册
			    // if(code == '') {
			    //     this.alertWarnLv = 4;
			    //     return false;
			    // }
			    // else {
			        if (this.isPhone(mobile)) {
			            //判断验证码
			            if (token == '' || typeof token == 'undefined') {
			                this.alertWarnLv = 3;
			                this.alertWarnContent = '请输入验证码';
			                return false;
			            }
			
			            this.alertWarnLv = 0;
			            this.$post(url, {
							openId : this.$store.getters['user/getOpenId'],
			                code : token,
			                mobile :  mobile,
			            }).then((res) => {
			                if (res.code == 200) {
								//更新数据
								let  custId = res.data.custId;
								let  userId = res.data.userId;
								this.$store.commit('user/setUserId', userId);
                    			this.$store.commit('user/setCustId', custId);
			                    
			                    this.logShow = false;
			                    this.logFormObj = {
			                        mobile: '',
			                        token: '',
			                        code: ''
								};
								// this.isstCoupon();   //判断是否有优惠券
			                    
			                } else {
			                    this.alertWarnLv = 3;
			                    this.alertWarnContent = res.msg;
			                }
			            })
			        } else {
			            this.alertWarnLv = 1;
			            this.btnDisable = false;
			        }
			        return false;
			    // }
			    
			},
			/**
			 * ，关闭弹窗
			 */
			clickClose: function () {
			    this.logShow = false;
			    this.logFormObj = {
			        mobile: '',
			        token: '',
			        code: ''
			    };
			},
			/**
			 * 清空
			 */
			clearTop: function () {
				setTimeout(function () {
					var scrollHeight = document.documentElement.scrollTop || document.body.scrollTop || 0;
					window.scrollTo(0, Math.max(scrollHeight - 1, 0));
				}, 100);
			},
			/**
			 * 获取手机验证码
			 */
			getMobileToken: function () {
			    if (this.btnDisable) {
			        return false;
			    }
			    this.btnDisable = true;
			    let mobile = this.logFormObj.mobile;
			    // if (this.isPhone(mobile)) {
				if (mobile.length == 11) {
			        this.alertWarnLv = 0;
			        this.$post('/userGetRand', {
			            openId : this.$store.getters['user/getOpenId'],
			            mobile :  mobile,
			        }).then((res) => {
			            // console.log(res)
			            if (res.code == 200) {
			                //循环倒计时
			                this.tokenBtnMsg = this.deadline + 's';
			                this.int_btnClock = window.setInterval(() => {
			                    if (this.deadline <= 0) {
			                        window.clearInterval(this.int_btnClock);
			                        this.tokenBtnMsg = '获取验证码';
			                        this.deadline = 60;
			                        this.btnDisable = false;
			                        return;
			                    }
			                    this.deadline--;
			                    this.tokenBtnMsg = this.deadline + 's';
			                }, 1000);
			            } else {
			                this.btnDisable = false;
			                this.alertWarnLv = 3;
			                this.alertWarnContent = res.msg;
			            }
			        }).catch(e => {
			            console.log(e);
			            this.btnDisable = false;
			            this.alertWarnLv = 3;
			            this.alertWarnContent = '网路异常请稍后重试';
			        });
			    } else {
			        this.alertWarnLv = 1;
			        this.btnDisable = false;
			    }
			    return false
			},
			// 检查字符串是否为合法手机号码
			isPhone: function (str) {
			    let reg = /^(1[3-9][0-9])[0-9]{8}$/;
			    if (reg.test(str)) {
			        // console.log('手机号码格式输入正确');
			        return true;
			    } else {
			        // console.log('请输入正确格式的手机号码');
			        return false;
			    }
			},
			/**
			 * 阻止提交
			 * @returns {boolean}
			 */
			logForm: function () {
			    this.submitBingMobile();
			    return false;
			},

			/**
			 * 跳转到微蓝
			 */ 
			navToL: function(e){
				let data = {
                    'appId' :this.$wechat.appId,
                    'openId' : this.$store.getters['user/getOpenId'],
                };
                let that= this;
                this.$post('/getToken', data).then((res) => {
                    if(res.code == 200) {
                        let token = res.data.token;
                        this.$store.state.user.token = token;
                        this.$store.commit('user/setToken', token);
                        let type = this.$store.getters['local/getType'];
                        setTimeout(function() {
							let name = "";
							let markId = "";
							if(e == 1) {
								name = escape("微蓝6");
								markId = 1047;
							}else if(e == 2) {
								name = escape("微蓝6 混动");
								markId = 1047;
							}else {
								name = escape("畅巡");
								markId = 1048;
							}
							that.$router.push({
								path : '/model',
								query:{
									markId : markId,
									type : name
								},
							})
						}, 200);
                    }else{
                        window.console.log('验证失败');
                    }
                });
			}
		}
	}
</script>

<style>
	.wrap {
		position: relative;
	}
	.img {
		width: 100%;
		height: auto;
		display: block;
	}
	#video1 {
		position: absolute;
		bottom: 70px;
		left: 50%;
		width: 698px;
		height: 370px;
		transform: translateX(-50%);
		background: #666;
	}
	.yhqpopup {
		background-color: transparent;
		width: 100%;
	}
	.popupClose {
		margin: 60px auto 0;
		width: 100px;
		height: 100px;
	}
	.pos_rel {
		position: relative;
	}
	.page {
		position: absolute;
	}
	.page2-cen {
		position: absolute;
		top: 0;
	}
	.xny-carbtn {
		position: absolute;
		top: 20px;
		left: 0;
		right: 0;
		margin: 0 auto;
		width: 690px;
	}
	.xny-carbtn1 {
		/* margin-bottom: 34px; */
		margin-bottom: 20px;
	}
	.xny-carbtn img {
		width: 100%;
	}
	.hdImg {
		width: 100%;
	}
	.footerBtn {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100px;
		line-height: 100px;
		text-align: center;
		font-size: 34px;
		color: #fff;
		/* background-image: linear-gradient(rgba(72,78,225, 1),rgba(51,20,197, 1)); */
		background-image: linear-gradient(rgba(72,78,225, 1),rgba(71,21,124, 1));
	}
	/* 弹窗 */
	.popupTC {
	    box-sizing: border-box;
	    width: 594px;
	    border-radius: 20px;
	}
	.tcshow {
	    padding: 80px;
	}
	.hd-yzm-ben {
		border: 1px solid #f2531d;
	}

	.wrap-210330 .zyq-form-input{
		padding-left: 0!important;
	}
	.wrap-210330 .zyq-hd-wrapper {
		padding: 0 ;
	}
</style>
