<template>
    <div class="user-custinfo">

        <form @submit.prevent="submitForm">
            <div class="info-container">
                <div class="cell-container">
                    <van-field label="原客戶单位:" disabled readonly :value="custName"/>
                </div>
                <div class="cell-container">
                    <van-field label="邀请码:" placeholder="请输入邀请码" v-model="dataForm.code"/>
                </div>
                <div class="cell-container">
                    <van-field label="电话:" value="" placeholder="请输入手机号" type="number" maxlength="11"  v-model="dataForm.mobile"/>
                </div>
                <div class="cell-container" v-if="!dataForm.hasPhone">
                    <van-field label="验证码:" value="" placeholder="请输入验证码"  type="number" maxlength="6"  v-model="dataForm.token">
                        <van-button slot="button" size="small" type="info"  @click="getMobileToken()">{{tokenBtnMsg}}</van-button>
                    </van-field>
                </div>
            </div>
            <div class="bottom-submit">
                <van-button type="info" size="large" native-type="submit" @click="saveSubmit()">确认</van-button>
            </div>
        </form>

        <van-overlay :show="loadShow"  class="overlay-div">
            <van-loading type="spinner"  class="load"/>
        </van-overlay>
    </div>
</template>

<script>
    import vue from 'vue';
    import { Button, Cell, Dialog} from 'vant';

    vue.use(Button).use(Cell).use(Dialog);
    export default {
        name: 'personal-custinfo',
        created(){
            this.loadShow = true;

            let data = {
                'appId' :this.$wechat.appId,
                'openId' : this.$store.getters['user/getOpenId'],
            };
            this.$post('/getUserCustInfo', data).then((res) => {
                if(res.code == 200){
                    let data = res.data;
                    this.custName = data['custName'];
                    this.dataForm.mobile = data['mobile'];

                    this.loadShow = false;
                }else{
                    Dialog.alert({
                        message: res.msg,
                    }).then(() => {
                        // on close 跳转到首页
                        window.location.href = '/personal';
                    });
                }
            });
        },
        data() {
            return {
                dataForm : {
                    'code' : '',
                    'mobile' : '',
                    'token' : '',
                },
                custName : '-',
                tokenBtnMsg : '获取验证码',
                deadline : 60,
                btnDisable : false,
                loadShow : false,
            }
        },
        methods: {
            /**
             * 获取手机验证码
             */
            getMobileToken: function () {
                if(this.btnDisable){
                    return false;
                }
                this.btnDisable = true;
                let mobile = this.dataForm.mobile;
                if(this.isPhone(mobile)){
                    this.alertWarnLv = 0;
                    this.$post('/userGetResetCustRand', {
                        openId : this.$store.getters['user/getOpenId'],
                        mobile :  mobile,
                    }).then((res) => {
                        if(res.code == 200){
                            //循环倒计时
                            this.tokenBtnMsg = this.deadline + 's';
                            this.int_btnClock = window.setInterval(() => {
                                if(this.deadline <= 0){
                                    window.clearInterval(this.int_btnClock);
                                    this.tokenBtnMsg = '获取验证码';
                                    this.deadline = 60;
                                    this.btnDisable = false;
                                    return;
                                }
                                this.deadline--;
                                this.tokenBtnMsg = this.deadline + 's';
                            },1000);
                        }else{
                            this.btnDisable = false;
                            this.alertWarnLv = 3;
                            this.alertWarnContent = res.msg;
                        }
                    }).catch(e => {
                        window.console.log(e);
                        this.btnDisable = false;
                        this.alertWarnLv = 3;
                        this.alertWarnContent = '网路异常请稍后重试';
                    });
                }else{
                    Dialog.alert({
                        message: '请输入正确的手机号'
                    }).then(() => {
                        // on close
                    });
                    this.btnDisable = false;
                }
                return false
            },
            /**
             * @returns {boolean}
             */
            submitForm(){
                return false;
            },
            /**
             * 上传数据
             * @returns {boolean}
             */
            saveSubmit(){
                let data = this.dataForm;
                if(data['mobile'] == '' || !this.isPhone(data['mobile'])){
                    Dialog.alert({
                        message: '请输入手机号'
                    }).then(() => {
                        // on close
                    });
                    return false;
                }
                if(data['token'] == ''){
                    Dialog.alert({
                        message: '请输入验证码'
                    }).then(() => {
                        // on close
                    });
                    return false;
                }
                if(data['code'] == ''){
                    Dialog.alert({
                        message: '请输入邀请码'
                    }).then(() => {
                        // on close
                    });
                    return false;
                }

                data['openId'] = this.$store.getters['user/getOpenId'];

                //直接提交
                window.console.log(data);
                this.loadShow = true;
                this.$post('/resetUserCust', data).then((res)=>{
                    if(res.code == 200){
                        Dialog.alert({
                            message: '信息修改成功',
                        }).then(() => {
                            // on close 刷新
                            this.$store.commit('user/setCustId', res.data.custId);
                            this.$store.commit('user/setCustMini', null);
                            window.location.reload();
                        });
                        this.loadShow = false;
                    }else{
                        Dialog.alert({
                            message: res.msg,
                        }).then(() => {
                            // on close 跳转到订单列表
                        });
                        this.loadShow = false;
                    }
                });

                return false;
            },
            /**
             * 检查字符串是否为合法手机号码
             */
            isPhone: function (str) {
                let reg = /^(1[3-9][0-9])[0-9]{8}$/;
                if (reg.test(str)) {
                    // console.log('手机号码格式输入正确');
                    return true;
                } else {
                    // console.log('请输入正确格式的手机号码');
                    return false;
                }
            },
        }
    }
</script>

<style>
    /*个人中心*/
    .user-custinfo {
        position: relative;
        background: white;
        height: 100%;
        /*font-family: "PingFangSC-Medium";*/
    }
    .user-custinfo form{
        position: relative;
        min-height: 100%;
    }
    .user-custinfo .info-container .cell-container{
        box-sizing: content-box;
        position: relative;
    }
    .user-custinfo .info-container .van-cell__title{
        text-align: left;
    }
    .user-custinfo .bottom-submit{
        position: absolute;
        width: 100%;
        bottom: 0;
    }
</style>