<template>
    <div class="feedback">
        <form @submit.prevent="submitForm">
            <div class="class-upload-container">
                <div class="upload-container">
                    <div class="upload-title">上传证明</div>
                    <div class="upload-box">
                        <div class="upload upload-1">
                            <van-uploader :after-read="afterRead" :before-delete="delImg" :name="0" :max-count="1" :file-list="fileList[0]">
                            </van-uploader>
                            <p>身份证正面照片</p>
                        </div>
                        <div class="upload upload-2">
                            <van-uploader :after-read="afterRead" :before-delete="delImg" :name="1" :max-count="1" :file-list="fileList[1]">
                            </van-uploader>
                            <p>身份证反面照片</p>
                        </div>
                    </div>
                    <p class="bottom-prompt">请确保身份证信息清晰并在有效期之内;</p>
                </div>
            </div>

            <div class="bottom-submit">
                <van-button type="info" size="large" native-type="submit" @click="saveSubmit()">保存</van-button>
            </div>
        </form>

        <van-overlay :show="loadShow"  class="overlay-div">
            <van-loading type="spinner"  class="load"/>
        </van-overlay>
    </div>
</template>

<script>
    import vue from 'vue';
    import { Button, Field , Dialog} from 'vant';

    vue.use(Button).use(Field ).use(Dialog);
    export default {
        name: 'feedback',
        created(){
            this.loadShow = true;
            let data = {'openId' : this.$store.getters['user/getOpenId']};

            this.$post('/getUserCardInfo', data).then((res)=>{
                if(res.code == 200){
                    let data = res.data;
                    let index = 0;
                    if(data[index]['id'] != 0){
                        let fileItem = {
                            url : data[index]['files'][0]['file'],
                            name : 'card1',
                        };
                        this.dataForm.cardId1 = data[index]['id'];
                        let fileList = this.fileList;
                        if(typeof fileList[index] == 'undefined'){
                            fileList[index] = [];
                        }
                        fileList[index].push(fileItem);
                        this.fileList = fileList;
                    }
                    index = 1;
                    if(data[index]['id'] != 0){
                        let fileItem = {
                            url : data[index]['files'][0]['file'],
                            name : 'card2',
                        };
                        this.dataForm.cardId2 = data[index]['id'];let fileList = this.fileList;
                        if(typeof fileList[index] == 'undefined'){
                            fileList[index] = [];
                        }
                        fileList[index].push(fileItem);
                        this.fileList = fileList;
                    }
                    this.loadShow = false;
                }else{
                    Dialog.alert({
                        message: res.msg,
                    }).then(() => {
                        // on close
                    });
                    this.loadShow = false;
                }
            })
        },
        data() {
            return {
                dataForm : {
                    'cardId1' : 0,
                    'cardId2' : 0,
                },
                fileList : [],
                loadShow : false,
            }
        },
        methods: {
            /**
             * @returns {boolean}
             */
            submitForm(){
                return false;
            },
            /**
             * 上传图片
             */
            afterRead(event, e) {
                //直接上传图片
                this.loadShow = true;
                let index = e.name;
                let fileInfo = {
                    'openId' : this.$store.getters['user/getUserId']
                };
                this.$uploadFile('/orderUploadFile', fileInfo, event.file).then((res)=>{
                    this.loadShow = false; if(res.code == 200){
                        let data = res.data;
                        let fileItem = {
                            url : res['baseUrl'] + data['path'] + '/' + data['fileName'],
                            name : '123',
                        };
                        let fileList = this.fileList;
                        if(typeof fileList[index] == 'undefined'){
                            fileList[index] = [];
                        }
                        fileList[index].push(fileItem);
                        this.fileList = fileList;
                        //只支持单个上传
                        if(index == 0){
                            this.dataForm.cardId1 = data['id'];
                        }else if(index == 1){
                            this.dataForm.cardId2 = data['id'];
                        }
                        this.$set(this.fileList , fileList, true);
                        this.loadShow = false;
                    }else{
                        Dialog.alert({
                            message: res.msg,
                        }).then(() => {
                            // on close 跳转到订单列表
                            window.location.href = '/orderList';
                        });
                        this.loadShow = false;
                    }
                });
            },
            /**
             * 删除图片
             */
            delImg(event, e){
                let fileIndex  = e.name;
                let fileList = this.fileList;
                let fileItem = fileList[fileIndex];
                fileItem = [];//强制全清空
                if(fileIndex == 0){
                    this.dataForm.cardId1 = 0;
                }else if(fileIndex == 1){
                    this.dataForm.cardId2 = 0;
                }
                fileList[fileIndex] = fileItem;
                this.$set(this.fileList , fileList, true);

            },
            /**
             * 上传数据
             * @returns {boolean}
             */
            saveSubmit(){
                let data = this.dataForm;
                if(data['cardId1'] == 0 && data['cardId2'] == 0){
                    Dialog.alert({
                        message: '请上传图片'
                    }).then(() => {
                        // on close
                    });
                    return false;
                }

                data['openId'] = this.$store.getters['user/getOpenId'];
                //直接提交
                this.loadShow = true;
                window.console.log(data);
                this.$post('/updateCardInfo', data).then((res)=>{
                    window.console.log(res);
                    if(res.code == 200){
                        Dialog.alert({
                            message: '提交成功',
                        }).then(() => {
                            // on close 刷新
                            window.location.reload();
                        });
                        this.loadShow = false;
                    }else{
                        Dialog.alert({
                            message: res.msg,
                        }).then(() => {
                            // on close
                        });
                        this.loadShow = false;
                    }
                });

                return false;

            }
        }
    }
</script>

<style>
    /*个人中心*/
    .feedback {
        position: relative;
        background: white;
        height: 100%;
        /*font-family: "PingFangSC-Medium";*/
    }
    .feedback .warn-info{
        color: red;
        font-size: 22px;
        text-align: left;
        padding: 10px 10px;
    }
    .feedback form{
        position: relative;
        min-height: 100%;
    }
    .feedback .class-upload-container{
        padding: 30px 20px;
        padding-top: 40px;
    }
    .feedback .class-upload-container .upload-container{
        box-shadow: 0 0 10px 1px #c6c6c7;
        padding: 16px 20px;
    }
    .feedback .class-upload-container .upload-container .upload-title{
        font-size: 26px;
        /*font-family: "PingFangSC-Bold";*/
    }
    .feedback .class-upload-container .upload-container .upload-box{
        margin-top: 50px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .feedback .upload-box .upload{
        width: 50%;
        text-align: center;
    }
    .feedback .upload-box .upload p{
        padding-bottom: 16px;
        font-size: 24px;
        /*font-family: "PingFangSC-Bold   ";*/
    }
    .feedback .upload-box .upload .van-uploader,.feedback .upload-box .upload .van-uploader__wrapper,.feedback .upload-box .van-uploader__upload{
        width: 100%;
    }
    .feedback .upload-box .van-uploader__upload{
        border: 1px dashed #666;
        border-radius: initial;
    }
    .feedback .upload-box .van-uploader__upload .van-uploader__upload-icon{
        border-radius: 50px;
        border: 1px solid #c2c2c2;
        padding: 10px;
        color: #c2c2c2;
    }
    .feedback .upload-box .van-uploader .van-uploader__preview{
        width: 100%;
    }
    .feedback .upload-box .van-uploader .van-uploader__preview-image{
        width: 100%;
    }
    .feedback .upload-box .van-uploader .van-uploader__preview-image, .van-image__img{
        max-height: 100%;
        max-width: 100%;
    }
    .feedback .class-upload-container .upload-container .bottom-prompt{
        font-size: 24px;
        color: #ff0000;
        text-align: left;
    }
</style>