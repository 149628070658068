<template>
    <div class="books-cardetail">
        <div class="top-bar">
            <div class="bar-item" v-for="(item, index) in list" :key="index" @click="navToBar(index)">
                <span v-if="barIndex == index" class="active">{{item.NAME}}</span>
                <span v-else>{{item.NAME}}</span>
            </div>
        </div>
        <div class="container" :class="padbottom">
            <div class="item" v-for="(item, index) in list" :key="index" :id="index">
                <label v-if="item.TYPE == 1">
                    <img class="item-img" :src="item.content">
                </label>
                <label v-else>
                    <div :id="index+'content'"></div>
<!--                    {{item.content}}-->
                </label>
            </div>
        </div>

        <div class="bottom-order-btn" v-if="info.JUMPSTATUS == 1" style="transform: translateZ(0);-webkit-transform: translateZ(0);">
            <div class="order-btn" @click="alertOrder">价格查询</div>
        </div>
        <van-overlay :show="loadShow" class="overlay-div">
            <van-loading type="spinner" class="load"/>
        </van-overlay>
    </div>
</template>
<script>
    import vue from 'vue';
    import {Button, Dialog } from 'vant';

    vue.use(Button, Dialog);
    export default {
        name: 'books-cardetail',
        created: function(){
            let query = this.$route.query;
            if (typeof query.id == 'undefined') {
                this.$router.push({
                    path : '/books/index'
                });
            }
            let booksCarId = query.id;
            this.$post('/booksCarDetail', {
                'openId' : this.$store.getters['user/getOpenId'],
                'booksCarId' : booksCarId,
            }).then((res)=> {
                if(res.code == 200){
                    let data = res.data;
                    this.list = data['detail'];
                    this.info = data['info'];
                    if(data['info']['JUMPSTATUS'] == 1){
                        this.padbottom = 'padbottom';
                    }
                }
                this.loadShow = false;
            }).then((res) => {
                let list = this.list;
                let len = list.length;
                if(len <= 0){
                    Dialog.alert({
                        message: '暂无相关车系信息'
                    }).then(() => {
                        // on close
                        this.$router.push({
                            path : '/books/index'
                        });
                    });
                    return false;
                }
                for (var i = 0; i < len ; i++){
                    if(list[i]['TYPE'] == 2){
                        $('#'+i+'content').html(list[i]['content']);
                    }
                }
            }).then((res) => {
                $('.tb2-lb').click(function(e){
                let ids = e.target.dataset.ids;
                let dom = $('.'+ids);
                if(dom.hasClass('zk')){
                    $('.'+ids).removeClass('zk');
                }else {
                    $('.'+ids).addClass('zk');
                }
            });
            })

            //临时处理获取token
            let data = {
                'appId' :this.$wechat.appId,
                'openId' : this.$store.getters['user/getOpenId'],
            };
            this.$post('/getToken', data).then((res) => {
                if(res.code == 200){
                    let token = res.data.token;
                    this.$store.commit('user/setToken', token);
                }else{
                    window.console.log('验证失败');
                }
            });
        },
        mounted: function(){
            $('.tb2-lb').click(function(e){
                let ids = e.target.dataset.ids;
                let dom = $('.'+ids);
                if(dom.hasClass('zk')){
                    $('.'+ids).removeClass('zk');
                }else {
                    $('.'+ids).addClass('zk');
                }
            });
            window.addEventListener('scroll', this.scrollTop);

            window.addEventListener('onload', this.winOnLoad);
        },
        data() {
            return {
                loadShow : false,
                barIndex : 0,
                info : {
                    'JUMPSTATUS' : 0,
                },
                list : [],
                scrollTimer : '',
                padbottom : '',
            }
        },
        methods: {
            scrollTop(){
                var winPos = $(window).scrollTop(); //屏幕位置
                let len = this.list.length;
                clearTimeout(this.scrollTimer);
                this.scrollTimer = setTimeout(() => {
                    // todo something scroll end
                    //变量scrollTop是滚动条滚动时，距离顶部的距离
                    var scrollTop = document.documentElement.scrollTop||document.body.scrollTop;
                    //变量windowHeight是可视区的高度
                    var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
                    //变量scrollHeight是滚动条的总高度
                    var scrollHeight = document.documentElement.scrollHeight||document.body.scrollHeight;
                    //滚动条到底部的条件
                    if(scrollTop+windowHeight==scrollHeight){
                        //写后台加载数据的函数
                        this.barIndex = len - 1;
                    }else{
                        if(len > 0){
                            let index = 0;
                            for (var i =0; i < len; i++){
                                let high = $('#'+i).offset().top;
                                if(winPos > high-56){
                                    index = i;
                                }else{
                                    break;
                                }
                            }
                            this.barIndex = index;
                        }
                    }
                }, 320);
            },
            alertOrder(){
                this.$store.commit('local/setProvinceId', 25);
                this.$store.commit('local/setCityId', 2501);
                this.$store.commit('local/setLocal', '上海-上海市');
                this.$store.commit('user/setFirst2', true);//过度处理

                window.location.href = '/model?markId='+ this.info.MARKID +'&type='+ this.info.TYPENAME+'&source=book' ;
                // window.location.href = '/model?markId='+ this.info.MARKID +'&type='+ this.info.TYPENAME+'&source=book&alert=TYalert';

                return false;
            },
            navToBar(index){
                let item = $('#'+index);
                $("html, body").animate({
                    scrollTop: item.offset().top-55
                }, {duration: 200,easing: "swing"});
                setTimeout(() => {
                    // todo something scroll end
                    clearTimeout(this.scrollTimer);
                    this.barIndex = index;
                }, 300);
            }
        }
    }
</script>

<style>
    /*主体*/
    .books-cardetail {
        background: #d4d4d4;
        width: 100%;
        overflow-x: hidden;
        position: relative;
        padding-top: 100px;
    }

    .books-cardetail .top-bar{
        z-index: 2;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100px;
        line-height: 100px;
        background: #fff;
        color: #999999;
        font-size: 30px;
        text-align: left;
        white-space: nowrap;
        overflow-x: scroll;
        overflow-scrolling: touch;
        border-bottom: 1px solid #d4d4d4;
    }

    .books-cardetail .top-bar .bar-item{
        display: inline-block;
        padding: 0 20px;
    }

    .books-cardetail .top-bar .bar-item:first-child{
        padding-left: 30px;
    }

    .books-cardetail .top-bar .bar-item:last-child{
        padding-right: 30px;
    }

    ::-webkit-scrollbar{
    display: none;
        /* background-color:transparent; */
    }

    .books-cardetail .top-bar .bar-item .active{
        color: #333333;
        font-weight: bold;
    }

    .books-cardetail .container,books-cardetail .container .item{
        width: 100%;
    }

    .books-cardetail .container.padbottom{
        padding-bottom: 110px;
    }

    .books-cardetail .container .item-img{
        width: 100%;
    }

    .books-cardetail .bottom-order-btn{
        z-index: 20;
        width: 100%;
        height: 100px;
        line-height: 100px;
        font-size: 30px;
        position: fixed;
        bottom: 0;
        left: 0;
        text-align: center;
        color: #fff;
        background-image: linear-gradient(90deg,
        #ff5236 0%,
        #ff1700 100%),
        linear-gradient(
                #ff292d,
                #ff292d);
    }
</style>
