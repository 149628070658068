<template class="act240402">
    <div class="con" id="act240402">
        <div class="bg">
            <img class="img" src="https://qn.yiquan.com.cn/act/20240402/3447cf36bebc21002f14a2a80cf4729.png" />
        </div>
        <div class="bg bg-bottom">
            <div class="inpu-form">
                <div class="item-input-item">
                    <div class="item-line item-line-2">
                        <div class="left-box">
                            预约车型
                        </div>
                        <div class="right-box right-box-2">
                            小米 SU7
                        </div>
                    </div>
                </div>
                <div class="item-input-item">
                    <div class="item-line">
                        <div class="left-box">
                            <pre>姓&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;名</pre>
                        </div>
                        <div class="right-box right-box-2">
                            <input v-model="orderFormObj.name" :rules="[{ required: true, message: '姓名' }]" placeholder="请输入姓名">
                        </div>
                    </div>
                </div>
                <div class="item-input-item">
                    <div class="item-line">
                        <div class="left-box">
                            联系方式
                        </div>
                        <div class="right-box right-box-2">
                            <input  type="tel" v-model="orderFormObj.mobile" :rules="[{ required: true, message: '手机号'}]" placeholder="请输入手机号" maxlength="11">
                        </div>
                    </div>
                </div>
                <div class="item-input-info">*仅限上海指定门店参加</div>
            </div>
            <div class="submit-btn-contianer" @click="submitInfo">
                <div class="submit-btn">立即预约</div>
            </div>
        </div>
        <!--        提示弹窗-->
        <van-popup v-model="alertShow" :style="{ 'background-color': 'transparent'}">
            <!--提交订单弹窗-->
            <div class="popup-box"  >
                <img class="img" src="https://qn.yiquan.com.cn/act/20240402/41a73099f5e739e832870a0dcf60127.png" />
                <div class="close-btn"  @click="alertShow = false">我知道了</div>
                <div class="close-box" @click="alertShow= false"></div>
            </div>
        </van-popup>

    </div>
</template>

<script>
    import vue from 'vue';
    import {
        Button,
        Dialog,
        List,
        Popup,
        Field,
        Toast,
        AddressEdit
    } from 'vant';

    vue.use(Button, Dialog, Popup, List, Field, Toast, AddressEdit);
    export default {
        name: 'act240402',
        data() {
            return {
                orderFormObj: {
                    name: '',
                    mobile : '',
                    city : '选择城市',
                    car: '请选择意向品牌',
                },
                alertShow: false,
                showPicker: true,
                buttonLock : false,
                pageType : 1,

                localPicker: false,

            }
        },
        created() {},
        methods: {
            /**
             * 清空
             */
            clearTop: function() {
                setTimeout(function() {
                    var scrollHeight = document.documentElement.scrollTop || document.body.scrollTop || 0;
                    window.scrollTo(0, Math.max(scrollHeight - 1, 0));
                }, 100);
            },
            // 提交信息
            submitInfo() {
                let that = this;
                if (that.btnDisable) {
                    return false;
                }
                that.btnDisable = true;
                let name = that.orderFormObj.name;
                let mobile = that.orderFormObj.mobile;
                if (name.length == 0 || name.length > 6) {
                    Dialog.alert({
                        message: "请输入姓名！"
                    });
                    that.btnDisable = false;
                    return false;
                } else if (mobile.length != 11) {
                    Dialog.alert({
                        message: "请正确输入手机号！"
                    });
                    that.btnDisable = false;
                    return false;
                }

                // 提交收货信息  partyId：正式服3 测试服5
                that.$post("/submit240402Party", {
                    openId: that.$store.getters['user/getOpenId'],
                    NAME: name,
                    MOBILE: mobile,
                }).then((res) => {
                    if (res.code == 200) {
                        that.orderFormObj = {
                            name: '',
                            mobile : '',
                            city : '选择城市',
                            car: '请选择意向品牌',
                        };
                        that.btnDisable = false;

                        that.alertShow = true;
                    } else {
                        that.btnDisable = false;
                        Dialog.alert({
                            message: res.msg
                        });
                    }
                })
            },
            //改变状态
            changeTo1(){
                this.$router.push(
                    {
                        path: '/pact20230509',
                    })
            },
            closeAlert: function(){

            },
        },
    }
</script>

<style>
    /*展示内容*/
    #act240402{
        background: #fff;
        position: relative;
    }

    #act240402 .bg{
        width: 100%;
        position: relative;
    }

    #act240402 .bg.bg-bottom{
        padding-bottom: 100px;
        position: relative;
        width: 100%;
        z-index: 10;
        margin-top: -112px;
    }

    #act240402 .bg img{
        width: 100%;
    }

    #act240402 .bg .submit-btn-contianer{
        padding:  0 46px;
    }

    #act240402 .bg .submit-btn{
        height: 85px;
        line-height: 85px;
        background-color: #24272c;
        border-radius: 10px;
        color: #fff;
        letter-spacing: 2px;
        font-size: 36px;
    }

    #act240402 .bg .item-input-info{
        font-size: 22px;
        line-height: 43px;
        color: #797979;
        text-align: left;
        padding: 2px 0;
        padding-left: 46px;
        padding-top: 50px;
        padding-bottom: 24px;
    }

    #act240402 .popup-box{
        margin: auto;
        width: 550px;
    }

    #act240402 .popup-box img{
        width: 100%;
    }

    #act240402 .popup-box .inpu-form{
        padding: 0 70px;
    }

    #act240402 .popup-box .close-btn{
        background: #24272c;
        font-size: 32px;
        color: #fff;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        top: 500px;
        width: 390px;
        padding: 20px 0;
        border-radius: 6px;
        overflow: hidden;
    }

    #act240402 .popup-box .close-box{
        width: 80px;
        height: 80px;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        background: transparent;
        z-index: 20;
        top: 650px;
    }

    #act240402 .item-input-item{
        flex-wrap: nowrap;
        padding: 0 46px;
    }
    #act240402 .item-input-item .item-line{
        border-bottom: 1px solid  #e1e1e1;
        display: flex;
        width: 100%;
        line-height: 112px;
    }

    #act240402 .left-box{
        width: 176px;
        text-align: left;
        font-size: 30px;
        color: #797979;
        height: 112px;
    }
    #act240402 .right-box{
        text-align: left;
        height: 112px;
    }

    #act240402 .right-box-2{
        position: relative;
    }

    #act240402 .right-box input,#act240402 .right-box .item-alert-btn{
        width: 100%;
        border: none;
        border-radius: 3px;
        /*line-height: 52px;*/
        line-height: 24px;
        padding: 44px 0;
        font-size: 22px;
        text-align: left;
        color: #797979;
        background: transparent;
    }

    #act240402 .right-box .arrow-icon{
        position: absolute;
        top: 0;
        bottom: 0;
        right: 110px;
        width: 24px;
        height: 24px;
        margin: auto;
    }

    #act240402 .right-box input::-webkit-input-placeholder{
        letter-spacing: 0;
        color: #797979;
    }
</style>
