<template>
    <div class="glitter-list">
        <div class="car-list">
            <van-swipe class="my-swipe" indicator-color="white" vertical :loop="loop" ref="swipe" v-if="icode != 'swhy0819'">
                <van-swipe-item>
                    <img class="car-img" :src='pageUrl' align="middle">
                </van-swipe-item>
                <van-swipe-item>
                    <img class="car-img" src='http://api.yiquan.com.cn/act/six/six-page-4.jpg' align="middle">
                </van-swipe-item>
                <van-swipe-item>
                    <img class="car-img" src='http://api.yiquan.com.cn/act/six/six-page-2.jpg' align="middle">
                    <div class="video-show">
                        <video src="http://www.yiquantuan.com/jslib/video/ba8dff73e1f5fce52b7c6aae9f4a3cee.mp4"
                               poster="http://api.yiquan.com.cn/act/six/b9322b023c7c7923085da4251298e6b.jpg"
                               webkit-playsinline=""
                               playsinline="true"
                               controls controlsList="nodownload"
                               x5-video-player-type="h5"
                               x5-video-player-fullscreen="true"
                        ></video>
                    </div>
                </van-swipe-item>
                <van-swipe-item>
                    <img class="car-img" src='http://api.yiquan.com.cn/act/six/six-page-3.jpg' align="middle">
                </van-swipe-item>
                <template #indicator>
                    <div class="custom-indicator">
                    </div>
                </template>
            </van-swipe>

            <van-swipe class="my-swipe" indicator-color="white" vertical :loop="loop" ref="swipe" v-else>
                <van-swipe-item>
                    <img class="car-img" src="http://api.yiquan.com.cn/act/six/swhy/1.jpg" align="middle">
                </van-swipe-item>
                <van-swipe-item>
                    <img class="car-img" src='http://api.yiquan.com.cn/act/six/swhy/2.jpg' align="middle">
                    <div class="video-show show-2">
                        <video src="http://www.yiquantuan.com/jslib/video/ba8dff73e1f5fce52b7c6aae9f4a3cee.mp4"
                               poster="http://api.yiquan.com.cn/act/six/b9322b023c7c7923085da4251298e6b.jpg"
                               webkit-playsinline=""
                               playsinline="true"
                               controls controlsList="nodownload"
                               x5-video-player-type="h5"
                               x5-video-player-fullscreen="true"
                        ></video>
                    </div>
                </van-swipe-item>
                <van-swipe-item>
                    <img class="car-img" src='http://api.yiquan.com.cn/act/six/swhy/3.jpg' align="middle">
                </van-swipe-item>
                <van-swipe-item>
                    <img class="car-img" src='http://api.yiquan.com.cn/act/six/swhy/4.jpg' align="middle">
                </van-swipe-item>
                <van-swipe-item>
                    <img class="car-img" src='http://api.yiquan.com.cn/act/six/swhy/5.jpg' align="middle">
                </van-swipe-item>
                <van-swipe-item>
                    <img class="car-img" src='http://api.yiquan.com.cn/act/six/swhy/6.jpg' align="middle">
                </van-swipe-item>
                <van-swipe-item>
                    <img class="car-img" src='http://api.yiquan.com.cn/act/six/swhy/7.jpg' align="middle">
                </van-swipe-item>
                <van-swipe-item>
                    <img class="car-img" src='http://api.yiquan.com.cn/act/six/swhy/8.jpg' align="middle">
                </van-swipe-item>
                <van-swipe-item>
                    <img class="car-img" src='http://api.yiquan.com.cn/act/six/swhy/9.jpg' align="middle">
                </van-swipe-item>
                <template #indicator>
                    <div class="custom-indicator">
                    </div>
                </template>
            </van-swipe>
        </div>
    </div>
</template>

<script>
    import vue from 'vue';
    import {Button, Dialog,Swipe, SwipeItem } from 'vant';

    vue.use(Button).use(Dialog).use(Swipe).use(SwipeItem);
    export default {
        name: 'books-index',
        created: function(){
            let that = this;

            wx.ready(function(){
                wx.hideAllNonBaseMenuItem();

                wx.showMenuItems({
                    menuList: ['menuItem:share:appMessage', 'menuItem:share:timeline'] // 要显示的菜单项，所有menu项见附录3
                });

                let baseUrl = that.$wechat.baseUrl;
                wx.updateAppMessageShareData({
                    title: '五周年感恩回馈', // 分享标题
                    desc: that.custName + '员工点击领取666元员工购车现金抵用券', // 分享描述
                    link: baseUrl + '/litter/gratitude?icode='+that.icode , // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: 'http://api.yiquan.com.cn/act/six/51e9430c0c1ae8cefb726c64b5eac46.png', // 分享图标
                    success: function () {
                        // 设置成功
                    }
                });

                wx.updateTimelineShareData({
                    title: that.custName + '员工点击领取666元员工购车现金抵用券', // 分享标题
                    link: baseUrl + '/litter/gratitude?icode='+that.icode , // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: 'http://api.yiquan.com.cn/act/six/51e9430c0c1ae8cefb726c64b5eac46.png', // 分享图标
                    success: function () {
                        // 设置成功
                    }
                });
            });

            let query = this.$route.query;
            if(query.icode != undefined){
                this.icode = query.icode;
                let icode = query.icode;

                //用户企业对应是否相同
                this.$post('/userCustState', {
                    openId : this.$store.getters['user/getOpenId'],
                }).then((res) => {
                    if(res.code == 200){
                        let invCode = icode;
                        let data =  res.data;
                        if(data.id == 0){
                            //绑定企业
                            this.$post('/userBindCust', {
                                openId : this.$store.getters['user/getOpenId'],
                                code :  invCode,
                            }).then((res) => {
                                if(res.code == 200){}
                            });
                        }
                    }
                });
            }
            switch (query.icode) {
                case 'coscoshipping':
                    //中国远洋海运集团有限公司
                    this.pageUrl = 'http://api.yiquan.com.cn/act/six/six-page-zyhy.jpg';
                    this.custName = '中远海运';
                    break;
                case 'gtja':
                    //国泰君安证券股份有限公司
                    this.pageUrl = 'http://api.yiquan.com.cn/act/six/six-page-gtja.jpg';
                    this.custName = '国泰君安';
                    break;
                case 'SIG2018':
                    //上海国际集团有限公司
                    this.pageUrl = 'http://api.yiquan.com.cn/act/six/six-page-gtja.jpg';
                    this.custName = '上海国际';
                    break;
                case 'baowu130':
                    //中国宝武钢铁集团有限公司
                    this.pageUrl = 'http://api.yiquan.com.cn/act/six/six-page-baowu.jpg';
                    this.custName = '宝武集团';
                    break;
                case 'ciecc':
                    //中国国际工程咨询有限公司
                    this.pageUrl = 'http://api.yiquan.com.cn/act/six/six-page-gjgc.jpg';
                    this.custName = '中资';
                    break;
                case '20200610135145':
                    //中国航空发动机集团有限公司
                    this.pageUrl = 'http://api.yiquan.com.cn/act/six/six-page-hkfdj.jpg';
                    this.custName = '中国航发';
                    break;
                case 'airchina':
                    //中国航空集团有限公司
                    this.pageUrl = 'http://api.yiquan.com.cn/act/six/six-page-zghk.jpg';
                    this.custName = '中国航空';
                    break;
                case 'jsyh':
                    //中国建设银行
                    this.pageUrl = 'http://api.yiquan.com.cn/act/six/six-page-js.jpg';
                    this.custName = '建设银行';
                    break;
                case 'cic2018':
                    //中国投资有限责任公司
                    this.pageUrl = 'http://api.yiquan.com.cn/act/six/six-page-zt.jpg';
                    this.custName = '中投集团';
                    break;
                case 'zgyl0831':
                    //中国银联股份有限公司
                    this.pageUrl = 'http://api.yiquan.com.cn/act/six/six-page-yl.jpg';
                    this.custName = '中国银联';
                    break;
                case 'zgyd0706':
                    //中国移动通信集团有限公司
                    this.pageUrl = 'http://api.yiquan.com.cn/act/six/six-page-zgyd.jpg';
                    this.custName = '中国移动';
                    break;
                case 'cmec2018':
                    //中国机械设备工程股份有限公司
                    this.pageUrl = 'http://api.yiquan.com.cn/act/six/six-page-zgjxsb.jpg';
                    this.custName = '中机';
                    break;
                case 'zgsf0701':
                    //中国商用飞机有限责任公司
                    this.pageUrl = 'http://api.yiquan.com.cn/act/six/six-page-sf.jpg';
                    this.custName = '中国商飞';
                    break;
                case 'swhy0819':
                    //申万宏源证券有限公司
                    this.pageUrl = 'http://api.yiquan.com.cn/act/six/six-page-swhy.jpg';
                    this.custName = '申万宏源';
                    break;
                case 'tk0613':
                    //泰康保险集团
                    this.pageUrl = 'http://api.yiquan.com.cn/act/six/six-page-tk.jpg';
                    this.custName = '泰康保险';
                    break;
                case 'jtyh0811':
                    //交通银行股份有限公司
                    this.pageUrl = 'http://api.yiquan.com.cn/act/six/six-page-jt.jpg';
                    this.custName = '交通银行';
                    break;
                case 'zgdx':
                    //中国电信集团有限公司
                    this.pageUrl = 'http://api.yiquan.com.cn/act/six/six-page-dx.jpg';
                    this.custName = '中国电信';
                    break;
                case 'pfyh':
                    //浦发银行
                    this.pageUrl = 'http://api.yiquan.com.cn/act/six/six-page-pf.jpg';
                    this.custName = '浦发银行';
                    break;
                default:
                    this.pageUrl = 'http://api.yiquan.com.cn/act/six/six-page-common.jpg';
                    this.custName = '尊敬的';
                    break;
            }


        },
        data() {
            return {
                loadShow : true,
                loop : false,
                buttonClass : '',
                listClass : '',
                type: 1,
                pageUrl : 'http://api.yiquan.com.cn/act/six/six-page-common.jpg',
                shareTitle : '',
                icode : '',
                custName : '',
            }
        },
        methods: {}
    }
</script>

<style>
    /*主体*/
    page{
        height: 100%;
    }
    .glitter-list {
        background: #d4d4d4;
        height: 100%;
        width: 100%;
    }
    .glitter-list .car-list{
        height: 100%;
        width: 100%;
        position: relative;
    }

    .glitter-list .van-swipe{
        width: 100%;
        height: 100%;
    }

    .glitter-list .van-swipe-item{
        width: 100%;
        height: 100%;
        text-align: center;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    .glitter-list .car-img{
        width: 100%;
        vertical-align:middle;
    }

    .glitter-list .left-box .left-button img{
        height: 110px;
        width: 60px;
        right: 18px;
        top:0;
        bottom: 0;
        margin: auto 0;
        position: absolute;

    }

    .glitter-list .btn-item{
        position: absolute;
        height: 1200px;
        width: 100%;
    }

    .glitter-list .btn-item .car-img2{
        position: absolute;
        top: 80%;
        left: 0;
        right: 0;
        margin: auto;
        width: 36px;
    }
    .glitter-list .btn-item-1 .car-img2,.glitter-list .btn-item-2 .car-img2{
        animation:iconmove2 2.8s infinite;
    }
    .glitter-list .btn-item .car-img3{
        position: absolute;
        bottom: 5%;
        width: 615px;
        padding-left: 5px;
        left: 0;
        right: 0;
        margin: auto;
    }
    .glitter-list .btn-item-3 .car-img3{
        top: 58%;
        bottom: auto;
    }
    .glitter-list .btn-item-3 .car-img2{
        top: 50%;
        animation:iconmove3 2.8s infinite;
    }

    .glitter-list .video-show{
        position: absolute;
        height: 400px;
        width: 478px;
        left: 0;
        right: 0;
        margin: auto;
        margin-top: 280px;
        background: rgba(0, 0, 0, .4);
        z-index: 0;
    }

    .glitter-list .video-show.show-2{
        position: absolute;
        height: 400px;
        width: 478px;
        left: 0;
        right: 0;
        margin: auto;
        margin-top: 320px;
        background: rgba(0, 0, 0, .4);
        z-index: 0;
    }

    .glitter-list .video-show video{
        height: 100%;
        width: 100%;
    }

    @keyframes iconmove2
    {
        0% {top:80%;}
        1% {top:80%;}
        49% {top:82%;}
        51% {top:82%;}
        99% {top:80%;}
        100% {top:80%;}
    }

    @-webkit-keyframes iconmove2 /*Safari and Chrome*/
    {
        0% {top:80%;}
        1% {top:80%;}
        49% {top:82%;}
        51% {top:82%;}
        99% {top:80%;}
        100% {top:80%;}
    }

    @keyframes iconmove3
    {
        0% {top:50%;}
        1% {top:50%;}
        49% {top:52%;}
        51% {top:52%;}
        99% {top:50%;}
        100% {top:50%;}
    }

    @-webkit-keyframes iconmove3 /*Safari and Chrome*/
    {
        0% {top:50%;}
        1% {top:50%;}
        49% {top:52%;}
        51% {top:52%;}
        99% {top:50%;}
        100% {top:50%;}
    }
</style>
