<template>
    <div class="author">
    </div>
</template>

<script>
    export default {
        name: 'jump',
        data() {
            return {}
        },
        created: function () {
            var code = this.$route.params.code;
            switch (code) {
                case '6jfvco':
                    window.location.replace("/series?markId=1047&provinceId=25&cityId=2501");
                    return;
                default:
                    window.location.replace("/index");
                    return;
            }
        },
        methods: {}
    }
</script>

<style>
    .author {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        padding: 0 45px;
    }
</style>
