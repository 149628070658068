<template>
    <div class="spcar-index">
        <form @submit.prevent="submitForm">
            <!--    头部banner主体-->
            <van-swipe :autoplay="3000" :show-indicators="false" class="banner" >
                <van-swipe-item v-for="(image, index) in banner" :key="index" @click="navToBannerUrl(index)">
                    <img :src="image">
                </van-swipe-item>
            </van-swipe>
            <div class="container-center">
                <p class="reminder-info">仅协议企业员工可以享受优惠</p>
                <div class="cell-container area-cell" :class="hasLocalCss">
                    <van-cell is-link @click="showLocalPopup" :value="localVal"/>
                    <img src="/images/icon_area.png">
                </div>
            </div>


            <van-button class="submit-btn" type="info" size="large">确定</van-button>
        </form>
        <van-popup v-model="showPicker" position="bottom" :close-on-click-overlay="false" safe-area-inset-bottom>
            <van-picker
                    title="请选择所在城市"
                    show-toolbar
                    :columns="columns"
                    value-key = "content"
                    @change="onLocalChange"
                    @cancel="showPicker = false"
                    @confirm="onLocalConfirm"
            ></van-picker>
        </van-popup>

        <van-overlay :show="loadShow"  class="overlay-div">
            <van-loading type="spinner"  class="load"/>
        </van-overlay>
    </div>
</template>

<script>
    import vue from 'vue';
    import {Button, Cell , Icon , Swipe, SwipeItem,  Popup, Picker, Dialog} from 'vant';

    vue.use(Button).use(Cell).use(Icon).use(Swipe).use(SwipeItem).use(Popup).use(Picker).use(Dialog);

    let citys = {};
    export default {
        name: 'car-brand',
        created: function(){
            //获取城市信息
            this.$post('/getCityList', {
            }).then((res) => {
                if(res.code == 200){
                    citys = res.data;
                    this.citys = citys;
                    this.columns = [
                        {
                            values: citys,
                            className: 'column1',
                        },
                            {
                                values: citys[0]['list'],
                                className: 'column2',
                            }
                        ];
                }else{
                    Dialog.alert({
                        message: res.msg
                    }).then(() => {
                        // on close
                    });
                }
            }).catch(
                (e) =>{
                    window.console.log(e);
                }
            );

            //获取token
            let token = this.$store.getters['user/getToken'];
            //判断token是否存在
            if(token == '' || token == null){
                //不存在手动获取
                let data = {
                    'appId' :this.$wechat.appId,
                    'openId' : this.$store.getters['user/getOpenId'],
                };
                this.$post('/getToken', data).then((res) => {
                    if(res.code == 200){
                        let token = res.data.token;
                        this.$store.commit('user/setToken', token);
                    }else{
                        window.console.log('验证失败');
                    }
                })
            }

            //获取用户企业展示图
            this.$post('/getUserCustBanner', {
                'openId' : this.$store.getters['user/getOpenId'],
                'type' : 2,
            }).then((res) => {
                if(res.code == 200){
                    let banner = res.data;
                    if(banner.length>0){
                        let list = [];
                        let len = banner.length;
                        for(var i = 0; i < len; i++){
                            list.push(banner[i]['file'])
                        }
                        this.banner = list;
                    }else{
                        this.banner = [
                            '/images/banner1.jpg',
                        ]
                    }
                }else{
                    Dialog.alert({
                        message: res.msg
                    }).then(() => {
                        // on close
                    });
                }
            }).catch(
                (e) =>{
                    window.console.log(e);
                }
            )
        },
        data() {
            return {
                banner: [],
                value: '',
                showPicker: false,
                showPicker2 : false,
                citys : citys,
                columns: [
                    {
                        values: Object.keys(citys),
                    },
                    {
                        values: '',
                        className: 'column2',
                        defaultIndex: 2
                    }
                ],
                localVal : "请选择所在城市",
                localVals : [],
                hasLocalCss : '',
                hasMarkCss : '',
                loadShow : false
            }
        },
        methods: {
            /**
             * 展示地址底部弹窗
             */
            showLocalPopup(){
                this.$data.showPicker = true;
            },
            /**
             * 地址选择变化
             */
            onLocalChange(picker) {
                let indexs = picker.getIndexes();
                picker.setColumnValues(1, this.citys[indexs[0]]['list']);
            },
            /**
             * 地址选择确认
             */
            onLocalConfirm(value){
                this.localVal = value[0]['content'] + '-' + value[1]['content'];
                this.localVals = value;
                this.hasLocalCss = "hasLocalCss";
                this.$data.showPicker = false;
            },
            /**
             * 提交表单
             * @returns {boolean}
             */
            submitForm(){
                if(this.localVals.length <= 0 || (typeof this.localVals[0]['id'] == 'undefined') || (typeof this.localVals[1]['id'] == 'undefined')){
                    Dialog.alert({
                        message: '请选择所在城市'
                    }).then(() => {
                        // on close
                    });
                    return false;
                }
                let provinceId = this.localVals[0]['id'];
                let cityId = this.localVals[1]['id'];

                this.$store.commit('local/setProvinceId', provinceId);
                this.$store.commit('local/setCityId', cityId);
                this.$store.commit('local/setLocal', this.localVal);

                this.$router.push({
                    path: "/sp-series",
                });
                return false;
            },
            /**
             * banner跳转
             */
            navToBannerUrl(index){
                if(!this.isCityShhow()) return false;
                var item = this.banner[index];

                if(item.fileurl != '' && item.fileurl != null){
                    window.location.href = item.fileurl;
                }else{
                }
            },
        }
    }
</script>

<style>
    /*主体*/
    .spcar-index {
        background: #fff;
        height: 100%;
    }
    .spcar-index  .banner {
        width: 100%;
    }
    .spcar-index .banner .van-swipe__track{
        width: 100%;
    }
    .spcar-index .banner img{
        width: 100%;
    }
    .spcar-index .container-center{
        width: 100%;
        padding: 0 56px;
        margin-top: 100px;
        color: #999;
    }
    .spcar-index .container-center .reminder-info{
        /*font-family: "PingFangSC-Medium";*/
        font-size: 30px;
        font-weight: normal;
        font-stretch: normal;
        text-align: left;
        color: #f93204;
        padding-bottom: 40px;
    }
    .spcar-index .container-center .cell-container{
        height: 100px;
        border-bottom: 1px solid #e7e7e7;
        border-top: 1px solid #e7e7e7;
        box-sizing: content-box;
        position: relative;
    }
    .spcar-index .container-center .area-cell{
        border-bottom: none;
    }
    .spcar-index .container-center .van-cell{
        height: 100px;
        padding-left: 100px;
    }
    .spcar-index .container-center .van-cell .van-cell__value{
        color: #979797;
    }
    .spcar-index .container-center .hasLocalCss .van-cell__value{
        color: #000;
    }
    .spcar-index .container-center .cell-container img{
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto 0;
        height: 50px;
        width: 55px;
    }
    .spcar-index .submit-btn{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 105px;
        line-height: 105px;
    }
    .spcar-index .brand-list .brand-item{
        position: relative;
        height: 150px;
        width: 100%;
        border-bottom: 1px solid #e7e7e7;
        line-height: 150px;
        text-align: left;
        padding-left: 180px;
    }
    .spcar-index .brand-list .brand-item img{
        width: 120px;
        height: 120px;
        position: absolute;
        left: 36px;
        top: 0;
        bottom: 0;
        margin: auto 0;
    }
    .spcar-index .overlay-div{
        position: fixed;
    }
    .overlay-div .load{
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
    .overlay-div .load .van-loading__spinner{
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
</style>
