<template>
    <div class="personal-index">
        <div class="top">
             <img src="https://qn.yiquan.com.cn/web/202401/p-banner.jpg">
<!--             <img src="https://qn.yiquan.com.cn/bg/20211025135845.jpg">-->
            <div class="user-info">
                <img class="head-img" :src="user.img">
                <label v-if=" hasUserId == true">
                    <p class="info-item user-name">{{user.mobile}}</p>
                    <p class="info-item user-cust">{{user.custName}}</p>
                </label>
                <label v-else>
                    <p class="info-item login-item">点击登录</p>
                </label>
            </div>
        </div>
        <div class="middle">
            <div class="middle-btns">
                <div class="btn-item btn-left">
                    <div class="cell-container coupon-cell">
                        <van-cell is-link @click="navToCoupon" title="优惠券" :value="couponNum"/>
                        <!-- <img class="coupon-img" src="/images/personal-coupon.png"> -->
						<i class="coupon-img personal-coupon1"></i>
                    </div>
                </div>
                <div class="btn-item  btn-right"  @click="callTel">
                    <div class="cell-container service-cell">
                        <van-cell is-link value="联系客服"/>
                        <!-- <img class="service-img" src="/images/personal-service.png"> -->
						<i class="service-img personal-service"></i>
                    </div>
                </div>
            </div>
            <div class="cell-container area-cell">
                <van-cell is-link @click="navToOrderList" value="我的订单"/>
                <!-- <img src="/images/personal-order.png"> -->
				<i class="personal-icon personal-order"></i>
            </div>
            <div class="cell-container car-cell">
                <van-cell is-link @click="navToUserInfo" value="个人信息"/>
                <!-- <img src="/images/personal-user.png"> -->
				<i class="personal-icon personal-user"></i>
            </div>
            <div class="cell-container car-cell">
                <van-cell is-link @click="navToCustInfo" value="变更客户属性"/>
                <!-- <img src="/images/personal-custchange.png"> -->
				<i class="personal-icon personal-custchange"></i>
            </div>
            <div class="cell-container car-cell">
                <van-cell is-link @click="navToFeedback" value="意见反馈"/>
                <!-- <img src="/images/personal-feedback.png"> -->
				<i class="personal-icon personal-feedback"></i>
            </div>
            <div class="cell-container car-cell">
                <van-cell is-link @click="alertShadowInfo" value="邀请同事"/>
                <!-- <img src="/images/personal-invite.png"> -->
				<i class="personal-icon personal-invite"></i>
            </div>
        </div>

        <van-overlay :show="shadowInfo" class="overlay-div" @click="shadowInfo = false">
            <img class="overlay-info" src="/images/share-info.png">
        </van-overlay>


        <div class="login-box-x" @click="navToLogin" v-if="hasUserId == false"></div>
    </div>
</template>

<script>
    import vue from 'vue';
    import { Button, Cell, Dialog} from 'vant';

    vue.use(Button).use(Cell).use(Dialog);
    export default {
        name: 'personal',
        created(){
            let that = this;
			//获取token
			let token = this.$store.getters['user/getToken'];
			//判断token是否存在
			if(token == '' || token == null){
			    //不存在手动获取
			    let data = {
			        'appId' :this.$wechat.appId,
			        'openId' : this.$store.getters['user/getOpenId'],
			    };
			    this.$getToken('/getToken', data).then((res) => {
			        if(res.code == 200){
			            let token = res.data.token;
			            that.$store.commit('user/setToken', token);
						//有token执行下面方法
						setTimeout(function() {
							that.commCreated();
						})
					}
				})
			}else {
				this.commCreated();
			}

            wx.ready(function(){
                wx.hideAllNonBaseMenuItem();

                wx.showMenuItems({
                    menuList: ['menuItem:share:appMessage'] // 要显示的菜单项，所有menu项见附录3
                });

                let baseUrl = that.$wechat.baseUrl;
                let custId = that.$store.getters['user/getCustId'];
                wx.updateAppMessageShareData({
                    title: '员工购车服务平台', // 分享标题
                    desc: that.user.custName + '在职员工专属优惠购车平台', // 分享描述
                    link: baseUrl + '/index/?custInfo=' + custId, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: baseUrl + '/images/icon/icon2.jpg', // 分享图标
                    success: function () {
                        // 设置成功
                    }
                });
            });
        },
        data() {
            return {
                'user' : {
                    // 'img' : "/images/user-default-img-five.png",
                    'img' : "http://qn.yiquan.com.cn/web/spring2021/touxiang.png",
                    'name' : '',
                    'mobile' : '',
                    'custName' : '',
                },
                hasUserId: true,
                'couponNum' : 0,
                'shadowInfo' : false,
            }
        },
        methods: {
			// 公共创建方法-新增方法
			commCreated() {
				let that = this;
				let data = {
				    'appId' :that.$wechat.appId,
				    'openId' : that.$store.getters['user/getOpenId'],
				};
				that.$post('/v2/getUserBase', data).then((res) => {
				    if(res.code == 200){
				        let data = res.data;
				        that.user['name'] = data['name'];
				        that.user['mobile'] = data['mobile'];
				        that.user['custName'] = data['custName'];
				        that.hasUserId = data['hasUserId'];
				        that.couponNum = data['couponCount'];
				    }else{
				        Dialog.alert({
				            message: res.msg,
				        }).then(() => {
				            //清空数据
				            that.$store.commit('user/setOpenId', '');
				            // on close 跳转到首页
				            window.location.href = '/index';
				        });
				    }
				});
			},
            //前往订单列表
            navToOrderList(){
                this.$router.push({
                    path: '/orderList'
                });
            },
            //前往用户修改
            navToUserInfo(){
                this.$router.push({
                    path: '/userinfo'
                });
            },
            navToCustInfo(){
                this.$router.push({
                    path: '/custinfo'
                });
            },
            navToFeedback(){
                this.$router.push({
                    path: '/feedback'
                });
            },
            navToCardFile(){
                this.$router.push({
                    path: '/cardfile'
                });
            },
            navToCoupon(){
                this.$router.push({
                    path : '/coupon'
                });
            },
            navToLogin(){
                this.$router.push({
                    path : '/login'
                });
            },
            alertShadowInfo(){
                 this.shadowInfo =  true;
            },
            callTel(){
                window.location.href = 'tel://400-000-2787';
            }
        }
    }
</script>

<style>
    /*个人中心*/
    .personal-index {
        background: #f1f0f6;
        position: relative;
        background: white;
        min-height: 100%;
    }
    .personal-index .top{
        width: 100%;
        position: relative;
        z-index: 1;
    }
    .personal-index .top img{
        width: 100%;
    }
    .personal-index .top .user-info{
        height: 200px;
        position: absolute;
        top: 80px;
        left: 30px;
        /* color: #fff; */
		/*color: #ab8661;*/
		color: #fff;
    }
    .personal-index .top .head-img{
        width: 120px;
        height: 120px;
        border-radius: 50%;
        overflow: hidden;
        position: absolute;
        left: 0;
        top: 0;
        margin: auto;
    }
    .personal-index .top .info-item{
        width: 100%;
        text-align: left;
        padding-left: 148px;
        padding-top: 26px;
    }
    .personal-index .top .login-item{
        padding-top: 40px;
    }
    .personal-index .top .user-cust{
        font-size: 22px;
        /* color: #fff; */
		/*color: #ab8661;*/
		color: #fff;
    }
    .personal-index .middle{
        width: 100%;
        padding-top: 38px;
        border-top-right-radius: 25px;
        border-top-left-radius: 25px;
        margin-top: -38px;
        background: #fff;
        position: absolute;
        z-index: 10;
    }
    .personal-index .middle .middle-btns{
        overflow:hidden;
        width: 100%;
    }
    .personal-index .middle .middle-btns .btn-item{
        width: 50%;
        float: left;
    }
    .personal-index .middle .middle-btns .btn-item .coupon-cell .van-cell__value{
        color: #ff3039;
    }
    .personal-index .middle .middle-btns .btn-item img.coupon-img{
        width: 60px;
        height: 40px;
        left: 16px;
    }
    .personal-index .middle .middle-btns .btn-item img.service-img{
        width: 42px;
        height: 42px;
        left: 40px;
    }
    .personal-index .middle .middle-btns .btn-item.btn-left{
        padding-left: 30px;
        padding-right: 15px;
    }
    .personal-index .middle .middle-btns .btn-item.btn-right{
        padding-left: 15px;
        padding-right: 30px;
    }
    .personal-index .middle .middle-btns .btn-item .cell-container{
        background: #f6f6f6;
        border-radius: 15px;
    }
    .personal-index .middle .middle-btns .btn-item .van-cell{
        background: #f6f6f6;
    }
    .personal-index .middle .cell-container{
        padding: 20px 0;
        border-bottom: 1px solid #e7e7e7;
        border-top: 1px solid #e7e7e7;
        box-sizing: content-box;
        position: relative;
    }
    .personal-index .middle .area-cell{
        border-bottom: none;
    }
    .personal-index .middle .cell-container{
        border: none;
    }
    .personal-index .middle .van-cell:not(:last-child)::after{
        border: none;
    }
    .personal-index .middle .van-cell{
        padding-left: 100px;
        margin: auto 0;
    }
    .personal-index .coupon-cell .van-cell{
        white-space: nowrap;
        padding-left: 86px;
    }
    .personal-index .middle .cell-container img{
         position: absolute;
         left: 52px;
         top: 0;
         bottom: 0;
         margin: auto 0;
         height: 34px;
         width: 34px;
     }
    .personal-index .overlay-div{
        z-index: 20!important;
    }
    .personal-index .overlay-div .overlay-info{
        position: fixed;
        top: 0;
        width: 66%;
        right: 45px;
    }
	/* 雪碧图 */
	.personal-icon {
		display: inline-block;
		position: absolute;
		left: 52px;
		top: 0;
		bottom: 0;
		margin: auto 0;
		height: 34px;
		width: 34px;
	}
	.coupon-img, .service-img {
		display: inline-block;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 16px;
		margin: auto 0;
		width: 60px;
		height: 40px;
	}
	.personal-coupon1 {
		background: url("https://qn.yiquan.com.cn/web/common/personal-icon.png") no-repeat 0 -44px;
		background-size: 260px 90px;
	}
	.personal-service {
		left: 40px;
		width: 42px;
		height: 42px;
		background: url("https://qn.yiquan.com.cn/web/common/personal-icon.png") no-repeat -70px -44px;
		background-size: 260px 90px;
	}
	.personal-order {
		background: url("https://qn.yiquan.com.cn/web/common/personal-icon.png") no-repeat 0 0;
		background-size: 260px 90px;
	}
	.personal-member {
		background: url("https://qn.yiquan.com.cn/web/common/personal-icon.png") no-repeat -44px 0;
		background-size: 260px 90px;
	}
	.personal-user {
		background: url("https://qn.yiquan.com.cn/web/common/personal-icon.png") no-repeat -88px 0;
		background-size: 260px 90px;
	}
	.personal-custchange {
		background: url("https://qn.yiquan.com.cn/web/common/personal-icon.png") no-repeat -132px 0;
		background-size: 260px 90px;
	}
	.personal-feedback {
		background: url("https://qn.yiquan.com.cn/web/common/personal-icon.png") no-repeat -176px 0;
		background-size: 260px 90px;
	}
	.personal-invite {
		background: url("https://qn.yiquan.com.cn/web/common/personal-icon.png") no-repeat -220px 0;
		background-size: 260px 90px;
	}

    .login-box-x{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        height: 100%;
        width: 100%;
        z-index: 10000;
    }
</style>