<template>
    <div class="author">
    </div>
</template>

<script>
    export default {
        name: 'clearPath',
        data() {
            return {}
        },
        created: function () {
            sessionStorage.clear();
            localStorage.clear();

            this.$router.push({
                path: '/author'
            });
        },
        methods: {}
    }
</script>

<style>
    .author {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        padding: 0 45px;
    }
</style>
