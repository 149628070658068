<template>
    <div class="blank-page">
        <!-- 绑定用户和企业 -->
        <van-overlay :show="logShow">
            <div class="wrapper wrapper-alert" @click.stop>
                <div class="log-block six-block">
<!--                    <div class="close-btn" @click="clickClose()">x</div>-->
                    <div class="title">
                        <p>注册</p>
                    </div>
                    <form class="submit-container" @submit.prevent="logForm">
                        <div class="form-input cust-container" v-if="showCode">
                            <input placeholder="请输入邀请码" v-model="logFormObj.code" @blur="clearTop"/>
                            <img src="/images/lab_y.png">
                        </div>
                        <div class="form-input mobile-container">
                            <input placeholder="请输入手机号" v-model="logFormObj.mobile" maxlength="11" @blur="clearTop"/>
                            <img src="/images/lab_m.png">
                            <div class="mobile-btn" @click="getMobileToken()">{{tokenBtnMsg}}</div>
                        </div>
                        <div class="form-input pwd-container">
                            <input placeholder="请输入验证码" v-model="logFormObj.token" @blur="clearTop" maxlength="6"/>
                            <img src="/images/lab_c-2.png">
                        </div>
                        <div class="warn-info">
                            <span v-if="alertWarnLv == 1">请输入正确的手机号</span>
                            <span v-else-if="alertWarnLv == 2">验证码错误</span>
                            <span v-else-if="alertWarnLv == 3">{{alertWarnContent}}</span>
                            <span v-else-if="alertWarnLv == 4">邀请码错误</span>
                        </div>

                        <van-button class="submit-btn" round type="info" size="large" native-type="submit">
                            <span v-if="showCode">确认</span>
                            <span v-else>确认</span>
                        </van-button>
                    </form>
                </div>
            </div>
        </van-overlay>
    </div>
</template>

<script>
    import vue from 'vue';
    import {Button, Dialog, Popup, Field, Toast} from 'vant';

    vue.use(Button).use(Dialog).use(Popup).use(Field).use(Toast);

    export default {
        name: 'blank-page',
        created: function () {
            //判断弹窗
            this.hasUserInfo().then((res) => {
                this.navDetail();
            });
        },
        data() {
            return {
                logShow: false,
                //主页身份登记
                showCode: true,
                logFormObj: {
                    mobile: '',
                    token: '',
                    code: ''
                },
                alertWarnLv: 0, //提示等级 0:无提示 1：请输入正确的手机号 2：验证码错误 3：其他 4：邀请码错误
                alertWarnContent: '',
                tokenBtnMsg: '获取验证码',
                deadline: 60,
                btnDisable: false,
            }
        },
        methods: {
            hasUserInfo(){
                // getUserInfoByOpenId
                return new Promise((resolve, reject) => {
                    this.$post('/getUserInfoByOpenId',{
                        openId : this.$store.getters['user/getOpenId']
                    }).then((res) => {
                        if(res.code == 200){
                            //判断是否有 所属企业和用户
                            let type = 3;
                            let userInfo = res.data;
                            if (userInfo.custId == 0) {
                                //无企业
                                this.showCode = true;
                                type = 1;
                            } else {
                                //有企业
                                this.showCode = false;
                                if (userInfo.userId == 0) {
                                    //无用户
                                    type = 2;
                                }
                            }
                            if (type == 3) {
                                //既有企业也有用户-弹出意向表
                                resolve(res);
                            } else {
                                //无-根据情况弹出定制弹窗
                                this.logShow = true;
                            }
                        }else{
                            Dialog.alert({
                                message: res.msg
                            }).then(() => {
                                // on close
                                // this.$router.push('/index');
                            });
                        }
                    });
                });
            },
            /**
             * 清空
             */
            clearTop: function () {
                setTimeout(function () {
                    var scrollHeight = document.documentElement.scrollTop || document.body.scrollTop || 0;
                    window.scrollTo(0, Math.max(scrollHeight - 1, 0));
                }, 100);
            },

            /**
             * 阻止提交
             * @returns {boolean}
             */
            logForm: function () {
                if (this.showCode) {
                    //绑定企业
                    this.submitCustCode();
                } else {
                    //直接提交绑定手机号
                    this.submitBingMobile();
                }
                return false;
            },
            /**
             * 绑定所属企业
             */
            submitCustCode: function () {
                let code = this.logFormObj.code;
                if (code == '') {
                    this.alertWarnLv = 4;
                    return false
                }
                //请求后端判断邀请码是否正确
                this.$post('/userBindCust', {
                    openId: this.$store.getters['user/getOpenId'],
                    code: code,
                }).then((res) => {
                    if (res.code == 200) {
                        this.showCode = false;
                        //提交绑定手机号
                        this.submitBingMobile();
                    } else {
                        this.alertWarnLv = 4;
                    }
                });
            },
            /**
             * 获取手机验证码
             */
            getMobileToken: function () {
                if (this.btnDisable) {
                    return false;
                }
                this.btnDisable = true;
                let mobile = this.logFormObj.mobile;
                if (this.isPhone(mobile)) {
                    this.alertWarnLv = 0;
                    this.$post('/userGetRand', {
                        openId: this.$store.getters['user/getOpenId'],
                        mobile: mobile,
                    }).then((res) => {
                        if (res.code == 200) {
                            //循环倒计时
                            this.tokenBtnMsg = this.deadline + 's';
                            this.int_btnClock = window.setInterval(() => {
                                if (this.deadline <= 0) {
                                    window.clearInterval(this.int_btnClock);
                                    this.tokenBtnMsg = '获取验证码';
                                    this.deadline = 60;
                                    this.btnDisable = false;
                                    return;
                                }
                                this.deadline--;
                                this.tokenBtnMsg = this.deadline + 's';
                            }, 1000);
                        } else {
                            this.btnDisable = false;
                            this.alertWarnLv = 3;
                            this.alertWarnContent = res.msg;
                        }
                    }).catch(e => {
                        window.console.log(e);
                        this.btnDisable = false;
                        this.alertWarnLv = 3;
                        this.alertWarnContent = '网路异常请稍后重试';
                    });
                } else {
                    this.alertWarnLv = 1;
                    this.btnDisable = false;
                }
                return false
            },
            /**
             *  提交绑定手机号
             * @returns {boolean}
             */
            submitBingMobile: function () {
                //绑定手机
                let url = '/userBindMobile';
                //请求绑定
                let mobile = this.logFormObj.mobile;
                let code = this.logFormObj.token;

                if (this.isPhone(mobile)) {
                    //判断验证码
                    if (code == '' || typeof code == 'undefined') {
                        this.alertWarnLv = 3;
                        this.alertWarnContent = '请输入验证码';
                        return false;
                    }

                    this.alertWarnLv = 0;
                    this.$post(url, {
                        openId: this.$store.getters['user/getOpenId'],
                        code: code,
                        mobile: mobile,
                    }).then((res) => {
                        if (res.code == 200) {
                            //对应跳转
                            this.navDetail();
                        } else {
                            this.alertWarnLv = 3;
                            this.alertWarnContent = res.msg;
                        }
                    }).catch(e => {
                        this.alertWarnLv = 3;
                        this.alertWarnContent = '网路异常请稍后重试';
                    });
                } else {
                    this.alertWarnLv = 1;
                    this.btnDisable = false;
                }
                return false;
            },
            // 检查字符串是否为合法手机号码
            isPhone: function (str) {
                let reg = /^(1[3-9][0-9])[0-9]{8}$/;
                if (reg.test(str)) {
                    // console.log('手机号码格式输入正确');
                    return true;
                } else {
                    // console.log('请输入正确格式的手机号码');
                    return false;
                }
            },
            //详细跳转
            navDetail : function(){
                let query = this.$route.query;
                let code = query.invCode;
                switch (code) {
                    case '20200813live':
                        //五周年直播间
                        this.navToHerf('https://live.vhall.com/216577831');
                        break;
                    case '20200813Coupon':
                        //扶贫项目优惠券
                        this.navToHerf('https://shop40695200.youzan.com/wscump/coupon-pack/fetch?alias=1a2wzc9vx&kdtId=40503032');
                        break;
                    default:
                        this.$router.go(-1);
                        break;
                }
            },
            //重新跳转到新的页面
            navToHerf : function(url){
                window.location.replace(url);
            }
        }
    }
</script>

<style>
    /*主体*/
    .blank-page {
        min-height: 100%;
        background: #eeeeee;
        position: relative;
    }
</style>
