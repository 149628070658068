<template>
    <div class="books-carlist">
        <div class="car-list" v-if="type == 1">
            <van-swipe class="my-swipe" indicator-color="white" vertical :loop="loop" @change="changeItem" ref="swipe">
                <van-swipe-item v-for="(item, index) in carlist " :key="index">
                    <img class="car-img" :src="item.file" align="middle">
                    <div class="bottom-more">
                        <van-button size="large" @click="navToDetail(index)">查看配置</van-button>
                        <div class="icon">
                            <img class="icon-img" src="/images/book/b-list-icon1.png">
                        </div>
                    </div>
                </van-swipe-item>
                <template #indicator>
                    <div class="custom-indicator">
                    </div>
                </template>
            </van-swipe>

            <div class="left-box" v-if="carlist.length > 0">
                <div class="left-button" :class="buttonClass" @click="showOtherList"><img src="/images/book/b-list-icon4.png"></div>
                <ul class="left-list" :class="listClass">
                    <li class="item" v-for="(item, index) in carlist" :key="index" @click="swiperIndex(index)">
                        <div class="info">{{item.TYPENAME}}</div>
                        <img class="line" src="/images/book/b-list-icon3.png">
                    </li>
                </ul>
            </div>
        </div>

        <div class="car-list2" v-else-if="type == 2">
            <div class="car-item" v-for="(item, index) in carlist" :key="index" @click="navToDetail(index)">
                <img class="car-img" :src="item.file">
            </div>
        </div>

        <div class="orientation-box">
            <div class="orientation">请锁定屏幕<br>以竖屏方式浏览</div>
        </div>

        <van-overlay :show="alertShow">
            <div class="wrapper" @click.stop>
                <div class="alertInfo">
                    <img v-if="booksMarkId == 3" class="TY-alertInfo" src="http://api.yiquan.com.cn/act/fiveth/alert/kdlk.png">
                    <img v-else-if="booksMarkId == 1" class="TY-alertInfo" src="http://api.yiquan.com.cn/act/fiveth/alert/bk.png">
                    <img v-else-if="booksMarkId == 2"  class="TY-alertInfo" src="http://api.yiquan.com.cn/act/fiveth/alert/xfl.png">
                    <img class="close-btn" src="/images/car/close-btn-2.png" @click="alertShow=false">
                </div>
            </div>
        </van-overlay>

        <van-overlay :show="loadShow" class="overlay-div">
            <van-loading type="spinner" class="load"/>
        </van-overlay>
    </div>
</template>

<script>
    import vue from 'vue';
    import {Button, Dialog,Swipe, SwipeItem } from 'vant';

    vue.use(Button, Dialog,Swipe, SwipeItem );
    export default {
        name: 'books-index',
        created: function(){
            let query = this.$route.query;
            if (typeof query.booksMarkId == 'undefined') {
                this.$router.replace({
                    path: '/books/index'
                });
                return false;
            }
            let booksMarkId = query.booksMarkId;
            this.booksMarkId = query.booksMarkId;
            this.type = query.type != undefined ? query.type : 1;

            this.$post('/booksCarList', {
                'openId' : this.$store.getters['user/getOpenId'],
                'booksMarkId' : booksMarkId,
            }).then((res)=> {
                if(res.code == 200){
                    let data = res.data;
                    this.carlist = data;
                }
                this.loadShow = false;
            })

            if(query.alert == 'fiveth'){
                this.alertShow = true;
            }
        },
        data() {
            return {
                loadShow : true,
                alertShow : false,
                loop : true,
                carlist : [],
                buttonClass : '',
                listClass : '',
                type: 1,
                booksMarkId : 0,
            }
        },
        methods: {
            showOtherList(){
                this.buttonClass = 'hidden';
                this.listClass = 'show';
            },
            changeItem(){
                this.buttonClass = '';
                this.listClass = '';
            },
            swiperIndex(index){
                this.$refs.swipe.swipeTo(index, {immediate :true});
            },
            navToDetail(index){
                let item = this.carlist[index];

                if(item['id'] != ''){
                    this.$router.push({
                        path : '/books/cardetail',
                        query:{
                            id : item['ID']
                        }})
                }
            }
        }
    }
</script>

<style>
    /*主体*/
    page{
        height: 100%;
    }
    .books-carlist {
        background: #d4d4d4;
        height: 100%;
        width: 100%;
    }
    .books-carlist .car-list{
        height: 100%;
        width: 100%;
        position: relative;
    }

    .books-carlist .van-swipe{
        width: 100%;
        height: 100%;
    }

    .books-carlist .van-swipe-item{
         width: 100%;
        height: 100%;
        text-align: center;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    .books-carlist .car-img{
        width: 100%;
        vertical-align:middle;
    }

    .books-carlist .bottom-more{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 0 30px;
        padding-bottom: 30px;
    }

    .books-carlist .bottom-more .van-button{
        background-color: transparent;
        color: #fff;
        font-size: 30px;
    }
    .books-carlist .bottom-more .icon{
        width: 100%;
        padding-top: 14px;
        position: relative;
        height: 42px;
        box-sizing: content-box;
    }
    .books-carlist .bottom-more .icon-img{
        width: 42px;
        height: 42px;
        position: absolute;
        top: 4px;
        left: 0;
        right: 0;
        margin: auto;
        animation:iconmove 2.8s infinite;
        -webkit-animation:iconmove 2.8s infinite; /* Safari 和 Chrome */
    }

    .books-carlist .left-box{
        position: absolute;
        height: 100%;
        left: 0;
        top: 0;
    }

    .books-carlist .left-box .left-button{
        background: rgba(255,255,255, .8);
        color: #010101;
        font-size: 24px;
        height: 190px;
        width: 190px;
        box-sizing: border-box;
        padding-left: 113px;
        line-height: 24px;
        border-radius: 50%;
        position: absolute;
        bottom: 360px;
        left: -95px;
        transition : left 1s;
        display:table-cell;
        text-align:left;
        vertical-align:middle;
    }

    .books-carlist .left-box .left-button.hidden{
        left: -200px;
        transition : left 1s;
    }

    .books-carlist .left-box .left-button img{
        height: 110px;
        width: 60px;
        right: 18px;
        top:0;
        bottom: 0;
        margin: auto 0;
        position: absolute;

    }

    .books-carlist .left-box .left-list{
        background: rgba(255,255,255, .8);
        color: #010101;
        font-size: 24px;
        padding-right: 25px;
        border-radius: 0 10px 10px 0;
        position: absolute;
        bottom: 300px;
        white-space: nowrap;
        left: -300px;
        transition : left 1s;
    }

    .books-carlist .left-box .left-list.show{
        left: 0;
        transition : left 1s;
    }

    .books-carlist .left-box .left-list .item{
        padding-top: 29px;
        text-align: left;
    }

    .books-carlist .left-box .left-list .item .info{
        width: 100%;
        padding-bottom: 29px;
        padding-left: 30px;
        line-height: 24px;
    }

    .books-carlist .left-box .left-list .item .line{
        display: block;
        width: 100px;
        padding-left: 10px;
        height: 1px;
    }

    .books-carlist .wrapper{
        padding: 0;
    }
    .books-carlist .alertInfo .TY-alertInfo{
        width: 100%;
        padding-top: 40px;
    }

    .books-carlist .alertInfo .close-btn{
        margin-top: 80px;
        width: 40px;
        height: 40px;
    }

    @keyframes iconmove
    {
        0% {top:4px;}
        1% {top:4px;}
        49% {top:16px;}
        51% {top:16px;}
        99% {top:4px;}
        100% {top:4px;}
    }
    ​
    @-webkit-keyframes iconmove /*Safari and Chrome*/
    {
        0% {top:4px;}
        1% {top:4px;}
        49% {top:16px;}
        51% {top:16px;}
        99% {top:4px;}
        100% {top:4px;}
    }

    .books-carlist .orientation {
        display: none;
    }

    @media screen and (min-width: 420px) and (orientation: landscape){

        .books-carlist .orientation-box {
            position: absolute;
            background-color: #000!important;
            height: 100%;
            width: 100%;
            top: 0;
        }

        .books-carlist .orientation::before{
            background: url('/images/icon-bg-b-width.png') no-repeat center 40% #000;
            background-size: 100%;
            bottom: 100%;
            content: "";
            height: 60px;
            left: 50%;
            position: absolute;
            -webkit-transform: translate(-50%,-10px);
            transform: translate(-50%,-10px);
            width: 110px;
        }

        .books-carlist .orientation {
            color: #fff;
            display: block;
            font-size: 18px;
            line-height: 24px;
            position: absolute;
            text-align: center;
            top: 50%;
            width: 100%;
        }
    }
</style>
