let baseUrl = '';
// let imgUrl = 'http://car.yiquanvip.com';
let imgUrl = 'https://api.yiquan.com.cn/';

if(process.env.NODE_ENV == 'development'){
    baseUrl = '/api';
} else if(process.env .NODE_ENV == 'production'){
    // baseUrl = 'http://car.yiquanvip.com/api';
    baseUrl = 'https://api.yiquan.com.cn/api';
    // baseUrl = '生产地址';
}

export {
    baseUrl,
    imgUrl
}