<template>
    <div class="glitter-list">
        <div class="car-list">
            <van-swipe class="my-swipe" indicator-color="white" vertical :loop="loop" ref="swipe" v-if="icode == 'swhy0819'">
                <van-swipe-item>
                    <img class="car-img" src='http://api.yiquan.com.cn/act/eight/swhy2/eight-1.jpg' align="middle">
                </van-swipe-item>
                <van-swipe-item>
                    <img class="car-img" src='http://api.yiquan.com.cn/act/eight/swhy2/eight-2.jpg' align="middle">
                </van-swipe-item>
                <van-swipe-item>
                    <img class="car-img" src='http://api.yiquan.com.cn/act/eight/swhy2/eight-3.jpg' align="middle">
                </van-swipe-item>
                <van-swipe-item>
                    <img class="car-img" src='http://api.yiquan.com.cn/act/eight/swhy2/eight-4.jpg' align="middle">
                </van-swipe-item>
                <van-swipe-item>
                    <img class="car-img" src='http://api.yiquan.com.cn/act/eight/swhy2/eight-5.jpg' align="middle">
                </van-swipe-item>
                <van-swipe-item>
                    <img class="car-img" src='http://api.yiquan.com.cn/act/eight/swhy2/eight-6.jpg' align="middle">
                </van-swipe-item>
                <van-swipe-item>
                    <img class="car-img" src='http://api.yiquan.com.cn/act/eight/swhy2/eight-7.jpg' align="middle">
                </van-swipe-item>
                <van-swipe-item>
                    <img class="car-img" src='http://api.yiquan.com.cn/act/eight/swhy2/eight-8.jpg' align="middle">
                </van-swipe-item>
                <van-swipe-item>
                    <img class="car-img" src='http://api.yiquan.com.cn/act/eight/swhy2/eight-9.jpg' align="middle">
                </van-swipe-item>
                <van-swipe-item>
                    <img class="car-img" src='http://api.yiquan.com.cn/act/eight/swhy2/eight-10.jpg' align="middle">
                </van-swipe-item>
                <van-swipe-item>
                    <img class="car-img" src='http://api.yiquan.com.cn/act/eight/swhy2/eight-11.jpg' align="middle">
                </van-swipe-item>
                <van-swipe-item>
                    <img class="car-img" src='http://api.yiquan.com.cn/act/eight/swhy2/eight-12.jpg' align="middle">
                </van-swipe-item>
            </van-swipe>
            <van-swipe class="my-swipe" indicator-color="white" vertical :loop="loop" ref="swipe" v-else-if="icode == 'jtyh0811' || icode == 'coscoshipping' || icode == 'baowu130' || icode == 'pfyh' || icode == 'gtja'|| icode == 'zgyl0831' || (icode == 'zgdx' && local == 'sh')">
                <van-swipe-item>
                    <img class="car-img" :src='pageUrl' align="middle">
                </van-swipe-item>
                <van-swipe-item>
                    <img class="car-img" src='http://api.yiquan.com.cn/act/eight/jh/eight-2.jpg' align="middle">
                </van-swipe-item>
                <van-swipe-item>
                    <img class="car-img" src='http://api.yiquan.com.cn/act/eight/jh/eight-3.jpg' align="middle">
                </van-swipe-item>
                <van-swipe-item>
                    <img class="car-img" src='http://api.yiquan.com.cn/act/eight/jh/eight-4.jpg' align="middle">
                </van-swipe-item>
                <van-swipe-item>
                    <img class="car-img" src='http://api.yiquan.com.cn/act/eight/jh/eight-5.jpg' align="middle">
                </van-swipe-item>
                <van-swipe-item>
                    <img class="car-img" src='http://api.yiquan.com.cn/act/eight/jh/eight-6.jpg' align="middle">
                </van-swipe-item>
                <van-swipe-item>
                    <img class="car-img" src='http://api.yiquan.com.cn/act/eight/jh/eight-7.jpg' align="middle">
                </van-swipe-item>
                <van-swipe-item>
                    <img class="car-img" src='http://api.yiquan.com.cn/act/eight/jh/eight-8.jpg' align="middle">
                </van-swipe-item>
                <van-swipe-item>
                    <img class="car-img" src='http://api.yiquan.com.cn/act/eight/jh/eight-9.jpg' align="middle">
                </van-swipe-item>
                <template #indicator>
                    <div class="custom-indicator">
                    </div>
                </template>
            </van-swipe>
            <van-swipe class="my-swipe" indicator-color="white" vertical :loop="loop" ref="swipe" v-else>
                <van-swipe-item>
                    <img class="car-img" :src='pageUrl' align="middle">
                </van-swipe-item>
                <van-swipe-item>
                    <img class="car-img" src='http://api.yiquan.com.cn/act/eight/eight-2.jpg' align="middle">
                </van-swipe-item>
                <van-swipe-item>
                    <img class="car-img" src='http://api.yiquan.com.cn/act/eight/eight-3.jpg' align="middle">
                </van-swipe-item>
                <van-swipe-item>
                    <img class="car-img" src='http://api.yiquan.com.cn/act/eight/eight-4.jpg' align="middle">
                </van-swipe-item>
                <van-swipe-item>
                    <img class="car-img" src='http://api.yiquan.com.cn/act/eight/eight-5.jpg' align="middle">
                </van-swipe-item>
                <van-swipe-item>
                    <img class="car-img" src='http://api.yiquan.com.cn/act/eight/eight-6.jpg' align="middle">
                </van-swipe-item>
                <template #indicator>
                    <div class="custom-indicator">
                    </div>
                </template>
            </van-swipe>
        </div>
    </div>
</template>

<script>
    import vue from 'vue';
    import {Button, Dialog,Swipe, SwipeItem } from 'vant';

    vue.use(Button).use(Dialog).use(Swipe).use(SwipeItem);
    export default {
        name: 'books-index',
        created: function(){
            let that = this;

            wx.ready(function(){
                wx.hideAllNonBaseMenuItem();

                wx.showMenuItems({
                    menuList: ['menuItem:share:appMessage', 'menuItem:share:timeline'] // 要显示的菜单项，所有menu项见附录3
                });

                let baseUrl = that.$wechat.baseUrl;
                if(that.icode == 'jtyh0811' || that.icode == 'coscoshipping' || that.icode == 'baowu130' || that.icode == 'pfyh' || that.icode == 'gtja'|| that.icode == 'zgyl0831' ||  (that.icode == 'zgdx' && that.local == 'sh')){
                    //特殊
                    wx.updateAppMessageShareData({
                        title: that.shareTitle, // 分享标题
                        desc: '点击领取'+that.custName + '员工专属福利', // 分享描述
                        link: baseUrl + '/litter/fiveth?icode=' + that.icode + '&local=' + that.local, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                        imgUrl: that.shareImg, // 分享图标
                        success: function () {
                            // 设置成功
                        }
                    });

                    wx.updateTimelineShareData({
                        title: '点击领取'+ that.custName + '员工专属福利', // 分享标题
                        link: baseUrl + '/litter/fiveth?icode='+that.icode + '&local=' + that.local , // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                        imgUrl: that.shareImg, // 分享图标
                        success: function () {
                            // 设置成功
                        }
                    });
                } if (that.icode == 'swhy0819'){
                    //申万宏源
                    wx.updateAppMessageShareData({
                        title: '一圈员工购车平台感恩回馈', // 分享标题
                        desc: '点击领取申万宏源员工专属购车福利', // 分享描述
                        link: baseUrl + '/litter/fiveth?icode=' + that.icode + '&local=' + that.local, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                        imgUrl: that.shareImg, // 分享图标
                        success: function () {
                            // 设置成功
                        }
                    });

                    wx.updateTimelineShareData({
                        title: '点击领取申万宏源员工专属购车福利', // 分享标题
                        link: baseUrl + '/litter/fiveth?icode='+that.icode + '&local=' + that.local , // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                        imgUrl: that.shareImg, // 分享图标
                        success: function () {
                            // 设置成功
                        }
                    });
                }else{
                    //其他
                    wx.updateAppMessageShareData({
                        title: '欢迎信', // 分享标题
                        desc: '欢迎'+that.custName + '员工加入员工购车服务平台', // 分享描述
                        link: baseUrl + '/litter/fiveth?icode='+that.icode , // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                        imgUrl: that.shareImg, // 分享图标
                        success: function () {
                            // 设置成功
                        }
                    });

                    wx.updateTimelineShareData({
                        title: '欢迎'+ that.custName + '员工加入员工购车服务平台', // 分享标题
                        link: baseUrl + '/litter/fiveth?icode='+that.icode , // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                        imgUrl: that.shareImg, // 分享图标
                        success: function () {
                            // 设置成功
                        }
                    });
                }
            });

            let query = this.$route.query;
            if(query.icode != undefined){
                this.icode = query.icode;
                let icode = query.icode;

                //用户企业对应是否相同
                this.$post('/userCustState', {
                    openId : this.$store.getters['user/getOpenId'],
                }).then((res) => {
                    if(res.code == 200){
                        let invCode = icode;
                        let data =  res.data;
                        if(data.id == 0){
                            //绑定企业
                            this.$post('/userBindCust', {
                                openId : this.$store.getters['user/getOpenId'],
                                code :  invCode,
                            }).then((res) => {
                                if(res.code == 200){}
                            });
                        }
                    }
                });
            }
            if(query.local != undefined){
                this.local = query.local
            }
            document.getElementById('titleId').innerHTML = '欢迎信';
            switch (query.icode) {
                case 'coscoshipping':
                    //中国远洋海运集团有限公司
                    this.pageUrl = 'http://api.yiquan.com.cn/act/eight/jh/eight-zgzy-1.jpg';
                    this.shareImg = 'http://api.yiquan.com.cn/act/eight/jh/share-bg.jpg';
                    this.custName = '中远海运';
                    this.shareTitle = '感恩回馈中远海运员工';
                    document.getElementById('titleId').innerHTML = '感恩回馈五周年';
                    break;
                case 'gtja':
                    //国泰君安证券股份有限公司
                    this.pageUrl = 'http://api.yiquan.com.cn/act/eight/jh/eight-gtja-1.jpg';
                    this.shareImg = 'http://api.yiquan.com.cn/act/eight/jh/share-bg.jpg';
                    this.custName = '国泰君安';
                    this.shareTitle = '感恩回馈国泰君安员工';
                    document.getElementById('titleId').innerHTML = '感恩回馈五周年';
                    break;
                case 'SIG2018':
                    //上海国际集团有限公司
                    this.pageUrl = 'http://api.yiquan.com.cn/act/eight/eight-1.jpg';
                    this.custName = '上海国际';
                    break;
                case 'baowu130':
                    //中国宝武钢铁集团有限公司
                    this.pageUrl = 'http://api.yiquan.com.cn/act/eight/jh/eight-bw-1.jpg';
                    this.shareImg = 'http://api.yiquan.com.cn/act/eight/jh/share-bg.jpg';
                    this.custName = '宝武集团';
                    this.shareTitle = '感恩回馈宝武集团员工';
                    document.getElementById('titleId').innerHTML = '感恩回馈五周年';
                    break;
                case 'ciecc':
                    //中国国际工程咨询有限公司
                    this.pageUrl = 'http://api.yiquan.com.cn/act/eight/eight-zz-1.jpg';
                    this.custName = '中咨';
                    break;
                case '20200610135145':
                    //中国航空发动机集团有限公司
                    this.pageUrl = 'http://api.yiquan.com.cn/act/eight/eight-1.jpg';
                    this.custName = '中国航发';
                    break;
                case 'airchina':
                    //中国航空集团有限公司
                    this.pageUrl = 'http://api.yiquan.com.cn/act/eight/eight-1.jpg';
                    this.custName = '中国航空';
                    break;
                case 'jsyh':
                    //中国建设银行
                    this.pageUrl = 'http://api.yiquan.com.cn/act/eight/eight-1-jsyh-2.jpg';
                    this.custName = '中国建设银行';
                    break;
                case 'cic2018':
                    //中国投资有限责任公司
                    this.pageUrl = 'http://api.yiquan.com.cn/act/eight/eight-1.jpg';
                    this.custName = '中投集团';
                    break;
                case 'zgyl0831':
                    //中国银联股份有限公司
                    this.pageUrl = 'http://api.yiquan.com.cn/act/eight/jh/eight-zgyl-1.jpg';
                    this.shareImg = 'http://api.yiquan.com.cn/act/eight/jh/share-bg.jpg';
                    this.custName = '中国银联';
                    this.shareTitle = '感恩回馈银联员工';
                    document.getElementById('titleId').innerHTML = '感恩回馈五周年';
                    break;
                case 'zgyd0706':
                    //中国移动通信集团有限公司
                    this.pageUrl = 'http://api.yiquan.com.cn/act/eight/eight-1.jpg';
                    this.custName = '中国移动';
                    break;
                case 'cmec2018':
                    //中国机械设备工程股份有限公司
                    this.pageUrl = 'http://api.yiquan.com.cn/act/eight/eight-zj-1.jpg';
                    this.custName = 'CMEC';
                    break;
                case 'zgsf0701':
                    //中国商用飞机有限责任公司
                    this.pageUrl = 'http://api.yiquan.com.cn/act/eight/eight-1.jpg';
                    this.custName = '中国商飞';
                    break;
                case 'swhy0819':
                    //申万宏源证券有限公司
                    this.pageUrl = 'http://api.yiquan.com.cn/act/eight/jh/eight-gtja-1.jpg';
                    this.shareImg = 'http://api.yiquan.com.cn/act/eight/jh/share-bg.jpg';
                    this.custName = '申万宏源';
                    this.shareTitle = '感恩回馈申万宏源员工';
                    document.getElementById('titleId').innerHTML = '感恩回馈五周年';
                    break;
                case 'tk0613':
                    //泰康保险集团
                    this.pageUrl = 'http://api.yiquan.com.cn/act/eight/eight-tk-1.jpg';
                    this.custName = '泰康集团';
                    break;
                case 'jtyh0811':
                    //交通银行股份有限公司
                    this.pageUrl = 'http://api.yiquan.com.cn/act/eight/jh/eight-1.jpg';
                    this.shareImg = 'http://api.yiquan.com.cn/act/eight/jh/share-bg.jpg';
                    this.custName = '交通银行';
                    this.shareTitle = '感恩回馈交行员工';
                    document.getElementById('titleId').innerHTML = '感恩回馈五周年';
                    break;
                case 'zgdx':
                    //中国电信集团有限公司
                    if(query['local'] == 'sh'){
                        this.pageUrl = 'http://api.yiquan.com.cn/act/eight/jh/eight-zgdx-1.jpg';
                        this.shareImg = 'http://api.yiquan.com.cn/act/eight/jh/share-bg.jpg';
                        this.custName = '中国电信';
                        this.shareTitle = '感恩回馈电信员工';
                        document.getElementById('titleId').innerHTML = '感恩回馈五周年';
                        break;
                    }else{
                        this.pageUrl = 'http://api.yiquan.com.cn/act/eight/eight-1-zgdx.jpg';
                        this.custName = '中国电信';
                        break;
                    }
                case 'pfyh':
                    //浦发银行
                    this.pageUrl = 'http://api.yiquan.com.cn/act/eight/jh/eight-pfyh-1.jpg';
                    this.shareImg = 'http://api.yiquan.com.cn/act/eight/jh/share-bg.jpg';
                    this.custName = '浦发银行';
                    this.shareTitle = '感恩回馈浦发员工';
                    document.getElementById('titleId').innerHTML = '感恩回馈五周年';
                    break;
                default:
                    this.pageUrl = 'http://api.yiquan.com.cn/act/eight/eight-1.jpg';
                    this.custName = '尊敬的';
                    break;
            }
        },
        data() {
            return {
                loadShow : true,
                loop : false,
                buttonClass : '',
                listClass : '',
                type: 1,
                pageUrl : 'http://api.yiquan.com.cn/act/six/six-page-common.jpg',
                shareTitle : '感恩回馈企业员工',
                icode : '',
                local : '',
                custName : '',
                shareImg : 'http://api.yiquan.com.cn/act/eight/share-bg.jpg',
            }
        },
        methods: {}
    }
</script>

<style>
    /*主体*/
    page{
        height: 100%;
    }
    .glitter-list {
        background: #d4d4d4;
        height: 100%;
        width: 100%;
    }
    .glitter-list .car-list{
        height: 100%;
        width: 100%;
        position: relative;
    }

    .glitter-list .van-swipe{
        width: 100%;
        height: 100%;
    }

    .glitter-list .van-swipe-item{
        width: 100%;
        height: 100%;
        text-align: center;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    .glitter-list .car-img{
        width: 100%;
        vertical-align:middle;
    }

    .glitter-list .left-box .left-button img{
        height: 110px;
        width: 60px;
        right: 18px;
        top:0;
        bottom: 0;
        margin: auto 0;
        position: absolute;

    }

    .glitter-list .btn-item{
        position: absolute;
        height: 1200px;
        width: 100%;
    }

    .glitter-list .btn-item .car-img2{
        position: absolute;
        top: 80%;
        left: 0;
        right: 0;
        margin: auto;
        width: 36px;
    }
    .glitter-list .btn-item-1 .car-img2,.glitter-list .btn-item-2 .car-img2{
        animation:iconmove2 2.8s infinite;
    }
    .glitter-list .btn-item .car-img3{
        position: absolute;
        bottom: 5%;
        width: 615px;
        padding-left: 5px;
        left: 0;
        right: 0;
        margin: auto;
    }
    .glitter-list .btn-item-3 .car-img3{
        top: 58%;
        bottom: auto;
    }
    .glitter-list .btn-item-3 .car-img2{
        top: 50%;
        animation:iconmove3 2.8s infinite;
    }

    .glitter-list .video-show{
        position: absolute;
        height: 400px;
        width: 478px;
        left: 0;
        right: 0;
        margin: auto;
        margin-top: 280px;
        background: rgba(0, 0, 0, .4);
        z-index: 0;
    }

    .glitter-list .video-show.show-2{
        position: absolute;
        height: 400px;
        width: 478px;
        left: 0;
        right: 0;
        margin: auto;
        margin-top: 320px;
        background: rgba(0, 0, 0, .4);
        z-index: 0;
    }

    .glitter-list .video-show video{
        height: 100%;
        width: 100%;
    }

    @keyframes iconmove2
    {
        0% {top:80%;}
        1% {top:80%;}
        49% {top:82%;}
        51% {top:82%;}
        99% {top:80%;}
        100% {top:80%;}
    }

    @-webkit-keyframes iconmove2 /*Safari and Chrome*/
    {
        0% {top:80%;}
        1% {top:80%;}
        49% {top:82%;}
        51% {top:82%;}
        99% {top:80%;}
        100% {top:80%;}
    }

    @keyframes iconmove3
    {
        0% {top:50%;}
        1% {top:50%;}
        49% {top:52%;}
        51% {top:52%;}
        99% {top:50%;}
        100% {top:50%;}
    }

    @-webkit-keyframes iconmove3 /*Safari and Chrome*/
    {
        0% {top:50%;}
        1% {top:50%;}
        49% {top:52%;}
        51% {top:52%;}
        99% {top:50%;}
        100% {top:50%;}
    }
</style>
