<template>
    <div class="series" id="series2">
        <div class="series-top" id="series-top">
            <div class="top-box">
                <div class="top-1 screen-box">
                    <div class="box-item left-city-btn" @click="showPicker = true">{{cityName}}<img class="arrow-btn1" :src="icon.arrowDownBtn"></div>
                    <div class="box-item search-input-box" @click="navToScreen(1)">
                        <img class="screen-icon" :src="icon.screenIcon"> Search
                    </div>
                    <div class="box-item right-screen-btn" @click="showScreenChoose">
                        <img class="screen-btn" :src="icon.screenBtn">筛选
                    </div>
                </div>

                <div class="screen-list-alert" :class="screenShow ? 'alert-show' : ''" id="screen-list-alert">
                    <div class="title-box">选择价格
                        <img class="arrow-btn3" :src="icon.arrowDownBtn">
                        <img class="close-btn" :src="icon.closeIconBtn" @click="hidenScrennChoose">
                    </div>
                    <div class="screen-list">
                        <div class="screen-item" v-for="(item, index) in screenChooseList" :key="index">
                            <div class="item-info"  :class="index == screenChooseKey ? 'choose': ''" @click="changeScreenChoose(index)">{{item.name}}</div>
                        </div>
                    </div>
                </div>
                <div class="top-2 mark-list" :class="markListShow ? 'list-show': ''">
                    <div class="mark-item" v-for="(item, index) in markList" :key="index" @click="chooseMark(item.id)" >
                        <span :class="markId == item.id ? 'choose' : ''">{{item.name}}</span>
                    </div>
                </div>
            </div>

            <div class="shadow-over" :class="shadowShow ? 'show' : ''"></div>
        </div>
        <!--    头部banner主体-->
        <van-swipe :autoplay="3000" indicator-color="#fff" class="banner">
            <van-swipe-item v-for="(image, index) in banner" :key="index">
                <img :src="image['file']">
            </van-swipe-item>
			<van-loading type="spinner" v-if="banner.length == 0"  class="imgload"/>
        </van-swipe>

        <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                class="series-list"
        >
            <div class="item-container-out" v-for="(series ,index) in list" :key="index" >
                <div class="item-container-3" @click="navToModel(index)">
                    <div class="img-container">
                        <img class="item-img" :src="series.file">
                    </div>
                    <div class="info-container">
                        <div class="move-box">
                            <div class="series-name">
                                {{series.name}}
                                <div class="tag-list">
                                    <div class="tag-item" v-for="(tagItem ,index) in series.tagList" :key="index">{{tagItem}}</div>
                                </div>
                            </div>
                            <div class="guide-price" v-if="series['max_guide'] == series['min_guide']">指导价: {{series['min_guide']}}</div>
                            <div class="guide-price" v-else>官方指导价: {{series['min_guide']}}-{{series['max_guide']}}
                                <img class="c3-arrow" src="/images/icon/arrow.png">
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </van-list>

        <van-popup v-model="showPicker" position="bottom" :close-on-click-overlay="false" safe-area-inset-bottom>
            <van-picker
                    title="请选择所在城市"
                    show-toolbar
                    :columns="columns"
                    value-key = "content"
                    @change="onLocalChange"
                    @cancel="showPicker = false"
                    @confirm="onLocalConfirm"
            ></van-picker>
        </van-popup>

        <van-overlay :show="loadShow"  class="overlay-div">
            <van-loading type="spinner"  class="load"/>
        </van-overlay>
    </div>
</template>

<script>
    import vue from 'vue';
    import {Swipe, List, Popup, Dialog, Picker} from 'vant';

    vue.use(Swipe).use(List).use(Popup).use(Dialog).use(Picker);

    let citys = {};
    export default {
        name: 'car-series',
        data() {
            return {
                subCommData : {},
                newenergy: 1, //1新能源, 2油车
                icon: {
                    screenBtn: '/images/index/icon_filter_filter.png',
                    arrowDownBtn: '/images/index/icon_arrow_chevron-down.png',
                    screenIcon: '/images/index/screen-icon.png',
                    closeIconBtn: '/images/index/icon_interfaces_close.png'
                },
                banner: [],
                list: [],
                loading: false,
                finished: true,
                loadShow: false,
                custMini : this.$store.getters['user/getCustMini'],
                markList: [
                    {'id': 1048, 'name': '别克'},
                ],
                markId : 0,
                markListShow: false,
                columns: [
                    {
                        values: Object.keys(citys),
                    },
                    {
                        values: '',
                        className: 'column2',
                        defaultIndex: 2
                    }
                ],
                cityName: '--',
                shadowShow: false,
                showPicker : false,
                screenShow: false,
                screenChooseKey : 0,
                screenChooseList : [
                    {'name' : '不限', 'max': 0, 'min' : 0},
                    {'name' : '5万以内', 'max': 0, 'min' : 0},
                    {'name' : '5-10万', 'max': 0, 'min' : 0},
                    {'name' : '10-15万', 'max': 0, 'min' : 0},
                    {'name' : '15-20万', 'max': 0, 'min' : 0},
                    {'name' : '20-25万', 'max': 0, 'min' : 0},
                    {'name' : '25-35万', 'max': 0, 'min' : 0},
                    {'name' : '35-40万', 'max': 0, 'min' : 0},
                    {'name' : '40-50万', 'max': 0, 'min' : 0},
                    {'name' : '50-100万', 'max': 0, 'min' : 0},
                    {'name' : '100万以上', 'max': 0, 'min' : 0},
                ]
            }
        },
        created:function(){
            var that = this;
            this.loadShow = true;
            //判断
            let query = this.$route.query;
            if(typeof query.type == 'undefined'){
                this.$router.replace({
                    path : '/index'
                });
                return false;
            }
            that.newenergy = query.type;

            var filter = '*',filterArray = [];
            if(typeof query.filter != 'undefined'){
                filter = query.filter != '' ? query.filter : '*';
                if(filter !== '*'){
                    filterArray = filter.split(',');
                }
            }

            //城市信息异步载入（无授权）
            that.getCityInfo();

            that.subCommData = {
                provinceId : this.$store.getters['local/getProvinceId'],
                cityId : this.$store.getters['local/getCityId'],
                custId : this.$store.getters['user/getCustId'],
                userId : this.$store.getters['user/getUserId']
            };

            if(typeof query.provinceId != 'undefined' && typeof query.provinceId != 'undefined'){
                //有地址
                that.subCommData.provinceId = query.provinceId;
                that.subCommData.cityId = query.cityId;
                this.$store.commit('local/setProvinceId', that.subCommData.provinceId);
                this.$store.commit('local/setCityId', that.subCommData.cityId);

                //更新数据
                let data = {
                    'appId' :this.$wechat.appId,
                    'openId' : this.$store.getters['user/getOpenId'],
                };
                that.$getToken('/getToken', data).then((res) => {
                    if(res.code == 200){
                        let token = res.data.token;
                        that.$store.commit('user/setToken', token);
                        //有token执行下面方法
                        setTimeout(function() {
                            that.getMarkInfo();
                        },600);
                    }else{
                        window.console.log('验证失败');
                    }
                })
            }else{
                //无城市信息
                if((typeof that.subCommData.provinceId == 'undefined' || that.subCommData.provinceId == 0 || that.subCommData.provinceId == null) || (typeof that.subCommData.cityId == 'undefined' || that.subCommData.cityId == 0 || that.subCommData.cityId == null)){
                    this.$router.replace({
                        path : '/index'
                    });
                    return false;
                }
            }

            that.getMarkInfo();

        },
        methods: {
            /**
             * 异步渲染城市信息
             */
            getCityInfo(){
                return new Promise((resolve, reject) => {
                    var that = this;
                    let cityList = "";
                    if(that.$store.getters['local/getCityList'] != undefined && that.$store.getters['local/getCityList'] != '') {
                        cityList = JSON.parse(that.$store.getters['local/getCityList']);
                    }

                    let timestamp = Date.now();
                    let timeQuantum = 86400000;
                    if(cityList != '') {
                        timeQuantum = Number(timestamp) - Number(cityList.timestamp)
                    }
                    if(timeQuantum >= 86400000 || cityList == '') {
                        //获取城市信息
                        that.getCityList();
                    }
                    else {
                        citys = cityList.data;
                        let brandKey = [];
                        if(that.$store.getters['local/getBrandKey'] != undefined && that.$store.getters['local/getBrandKey'] != null && that.$store.getters['local/getBrandKey'].length != 0) {
                            brandKey = JSON.parse(that.$store.getters['local/getBrandKey']);
                        }else {
                            brandKey = [0,0];
                        }
                        that.citys = citys;
                        that.columns = [
                            {
                                values: citys,
                                className: 'column1',
                                defaultIndex: brandKey[0],
                            },
                            {
                                values: citys[brandKey[0]]['list'],
                                className: 'column2',
                                defaultIndex: brandKey[1],
                            }
                        ];
                        let brandLoacl = that.$store.getters['local/getBrandLocal'];
                        if(brandLoacl.length > 0){
                            let brandKey = that.$store.getters['local/getBrandKey'];
                            that.onLocalConfirm(JSON.parse(brandLoacl), JSON.parse(brandKey));
                        }
                    }
                    //渲染展示城市
                    var citysInfo = this.$store.getters['local/getLocal'];
                    console.log(citysInfo);
                    citysInfo  = citysInfo.split('-');
                    this.cityName = citysInfo[1];
                });

            },
            /**
             * 异步渲染品牌
             */
            getMarkInfo(){
                var that = this;
                return new Promise((resolve, reject) => {
                    this.$post('/getBrandList', {
                        provinceId : that.subCommData.provinceId,
                        cityId : that.subCommData.cityId,
                        custId : that.subCommData.custId,
                        userId : that.subCommData.userId,
                        newenergy : that.newenergy
                    }).then((res) => {
                        if(res.code == 200){
                            console.log(res);
                            this.markList = res.data;
                            if(res.data.length > 0){
                                this.markId = res.data[0]['id'];
                                that.getSereis(that.subCommData);
                            }else{
                                this.loadShow = false;
                            }
                        }else{
                            Dialog.alert({
                                message: res.msg
                            }).then(() => {
                                // on close
                                this.$router.back();
                            });
                        }
                    });
                });
            },
            getSereis(data){
                var that = this;
                var filter = '*',filterArray = [];
                this.$post('/getNewSeries', {
                    markId : this.markId,
                    provinceId : data.provinceId,
                    cityId : data.cityId,
                    custId : data.custId,
                    userId : data.userId,
                    newenergy: that.newenergy
                }).then((res) => {
                    if(res.code == 200){
                        let list = res.data['list'],len = list.length, showList = [];
                        if(len > 0){
                            for (let i = 0; i < len; i++){
                                var item = list[i];
                                if(filter !== '*'){
                                    if(this.IsInArray(filterArray, item['name'])){
                                        item = this.itemChange(item);
                                        showList.push(item);
                                    }
                                }else{
                                    item = this.itemChange(item);
                                    showList.push(item);
                                }
                            }
                        }
                        this.list = showList;
                        this.banner = res.data['banner'];
                        this.loadShow = false;
                    }else{
                        Dialog.alert({
                            message: res.msg
                        }).then(() => {
                            // on close
                            this.$router.back();
                        });
                    }
                });
            },
            navToModel(index){
                let item = this.list[index]['name'].trim();
                let name = escape(item);

                this.$router.push({
                    path : 'model' ,
                    query:{
                        markId : this.markId,
                        type : name
                    }})
            },
            navToScreen(screenChoose = 0){
                this.$router.push({
                    path : 'carScreen' ,
                    query:{
                        type: this.newenergy,
                        markId: this. markId,
                        screenIndex: this.screenChooseKey,
                        screenChoose: screenChoose,
                    }})
            },
            IsInArray(arr,val){
                var testStr=','+arr.join(",")+",";
                return testStr.indexOf(","+val+",")!=-1;
            },
            itemChange(item){
                if(item['ACTIVITYTYPE'] == 5){
                    item['sub'] = this.numberFormat2(item['sub']);
                    item['max_sub'] = this.numberFormat2(item['max_sub']);
                    item['min_sub'] = this.numberFormat2(item['min_sub']);
                    item['max_guide'] = this.numberFormat(item['max_guide']);
                    item['min_guide'] = this.numberFormat(item['min_guide']);
                }else{
                    item['sub'] = this.numberFormat(item['sub']);
                    item['max_sub'] = this.numberFormat(item['max_sub']);
                    item['min_sub'] = this.numberFormat(item['min_sub']);
                    item['max_guide'] = this.numberFormat(item['max_guide']);
                    item['min_guide'] = this.numberFormat(item['min_guide']);
                }
                var  tagList = [];
                console.log(item['sub']);
                if(item['sub'] != 0){
                    if(this.custMini == '农业银行'){
                        tagList.push('中国农业银行特约客户价');
                        tagList.push('至高立减'+item['sub']+'元');
                    }else if(this.custMini == '一圈会员'){
                        tagList.push('一圈会员专属');
                        tagList.push('至高立减'+item['sub']+'元');
                    }else{
                        tagList.push(this.custMini + '员工价');
                        tagList.push('至高立减'+item['sub']+'元');
                    }
                }

                item['tagList'] = tagList;

                return item;
            },
            showScreenChoose(){
                this.screenShow =true;
                this.shadowShow = true;
            },
            hidenScrennChoose(){
                this.screenShow =false;
                this.shadowShow = false;
            },
            changeScreenChoose(index){
                this.screenChooseKey = index;
                this.screenShow =false;
                this.shadowShow = !this.shadowShow;

                this.navToScreen();
            },
            changeMarkshow(){
                this.markListShow = !this.markListShow;
                this.shadowShow = !this.shadowShow;
            },
            chooseMark(markId){
                this.markId = markId;
                this.loadShow = true;
                this.getSereis(this.subCommData);
            },
            //获取城市信息
            getCityList() {
                let that = this;
                that.$post('/getCityList', {
                }).then((res) => {
                    if(res.code == 200){
                        citys = res.data;
                        //创建一个时间戳
                        let time = Date.now();
                        let cityArr = {};
                        cityArr.data = citys;
                        cityArr.timestamp = time;
                        this.$store.commit('local/setCityList', JSON.stringify(cityArr));
                        that.citys = citys;
                        let brandKey = [];
                        if(this.$store.getters['local/getBrandKey'] != undefined && this.$store.getters['local/getBrandKey'] != null && this.$store.getters['local/getBrandKey'].length != 0) {
                            brandKey = JSON.parse(this.$store.getters['local/getBrandKey']);
                        }else {
                            brandKey = [0,0];
                        }
                        that.columns = [
                            {
                                values: citys,
                                className: 'column1',
                                defaultIndex: brandKey[0],
                            },
                            {
                                // values: citys[0]['list'],
                                values: citys[brandKey[0]]['list'],
                                className: 'column2',
                                defaultIndex: brandKey[1],
                            }
                        ];
                        let brandLoacl = this.$store.getters['local/getBrandLocal'];
                        if(brandLoacl.length > 0){
                            let brandKey = this.$store.getters['local/getBrandKey'];
                            this.onLocalConfirm(JSON.parse(brandLoacl), JSON.parse(brandKey));
                        }
                    }else{
                        Dialog.alert({
                            message: res.msg
                        }).then(() => {
                            // on close
                        });
                    }
                }).catch(
                    (e) =>{
                        window.console.log(e);
                    }
                );
            },
            /**
             * 地址选择变化
             */
            onLocalChange(picker) {
                let indexs = picker.getIndexes();
                picker.setColumnValues(1, this.citys[indexs[0]]['list']);

                this.getMarkInfo();
            },
            /**
             * 地址选择确认
             */
            onLocalConfirm(value, key){
                this.localVal = value[0]['content'] + '-' + value[1]['content'];
                this.cityName = value[1]['content'];
                let localVals = value;

                this.$store.commit('local/setProvinceId', localVals[0]['id']);
                this.$store.commit('local/setCityId', localVals[1]['id']);
                this.$store.commit('local/setLocal', this.localVal);
                this.$data.showPicker = false;
            },
            numberFormat(value) {
                var param = {};
                var k = 10000,
                    sizes = ['', '万', '亿', '万亿'],
                    i;
                if(value < k){
                    param.value =value
                    param.unit=''
                }else{
                    i = Math.floor(Math.log(value) / Math.log(k));

                    param.value = ((value / Math.pow(k, i)));
                    param.unit = sizes[i];
                }
                // return param;
                return param.value+param.unit;
            },
            numberFormat2(value){
                var param = {};
                var k = 10000,
                    sizes = ['', '万', '亿', '万亿'],
                    i;
                if(value < k){
                    param.value =value
                    param.unit=''
                }else{
                    i = Math.floor(Math.log(value) / Math.log(k));

                    param.value = ((value / Math.pow(k, i)));
                    param.unit = sizes[i];
                }

                let reg = /^[0-9]/;
                param.value = param.value.toString();
                let len = param.value.length;
                let newValue = '';
                for (var i = 0; i < len ; i++){
                    let item = param.value.charAt(i);
                    if(i != 0){
                        item = item.replace(reg, 'x');
                    }
                    newValue += item;
                }

                // return param;
                return newValue+param.unit;
            },
        }
    }
</script>

<style>
    /*主体*/
    .series {
        background: #fff;
        height: 100%;
    }
    .series  .banner {
        width: 100%;
    }
    .series .banner img{
        width: 100%;
        padding: 0 20px;
    }
    /*导航*/
    #series2 #series-top .top-1{
        padding-top: 20px;
    }
    #series2 #series-top .top-box,#series2 #series-top .top-1,#series2 #series-top .top-2{
        z-index: 30;
        background: #fff;
        position: relative;
    }

    #series2 .screen-box{
        display: flex;
        line-height: 60px;
        padding: 0 20px;
        justify-content: space-between;
    }
    #series2 .screen-box .box-item{
        position: relative;
    }
    #series2 .screen-box img{
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
    }
    #series2 .screen-box .left-city-btn{
        padding-right: 40px;
    }
    #series2 .screen-box .arrow-btn1{
        width: 40px;
        height: 40px;
        right: 0;
    }

    #series2 .screen-box .search-input-box{
        position: relative;
        height: 64px;
        line-height: 64px;
        background: rgba(118, 118, 128, 0.12);
        color: rgba(158, 158, 160, 0.6);
        width: 65%;
        border-radius: 50px;
        z-index: 30;
    }

    #series2 .screen-box .right-screen-btn{
        padding-left: 30px;
    }
    #series2 .screen-box .search-input-box{
        text-align: left;
        padding-left: 76px;
    }
    #series2 .screen-box .search-input-box .screen-icon{
        position: absolute;
        width: 30px;
        height: 30px;
        left: 32px;
        top: 0;
        bottom: 0;
        margin: auto;
    }
    #series2 .screen-box .screen-btn{
        width: 28px;
        height: 28px;
        box-sizing: content-box;
        padding-bottom: 4px;
        left: 0;
    }

    #series2 #screen-list-alert{
        position: absolute;
        background: #fff;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        z-index: 50;
        display: none;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
    }
    #series2 #screen-list-alert.alert-show{
        display: block;
    }

    #series2 #screen-list-alert .title-box{
        width: 100%;
        text-align: left;
        height: 88px;
        line-height: 88px;
        position: relative;
        padding: 0 20px;
        font-size: 32px;
    }
    #series2 #screen-list-alert .title-box .arrow-btn3{
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        height: 48px;
        width: 48px;
    }
    #series2 #screen-list-alert .title-box .close-btn{
        position: absolute;
        right: 20px;
        top: 0;
        bottom: 0;
        margin: auto;
        height: 48px;
        width: 48px;
    }
    #series2 #screen-list-alert .screen-list{
        display: flex;
        flex-wrap: wrap;
        justify-content: start;
        align-content: center;
        padding: 0 10px;
        font-size: 24px;
    }
    #series2 #screen-list-alert .screen-list .screen-item{
        width: 25%;
        padding: 0 10px;
        padding-bottom: 20px;
    }

    #series2 #screen-list-alert .screen-list .screen-item .item-info{
        height: 64px;
        line-height: 64px;
        background: rgba(240, 243, 248, 1);
        border-radius: 6px;
    }
    #series2 #screen-list-alert .screen-list .screen-item .item-info.choose{
        color: #000;
        background: rgba(231, 240, 255, 1);
        font-weight: bold;
    }

    #series2 #series-top .mark-list{
        padding: 0 20px;
        white-space: nowrap;
        color: rgba(158, 158, 160, 0.8);
        margin-top: 20px;
        position: relative;
        line-height: 30px;
        transition: all 0.25s;
        text-align: left;
        font-size: 28px;
        padding-bottom: 20px;
        overflow: scroll;
    }
    #series2 #series-top .mark-list.list-show{
        padding-bottom: 50px;
        height: auto;
        max-height: 600px;
        overflow: hidden;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
    }
    #series2 #series-top .mark-list .mark-item{
        padding: 0 32px;
        display: inline-block;
    }
    #series2 #series-top .mark-list .mark-item:first-child{
        padding-left: 0;
    }
    #series2 #series-top .mark-list .mark-item:last-child{
         padding-right: 0;
     }
    #series2 #series-top .mark-list .mark-item span{
        display: block;
        padding-bottom: 8px;
    }
    #series2 #series-top .mark-list .mark-item .choose{
        color: rgba(0, 0, 0, 1);
        border-bottom: 4px solid rgba(204, 204, 204, 1);
    }
    #series2 #series-top .mark-list .arrow-btn2{
        position: absolute;
        right: 20px;
        top:  0;
        background: #fff;
        height: 50px;
        margin: auto;
        width: 14%;
        transition: all 0.25s;
    }
    #series2 #series-top .mark-list arrow-btn2 img{
        height: 50px;
        width: 50px;
    }
    #series2 #series-top .mark-list .arrow-btn2.arrow-btn-up{
        left: 0;
        right: 0;
        bottom: 0;
        top: auto;
        transform: rotate(180deg);
        background: transparent;
    }

    #series2 #series-top .shadow-over{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0,0,0,0.6);
        z-index: 20;
        display: none;
    }
    #series2 #series-top .shadow-over.show{
        display: block;
    }
    /*列表主体*/
    /*模板3*/
    #series2 .series-list .item-container-3{
        position: relative;
        padding: 0 30px;
        min-height: 260px;
    }
    #series2 .series-list .item-container-3 .img-container{
        position: relative;
        width: 100%
    }
    #series2 .series-list .item-container-3 .img-container .item-img{
        width: 100%;
        box-sizing: content-box;
    }
    #series2 .series-list .item-container-3 .info-container{
        text-align: left;
        padding-top: 8px;
        padding-bottom: 34px;
        border-bottom: 1px solid #e8e8e8;
        position: relative;
    }
    #series2 .series-list .item-container-3 .info-container .series-name{
        font-weight: bold;
        font-size: 36px;
        position: relative;
    }
    #series2 .series-list .item-container-3 .info-container .tag-list{
        display: inline-flex;
        padding-left: 10px;
        vertical-align: top;
    }
    #series2 .series-list .item-container-3 .info-container .tag-list .tag-item{
        display: inline-block;
        font-weight: normal;
        font-size: 16px;
        height: 36rpx;
        line-height: 24px;
        align-items: center;
        text-align: center;
        padding: 0 12px;
        color: #fff;
        background: linear-gradient(90deg, rgba(255, 176, 91, 1) 0%, rgba(255, 140, 82, 1) 100%);
        border-radius: 5px;
        top: 0;
        margin: auto;
        margin-left: 20px;
    }
    #series2 .series-list .item-container-3 .info-container .tag-list .tag-item:nth-child(1){
        margin-left: auto;
    }
    #series2 .series-list .item-container-3 .info-container .guide-price{
        padding-top: 24px;
        color: #696969;
        font-size: 24px;
        position: relative;
    }
    #series2 .series-list .item-container-3 .info-container .c3-arrow{
        position: absolute;
        right: 0;
        top: auto;
        bottom: 0;
        margin: auto;
        height: 20px;
    }

    .overlay-div{
        position: fixed;
    }
    .overlay-div .load{
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
    .overlay-div .load .van-loading__spinner{
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }


    #series2 #cityChoose-popup{
        width: 80%;
        padding: 48px 0;
        padding-bottom: 10px;
        border-radius: 15px;
        font-size: 32px;
        color: #000;
    }
    #series2 #cityChoose-popup .city-choose-btn{
        padding: 40px 30px;
        padding-top: 56px;

    }
    #series2 #cityChoose-popup .choose-btn{
        background: rgba(245, 248, 255, 1);
        line-height: 88px;
        font-size: 28px;
        position: relative;
        border-radius: 15px;
        padding-left: 76px;
        text-align: left;
        color: rgba(105, 105, 105, 1);
    }
    #series2 #cityChoose-popup .city-choose-btn .city-popup-icon{
        position: absolute;
    }
    #series2 #cityChoose-popup .city-choose-btn .city-point-icon{
        left: 20px;
        top: 0;
        bottom: 0;
        margin: auto;
    }
    #series2 #cityChoose-popup .city-choose-btn .city-arrow-icon{
        right: 20px;
        top: 0;
        bottom: 0;
        margin: auto;
    }
    #series2 #cityChoose-popup .cityChoose-title{
        font-weight: bold;
    }
    #series2 #cityChoose-popup .submit-btn{
        color: #000;
        border-top: 1px solid rgba(204, 204, 204, 0.5);
        padding-top: 16px;
        line-height: 48px;
    }
</style>
