<template>
    <div class="detail" :class="isXClass">
        <!--头部banner主体-->
		<div class="detail-banner">
			<van-swipe :autoplay="3000" v-if="info.car3D.has3D == false || car360 == false" indicator-color="#656565" class="banner">
			    <van-swipe-item v-for="(image, index) in info.banner" :key="index">
			        <img :src="image.file">
			    </van-swipe-item>
			</van-swipe>
			<div class="car3D" v-else>
				<!-- <img class="car3D-icon" src="http://qn.yiquan.com.cn/web/common/360.png" alt=""> -->
				<img class="car3D-icon" src="http://qn.yiquan.com.cn/web/common/360-icon.png" alt="">
				<img id="car-img" :src="imgsrc" alt="">
				<canvas id="canvas" style="width:100%" @touchstart="doMouseDown" @tochend="tochend" @touchmove="doMouseMove"></canvas> 
			</div>
		</div>
        <div class="car-info">
            <p class="car-name"><span v-if="info.year != '' && info.year != null">{{info['year']}}款</span>
                {{info['type_name']}} {{info['config_info']}}</p>
            <p class="guide-price">厂商指导价: <span> {{info['guide_price'][0]}}{{info['guide_price'][1]}}</span></p>
            <p class="guide-price" v-if="info['country_price'] != 0">全国平均售价: <span> {{info['guide_price'][0]}}{{info['guide_price'][1]}}</span></p>
        </div>
        <label v-if="info['showSubCard']">
            <div class="price-info price-info2" v-if="info['ACTIVITYTYPE'] == 1">
                <img  class="card-bg" src="/images/btqk_bg2.png">
    <!--            <div class="coupon-btn" @click="showCoupon"><i class="van-icon van-icon-arrow van-cell__right-icon"></i>-->
    <!--                <div class="info">使用优惠券</div>-->
    <!--            </div>-->
                <label v-if="info['CHANGEPRICE'] != 1">
                    <div class="down-price down-price2">
                        补贴：{{info['SUBPLICY']}}
                    </div>
                    <div class="current-price current-price-3" :class="fontColor">
                        <div v-if="info['mark_name']=='广汽本田'" class="info-top">最高大客户折让</div>
                        <div v-else class="info-top">到店议价基础上补贴金额</div>
                        <div class="price-bottom">{{info['sub_price'][0]}}{{info['sub_price'][1]}}元</div>
                    </div>
                </label>
    <!--            <div class="other-info">-->
    <!--                <img class="other-img" src="/images/car/gift-2.png">-->
    <!--                <p class="other-info-content">额外20000礼包</p>-->
    <!--            </div>-->
            </div>
            <div class="price-info" v-else>
            <img class="card-bg" :src="cardBg">
            <label v-if="info['CHANGEPRICE'] == 1">
                <div class="current-price changeInfo"> <span>{{info['CHANGEINFO']}}</span></div>
            </label>
            <label v-else>
                <div v-if="info.ACTIVITYTYPE != 5" class="coupon-btn" @click="showCoupon"><i class="van-icon van-icon-arrow van-cell__right-icon"></i>
                    <div class="info">使用优惠券</div>
                </div>
                <div v-if="info.ACTIVITYTYPE == 5" class="down-price">员工优惠价请来电咨询</div>
                <div v-else class="down-price">
                    <img class="arrow-down" src="http://qn.yiquan.com.cn/web/spring2021/series-down2.png">
                    立减：{{info['sub'][0]}}{{info['sub'][1]}}
                </div>
                <div class="current-price" :class="fontColor">¥ ： <span>{{info['show_sub_price'][0]}}</span><span class="small-percentage">{{info['show_sub_price'][1]}}({{info['pricePercentage']}})</span></div>
            </label>
            <div class="other-gift-info" v-if="info['OTHERINFO'] != '' && info['OTHERINFO'] != null">
                <label v-if="info.OTHERINFO == '此优惠价格已包含国补'">
<!--                    <img class="other-img" src="/images/car/info-2.png">-->
<!--                    <p class="other-info-content">{{info['OTHERINFO']}}</p>-->
<!--                        <img class="other-img-show" src="http://api.yiquan.com.cn/web/20year/bhgb.png">-->
                </label>
                <label v-else>
                    <img class="other-img" src="/images/car/gift-2.png">
                    <p class="other-info-content">{{info['OTHERINFO']}}</p>
                </label>
            </div>
        </div>
        </label>
        <label v-else>
            <div class="price-info" v-if="cardBg != ''">
                <img class="card-bg" :src="cardBg">
                <label v-if="info['CHANGEPRICE'] == 1">
                    <div class="current-price changeInfo"> <span>{{info['CHANGEINFO']}}</span></div>
                </label>
                <label v-else>
                    <div v-if="info.ACTIVITYTYPE != 5" class="coupon-btn coupon-btn-2" @click="showCoupon">
                        <div class="info"></div>
                    </div>
                    </label>
                <div class="other-gift-info" v-if="info['OTHERINFO'] != '' && info['OTHERINFO'] != null">
                    <label v-if="info.OTHERINFO == '此优惠价格已包含国补'">
                        <!--                    <img class="other-img" src="/images/car/info-2.png">-->
                        <!--                    <p class="other-info-content">{{info['OTHERINFO']}}</p>-->
                        <!--                        <img class="other-img-show" src="http://api.yiquan.com.cn/web/20year/bhgb.png">-->
                    </label>
                    <label v-else>
                        <img class="other-img" src="/images/car/gift-2.png">
                        <p class="other-info-content">{{info['OTHERINFO']}}</p>
                    </label>
                </div>
            </div>
        </label>
        <div class="ensure-info">
            <div class="title">一圈保证</div>
            <img class="ensure-contnet" src="https://qn.yiquan.com.cn/web/common/yqtBz1.png">
        </div>
        <div class="other-info" v-if="info.content.policy != '' || info.content.process != '' || (info.content.config != '' && info.content.config != null)">
            <van-tabs v-model="active" color="#333" title-active-color="#333">
                <van-tab title="政策详情" v-if="info.content.policy != ''"><span class="base-info" v-html="info.content.policy"></span></van-tab>
                <van-tab title="购车流程" v-if="info.content.process != ''"><span class="base-info" v-html="info.content.process"></span></van-tab>
                <van-tab title="车型配置" v-if="info.content.config != '' && info.content.config != null"><span class="base-info" v-html="info.content.config"></span></van-tab>
            </van-tabs>
        </div>
        <div class="buy-btn five-btn" :class="isXClass">
            <div class="customer-btn" @click="callTel">
                <img class="customer-icon" src="/images/icon/kefu-icon2.png">
                <span class="customer-info">联系客服</span>
            </div>
<!--            <label v-if="markId == 1054">-->
<!--                <van-button class="btn-2" type="info" size="noraml" @click="showCoupon(2)">贷款购车</van-button>-->
<!--                <van-button class="btn-1" type="info" size="noraml" @click="showCoupon(1)">全款购车</van-button>-->
<!--            </label>-->
<!--            <label v-else>-->
                <van-button class="btn-3 no-price" type="info" size="noraml"  v-if="info['CHANGEPRICE'] == 1" @click="showChangeInfo">我要购车</van-button>
                <van-button class="btn-3" type="info" size="noraml" @click="showCoupon(1)" v-else>我要购车</van-button>
<!--            </label>-->
        </div>
        <!--优惠券-->
        <van-popup class="coupon-alert"  round
                   closeable v-model="showPicker" position="bottom" :close-on-click-overlay="false" safe-area-inset-bottom>
            <p class="coupon-title">我的优惠券</p>
            <div class="empety-box" v-if="isEmpty">
                <img src="https://qn.yiquan.com.cn/web/common/quesheng2.png">
            </div>
            <div  v-else class="coupon-list">
                <label v-if="list.length > 0">
                    <div class="coupon-minititle"><span class="coupon-line"></span>可用优惠券</div>
                    <div class="coupon-item item-1" v-for="(item, index) in list" :key="index" >
                        <img class="bg" src="https://qn.yiquan.com.cn/web/common/coupon-bg.png"/>
                        <div v-if="type == 1 || type == 4" class="choose-container">
                            <img v-if="item['choose'] == 'checked'" class="choose-btn" src="https://qn.yiquan.com.cn/web/common/detail-choose-red.png" @click="couponUnchoose(index)"/>
                            <img v-else class="choose-btn" src="https://qn.yiquan.com.cn/web/common/detail-choose-common.png" @click="couponChoose(index)"/>
                        </div>
                        <div class="item-info">
                            <div class="left-box item-box">
                                <div class="money"><label v-if="item['type'] == 1 || item['type'] == 2">¥</label><span>{{item['show_info']}}</span></div>
                                <div class="small-title">{{item['short_name']}}</div>
                            </div>
                            <div class="right-box item-box">
                                <div class="item-title">{{item['name']}}</div>
                                <div class="item-desc">
                                    <p class="remark">{{item['content']}}</p>
                                    <p class="deadline">有效期至{{item['end_date']}}</p>
                                </div>
                                <div v-if="type == 1 || type == 4">
                                </div>
                                <div v-else-if="type == 2" class="coupon-status">
                                    <img src="https://qn.yiquan.com.cn/web/common/coupon-used.png">
                                </div>
                                <div v-else-if="type == 3" class="coupon-status">
                                    <img src="https://qn.yiquan.com.cn/web/common/coupon-over.png">
                                </div>
                            </div>
                        </div>
                    </div>
                </label>
                <label v-if="list2.length > 0">
                    <div class="coupon-minititle"><span class="coupon-line"></span>不可用优惠券</div>
                    <div class="coupon-item item-2" v-for="(item, index) in list2" :key="index">
                        <img class="bg" src="https://qn.yiquan.com.cn/web/common/coupon-bg.png"/>
                        <label v-if="type == 1 || type == 4">
                            <img v-if="item['choose'] == 'checked'" class="choose-btn" src="https://qn.yiquan.com.cn/web/common/detail-choose-red.png">
                            <img v-else class="choose-btn" src="https://qn.yiquan.com.cn/web/common/detail-choose-common.png">
                        </label>
                        <div class="item-info">
                            <div class="left-box item-box">
                                <div class="money"><label v-if="item['type'] == 1 || item['type'] == 2">¥</label><span>{{item['show_info']}}</span></div>
                                <div class="small-title">{{item['short_name']}}</div>
                            </div>
                            <div class="right-box item-box">
                                <div class="item-title">{{item['name']}}</div>
                                <div class="item-desc">
                                    <p class="remark">{{item['content']}}</p>
                                    <p class="deadline">有效期至{{item['end_date']}}</p>
                                </div>
                                <div v-if="type == 1 || type == 4">
                                </div>
                                <div v-else-if="type == 2" class="coupon-status">
                                    <img src="/images/coupon-used.png">
                                </div>
                                <div v-else-if="type == 3" class="coupon-status">
                                    <img src="/images/coupon-over.png">
                                </div>
                            </div>
                        </div>
                    </div>
                </label>
            </div>
            <div class="coupon-bottom">
                <div class="price-show" v-if="info['showSubPrice']" >员工优惠价：<span class="small-price">¥</span><span class="big-price">{{info['sub_price'][0]}}{{info['sub_price'][1]}}</span></div>
                <div class="submit-price" @click="navToBuy">立即使用</div>
            </div>
        </van-popup>

        <van-overlay :show="loadShow" class="overlay-div">
            <van-loading type="spinner" class="load"/>
        </van-overlay>
<!--        <link rel="stylesheet" type="text/css" href="http://car.yiquanvip.com/jslib/img/xiangqing.css"/>-->
        <link rel="stylesheet" type="text/css" href="http://api.yiquan.com.cn/jslib/img/xiangqing.css"/>
    </div>
</template>

<script>
    import vue from 'vue';
    import {Swipe, List, Button, Tab, Tabs, Dialog, ActionSheet} from 'vant';

    vue.use(Swipe).use(List).use(Button).use(Tab).use(Tabs).use(Dialog).use(ActionSheet);
	// 3D 公共变量
	var canvas = document.getElementById("canvas"),
		DPR =  window.devicePixelRatio, //获取设备的物理像素比
		viewW = window.innerWidth,
		viewH = viewW*0.6,
		cansW = viewW*DPR,//放大canvas
		cansH = viewH*DPR,
		ctx = "",
		imgArr = [],//图片数组
		curDeg = 0,//代表当前显示的图片下标
		imgTotal = 36;//图片总数
	var started = false; 
	var startTime = 0; //开始时间
	var endTime = 0; //结束时间
	var distances = "";//移动的距离
	var timeDis = "";//时间差
	var speed = "";//速度
	
	var startX = "";//开始时的X轴
	//手指触摸起点
	var startPoint = 0,
	//手指离开终点
	tempPoint = 0,
	//滑动多长距离，这里取（canvas宽/图片总数的一半）
	//数值越大约灵敏
	distance = cansW/70;
	var width = ""; 
	var height = "";
	var baseURL = ""; //图片前缀
	
    export default {
        name: 'car-detail',
        data() {
            return {
                info: {
                    banner: [
                    ],
                    mark_name: '-',
                    year: '',
                    type_name: '-',
                    config_info: '-',
                    content: {
                        config: '',
                        policy: '',
                        process: ''
                    },
                    OTHERINFO : '',
                    CHANGEPRICE : 1,
                    guide_price : ['-', '-'],
                    sub_price: ['-', '-'],
					car3D: {
						has3D: true
					}
                },
                downPrice : 0,
                type : 4,
                active: 0,
                loadShow: false,
                showPicker: false,
                isEmpty: false,
                list : [],
                list2 : [],
                loading: false,
                finished: true,
                typeId : 0,
                usedUserCouponId: 0,
                couponId : 0,
                fontColor: '',
                isXClass : '',
                markId : 0,
                loanType : 1,
                cardBg: '',
				imgsrc: "", //360 首张图片地址
				car360: true, //是否显示360
            }
        },
		mounted() {
		    // this.initCanvas();
		},
        created: function () {
            this.loadShow = true;
			//360 重置公共变量
			ctx = "",
			imgArr = [],//图片数组
			curDeg = 0,//代表当前显示的图片下标
			imgTotal = 36;//图片总数
			// started = false; 
			startTime = 0; //开始时间
			endTime = 0; //结束时间
			distances = "";//移动的距离
			timeDis = "";//时间差
			speed = "";//速度
			
			startX = "";//开始时的X轴
			//手指触摸起点
			startPoint = 0,
			//手指离开终点
			tempPoint = 0;
			
            let query = this.$route.query;
            if (typeof query.markId == 'undefined' || typeof query.markTypeId == 'undefined' || typeof query.id == 'undefined') {
                this.$router.replace({
                    path: '/index'
                });
                return false;
            }

            let markId = query.markId;
            this.markId = markId;
            let typeId = query.id;
            this.typeId = query.id;
            let provinceId = this.$store.getters['local/getProvinceId'];
            let cityId = this.$store.getters['local/getCityId'];
            let custId = this.$store.getters['user/getCustId'];
            let userId = this.$store.getters['user/getUserId'];

            if ((typeof provinceId == 'undefined' || provinceId == 0 || provinceId == null) || (typeof cityId == 'undefined' || cityId == 0 || cityId == null)) {
                this.$router.replace({
                    path: '/index'
                });
                return false;
            }
			
            this.$post('getCarInfo', {
                provinceId: provinceId,
                cityId: cityId,
                custId: custId,
                markId: markId,
                typeId: typeId,
                userId: userId,
            }).then((res) => {
                if (res.code == 200) {
                    let data = res.data;
                    if(data['ACTIVITYTYPE'] == 5){
                        data['sub'] = this.numberFormat2(data['guide_price'] - data['sub_price']);
                        data['old_guide_price'] = Object.assign(data['guide_price']);//保留数字形态的指导价
                        data['guide_price'] = this.numberFormat(data['guide_price']);
                        data['old_sub_price'] = Object.assign(data['sub_price']);//保留数字形态的员工价
                        data['show_sub_price'] = this.numberFormat2(data['sub_price']);
                        data['sub_price'] = this.numberFormat2(data['sub_price']);
                    }else{
                        data['sub'] = this.numberFormat(data['guide_price'] - data['sub_price']);
                        data['old_guide_price'] = Object.assign(data['guide_price']);//保留数字形态的指导价
                        data['guide_price'] = this.numberFormat(data['guide_price']);
                        data['old_sub_price'] = Object.assign(data['sub_price']);//保留数字形态的员工价
                        data['show_sub_price'] = this.numberFormat(data['sub_price']);
                        data['sub_price'] = this.numberFormat(data['sub_price']);
                    }
                    let pricePercentage = data['old_sub_price'] / data['old_guide_price'] * 100;
                    let fixedPricePercentage = parseInt(pricePercentage).toString();

                    let fixedPricePercentageStrS = fixedPricePercentage.split('');
                    let len = fixedPricePercentageStrS.length;
                    fixedPricePercentage = '';
                    for(var i = 0; i < len ; i++){
                        if(fixedPricePercentageStrS[i] != 0){
                            fixedPricePercentage += fixedPricePercentageStrS[i];
                        }
                    }

                    let fixedPricePercentage2 = this.SectionToChinese(fixedPricePercentage);
                    fixedPricePercentage = '约' + fixedPricePercentage + '折';
                    data['pricePercentage'] = fixedPricePercentage;
                    if(data['OTHERINFO'] == '此优惠价格已包含国补'){
                        //国补价格
                        this.cardBg = 'http://qn.yiquan.com.cn/web/spring2021/diban1.png';
                    }
                    this.info = data;
                    this.loadShow = false;
					//3D看车
					if(data.car3D.has3D) {
						imgTotal = data.car3D.info.NUM;
						baseURL = data.car3D.info.prefixPath;
						this.imgsrc = data.car3D.info.prefixPath + '/1.jpg';
						this.initCanvas(this.markId);
					}else {
						this.car360 = false;
					}
					if(data.showSubCard == 0){
                        this.cardBg = data.bannerFile;
                    }else{
					    if(custId == 101088){
                            this.cardBg = 'http://qn.yiquan.com.cn/web/spring2021/diban3.png';
                        }else{
                            this.cardBg = 'http://qn.yiquan.com.cn/web/spring2021/diban2.png';
                        }
                    }
                } else {
                    Dialog.alert({
                        message: res.msg
                    }).then(() => {
                        // on close
                        this.$router.replace({
                            path: '/index'
                        });
                    });
                }
            });
        },
        methods: {
            navToBuy() {
                //存入优惠券信息
                let couponInfo = [];
                let list = this.list;
                let len = list.length;
                for(let i = 0; i < len ; i++){
                    if(this.list[i]['choose'] == 'checked'){
                         couponInfo.push({
                             'ID' : this.list[i]['ID'],
                             'user_coupon_id' : this.list[i]['user_coupon_id'],
                             'downPrice' : this.list[i]['downPrice'],
                         });
                    }
                }
                this.$store.commit('order/setCouponInfo', JSON.stringify(couponInfo));
                // console.log(JSON.parse(this.$store.state.order.couponInfo));
                this.$router.push({
                    path: 'selection',
                    query: {
                        markId: this.$route.query.markId,
                        markTypeId: this.$route.query.markTypeId,
                        id: this.$route.query.id,
                        loanType : this.loanType,
                    }
                })
            },
            showCoupon(type) {
                this.loanType = type;
                let userId = this.$store.getters['user/getUserId'];
                if(userId == 0){
                    this.$router.push({
                        path: 'selection',
                        query: {
                            markId: this.$route.query.markId,
                            markTypeId: this.$route.query.markTypeId,
                            id: this.$route.query.id,
                            loanType : this.loanType,
                        }
                    })
                }else{
                    this.firstLoad();
                }
            },
            numberFormat(value) {
                var param = {};
                var k = 10000,
                    sizes = ['', '万', '亿', '万亿'],
                    i;
                if (value < k) {
                    param.value = value;
                    param.unit = ''
                } else {
                    i = Math.floor(Math.log(value) / Math.log(k));

                    param.value = ((value / Math.pow(k, i)));
                    param.unit = sizes[i];
                }
                // return param;
                return [param.value , param.unit];
            },
            numberFormat2(value){
                var param = {};
                var k = 10000,
                    sizes = ['', '万', '亿', '万亿'],
                    i;
                if(value < k){
                    param.value =value;
                    param.unit=''
                }else{
                    i = Math.floor(Math.log(value) / Math.log(k));

                    param.value = ((value / Math.pow(k, i)));
                    param.unit = sizes[i];
                }

                let reg = /^[0-9]/;
                param.value = param.value.toString();
                let len = param.value.length;
                let newValue = '';
                for (var i = 0; i < len ; i++){
                    let item = param.value.charAt(i);
                    if(i != 0){
                        item = item.replace(reg, 'x');
                    }
                    newValue += item;
                }

                return [newValue, param.unit];
            },
            firstLoad (){
                this.isEmpty = true;
                this.loadList(this.pageIndex).then((data) => {
                    let list = data.list;
                    let len = list.length;
                    for (var i = 0; i < len ; i++){
                        list[i]['choose'] = '';
                        list[i]['downPrice'] = 0;
                    }

                    this.list = list;
                    this.pageIndex = data.page;
                    if(data.pageCount > 0){
                        this.isEmpty = false;
                    }
                    this.$data.showPicker = true;

                    this.getPrice();
                });
            },
            onLoad() {
                // 异步更新数据更多数据
                if(!this.finished && !this.isLoading){
                    setTimeout(() => {
                        let pageIndex = this.pageIndex;
                        pageIndex++;
                        this.loadList(pageIndex).then((data) => {
                            let list = this.list;
                            this.list = list.concat(data.list);
                            this.pageIndex = data.page;
                        });
                    }, 500);
                }
            },
            loadList (pageIndex){
                this.loading=true;
                this.isLoading = true;
                return new Promise((resolve) => {
                    let data = {
                        'appId' :this.$wechat.appId,
                        'openId' : this.$store.getters['user/getOpenId'],
                        'type' : this.type,
                        'typeId' : this.typeId,
                        'pageIndex' : pageIndex,
                        'pageSize' : this.pageSize,
                    };
                    this.$post('/getCarCoupon', data).then((res) => {
                        if(res.code == 200){
                            resolve(res.data);
                            this.finished = true;
                        }
                        this.loading = false;
                        this.isLoading = false;
                    });
                })
            },
            /**
             * 获取优惠券信息
             */
            chooseCoupon(userCouponId, couponId, index){
                //获取优惠券信息（二次确认是否能够使用[包含所属物品是否可以降价，优惠券是否存在可以使用并且有未过期]）
                //传入值为 持有id， 优惠券id， 所在车ID （ctiId）
                let data = {
                    'userCouponId' :userCouponId,
                    'couponId' : couponId,
                    'typeId' : this.typeId,
                    'openId' : this.$store.getters['user/getOpenId'],
                };
                //存入降价信息（含优惠券持有id，优惠券id， 优惠券降价金额）
                this.$post('/canUsedCoupon', data).then((res) => {
                    if(res.code == 200){
                        let data = res.data;
                        this.downPrice = data['downPrice'];
                        this.list[index]['downPrice'] = data['downPrice'];
                        this.getPrice();
                    }else{
                        Dialog.alert({
                            message: res.msg
                        }).then(() => {
                            // on close
                        });
                    }
                });
            },
            /**
             * 选择优惠券
             */
            couponChoose(index){
                this.list[index]['choose'] = 'checked';
                if(this.list[index]['downPrice'] == 0){
                    this.chooseCoupon(this.list[index]['user_coupon_id'], this.list[index]['ID'], index);
                }else{
                    this.getPrice();
                }
            },
            /**
             * 取消选择优惠券
             * @param index
             */
            couponUnchoose(index){
                this.list[index]['choose'] = '';
                this.getPrice();
            },
            /**
             * 计算价格
             */
            getPrice(){
                let info = this.info;
                let subPrice = Object.assign(info['old_sub_price']);//保留数字形态的员工价
                let downPrice = 0;
                let  list =this.list;
                let len  = list.length;
                for (var i = 0; i < len ; i++){
                    if(this.list[i]['choose'] == 'checked'){
                        downPrice += this.list[i]['downPrice'];
                    }
                }
                info['sub_price'] = this.numberFormat(subPrice - downPrice);
                this.info = info;
            },
            callTel(){
                window.location.href = 'tel://400-000-2787';
            },
            SectionToChinese(section) {
                section = section.split('');
                var chnNumChar = ["", "一", "二", "三", "四", "五", "六", "七", "八", "九"];
                var chnStr = '';
                let len = section.length;
                for(var i = 0; i < len ; i++){
                    let j = section[i];
                    chnStr += chnNumChar[j];
                }
                return chnStr;
            },
            showChangeInfo(){
                Dialog.alert({
                    message: this.info.CHANGEINFO
                }).then(() => {
                    // on close
                    // this.$router.push('/index');
                });
            },
			//3D看车
			initCanvas(markId) {
				canvas = document.getElementById("canvas");
				ctx = canvas.getContext("2d");
				canvas.width = window.innerWidth*DPR;// window.innerWidth;  
				canvas.height = canvas.width * 0.6;//window.innerHeight; 
				width = canvas.width;
				height = canvas.height;
				
				var carImg = document.getElementById("car-img"),
				imgURL ="",
				imgObj = null,
				imgIndex = 0;
				carImg.onerror = () => { // 图片加载错误时的替换图片
					this.car360 = false;
				};
				//loading
				for(var i = 1;i < imgTotal+1;i++){
					imgURL = baseURL + "/" + i + ".jpg";
					imgObj = new Image();
					imgObj.src = imgURL;
					//将所有图片对象压入一个数组,方便调用
					imgArr.push(imgObj);
					imgObj.onload = function() {
						imgIndex ++;
						if(imgIndex == imgTotal) {
							// if(viewW > 750) {
							// 	this.redraw();
							// }
							//默认图
							ctx.drawImage(imgArr[0], 0, 0, width, height); //处理图片
							if(markId == '1047') {
								var carMb = new Image();
								carMb.src = "https://qn.yiquan.com.cn/bk/car-mb.png";
								carMb.onload = function() {
									ctx.drawImage(carMb, 0, 0, width, height); //处理图片
								}
							}
						}
					}
					// console.log(imgIndex);
					// if(i == imgTotal) {
					// 	//默认第一张图
					// 	this.drawImg(0);
					// }
				}
			},
			doMouseDown(event) {
				//记录起始触摸点
				startPoint = event.touches[0].clientX;
				startX = event.touches[0].clientX;
				startTime = Date.now();
				//去掉默认事件，iPhone下可去除双击页面默认跳动(翻页)效果
				event.preventDefault();
			},
			tochend(event) {
				//移动端手指离开屏幕
				//移动距离
				distances = tempPoint - startX;
				endTime = Date.now();
				//时间差
				timeDis = endTime - startTime;
				//速度
				speed = (distances / timeDis);
				var time = 0;
				var timer = setInterval(function() {
					time ++;
					if(time == 7) {
						clearInterval(timer);
					}
					else {
						tempPoint = tempPoint + speed*time;
						if((tempPoint - startPoint) > distance) {
							drawImg(curDeg,"left");
							startPoint = tempPoint;
						} else if((tempPoint - startPoint) < -distance) {
							drawImg(curDeg,"right");
							startPoint = tempPoint;
						}
					}
				},18);
			},
			doMouseMove(event) {
				tempPoint = event.touches[0].clientX;
				//向右移动
				if((tempPoint - startPoint) > distance) {
					this.drawImg(curDeg,"left");
					//符合距离条件移动后，将记录点设到手指最新位置
					startPoint = tempPoint;
				}else if((tempPoint - startPoint) < -distance) {//左
					this.drawImg(curDeg,"right");
					startPoint = tempPoint;
				}
				//禁止移动页面
				event.preventDefault();
			}, 
			doMouseUp(event) { 
				var x = event.pageX;
				var y = event.pageY;
				var canvas = event.target;
				var loc = this.getPointOnCanvas(canvas, x, y);  
				if (started) {  
					this.doMouseMove(event);  
					startedX = -1;  
					started = false;
					newfor = true;
					number = frame;
				}
			},
			doMouseleave(event) {
				if (started) {  
					startedX = -1;
					started = false;
					newfor = true;
					number = frame;
				}  
			},
			getPointOnCanvas(canvas, x, y) {
				var bbox = canvas.getBoundingClientRect();  
				return { x: x - bbox.left * (canvas.width / bbox.width),  
						y: y - bbox.top * (canvas.height / bbox.height)  
				};  
			},
			//绘图
			//参数：图片对象下标，移动方向
			drawImg(n,type){
				if(type == "left"){
					if(curDeg > 0){
						curDeg--;
					}else{
						curDeg = imgTotal-1;
					}
				}else if(type == "right"){
					if(curDeg < imgTotal-1){
						curDeg++;
					}else{
						curDeg = 0;
					}
				}
				n = curDeg;
				ctx.clearRect(0,0,cansW,cansH);
				//参数：图片对象，X偏移量，Y偏移量，图宽，图高
				ctx.drawImage(imgArr[n],0,0,width,height);
				if(this.markId == '1047') {
					var carMb = new Image();
					carMb.src = "https://qn.yiquan.com.cn/bk/car-mb.png";
					ctx.drawImage(carMb, 0, 0, width, height); //加载图片蒙版
				}
			}
			
		}
    }
</script>

<style>
    /*车详情页*/
    .detail {
        background: #fff;
        position: relative;
        padding-bottom: 130px;
        height: 100%;
        overflow: scroll;
    }
	.detail-banner {
		width: 100%;
		min-height: 450px;
		display: flex;
		align-items: flex-end;
		justify-content: center;
	}
    .detail .banner {
        width: 100%;
    }

    .detail .banner img {
        width: 100%;
    }

    .detail .car-info {
        width: 100%;
        padding: 0 30px;
    }

    .detail .car-info .car-name {
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        color: #333;
		font-weight: 600;
        /*font-family: "PingFangSC-Bold";*/
    }

    .detail .car-info .guide-price {
        color: #858585;
        font-size: 28px;
        margin-top: 30px;
        text-align: left;
    }

    .detail .car-info .guide-price > span {
        color: #F00;
    }

    .detail .price-info {
        margin-top: 20px;
        width: 100%;
        padding: 15px 30px;
        padding-bottom: 24px;
        color: #fff;
        /* color: #fff0d7; */
        position: relative;
    }

    .detail .price-info .card-bg {
        width: 100%;
       /* box-shadow: 0px 11px 40px 0px rgba(229, 26, 4, 0.3); */
        box-shadow: 0px 11px 40px 0px rgba(171, 134, 97, 0.2);
        /*border-radius: 20px;*/
    }

    .detail .price-info .coupon-btn {
        position: absolute;
        /*font-family: "PingFangSC-Medium";*/
        top: 36px;
        right: 60px;
        height: 60px;
        white-space: nowrap;
        font-weight: bold;
    }

    .detail .price-info .coupon-btn.coupon-btn-2{
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 4px !important;
        color: #585858;
        width: 200px;
        height: 100px;
        top: 10px;
        right: 30px;
    }

    .detail .price-info .coupon-btn .info {
        position: absolute;
        right: 0;
        top: 26px;
        bottom: 0;
        margin: auto;
        padding-right: 40px;
        height: 100px;
        line-height: 100px;
        font-size: 28px;
        letter-spacing: 1.5px;
    }

    .detail .price-info .coupon-btn .van-cell__right-icon {
        color: #fff;
        float: right;
        margin: auto 0;
        position: absolute;
        right: 0;
        top: 26px;
        bottom: 0;
    }

    .detail .price-info .coupon-btn .van-cell__right-icon.van-icon-arrow::before{
        color: #fff0d7;
    }

    .detail .price-info .coupon-btn.coupon-btn-2 .van-cell__right-icon.van-icon-arrow::before{
        color: #585858;
    }

    .detail .price-info .down-price {
        position: absolute;
        /*font-family: "PingFangSC-Medium";*/
        top: 65px;
        left: 190px;
        font-weight: 700;
    }

    .detail .price-info .down-price .arrow-down{
        width: 19px;
        height: 27px;
        position: absolute;
        left: -24px;
        top: 0;
    }

    .detail .price-info .current-price {
        position: absolute;
        /*font-family: "PingFangSC-Bold";*/
        top: 175px;
        left: 60px;
        font-weight: bold;
    }

    .detail .price-info .changeInfo{
        top: 140px;
    }

    .detail .price-info .current-price .small-percentage{
        font-size: 26px;
        /*opacity: 0.7;*/
    }

    .detail .price-info .current-price-3{
        top: 185px;
    }

    .detail .price-info .current-price span {
        font-size: 60px;
    }

    .detail .price-info .changeInfo span{
        font-size: 40px;
    }
    .detail .price-info .current-price.is-red{
        color: #fff;
        -webkit-text-stroke: 0.6px #ffba10;
        box-shadow: none;
    }

    .detail .price-info2 {
        padding: 0;
        margin-top: 0;
    }

    .detail .price-info2 .down-price2 {
        font-size: 30px;
        top: 80px;
    }

    .detail .price-info2 .current-price{
        left: 190px;
        text-align: left;
    }

    .detail .price-info2 .current-price .info-top{
        font-size: 30px;
    }

    .detail .price-info2 .current-price .price-bottom{
        margin-top: 20px;
        font-size: 50px;
    }

    .detail .ensure-info {
        width: 100%;
    }

    .detail .ensure-info .title {
        background-color: #f1f1f1;
        /*font-family: "PingFangSC-Bold";*/
        font-size: 30px;
        font-weight: normal;
        font-stretch: normal;
        color: #4a4a4a;
        padding: 30px;
        text-align: left;
    }

    .detail .ensure-info .ensure-contnet {
        width: 100%;
    }

    .detail .other-info {
        border-top: 10px solid #f2f9ff;
        width: 100%;
    }

    .detail .other-info .base-info {
        width: 100%;
        display: block;
        line-height: normal;
        text-align: left;
        padding: 10px;

    }

    .detail .other-info .base-info > div {
        margin: 0 !important;
    }

    .detail .other-info .base-info img {
        width: 100%;
    }

    .detail .other-info .base-info img, .other-info .buy-img {
        width: 100%;
    }

    .detail .buy-btn {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 120px;
        background: #fff;
        z-index: 200;
        box-shadow: 0 0 30px rgba(0,18,42, 0.08);
    }

    .detail .buy-btn .customer-btn{
        padding-top: 8px;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 186px;
        height: 100px;
        z-index: 2;
    }

    .detail .buy-btn .customer-btn .customer-icon{
        width: 37px;
        height: 38px;
        position: absolute;
        top: 19px;
        left: 0;
        right: 0;
        margin: auto;
    }

    .detail .buy-btn .customer-btn .customer-info{
        font-size: 20px;
        color: #5a5a5a;
        position: absolute;
        bottom: 16px;
        left: 0;
        right: 0;
        margin: auto;
    }

    /*.detail .buy-btn .van-button {*/
    /*    width: 100%;*/
    /*    height: 100px;*/
    /*    padding-left: 186px;*/
    /*}*/

    .detail .buy-btn .van-button{
        width: 520px;
        position: absolute;
        top: 9px;
        right: 30px;
        text-align: center;
    }

    .detail .overlay-div {
        position: fixed;
    }

    .detail .overlay-div .load {
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .detail .overlay-div .load .van-loading__spinner {
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .detail .coupon-alert{
        height: 70%;
        overflow: hidden;
    }
    .detail .coupon-title{
        height: 100px;
        line-height: 100px;
        background: #fff;
        text-align: center;
        font-weight: bold;
        color: #414141;
        position: absolute;
        width: 100%;
        z-index: 1;
    }
    .detail .coupon-list{
        background: #f6f6f6;
        width: 100%;
        height: 100%;
        overflow: auto;
        padding-top: 100px;
        padding-bottom: 150px;
    }
    .detail .coupon-list .coupon-minititle{
        text-align: left;
        font-size: 24px;
        color: #414141;
        padding: 48px 30px;
        line-height: 24px;
        padding-bottom: 20px;
    }
    .detail .coupon-list .coupon-minititle span{
        border-left: 4px solid #fe2a3c;
        padding-right: 10px;
    }
    .detail .coupon-list .coupon-item{
        width: 100%;
        padding: 18px 30px;
        position: relative;
    }
    .detail .coupon-item{
        position: relative;
    }
    .detail .coupon-item .bg{
        width: 100%;
    }
    .detail .coupon-item .item-info{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        padding: 36px 30px;
    }
    .detail .coupon-item .item-box{
        float: left;
        position: relative;
        height: 100%;
        top: 0;
    }
    .detail .coupon-item .left-box{
        width: 30%;
        color: #979797;
        margin-top: 65px;
        font-size: 23px;
    }
    .detail .coupon-item.item-1 .left-box,.detail .coupon-item.item-4 .left-box{
        color: #ff293d;
    }
    .detail .coupon-item .left-box .money span{
        font-size: 40px;
        font-weight: bold;
    }
    .detail .coupon-item .left-box .small-title{
        margin-top: 10px;
    }
    .detail .coupon-item .right-box{
        width: 70%;
        font-size: 20px;
        color: #979797;
        padding-top: 50px;
        padding-left: 43px;
        /*padding-right: 30px;*/
        text-align: left;
        position: relative;
        padding-right: 140px;
    }
    .detail .coupon-item .right-box .item-title{
        font-size: 24px;
        line-height: 34px;
        font-weight: bold;
        color: #3c3c3c;
        overflow: hidden;
        white-space: nowrap;
    }
    .detail .coupon-item .right-box .item-desc{
        margin-top: 10px;
        line-height: 28px;
        /*padding-right: 120px;*/
    }
    .detail .coupon-item .right-box .remark{
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    .detail .coupon-item .right-box .used-btn{
        position: absolute;
        right: 10px;
        top: 0;
        bottom: 0;
        margin: auto;
    }
    .detail .coupon-item .choose-container{
        position: absolute;
        height: 100%;
        width: 100%;
        display: block;
        top: 0;
        right: 0;
        z-index: 2;
    }
    .detail .coupon-item .choose-btn{
        position: absolute;
        right: 30px;
        top: 18px;
        width: 80px;
        border-top-right-radius: 10px;
    }
    .detail .coupon-item .right-box .coupon-status{
        position: absolute;
        right: 30px;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 100px;
        height: 100px;
    }
    .detail .coupon-item .right-box .coupon-status img{
        width: 100%;
        height: 100%;
    }
    .detail .coupon-item .right-box .checkBox-t{
        position: absolute;
        right: 0;
        top: 0;
    }
    .detail .coupon-alert .coupon-bottom{
        position: absolute;
        height: 150px;
        width: 100%;
        bottom: 0;
        left: 0;
        background: #fff;
    }
    .detail .coupon-alert .coupon-bottom .price-show{
        text-align: left;
        line-height: 150px;
        font-size: 22px;
        padding: 0 30px;
    }
    .detail .coupon-alert .coupon-bottom .price-show span{
        color: #ff2d1f;
    }
    .detail .coupon-alert .coupon-bottom .price-show .big-price{
        font-size: 34px;
        font-weight: bold;
        letter-spacing: 1px;
        padding-left: 10px;
    }
    .detail .coupon-alert .coupon-bottom .submit-price{
        position: absolute;
        right: 30px;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 250px;
        height: 78px;
        line-height: 78px;
        background-image: linear-gradient(90deg,
        #ff273f 0%,
        #ff580f 100%),
        linear-gradient(
                #eeeeee,
                #eeeeee);
        background-blend-mode: normal,
        normal;
        color: #ffffff;
        font-size: 28px;
        border-radius: 50px;
        letter-spacing: 1px;
        font-weight: bold;
    }
    .detail .empety-box{
        width: 100%;
        position: absolute;
        top: 0;
    }
    .detail .empety-box img{
        width: 460px;
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
        top: 320px;
    }


    .detail .price-info .other-gift-info{
        display: inline-block;
        position: absolute;
        padding-left: 52px;
        padding-right: 14px;
        font-size: 22px;
        color: #ff0000;
        /*background: #fff;*/
        border-radius: 50px;
        height: 38px;
        line-height: 38px;
        left: 140px;
        bottom: 42px;
        letter-spacing: 0px;
    }
    .detail .price-info .other-gift-info .other-img{
        position: absolute;
        width: 24px;
        height: 24px;
        left: 14px;
        top: 0;
        bottom: 4px;
        margin: auto;
    }
    /*five*/
    .detail .buy-btn.five-btn .van-button {
        border: none;
        border-radius: 50px;
        height: 88px;
        top: 0;
        bottom: 0;
        margin: auto;
        line-height: 88px;
        color: #fff;
        font-size: 30px;
        font-weight: bold;
        letter-spacing: 1px;
    }

    .detail .buy-btn.five-btn .van-button.btn-1{
        background-image: linear-gradient(-51deg,
        #ff4200 0%,
        #ff2727 100%),
        linear-gradient(
                #1b8fef,
                #1b8fef);
        box-shadow: 0px 11px 40px 0px rgba(229, 26, 4, 0.38);
        width: 260px;
    }

    .detail .buy-btn.five-btn .van-button.btn-2{
        width: 260px;
        right: 300px;
        background-image: linear-gradient(270deg,
        #1b8fef 0%,
        #1271ff 100%),
        linear-gradient(
                #1b8fef,
                #1b8fef);
        box-shadow: 0px 11px 40px 0px rgba(25, 137, 242, 0.38);
    }

    .detail .buy-btn.five-btn .van-button.btn-3{
        background-image: linear-gradient(-51deg,
        #ff4200 0%,
        #ff2727 100%),
        linear-gradient(
                #1b8fef,
                #1b8fef);
        box-shadow: 0px 11px 40px 0px rgba(229, 26, 4, 0.38);
    }

    .detail .buy-btn.five-btn .van-button.btn-3.no-price{
        background-image:linear-gradient(90deg,#D5D5D5,#cccccc),linear-gradient(#D5D5D5,#cccccc);
        color: #909090;
        box-shadow: 0px 11px 40px 0px rgba(3, 3, 3, 0.38);
    }
	/* 后续修改 */
    .detail .car3D {
		position: relative;
	}
    .detail .car3D-icon {
		position: absolute;
		top: 50px;
		right: 34px;
		width: 90px;
		height: auto;
	}

    .detail .banner{
        display: block;
    }
</style>