<template>
    <div class="pay-info">
        <div class="item-container">
            <div class="img-container">
                <img class="item-img" :src="order.file">
            </div>

            <div class="info-container">
                <div class="info-name">{{order.NAME}}</div>
                <div class="info-price">￥{{order.price}}</div>
            </div>
        </div>

        <div class="bottom-info">
            <div class="all-price">合计金额：<span>¥{{order.price}}</span></div>
            <div class="buy-btn" @click="toPay">去支付</div>
        </div>

        <van-overlay :show="loadShow"  class="overlay-div">
            <van-loading type="spinner"  class="load"/>
        </van-overlay>
    </div>
</template>

<script>
    import vue from 'vue';
    import { Button, Field, Uploader, Dialog} from 'vant';

    vue.use(Button).use(Field).use(Uploader).use(Dialog);

    export default {
        name: 'order-confirm',
        data() {
            return {
                loadShow: true,
                order : {
                    'file' : '',
                    'name' : '',
                    'price' : '',
                },
                orderId : 1,
            }
        },
        created: function(){
            this.loadShow = true;
            let data = {
                'appId' :this.$wechat.appId,
                'openId' : this.$store.getters['user/getOpenId'],
                'orderId' : this.orderId
            };

            this.$post('/goodsOrderPayInfo', data).then((res) => {
                if(res.code == 200){
                    this.loadShow = false;
                    let order = res.data;

                    order.price = order.PRICE/ 100;
                    this.order = order;
                }else{
                    Dialog.alert({
                        message: res.msg,
                    }).then(() => {
                        // on close 跳转到首页
                    });
                }
            });

        },
        methods: {
            toPay(){
                let data = {
                    'appId' :this.$wechat.appId,
                    'openId' : this.$store.getters['user/getOpenId'],
                    'orderId' : this.orderId
                };

                this.$post('/perPayOrder', data).then((res) => {
                    if(res.code == 200){
                        let data = res.data;

                        wx.ready(function(){
                            wx.chooseWXPay({
                                timestamp: data.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
                                nonceStr: data.nonceStr, // 支付签名随机串，不长于 32
                                package: data.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
                                signType: data.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
                                paySign: data.sign, // 支付签名
                                success: function (res) {
                                    // //跳转到支付成功页面有这个页面
                                    console.log(res);
                                    // $this.$router.push({
                                    //     path: "/success_page",
                                    //     name:"success_page"
                                    // })
                                    // console.log(res);
                                },
                                cancel: function (res) {//提示引用的是mint-UI里toast
                                    console.log('已取消支付');
                                },
                                fail: function (res) {
                                    console.log('支付失败，请重试');
                                }
                            })
                        })
                    }
                });
            }
        }
    }
</script>

<style>
    /*确认订单页*/
    .pay-info {
        background: #fff;
        position: relative;
        color: #000;
        min-height: 100%;
    }

    .pay-info .item-container{
        position: relative;
        padding: 0 24px;
        padding-top: 36px;
        min-height: 240px;
        padding-left: 300px;
        box-sizing: content-box;
    }
    .pay-info .item-container .img-container{
        position: absolute;
        left: 24px;
        width: 240px;
    }
    .pay-info .item-container .img-container .item-img{
        width: 240px;
        height: 180px;
        border-radius: 10px;
    }
    .pay-info .item-container .info-container{
        text-align: left;
    }

    .pay-info .item-container .info-container .info-name{
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-size: 30px;
        line-height: 40px;
        min-height: 80px;
        text-align: left;
        font-weight: bold;
    }

    .pay-info .item-container .info-container .info-price{
        text-align: right;
        position: absolute;
        bottom: 50px;
        right: 30px;
    }

    .pay-info .bottom-info{
        position: absolute;
        bottom: 0;
        left: 0;
        height: 100px;
        line-height: 100px;
        padding: 0 30px;
        width: 100%;
        text-align: left;
    }

    .pay-info .bottom-info .all-price{
        color: #333;
        font-size: 26px;
    }
    
    .pay-info .bottom-info .all-price span{
        color: #e94946;
        font-weight: bold;
        font-size: 30px;
    }

    .pay-info .bottom-info .buy-btn{
        text-align: center;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        right: 30px;
        height: 78px;
        line-height: 78px;
        color: #fff;
        width: 380px;
        border-radius: 50px;
        background-image: linear-gradient(-51deg,
        #ff4200 0%,
        #ff2727 100%),
        linear-gradient(
                #1b8fef,
                #1b8fef);

    }
</style>