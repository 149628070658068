<template>
    <div class="test-get-coupon">
        <div class="bg-coupon">
            <img class="bg-img" src="/images/coupon/coupon-act-bg.png">
            <div class="coupon-btn" @click="getCoupon">
                <img class="bg-img" src="/images/coupon/coupon-act-btn.jpg">
            </div>
        </div>

        <van-overlay :show="loadShow" class="overlay-div">
            <van-loading type="spinner" class="load"/>
        </van-overlay>
    </div>
</template>

<script>
    import vue from 'vue';
    import {Button, Dialog} from 'vant';

    vue.use(Button, Dialog);

    export default {
        name: 'test-coupon',
        created: function(){
            this.loadShow = true;
            this.$post('/canGetTestCoupon', {
                'openId' : this.$store.getters['user/getOpenId'],
            }).then((res)=> {
                if(res.code == 200){
                    let data = res.data;
                    if(data['error']){
                        Dialog.alert({
                            message: data['msg']
                        }).then(() => {
                            // on close
                            // this.$router.push('/index');
                        });
                    }
                }
                this.loadShow = false;
            })
        },
        data() {
            return {
                loadShow : true,
            }
        },
        methods: {
            getCoupon:function(){
                this.loadShow = true;
                this.$post('/getTestCoupon', {
                    'openId' : this.$store.getters['user/getOpenId']
                }).then((res) => {
                    if(res.code == 200){
                        let data = res.data;
                        if(data['error']){
                            Dialog.alert({
                                message: data['msg']
                            }).then(() => {
                                // on close
                                // this.$router.push('/index');
                            });
                        }else{
                            //成功
                            Dialog.alert({
                                message: data['msg']
                            }).then(() => {
                                window.location.href = "/index";
                                // on close
                                // this.$router.push('/index');
                            });
                        }
                    }
                    this.loadShow = false;
                })
            }
        }
    }
</script>

<style>
    /*主体*/
    .test-get-coupon {
        background: #447cfc;
        height: 100%;
    }
    .test-get-coupon .bg-coupon{
        position: relative;
    }
    .test-get-coupon .bg-img{
        width: 100%;
    }
    .test-get-coupon .coupon-btn{
        position: absolute;
        width: 54%;
        top: 60%;
        left: 0;
        right: 0;
        margin: auto;
    }
</style>
