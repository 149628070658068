<template class="hd20200716hd">
	<div class="zbhd-con">
		<!-- 顶部显示个人中奖信息 -->
		<div class="zbhd--top-btn" @click="jsshowMessage()">中奖<br>信息</div>
		<div class="zbhd-hb bg-f00">
			<img class="zbhd-hb-img" src="https://qn.yiquan.com.cn/20210723/zbhd-bg.jpg">
			<div class="zbhd-hb-btn" @click="getmh()"></div>
		</div>
		<!-- 盲盒弹窗 -->
		<div class="mhpopup">
			<div class="mhpopup-bg" :class="ismhPopupShow?'popup-show':''"></div>
			<div class="mhpopup-cont" :class="ismhPopupShow?'popup-cont-show':''">
				<img class="mh-img" src="https://qn.yiquan.com.cn/20210723/zbhd-mh.png" @click="getzbhd()">
			</div>
		</div>
		<!-- 红包弹窗 -->
		<div class="hbPopup">
			<!-- <div class="hbPopup-bg" :class="ishdPopupShow?'popup-show':''" @click="close()"></div> -->
			<div class="hbPopup-bg" :class="ishdPopupShow?'popup-show':''"></div>
			<div class="hbPopup-cont" :class="ishdPopupShow?'popup-cont-show':''">
				<div class="hbPopup-cont-text-box">
					<div>
						<div class="hbPopup-cont-text">
							<span class="hbPopup-cont-header">{{prizeArr.GIFTNAME}}</span>
						</div>
						<div class="hbPopup-cont-time">
							<p v-if="prizeArr.TYPE == 1">有效期：自发放日期起<span class="color_red"> 30日生效</span></p>
							<p v-else-if="prizeArr.TYPE == 2">*领取后联系客服将在<span class="color_red">72小时内</span>发送快递至您的收件地址
							</p>
						</div>
					</div>
				</div>
				<div class="hbPopup-cont-btn" @click="getPrize()">
					立即领取
				</div>
			</div>
		</div>
		<!-- 信息弹窗 -->
		<div class="messagepopup">
			<van-popup v-model="show" closeable :close-on-click-overlay="false" position="bottom"
				:style="{ height: '75%' }">
				<div class="messagepopup-cont-header">收件信息</div>
				<div class="messagepopup-cont">
					<div class="messagepopup-cont-ul">
						<div class="messagepopup-cont-li">
							<label><span class="color_red">*</span> 收件人</label>
							<input class="messagepopup-input" v-model="cityObj.name" maxlength="6" type="text"
								name="name" placeholder="请填写收件人姓名" />
						</div>
						<div class="messagepopup-cont-li">
							<label><span class="color_red">*</span> 手机号码</label>
							<input class="messagepopup-input" v-model="cityObj.mobile" maxlength="11" type="text"
								name="tel" placeholder="请填写收件人手机号码" />
						</div>
						<div class="messagepopup-cont-li">
							<label><span class="color_red">*</span> 选择地区</label>
							<span class="messagepopup-input messagepopup-xz" @click="getCity()">{{cityObj.city}}</span>
						</div>
						<div class="messagepopup-cont-li">
							<label><span class="color_red">*</span> 详细地址</label>
							<textarea class="messagepopup-textarea" v-model="cityObj.address" maxlength="50"
								placeholder="请填写收件人详细地址"></textarea>
						</div>
						<div class="messagepopup-cont-li">
							<label>邮政编码</label>
							<input class="messagepopup-input" v-model="cityObj.code" maxlength="6" type="text"
								name="yzbm" placeholder="请填写邮政编码" />
						</div>
					</div>
					<div class="messagepopup-cont-tag color_red">
						<p>*提交后24小时内客服会和您联系确认，请注意接听电话</p>
						<p>*若有疑问欢迎咨询客服400-000-2787</p>
					</div>
					<div class="messagepopup-cont-btn" @click="submit(1)">提交</div>
				</div>
			</van-popup>
		</div>
		<!-- 地址弹窗 -->
		<van-popup v-model="showPicker" position="bottom" :close-on-click-overlay="false" safe-area-inset-bottom>
			<van-picker title="请选择所在城市" show-toolbar :columns="columns" value-key="content" @change="onLocalChange"
				@cancel="closePicker()" @confirm="onLocalConfirm"></van-picker>
		</van-popup>
		<!-- 提交成功弹窗 -->
		<van-popup v-model="showSubmitType" :close-on-click-overlay="false" safe-area-inset-bottom
			:style="{ width: '80%' }">
			<div class="submitTypePopup">
				<img src="https://qn.yiquan.com.cn/20210723/zbhd-tijiao-top.jpg">
				<p class="submitTypePopup-header">提交成功！</p>
				<p class="submitTypePopup-text">请耐心等待客服联系......</p>
				<div class="submitTypePopup-btn" @click="submit(2)">好的</div>
			</div>
		</van-popup>
		<!-- 中奖弹窗 -->
		<van-popup v-model="showPrize" position="bottom" :close-on-click-overlay="false" safe-area-inset-bottom
			:style="{ width: '100%',height: '85%',background: 'transparent', overflow: 'hidden'}">
			<div class="prizePopup">
				<img class="prizePopup-img" v-if="prizeArr.TYPE == 0"
					src="https://qn.yiquan.com.cn/20210723/zbhd-sb.png">
				<img class="prizePopup-img" v-else src="https://qn.yiquan.com.cn/20210723/zbhd-gx.png">
				<div class="prizePopup-cont" :class="prizeArr.TYPE == 1?'prizePopup-cont2':''">
					<div v-if="prizeArr.TYPE == 1">
						<span class="hbPopup-cont-header color_red">{{prizeArr.GIFTNAME}}</span>
						<p>*客服将在72个小时内联系您，请耐心等待！</p>
						<p>*若有疑问欢迎咨询客服400-000-2787</p>
					</div>
					<div v-else-if="prizeArr.TYPE == 2">
						<span class="hbPopup-cont-header color_red">{{prizeArr.GIFTNAME}}</span>
						<p>*客服将在72个小时内将奖品寄出至您提交的地址，请耐心等待！</p>
						<p>*若有疑问欢迎咨询客服400-000-2787</p>
					</div>
					<div v-else-if="prizeArr.TYPE == 0">
						<span class="hbPopup-cont-header-title">这次没有中奖嗷，不要灰心。</span>
						<span class="hbPopup-cont-header-title color_red">观看直播还有更多抽奖机会</span>
					</div>
					<div class="prizePopup-cont-btn" :class="prizeArr.TYPE == 1?'prizePopup-cont-btn2':''"
						@click="linkHref()">返回直播</div>
				</div>
				<!-- <div class="prizePopup-cont">
					<span class="hbPopup-cont-header-title">这次没有中奖嗷，不要灰心。</span>
					<span class="hbPopup-cont-header-title color_red">观看直播还有更多抽奖机会</span>
					<div class="prizePopup-cont-btn">返回直播</div>
				</div> -->
				<!-- <div class="prizePopup-cont prizePopup-cont2">
					<span class="hbPopup-cont-header color_red">获得员工专属保养优惠一份</span>
					<p>*门店地址：上海市浦建路1281号，上海东昌别克</p>
					<p>*客服将在72个小时内将奖品寄出至您提交的地址，请耐心等待！</p>
					<p>*若有疑问欢迎咨询客服400-000-2787</p>
					<div class="prizePopup-cont-btn">返回直播</div>
				</div> -->
				<div class="prizePopup-bottom" :class="prizeArr.TYPE == 1?'prizePopup-bottom2':''">
					<img class="prizePopup-bg" src="https://qn.yiquan.com.cn/20210723/zbhd-popup-bg.png">
					<!-- <div class="prizePopup-bottom-cont"> -->
					<vue-seamless-scroll :data="options" :class-option="defaultOption"
						class="prizePopup-bottom-cont seamless-warp"
						:class="prizeArr.TYPE == 1?'prizePopup-bottom-cont2':''">
						<ul class="item">
							<li v-for="item in options">
								恭喜<span class="title" v-text="item.NAME"></span> 获得 <span class="date"
									:class="item.TYPE == 1?'color_red':''" v-text="item.GIFTNAME"></span>
							</li>
						</ul>
					</vue-seamless-scroll>
				</div>
			</div>
		</van-popup>
		<!-- 登录弹窗 -->
		<van-popup v-model="logShow" class="popupTC zbhd-logoPopup" :close-on-click-overlay='false'>
			<div class="wrapper-alert zyq-hd-wrapper" @click.stop>
				<div class="log-block six-block zyq-hd-block">
					<!-- <div class="close-btn close-btn2" @click="clickClose()">x</div> -->
					<div class="title">
						<p>填写注册信息</p>
					</div>
					<form class="submit-container zyq-submit-container" @submit.prevent="submitCustCode">
						<div class="form-input mobile-container zyq-form-input" v-if="!mobileType">
							<input placeholder="邀请码" v-model="logFormObj.code" @blur="clearTop" />
							<img src="/images/act/six/six-act-u.png">
						</div>
						<div class="form-input mobile-container zyq-form-input">
							<input placeholder="手机号" v-model="logFormObj.mobile" maxlength="11" @blur="clearTop" />
							<img src="/images/lab_m.png">
						</div>
						<div class="form-input pwd-container zyq-form-input border0">
							<div class="zyq-yzm-box">
								<input placeholder="验证码" class="yzm-input" v-model="logFormObj.token" @blur="clearTop"
									maxlength="6" />
							</div>
							<img src="/images/lab_c-2.png">
							<div class="mobile-btn yzm-btn hd-yzm-ben" @click="getMobileToken()">{{tokenBtnMsg}}</div>
						</div>
						<div class="warn-info zyq-warn-info">
							<span v-if="alertWarnLv == 1">请输入正确的手机号</span>
							<span v-else-if="alertWarnLv == 2">验证码错误</span>
							<span v-else-if="alertWarnLv == 3">{{alertWarnContent}}</span>
							<span v-else-if="alertWarnLv == 4">邀请码错误</span>
							<span v-else-if="alertWarnLv == 5">您已参加本次活动！</span>
							<span v-else-if="alertWarnLv == 6">请输入姓名</span>
						</div>
						<!-- <div class="zyq-color-red">注：收到您的预约信息后会有销售主动联系您；</div> -->

						<van-button class="zyq-submit-btn" round type="info" size="large" native-type="submit">
							<span>确认</span>
						</van-button>
					</form>
				</div>
			</div>
		</van-popup>
		<!-- 中奖信息弹窗 -->
		<van-popup class="zjMessagePopup" closeable v-model="showMessage" :close-on-click-overlay="false" safe-area-inset-bottom
			:style="{ width: '80%' }">
			<div class="zjMessagePopup-cont-header">中奖信息</div>
			<div class="zjMessagePopup-cont">
				<div class="zjMessagePopup-cont-ul">
					<div v-if="zjarr.length != 0">
						<div class="zjMessagePopup-cont-li"  v-for="(item,key) in zjarr">
							<div :class="item.TYPE == 0?'':'color-red'">获得{{item.GIFTNAME}}</div>
						</div>
					</div>
					<div v-else>
						<div class="zjMessagePopup-cont-li">
							<div>暂无中奖信息</div>
						</div>
					</div>
				</div>
			</div>
		</van-popup>
	</div>
</template>

<script>
	import vue from 'vue';
	import {
		Button,
		Dialog,
		List,
		Popup,
		Field,
		Toast,
		AddressEdit
	} from 'vant';
	//导入加密解密
	import Crypto from '../../config/Crypto';

	vue.use(Button, Dialog, Popup, List, Field, Toast, AddressEdit);
	import vueSeamlessScroll from 'vue-seamless-scroll'
	let citys = {};
	export default {
		name: 'vlhd',
		components: {
			vueSeamlessScroll
		},
		data() {
			return {
				ismhPopupShow: false, // 是否显示盲盒
				ishdPopupShow: false, //是否显示弹窗
				show: false, //收货地址弹窗
				showPicker: false, //地址弹窗
				showSubmitType: false, //提交成功弹窗
				showPrize: false, //中奖弹窗
				logShow: false, //登录弹窗
				tokenBtnMsg: '获取验证码',
				deadline: 60, //秒数
				btnDisable: false, //重复点击
				mobileType: false, //是否填写邀请码
				logFormObj: { //提交登录信息
					code: '',
					mobile: '',
					token: ''
				},
				cityObj: { // 收货地址
					name: "", //姓名
					mobile: "", //手机号
					city: "请选择所在地区", //城市
					address: "", //地址
					code: "", //邮编
				},
				alertWarnLv: 0, //提示等级 0:无提示 1：请输入正确的手机号 2：验证码错误 3：其他 4：邀请码错误 5:您已参加 6.姓名
				alertWarnContent: "", //其他提示信息
				cityWarnLv: 0, //地址信息提示 0：无提示 1、姓名不为空 2、手机号错误 3、城市为空 4、详细地址 5、邮编
				cityWarnContent: "", //地址信息提示信息
				columns: [{
						values: Object.keys(citys),
					},
					{
						values: '',
						className: 'column2',
						defaultIndex: 2
					}
				],
				localVal: "请选择所在城市",
				localVals: [],
				firstCity: false, //自检中是否有省份城市
				options: [], //中奖列表
				cityType: 0, //参数说明：0：其他地区；1：上海地区；2：北京地区；3：广东地区
				isCityType: false, //是否是第一次获取当前位置
				orderId: 0, //订单id
				isLottery: false, //是否可以抽奖 
				//对接接口后
				prizeType: "", //奖品类型
				prizeName: "", //奖品名称
				prizeArr: [], //奖品信息
				partyId: "", //活动id 3,4,5
				showMessage: false,
				zjarr: [], //中奖信息
			}
		},
		// 监听属性 类似于data概念
		computed: {
			defaultOption() {
				return {
					step: 0.4, // 数值越大速度滚动越快
					limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
					hoverStop: false, // 是否开启鼠标悬停stop
					direction: 1, // 0向下 1向上 2向左 3向右
					openWatch: true, // 开启数据实时监控刷新dom
					singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
					singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
					waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
				}
			}

		},
		created() {
			let that = this;
			//判断是否有partryId
			let query = this.$route.query;
			if (typeof query.partyId == 'undefined' || (Crypto.decode(query.partyId) != "partyId3" && Crypto.decode(query
					.partyId) != "partyId4" && Crypto.decode(query.partyId) != "partyId5")) {
				this.$router.replace({
					path: '/index'
				});
				return false;
			}

			if (Crypto.decode(query.partyId) == "partyId3") {
				this.partyId = 3;
			} else if (Crypto.decode(query.partyId) == "partyId4") {
				this.partyId = 4;
			} else if (Crypto.decode(query.partyId) == "partyId5") {
				this.partyId = 5;
			}

			that.getCityList();
			let str = Crypto.encode("partyId3"); //对字符串进行加密
			// console.log(Crypto.encode("partyId3"));
			// console.log(Crypto.decode(str)); //对字符串进行解密
			// 验证是否登录：如果未登录弹登录注册窗
			let openid = that.$store.getters['user/getOpenId'];
			let userId = that.$store.getters['user/getUserId'];
			let custId = that.$store.getters['user/getCustId'];
			// console.log(openid, userId, custId);
			if (openid != '' && userId != 0 && custId != 0) {
				// thata.logShow = true;
				//获取抽奖信息
				that.partyGetGiftOrderInfo();
			} else {
				if (userId == 0 && custId != 0) {
					that.mobileType = true;
					that.logShow = true;
				} else {
					that.logShow = true;
				}
			}
			// 获取当时位置
			that.getLocation();
		},
		methods: {
			// 登录相关接口
			/**
			 * 获取手机验证码
			 */
			getMobileToken: function() {
				if (this.btnDisable) {
					return false;
				}
				this.btnDisable = true;
				let mobile = this.logFormObj.mobile;
				if (mobile.length == 11) {
					this.alertWarnLv = 0;
					this.$post('/userGetRand', {
						openId: this.$store.getters['user/getOpenId'],
						mobile: mobile,
					}).then((res) => {
						if (res.code == 200) {
							//循环倒计时
							this.tokenBtnMsg = this.deadline + 's';
							this.int_btnClock = window.setInterval(() => {
								if (this.deadline <= 0) {
									window.clearInterval(this.int_btnClock);
									this.tokenBtnMsg = '获取验证码';
									this.deadline = 60;
									this.btnDisable = false;
									return;
								}
								this.deadline--;
								this.tokenBtnMsg = this.deadline + 's';
							}, 1000);
						} else {
							this.btnDisable = false;
							this.alertWarnLv = 3;
							this.alertWarnContent = res.msg;
						}
					}).catch(e => {
						this.btnDisable = false;
						this.alertWarnLv = 3;
						this.alertWarnContent = '网路异常请稍后重试';
					});
				} else {
					this.alertWarnLv = 1;
					this.btnDisable = false;
				}
				return false
			},
			/**
			 * 提交邀请码
			 * @returns {boolean}
			 */
			submitCustCode: function() {
				let id = this.id;
				let code = this.$data.logFormObj.code;
				let mobile = this.logFormObj.mobile;
				let token = this.logFormObj.token;

				if (mobile == '') {
					this.alertWarnLv = 1;
					return false
				}
				if (token == '') {
					this.alertWarnLv = 2;
					return false
				}
				let mobileType = this.mobileType;
				if (mobileType) {
					let id = this.id;
					this.submitBingMobile(id);
					return false;
				}

				if (code == '') {
					this.alertWarnLv = 4;
					return false
				}
				//请求后端判断邀请码是否正确
				// this.$data.logShow = false;
				this.$post('/userBindCust', {
					openId: this.$store.getters['user/getOpenId'],
					code: code,
				}).then((res) => {
					let that = this;
					if (res.code == 200) {
						that.$data.alertWarnLv = 0;
						// that.$data.alertWarnContent = res.msg;
						let id = this.id;
						this.mobileType = true;

						that.submitBingMobile();
					} else {
						that.$data.alertWarnLv = 3;
						that.$data.alertWarnContent = res.msg;
					}
				});

			},
			/**
			 *  提交手机号
			 * @returns {boolean}
			 */
			submitBingMobile: function() {
				//请求绑定
				// let code = this.logFormObj.code;
				let mobile = this.logFormObj.mobile;
				let token = this.logFormObj.token;
				let url = '/userBindMobile'; //注册
				// if(code == '') {
				//     this.alertWarnLv = 4;
				//     return false;
				// }
				// else {
				if (mobile.length == 11) {
					//判断验证码
					if (token == '' || typeof token == 'undefined') {
						this.alertWarnLv = 3;
						this.alertWarnContent = '请输入验证码';
						return false;
					}

					this.alertWarnLv = 0;
					this.$post(url, {
						openId: this.$store.getters['user/getOpenId'],
						code: token,
						mobile: mobile,
					}).then((res) => {
						if (res.code == 200) {
							//更新数据
							let custId = res.data.custId;
							let userId = res.data.userId;
							this.$store.commit('user/setUserId', userId);
							this.$store.commit('user/setCustId', custId);

							this.logShow = false;
							this.logFormObj = {
								mobile: '',
								token: '',
								code: ''
							};
						} else {
							this.alertWarnLv = 3;
							this.alertWarnContent = res.msg;
						}
					})
				} else {
					this.alertWarnLv = 1;
					this.btnDisable = false;
				}
				return false;
				// }

			},
			/**
			 * ，关闭登录弹窗
			 */
			clickClose: function() {
				this.logShow = false;
				this.logFormObj = {
					mobile: '',
					token: '',
					code: ''
				};
			},
			/**
			 * 清空
			 */
			clearTop: function() {
				setTimeout(function() {
					var scrollHeight = document.documentElement.scrollTop || document.body.scrollTop || 0;
					window.scrollTo(0, Math.max(scrollHeight - 1, 0));
				}, 100);
			},
			//页面相关接口
			// 获取抽奖信息
			partyGetGiftOrderInfo() {
				//抽奖信息 partyId：正式服3 测试服5
				this.$post("/partyGetGiftOrderInfo", {
					openId: this.$store.getters['user/getOpenId'],
					partyId: this.partyId,
				}).then((res) => {
					if (res.code == 200) {
						let that = this;
						let data = res.data;
						if (data.hasOrder) {
							// 是否填写收货信息
							console.log(data);
							// 关闭抽奖按钮
							that.isLottery = false;
							that.prizeArr = data;
							that.orderId = data.ID;
							if (data.ISUPDATE == 0 && data.type != 0) {
								//已中奖并未填写收货信息-弹出中奖弹窗
								that.ishdPopupShow = true;
							} else {
								// 获取中奖列表用于展示
								that.partyGetGiftOrderList();
							}
						} else {
							//未抽奖可以点击抽奖
							that.isLottery = true;
						}
					} else {
						// this.alertWarnLv = 3;
						// this.alertWarnContent = res.msg;
					}
				})
			},
			// 获取中奖列表
			partyGetGiftOrderList() {
				//根据活动id获取中奖列表  partyId：正式服3 测试服5
				this.$post("/partyGetGiftOrderList", {
					openId: this.$store.getters['user/getOpenId'],
					partyId: "3,4,5",
				}).then((res) => {
					if (res.code == 200) {
						let data = res.data;
						console.log(data);
						this.options = data;
						// 已抽奖显示抽奖结果页
						this.showPrize = true;
					} else {
						// this.alertWarnLv = 3;
						// this.alertWarnContent = res.msg;
					}
				})
			},
			// 弹出盲盒
			getmh() {
				this.ismhPopupShow = true;
			},
			// 领取红包
			getzbhd() {
				let that = this;
				// 验证是否登录：如果未登录弹登录注册窗
				let openid = that.$store.getters['user/getOpenId'];
				let userId = that.$store.getters['user/getUserId'];
				let custId = that.$store.getters['user/getCustId'];
				if (openid != '' && userId != 0 && custId != 0) {
					//判断是否可以抽奖
					console.log(that.isLottery);
					if (that.isLottery) {
						//根据地区和个人信息抽奖  partyId：正式服3 测试服5
						that.$post("/partyGetGift", {
							openId: that.$store.getters['user/getOpenId'],
							partyId: this.partyId,
							cityType: that.cityType,
						}).then((res) => {
							if (res.code == 200) {
								//关闭抽奖按钮
								that.isLottery = false;
								// 获取抽奖信息
								that.partyGetGiftOrderInfo();
								that.orderId = res.data.orderId;
								console.log(res.data.orderId);
							} else {
								Dialog.confirm({
									title: '',
									message: res.msg
								});
								that.ismhPopupShow = false;
							}
						})
					} else {
						that.isLottery = false;
						// 获取抽奖信息
						that.partyGetGiftOrderInfo();
					}
				} else {
					that.logShow = true;
				}
			},
			// 点击显示中奖信息弹窗
			jsshowMessage() {
				this.showMessage = true;
				this.getMessage();
			},
			// 获取中奖信息
			getMessage() {
				let that = this;
				that.$post("/partyGetMyGiftList", {
					openId: that.$store.getters['user/getOpenId'],
				}).then((res) => {
					if (res.code == 200) {
						let zjarr = [];
						for(var i = 0; i < res.data.length; i++) {
							if(i<=2) {
								zjarr.push(res.data[i])
							}
						}
						that.zjarr= zjarr;
					} else {
						Dialog.confirm({
							title: '',
							message: res.msg
						});
						that.showMessage = false;
					}
				})
			},
			// 关闭弹窗
			close() {
				this.ishdPopupShow = false;
			},
			// 关闭地址弹窗
			closePicker() {
				if (this.isCityType) {
					this.showPicker = false;
				} else {
					this.showPicker = false;
					this.show = true;
				}
			},
			// 立即领取
			getPrize() {
				let that = this;
				that.ishdPopupShow = false;
				let time = setTimeout(function() {
					that.show = true;
					clearTimeout(time);
				}, 450);
			},
			// 返回直播
			linkHref() {
				// window.location.href = e;
				this.$router.go(-1); //返回
				// 在需要的地方加入下面这行代码
				wx.closeWindow();
			},
			// 提交信息
			submit(e) {
				let that = this;
				if (e == 1) {
					if (that.btnDisable) {
						return false;
					}
					that.btnDisable = true;
					let name = that.cityObj.name;
					let mobile = that.cityObj.mobile;
					let city = that.cityObj.city;
					let address = that.cityObj.address;
					let code = that.cityObj.code;
					if (name.length == 0 || name.length > 6) {
						Dialog.confirm({
							title: '',
							message: "请正确输入姓名！"
						});
						that.btnDisable = false;
						return false;
					} else if (mobile.length != 11) {
						Dialog.confirm({
							title: '',
							message: "请正确输入手机号！"
						});
						that.btnDisable = false;
						return false;
					} else if (city.length == 0) {
						Dialog.confirm({
							title: '',
							message: "请选择城市！"
						});
						that.btnDisable = false;
						return false;
					} else if (address.length == 0) {
						Dialog.confirm({
							title: '',
							message: "请填写详细地址！"
						});
						that.btnDisable = false;
						return false;
					}

					// 提交收货信息  partyId：正式服3 测试服5
					that.$post("/partyUpdateGiftOrderInfo", {
						openId: that.$store.getters['user/getOpenId'],
						partyId: this.partyId,
						orderId: that.orderId,
						name: name,
						mobile: mobile,
						city: city,
						address: address,
						code: code,
					}).then((res) => {
						console.log(res);
						if (res.code == 200) {
							let data = res.data;
							that.show = false;
							let time = setTimeout(function() {
								that.showSubmitType = true;
								clearTimeout(time);
							}, 450);
							that.btnDisable = false;
						} else {
							that.btnDisable = false;
							Dialog.confirm({
								title: '',
								message: res.msg
							});
						}
					})
				} else {
					that.partyGetGiftOrderList();
					that.showSubmitType = false;
					let time = setTimeout(function() {
						that.showPrize = true;
						clearTimeout(time);
					}, 450);
				}
			},
			//点击弹出城市弹窗
			getCity() {
				let that = this;
				that.show = false;
				that.showPicker = true;
				//更改是否第一次地址弹窗
				that.isCityType = false;
				console.log(that.isCityType);
			},
			//获取城市信息
			getCityList() {
				let that = this;
				that.$post('/getCityList', {}).then((res) => {
					if (res.code == 200) {
						citys = res.data;
						//创建一个时间戳
						let time = Date.now();
						let cityArr = {};
						cityArr.data = citys;
						cityArr.timestamp = time;
						this.$store.commit('local/setCityList', JSON.stringify(cityArr));
						that.citys = citys;
						let brandKey = [];
						if (this.$store.getters['local/getBrandKey'] != undefined && this.$store.getters[
								'local/getBrandKey'] != null && this.$store.getters['local/getBrandKey'].length !=
							0) {
							brandKey = JSON.parse(this.$store.getters['local/getBrandKey']);
						} else {
							brandKey = [0, 0];
						}
						that.columns = [{
								values: citys,
								className: 'column1',
								defaultIndex: brandKey[0],
							},
							{
								// values: citys[0]['list'],
								values: citys[brandKey[0]]['list'],
								className: 'column2',
								defaultIndex: brandKey[1],
							}
						];
						let brandLoacl = this.$store.getters['local/getBrandLocal'];
						// if(brandLoacl.length > 0){
						//     let brandKey = this.$store.getters['local/getBrandKey'];
						//     this.onLocalConfirm(JSON.parse(brandLoacl), JSON.parse(brandKey));
						// }
					} else {
						Dialog.alert({
							message: res.msg
						}).then(() => {
							// on close
						});
					}
				}).catch(
					(e) => {
						window.console.log(e);
					}
				);
			},
			/**
			 * 地址选择变化
			 */
			onLocalChange(picker) {
				let indexs = picker.getIndexes();
				picker.setColumnValues(1, this.citys[indexs[0]]['list']);
			},
			/**
			 * 地址选择确认
			 */
			onLocalConfirm(value, key) {
				this.localVal = value[0]['content'] + '-' + value[1]['content'];
				this.localVals = value;
				let valStr = JSON.stringify(value);
				this.$store.commit('local/setBrandLocal', valStr);
				this.$store.commit('local/setBrandKey', JSON.stringify(key));
				// this.hasLocalCss = "hasLocalCss";
				console.log(this.$data.showPicker);
				if (this.isCityType) {
					this.showPicker = false;
				} else {
					this.showPicker = false;
					this.show = true;
				}
				// if (this.firstCity) {
				// 	console.log(this.firstCity);
				// 	this.saveUserCity();
				// }
				// 获取地区类型
				console.log(this.$data.isCityType);
				this.cityObj.city = this.localVal;
				if (this.$data.isCityType) {
					switch (value[0]['id']) {
						case "25":
							this.cityType = 1;
							break;
						case "62":
							this.cityType = 2;
							break;
						case "65":
							this.cityType = 3;
							break;
						default:
							this.cityType = 0;
					}
				}

			},
			// 自动定位方法
			//获取经纬度坐标
			getLocation: function() {
				let that = this;
				//获取地理位置
				wx.ready(function() {
					wx.checkJsApi({
						jsApiList: [
							'getLocation'
						],
						success: function(res) {
							if (res.checkResult.getLocation == false) {
								alert('你的微信版本太低，不支持微信JS接口，请升级到最新的微信版本！');
								return;
							}
						}
					});
					wx.error(function(res) {
						that.$data.isCityType = true;
						that.$data.showPicker = true;
					});
					wx.getLocation({
						success: function(res) {
							var lat = res.latitude;
							var lon = res.longitude;
							var key = "25ZBZ-OIU6D-5CL4J-HQTJM-QITQO-YPFYM"
							that.getCoderInfo(lat, lon);
						},
						cancel: function(res) {
							//当无法获取当前位置是执行 弹窗城市地区
							that.$data.showPicker = true;
							that.$data.isCityType = true;
						},
						fail: function(res) {
							//当无法获取当前位置是执行 弹窗城市地区
							that.$data.showPicker = true;
							that.$data.isCityType = true;
						}
					});
				});
			},
			//通过经纬度获取位置信息
			getCoderInfo: function(lat, lon) {
				console.log(lat, lon);
				this.$post('/getCoderInfo', {
					location: lat + ',' + lon
				}).then((res) => {
					let that = this;
					let data = res.data;
					if (res.code == 200) {
						let province = data.address_component.province;
						let city = data.address_component.city;
						let district = data.address_component.district;

						let provinces = ['上海市', '北京市', '天津市'];
						let citys = ['上海市', '北京市', '天津市', '杭州市', '宁波市', '温州市', '湖州市', '嘉兴市', '绍兴市', '金华市',
							'衢州市', '舟山市', '台州市', '丽水市', '重庆市'
						];
						for (var i = 0; i < provinces.length; i++) {
							if (province == provinces[i]) {
								province = province.split("市")[0];
								break;
							} else {
								province = province.split("省")[0];
							}
						}

						for (var v = 0; v < citys.length; v++) {
							if (city == citys[v]) {
								break;
							} else {
								city = city.split("市")[0];
							}
						}
						that.localVal = province + "-" + city;
						// 新增-赋值收货地址
						that.cityObj.city = province + "-" + city;
						that.getLocalId(city);
					} else {
						Dialog.alert({
							message: res.msg
						}).then(() => {
							// on close
						});
					}

				}).catch(
					(e) => {
						//当无法获取当前位置是执行 弹窗城市地区
						this.$data.showPicker = true;
						window.console.log(e);
					}
				);
			},
			//获取地址匹配Id信息
			getLocalId(cityName) {
				console.log(cityName);
				this.$post('/getLocalId', {
					cityName: cityName
				}).then((res) => {
					let data = res.data;
					if (res.code == 200) {
						if (data.provincesId == 0 || data.cityId == 0) {
							this.$data.showPicker = true;
							return false;
						}
						let provinceId = data.provincesId;
						let cityId = data.cityId;
						let loc = this.citys;
						let localVal = this.localVal;
						let xhprovince = 0;
						let xhcity = 0;
						let valStr = [];
						let brandkey = [];
						for (var i = 0; i < loc.length; i++) {
							if (provinceId == loc[i].id) {
								valStr = loc[i];
								xhprovince = i;
							}
						}
						let localVals = [];
						localVals.push(valStr);
						for (var o = 0; o < valStr["list"].length; o++) {
							if (cityId == valStr["list"][o].id) {
								localVals.push(valStr["list"][o]);
								xhcity = o;
							}
						}
						this.localVals = localVals;
						brandkey = [xhprovince, xhcity];
						this.$store.commit('local/setProvinceId', provinceId);
						this.$store.commit('local/setCityId', cityId);
						this.$store.commit('local/setLocal', localVal);
						this.$store.commit('local/setBrandLocal', JSON.stringify(localVals));
						this.$store.commit('local/setBrandKey', JSON.stringify(brandkey));

						switch (provinceId) {
							case "25":
								this.cityType = 1;
								break;
							case "62":
								this.cityType = 2;
								break;
							case "65":
								this.cityType = 3;
								break;
							default:
								this.cityType = 0;
						}
					} else {
						Dialog.alert({
							message: res.msg
						}).then(() => {
							// on close
						});
					}
				}).catch(
					(e) => {
						//当无法获取当前位置是执行 弹窗城市地区
						this.$data.showPicker = true;
						window.console.log(e);
					}
				);
			},
		}
	}
</script>

<style>
	.zbhd--top-btn {
		position: fixed;
		top: 120px;
		right: 30px;
		padding: 10px;
		text-align: center;
		line-height: 40px;
		width: 100px;
		height: 100px;
		color: #A31624;
		border-radius: 50px;
		background-color: #EFBC8D;
		z-index: 9999;
		box-shadow: 0 0 5px 4px #EFBC8D;
	}
	.color-red {
		color: #F00;
	}
	.margin-r20 {
		margin-right: 20px;
	}

	/* 直播活动样式 */
	.hd20200716hd {
		background-color: #aa1626;
	}

	img[src=""],
	img:not([src]) {
		opacity: 0;
	}

	.zbhd-con,
	.zbhd-hb {
		width: 100%;
		height: 100%;
		background-color: #aa1626;
	}

	.zbhd-hb {
		position: relative;
		background-color: #aa1626;
	}

	.zbhd-hb-img {
		display: block;
		width: 100%;
		height: auto;
		background-color: #aa1626;
	}

	.zbhd-hb-btn {
		position: absolute;
		top: 750px;
		left: 50%;
		width: 200px;
		height: 200px;
		transform: translateX(-50%);
	}

	/* 盲盒弹窗样式 */
	.mhpopup {
		position: relative;
	}

	.mhpopup-bg {
		position: fixed;
		top: 0;
		left: 0;
		display: none;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, .75);
	}

	.mhpopup-cont {
		position: fixed;
		top: 50%;
		left: 55%;
		/* padding: 246px 0 0;
		width: 605px;
		height: 538px;
		background: url(https://qn.yiquan.com.cn/20210723/zbhd-hb-bg.png) no-repeat;
		background-size: cover; */
		transform: translateX(-50%) translateY(-50%) scale(0, 0);
		box-sizing: border-box;
		transition: all .5s;
	}

	.mhpopup-cont .mh-img {
		width: 530px;
		animation: zy 1s .15s linear infinite;
		-moz-animation: zy 1s .15s linear infinite;
		/* Firefox */
		-webkit-animation: zy 1s .15s linear infinite;
		/* Safari and Chrome */
		-o-animation: zy 1s .15s linear infinite;
		/* Opera */
	}

	/* 红包弹窗样式 */
	.hbPopup {
		position: relative;
		/* display: none; */
	}

	.hbPopup-bg {
		position: fixed;
		top: 0;
		left: 0;
		display: none;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, .75);
	}

	.hbPopup-cont {
		position: fixed;
		top: 50%;
		left: 50%;
		padding: 246px 0 0;
		width: 605px;
		height: 538px;
		background: url(https://qn.yiquan.com.cn/20210723/zbhd-hb-bg.png) no-repeat;
		background-size: cover;
		transform: translateX(-50%) translateY(-50%) scale(0, 0);
		box-sizing: border-box;
		transition: all .5s;
	}

	.hbPopup-cont-text-box {
		position: relative;
		margin: 0 auto;
		width: 553px;
		height: 148px;
		background-color: #fff;
		border-radius: 10px;
	}

	.hbPopup-cont-text-box::after {
		content: "";
		position: absolute;
		top: 80px;
		left: -13.5px;
		width: 27px;
		height: 27px;
		background-color: #fe9b56;
		border-radius: 0 50% 50% 0;
	}

	.hbPopup-cont-text-box::before {
		content: "";
		position: absolute;
		top: 80px;
		right: -13.5px;
		width: 27px;
		height: 27px;
		background-color: #fe9956;
		border-radius: 50% 0 0 50%;
	}

	.hbPopup-cont-text {
		margin: 0 30px;
		padding: 30px 0 0;
		/* display: flex;
		align-items: center; */
		width: calc(100% - 60px);
		height: calc(94px);
		line-height: 64px;
		font-size: 24px;
		color: #e7351a;
		box-sizing: border-box;
		border-bottom: 1px dashed #dedede;
	}

	.hbPopup-cont-text img {
		padding-right: 10px;
		width: 250px;
		height: 35px;
		vertical-align: sub;
	}

	.hbPopup-cont-header {
		display: block;
		font-family: "黑体";
		font-size: 36px;
		font-weight: 600;
	}

	.hbPopup-cont-header-title {
		margin: 20px 0;
		display: block;
		font-family: "黑体";
		text-align: center;
		font-size: 30px;
		font-weight: 600;
	}

	.hbPopup-cont-time {
		margin: 0 30px;
		width: 100%;
		height: 54px;
		font-size: 20px;
		line-height: 54px;
		text-align: left;
	}

	.hbPopup-cont-btn {
		margin: 22px auto 0;
		width: 553px;
		height: 94px;
		line-height: 94px;
		font-size: 39px;
		color: #ffedd6;
		background: url(https://qn.yiquan.com.cn/20210723/zbhd-hb-ben-bg.png) no-repeat;
		background-size: 100%;
	}

	/* 弹出 */
	.popup-show {
		display: block;
	}

	.popup-cont-show {
		transform: translateX(-50%) translateY(-50%) scale(1, 1);
	}

	/* 信息弹窗 */
	.messagepopup {
		position: relative;
	}

	/* .messagepopup .messagepopup-bg {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, .75);
	}

	.messagepopup .messagepopup-cont {
		position: fixed;
		bottom: 0;
		left: 50%;
		padding: 0 20px;
		width: 100%;
		height: 800px;
		background-color: #fff;
		transform: translateX(-50%);
		box-sizing: border-box;
		transition: all .5s;
		border-radius: 20px 20px 0 0;
	} */

	.messagepopup-cont-header {
		margin: 14px 0 0;
		width: 100%;
		height: 80px;
		font-size: 36px;
		line-height: 80px;
		border-bottom: 1px solid #ddd;
	}

	.messagepopup-cont {
		border-top: 10px solid #f3f3f3;
	}

	.messagepopup-cont-li {
		padding: 0 20px;
		width: 100%;
		/* height: 86px; */
		line-height: 86px;
		display: flex;
		justify-content: flex-start;
		border-bottom: 1px solid #ddd;
	}

	.messagepopup-cont label {
		display: inline-block;
		width: 200px;
		text-align: left;
	}

	.messagepopup-cont textarea {
		width: calc(100% - 220px);
		height: 120px;
		line-height: 60px;
	}

	.messagepopup-cont input,
	.messagepopup-cont textarea {
		width: calc(100% - 220px);
		color: #a4a4a4;
		resize: none;
		border: none;
		outline: none;
	}

	.messagepopup-input,
	.messagepopup-textarea {
		color: #a4a4a4;
	}

	.messagepopup-xz {
		position: relative;
		width: calc(100% - 220px);
		text-align: left;
	}

	.messagepopup-xz::after {
		content: ">";
		position: absolute;
		top: 0;
		right: 0;
	}

	.messagepopup-cont-tag {
		padding: 20px 20px;
		font-size: 24px;
		line-height: 40px;
		text-align: left;
	}

	.messagepopup-cont-btn {
		margin: 0 auto;
		width: calc(100% - 40px);
		height: 88px;
		font-size: 32px;
		line-height: 88px;
		color: #fff;
		background-color: #48a3ff;
		border-radius: 10px;
	}

	/* 提交成功弹窗 */
	.submitTypePopup,
	.submitTypePopup img {
		width: 100%;
	}

	.submitTypePopup-header {
		margin: 40px 0;
		font-size: 38px;
		color: #333;
		font-weight: 600;
	}

	.submitTypePopup-text {
		margin: 0 0 120px 0;
		font-size: 28px;
		color: #999999;
	}

	.submitTypePopup-btn {
		margin: 0 auto 40px;
		width: 300px;
		height: 80px;
		line-height: 80px;
		text-align: center;
		font-size: 28px;
		color: #4d2ebe;
		border: 1px solid #4d2ebe;
		border-radius: 50px;
	}

	/* 中奖弹窗 */
	.prizePopup {
		/* position: relative; */
	}

	.prizePopup .prizePopup-img {
		margin: 0 auto;
		width: 204px;
		height: 55px;
	}

	.prizePopup-bottom {
		position: relative;
		margin-top: -160px;
		/* margin-top: -100px; */
	}

	.prizePopup-bottom2 {
		position: relative;
		/* margin-top: -160px; */
		margin-top: -95px;
	}

	.prizePopup-bottom .prizePopup-bg {
		width: 100%;
	}

	.prizePopup-cont {
		position: relative;
	}

	.prizePopup-cont,
	.prizePopup-bottom-cont {
		margin: 114px auto 0;
		padding: 26px 50px;
		width: 540px;
		height: 390px;
		border-radius: 20px;
		box-sizing: border-box;
		background-image: linear-gradient(to right, #f6dcc1, #ebb48e);
		overflow: hidden;
	}

	.prizePopup-cont2 {
		padding: 26px;
	}

	.prizePopup-cont p {
		margin: 15px 0 0;
		text-align: left;
		font-size: 24px;
		line-height: 34px;
		color: #2b2b2b;
	}

	.prizePopup-bottom-cont {
		position: absolute;
		top: 50px;
		/* top: 20px; */
		left: 50%;
		transform: translateX(-50%);
		overflow-y: hidden;
	}

	.prizePopup-bottom-cont2 {
		position: absolute;
		/* top: 50px; */
		top: 15px;
		left: 50%;
		transform: translateX(-50%);
		overflow-y: hidden;
	}

	.prizePopup-bottom-cont .item {}

	.prizePopup-bottom-cont li {
		text-align: left;
		line-height: 50px;
		font-size: 24px;
		color: #000;
	}

	.prizePopup-cont-btn {
		position: absolute;
		bottom: 130px;
		left: 50%;
		margin: 10px 0 0;
		display: inline-block;
		width: 160px;
		height: 46px;
		line-height: 46px;
		font-size: 24px;
		color: #111;
		border-radius: 20px;
		border: 1px solid #111;
		transform: translateX(-50%);
		z-index: 100;
	}

	.prizePopup-cont-btn2 {
		bottom: 50px;
	}

	#mesBox {
		position: absolute;
		top: 0;
	}

	.anim {
		transition: all 1s;
	}

	/* 登录弹窗 */
	.popupTC {
		box-sizing: border-box;
		width: 594px;
		border-radius: 20px;
	}

	.zbhd-logoPopup .mobile-btn {
		color: #9e1524 !important;
		border: 1px solid #9e1524 !important;
	}

	.zbhd-logoPopup .van-button--info {
		background-color: #9e1524;
		border: 1px solid #9e1524;
	}

	input::placeholder,
	textarea::placeholder {
		color: #a4a4a4;
	}

	.zjMessagePopup {
		min-height: 400px;
		border-radius: 20px 20px;
		background-color: #fff;
	}

	.zjMessagePopup-cont-header {
		margin: 14px 0 0;
		width: 100%;
		height: 80px;
		font-size: 36px;
		line-height: 80px;
		border-bottom: 1px solid #ddd;
	}

	.zjmessagepopup-cont {
		border-top: 10px solid #f3f3f3;
	}

	.zjMessagePopup-cont-li {
		padding: 0 20px;
		width: 100%;
		line-height: 86px;
		display: flex;
		font-size: 28px;
		justify-content: flex-start;
		border-bottom: 1px solid #ddd;
	}

	/* 动画晃动 */
	@-webkit-keyframes zy {
		10% {
			transform: rotate(15deg);
		}

		20% {
			transform: rotate(-10deg);
		}

		30% {
			transform: rotate(5deg);
		}

		40% {
			transform: rotate(-5deg);
		}

		50%,
		100% {
			transform: rotate(0deg);
		}
	}

	@-moz-keyframes zy {
		10% {
			transform: rotate(15deg);
		}

		20% {
			transform: rotate(-10deg);
		}

		30% {
			transform: rotate(5deg);
		}

		40% {
			transform: rotate(-5deg);
		}

		50%,
		100% {
			transform: rotate(0deg);
		}
	}

	@-o-keyframes zy {
		10% {
			transform: rotate(15deg);
		}

		20% {
			transform: rotate(-10deg);
		}

		30% {
			transform: rotate(5deg);
		}

		40% {
			transform: rotate(-5deg);
		}

		50%,
		100% {
			transform: rotate(0deg);
		}
	}

	@keyframes zy {
		10% {
			transform: rotate(15deg);
		}

		20% {
			transform: rotate(-10deg);
		}

		30% {
			transform: rotate(5deg);
		}

		40% {
			transform: rotate(-5deg);
		}

		50%,
		100% {
			transform: rotate(0deg);
		}
	}
	.bg-f00 {
		background-color: #aa1626 !important;
	}

	/* 直播活动样式 */
</style>
