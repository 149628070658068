<template>
	<div class="wrap">
		<!-- <img class="hdImg" src="/images/act/hd/velite-bg.jpg" alt=""> -->
		<img class="img" src="/images/act/hd/banner.jpg" alt="">
		<div class="page2 pos_rel">
			<img class="img" src="/images/act/hd/page2.jpg" alt="">
			<div class="page2-cen">
				<img class="img" src="/images/act/hd/hd1.png" alt="">
				<img class="img" src="/images/act/hd/hd2.png" alt="">
			</div>
			<video id="video1" controls>
				<source src="http://admin.yiquan.com.cn/v/666.mp4" type="video/mp4">
				您的浏览器不支持 HTML5 video 标签。
			</video>
		</div>
		<div class="page3 pos_rel">
			<img class="img" src="/images/act/hd/page3.jpg" alt="">
			<div class="carbtn">
				<img class="carbtn1" src="/images/act/hd/velite1.png" @click="click(1)" alt="">
				<img class="carbtn2" src="/images/act/hd/velite2.png" @click="click(2)" alt="">
			</div>
		</div>
		<!-- <div class="footerBtn" @click="click()">立即参与</div> -->
		<!-- 弹窗 -->
		<van-popup v-model="logShow" class="popupTC" :close-on-click-overlay='false' >
		    <div class="wrapper-alert zyq-hd-wrapper" @click.stop >
		        <div class="log-block six-block zyq-hd-block">
		            <div class="close-btn close-btn2" @click="clickClose()">x</div> 
		            <div class="title">
		                <p>填写注册信息</p>
		            </div>
		            <form class="submit-container zyq-submit-container" @submit.prevent="submitCustCode">
		                <div class="form-input mobile-container zyq-form-input" v-if="!mobileType">
		                    <input placeholder="邀请码" v-model="logFormObj.code" @blur="clearTop"/>
		                    <img src="/images/act/six/six-act-u.png">
		                </div>
		                <div class="form-input mobile-container zyq-form-input">
		                    <input placeholder="手机号" v-model="logFormObj.mobile" maxlength="11" @blur="clearTop"/>
		                    <img src="/images/lab_m.png">
		                </div>
		                <div class="form-input pwd-container zyq-form-input border0">
		                    <div class="zyq-yzm-box">
		                        <input placeholder="验证码" class="yzm-input" v-model="logFormObj.token" @blur="clearTop" maxlength="6"/>
		                    </div>
		                    <img src="/images/lab_c-2.png">
		                    <div class="mobile-btn yzm-btn hd-yzm-ben" @click="getMobileToken()">{{tokenBtnMsg}}</div>
		                </div>
		                <div class="warn-info zyq-warn-info">
		                    <span v-if="alertWarnLv == 1">请输入正确的手机号</span>
		                    <span v-else-if="alertWarnLv == 2">验证码错误</span>
		                    <span v-else-if="alertWarnLv == 3">{{alertWarnContent}}</span>
		                    <span v-else-if="alertWarnLv == 4">邀请码错误</span>
		                    <span v-else-if="alertWarnLv == 5">您已参加本次活动！</span>
		                    <span v-else-if="alertWarnLv == 6">请输入姓名</span>
		                </div>
		                <!-- <div class="zyq-color-red">注：收到您的预约信息后会有销售主动联系您；</div> -->
		
		                <van-button class="zyq-submit-btn" round type="info" size="large" native-type="submit">
		                    <span>确认</span>
		                </van-button>
		            </form>
		        </div>
		    </div>
		</van-popup>
		<!-- <van-popup v-model="showPicker" position="bottom" :close-on-click-overlay="false" safe-area-inset-bottom>
		    <van-picker
		            title="请选择所在城市"
		            show-toolbar
		            :columns="columns"
		            value-key = "content"
		            @change="onLocalChange"
		            @confirm="onLocalConfirm"
		    ></van-picker>
		</van-popup> -->
		<!-- 优惠券弹窗 -->
		<van-popup v-model="yhqshow" class="yhqpopup" :close-on-click-overlay="false">
			<img class="img" src="/images/act/hd/tc.png" alt="">
			<img class="popupClose img" @click="closePopup" src="/images/act/hd/close.png" alt="">
		</van-popup>
	</div>
</template>

<script>
	import vue from 'vue';
	import {Button, Dialog, List, Popup, Field, Toast} from 'vant';
	
	vue.use(Button, Dialog, Popup, List, Field, Toast);
	let citys = {};
	export default {
		name: 'vlhd',
		data() {
			return {
				id: '',
				logShow: false, //登录弹窗
				tokenBtnMsg: '获取验证码',
				deadline: 60, //秒数
				btnDisable: false, //重复点击
				showPicker: false, //地区弹窗
				logFormObj: {  //提交信息
					code: '',
					mobile: '',
					token: ''
				},
				alertWarnLv: 0, //提示等级 0:无提示 1：请输入正确的手机号 2：验证码错误 3：其他 4：邀请码错误 5:您已参加 6.姓名
				alertWarnContent: "",  //其他提示信息
				citys : citys, //城市
				columns: [
					{
						values: Object.keys(citys),
					},
					{
						values: '',
						className: 'column2',
						defaultIndex: 2
					}
				],
				localVal : "请选择所在城市",
				localVals : [],
				code: "",
				yhqshow: false, //优惠券弹窗
				mobileType: false, //是否填写邀请码
				Type: true,
				obtain: false,  //是否领取优惠券
			}
		},
		created() {
			let that = this;
			//获取用户位置
			// wx.ready(function () { 
			// 	wx.checkJsApi({
			// 		jsApiList: [
			// 			'getLocation'
			// 		],
			// 		success: function (res) {
			// 			if (res.checkResult.getLocation == false) {
			// 				alert('你的微信版本太低，不支持微信JS接口，请升级到最新的微信版本！');
			// 				return;
			// 			}
			// 		}
			// 	}); 
			// 	wx.error(function(res){
			// 		console.log("接口调取失败")
			// 	});
			// 	wx.getLocation({
			// 		success: function (res) {
			// 			var lat = res.latitude;
			// 			var lon = res.longitude;
			// 			console.log(JSON.stringify(res));
			// 			var key = "25ZBZ-OIU6D-5CL4J-HQTJM-QITQO-YPFYM";
			// 				// o6KgywD65MUvDIL7pX9G6jLHUhnI
			// 			that.getCoderInfo(lat,lon);
			// 		},
			// 		cancel: function (res) {
			// 			console.log('用户拒绝授权获取地理位置');
			// 			//当无法获取当前位置是执行 弹窗城市地区
			// 			that.$data.showPicker = true;
			// 		}
			// 	});
			// });
			let openid = that.$store.getters['user/getOpenId'];
			let path = this.$route.path;
			let query  = this.$route.query.code;
			this.$data.code = this.$route.query.code;
			let length = window.location.href;
			//获取城市信息
			// this.$post('/getCityList', {
			// }).then((res) => {
			//     if(res.code == 200){
			//         citys = res.data;
			//         this.citys = citys;
			//         this.columns = [
			//             {
			//                 values: citys,
			//                 className: 'column1',
			//             },
			// 			{
			// 				values: citys[0]['list'],
			// 				className: 'column2',
			// 			}
			//         ];
			
			//         let brandLoacl = this.$store.getters['local/getBrandLocal'];
			//         if(brandLoacl.length > 0){
			//             let brandKey = this.$store.getters['local/getBrandKey'];
			//             this.onLocalConfirm(JSON.parse(brandLoacl), JSON.parse(brandKey));
			//         }
			//     }else{
			//         Dialog.alert({
			//             message: res.msg
			//         }).then(() => {
			//             // on close
			//         });
			//     }
			// }).catch(
			//     (e) =>{
			//         window.console.log(e);
			//     }
			// );
			//判断是否登录 并且未领取优惠券
			let userId = this.$store.getters['user/getUserId'];
			let custId = this.$store.getters['user/getCustId'];
			if(openid != '' && userId != 0) {
				this.isstCoupon2();
				this.Type = false;
			}
		},
		methods: {
			//关闭优惠券弹窗
			closePopup() {
				this.yhqshow = false;
				let type = this.Type;
				if(!type) {

				}else {
					let e = this.$data.id;
					this.navToL(e);
				}
			},
			//判断是否领取优惠券
			isstCoupon2() {
				let that = this;
				var appid = that.$wechat.appId;
				let openId = that.$store.getters['user/getOpenId'];
				that.$post('/getUserCoupon20210309', {
					openId: openId,
					appId: appid
				}).then((res) => {
					let data = res.data;
				    if(res.code == 200){
						console.log(data.type);
						//判断是否是中国移动
						if(data.errorCustId == 0) {
							//是中国移动
							//判断是否有错误
							if(!data.error) {
								//无错误
								//判断是否领取过
								if(data.type == 1) {
									//未领取过
									//弹出领取优惠券弹窗
									// that.yhqshow = true;
									//自动领取优惠券
									// setTimeout(function(){
									// 	that.obtainCoupon();
									// },500);
									if(that.obtain) {
										// setTimeout(function(){
											that.obtainCoupon();
										// },500);
									}
								}else {
									//已领取过
									Dialog.alert({
									    message: res.msg
									}).then(() => {
									    // on close
									});
								}
							}else {
								//有错误
								Dialog.alert({
								    message: res.msg
								}).then(() => {
								    // on close
								});
							}
						}else {
							//不是中国移动 - 弹窗后跳转到首页
							Dialog.alert({
							    message: res.msg
							}).then(() => {
							    // on close
								that.$router.replace({
									path : '/index'
								})
							});
						}
						//if(data.type != 1) {
						// 	let e = this.$data.id;
						// 	// this.navToL(e);
						// }
						// else {
						// 	this.yhqshow = true;
						// 	let that = this;
						// 	setTimeout(function(){
						// 		that.obtainCoupon();
						// 	},500);
						// }
				    }else{
				        Dialog.alert({
				            message: res.msg
				        }).then(() => {
				            // on close
				        });
				    }
				}).catch(
				    (e) =>{
				        window.console.log(e);
				    }
				);
			},
			//获取优惠券
			obtainCoupon() {
				let that = this;
				var appid = that.$wechat.appId;
				let openId = that.$store.getters['user/getOpenId'];
				that.$post('/getCoupon20210309', {
					openId: openId,
					appId: appid
				}).then((res) => {
					let data = res.data;
					if(res.code == 200){
						//判断是否异常
						if(!data.error) {
							//无错误
							Dialog.alert({
								message: data.msg
							}).then(() => {
								// on close
							});
						}else {
							//有错误 弹报错窗
							Dialog.alert({
								message: data.msg
							}).then(() => {
								// on close
								that.yhqshow = true;
							});
						}
					}else{
						Dialog.alert({
							message: res.msg
						}).then(() => {
							// on close
						});
					}
				}).catch(
					(e) =>{
						window.console.log(e);
					}
				);
			},
			//通过code 获取用户openid
			getUserInfoByCode(code) {
				var appid = this.$wechat.appId;
				this.$post('/getUserInfoByCode', {
					code: code,
					appId: appid
				}).then((res) => {
					console.log(res);
					let data = res.data;
				    if(res.code == 200){
				        //去除后if内跳转无法生效
						let that = this;

                        let data =res.data;
                        let custId = data.custId;
                        let userId = data.userId;
                        //更新数据
                        that.$store.commit('user/setOpenId', data.openid);
                        that.$store.commit('user/setUserId', userId);
                        that.$store.commit('user/setCustId', custId);
                        let urlType = this.$store.getters['local/getType'];
						let url = this.$store.getters['local/getUrl'];
						console.log(custId, userId);
						if(custId == 0 && userId == 0) {
							// that.logShow = true;
						}
						else {
							// that.isstCoupon();   //判断是否有优惠券
						}
						that.getToken();  //获取token
						state =JSON.parse(window.atob(state));
                        if(urlType == 7){
                            //特殊跳转
                            this.$store.commit('user/setFirst', false);
                            this.$router.push({
                                path : url ,
                                query : state
                            }).catch((e) => {
                                this.$router.push({
                                    path : url ,
                                    query : state
                                });
                            });
                            return false;
						}
				    }else{
				        // Dialog.alert({
				        //     message: res.msg
				        // }).then(() => {
				        //     // on close
				        // });
				    }
				}).catch(
				    (e) =>{
				        window.console.log(e);
				    }
				);
			},
			//获取token
			getToken() {
				let data = {
                    'appId' :this.$wechat.appId,
                    'openId' : this.$store.getters['user/getOpenId'],
                };
                let that= this;
                this.$post('/getToken', data).then((res) => {
                    if(res.code == 200){
                        let token = res.data.token;
                        this.$store.state.user.token = token;
                        this.$store.commit('user/setToken', token);
                        let type = this.$store.getters['local/getType'];
                        
                    }else{
                        window.console.log('验证失败');
                    }
                });
			},
			//点击领取按钮
			click:function() {
				// this.$data.id = e;
				let openId = this.$store.getters['user/getOpenId'];
				let userId = this.$store.getters['user/getUserId'];
                let custId = this.$store.getters['user/getCustId'];
				this.obtain = true;
				//判断是否有openid 和userId、custId
				if(openId != '' && userId == 0) {
					if(custId != 0) {
						//弹出登录弹窗
						this.mobileType = true;
					}
					//弹出登录弹窗
					this.logShow = true;
				}
				else {
					this.isstCoupon2();
				}
			},
			/**
			 * 提交邀请码
			 * @returns {boolean}
			 */
			submitCustCode : function (){
				let id = this.id;
			    let code = this.$data.logFormObj.code;
				let mobile = this.logFormObj.mobile;
				let token = this.logFormObj.token;
				console.log(code, mobile, token);
				
				if(mobile == ''){
				    this.alertWarnLv = 1;
				    return false
				}
				if(token == ''){
				    this.alertWarnLv = 2;
				    return false
				}
				//http://test.yiquanvip.com/act/20201111vl
				let mobileType = this.mobileType;
				// console.log(mobileType);
				if(mobileType) {
					let id = this.id;
					// console.log("走这里");
					this.submitBingMobile(id);
					return false;
				}

			    if(code == ''){
			        this.alertWarnLv = 4;
			        return false
			    }
			    //请求后端判断邀请码是否正确
				// this.$data.logShow = false;
					this.$post('/userBindCust', {
			        openId : this.$store.getters['user/getOpenId'],
			        code :  code,
					}).then((res) => {
						let that = this;
						if(res.code == 200){
							that.$data.alertWarnLv = 0;
							// that.$data.alertWarnContent = res.msg;
							let id = this.id;
							this.mobileType = true;

							that.submitBingMobile();
						}else{
							that.$data.alertWarnLv = 3;
							that.$data.alertWarnContent = res.msg;
						}
					});
			    
			},
			/**
			 *  提交手机号
			 * @returns {boolean}
			 */
			submitBingMobile: function () {
			    //请求绑定
				// let code = this.logFormObj.code;
				let mobile = this.logFormObj.mobile;
				let token = this.logFormObj.token;
				let url = '/userBindMobile';  //注册
			    // if(code == '') {
			    //     this.alertWarnLv = 4;
			    //     return false;
			    // }
			    // else {
			        if (mobile.length == 11) {
			            //判断验证码
			            if (token == '' || typeof token == 'undefined') {
			                this.alertWarnLv = 3;
			                this.alertWarnContent = '请输入验证码';
			                return false;
			            }
			
			            this.alertWarnLv = 0;
			            this.$post(url, {
							openId : this.$store.getters['user/getOpenId'],
			                code : token,
			                mobile :  mobile,
			            }).then((res) => {
			                if (res.code == 200) {
								//更新数据
								let  custId = res.data.custId;
								let  userId = res.data.userId;
								this.$store.commit('user/setUserId', userId);
                    			this.$store.commit('user/setCustId', custId);
			                    
			                    this.logShow = false;
			                    this.logFormObj = {
			                        mobile: '',
			                        token: '',
			                        code: ''
								};
								this.isstCoupon2();   //判断是否有优惠券
			                    // this.TCshow = true;
			                    // Toast('报名成功');
			                    // Dialog.alert({
			                    //     message: '注册成功',
			                    // }).then(() => {
			                        
			                    // });
			                } else {
			                    this.alertWarnLv = 3;
			                    this.alertWarnContent = res.msg;
			                }
			            })
			        } else {
			            this.alertWarnLv = 1;
			            this.btnDisable = false;
			        }
			        return false;
			    // }
			    
			},
			/**
			 * ，关闭弹窗
			 */
			clickClose: function () {
			    this.logShow = false;
			    this.logFormObj = {
			        mobile: '',
			        token: '',
			        code: ''
			    };
			},
			/**
			 * 清空
			 */
			clearTop: function () {
				setTimeout(function () {
					var scrollHeight = document.documentElement.scrollTop || document.body.scrollTop || 0;
					window.scrollTo(0, Math.max(scrollHeight - 1, 0));
				}, 100);
			},
			/**
			 * 获取手机验证码
			 */
			getMobileToken: function () {
			    if (this.btnDisable) {
			        return false;
			    }
			    this.btnDisable = true;
			    let mobile = this.logFormObj.mobile;
			    if (this.isPhone(mobile)) {
			        this.alertWarnLv = 0;
			        this.$post('/userGetRand', {
			            openId : this.$store.getters['user/getOpenId'],
			            mobile :  mobile,
			        }).then((res) => {
			            // console.log(res)
			            if (res.code == 200) {
			                //循环倒计时
			                this.tokenBtnMsg = this.deadline + 's';
			                this.int_btnClock = window.setInterval(() => {
			                    if (this.deadline <= 0) {
			                        window.clearInterval(this.int_btnClock);
			                        this.tokenBtnMsg = '获取验证码';
			                        this.deadline = 60;
			                        this.btnDisable = false;
			                        return;
			                    }
			                    this.deadline--;
			                    this.tokenBtnMsg = this.deadline + 's';
			                }, 1000);
			            } else {
							
			                this.btnDisable = false;
			                this.alertWarnLv = 3;
			                this.alertWarnContent = res.msg;
			            }
			        }).catch(e => {
			            console.log(e);
			            this.btnDisable = false;
			            this.alertWarnLv = 3;
			            this.alertWarnContent = '网路异常请稍后重试';
			        });
			    } else {
			        this.alertWarnLv = 1;
			        this.btnDisable = false;
			    }
			    return false
			},
			// 检查字符串是否为合法手机号码
			isPhone: function (str) {
			    let reg = /^(1[3-9][0-9])[0-9]{8}$/;
			    if (reg.test(str)) {
			        // console.log('手机号码格式输入正确');
			        return true;
			    } else {
			        // console.log('请输入正确格式的手机号码');
			        return false;
			    }
			},
			/**
			 * 阻止提交
			 * @returns {boolean}
			 */
			logForm: function () {
			    this.submitBingMobile();
			    return false;
			},
		}
	}
</script>

<style>
	.wrap {
		position: relative;
	}
	.img {
		width: 100%;
		height: auto;
		display: block;
	}
	#video1 {
		position: absolute;
		bottom: 70px;
		left: 50%;
		width: 698px;
		height: 370px;
		transform: translateX(-50%);
		background: #666;
	}
	.yhqpopup {
		background-color: transparent;
		width: 100%;
	}
	.popupClose {
		margin: 60px auto 0;
		width: 100px;
		height: 100px;
	}
	.pos_rel {
		position: relative;
	}
	.page {
		position: absolute;
	}
	.page2-cen {
		position: absolute;
		top: 0;
	}
	.carbtn {
		position: absolute;
		top: 60px;
		left: 0;
	}
	.carbtn1 {
		/* margin-bottom: 60px; */
	}
	.carbtn img {
		width: 100%;
	}
	.hdImg {
		width: 100%;
	}
	.footerBtn {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100px;
		line-height: 100px;
		text-align: center;
		font-size: 34px;
		color: #fff;
		/* background-image: linear-gradient(rgba(72,78,225, 1),rgba(51,20,197, 1)); */
		background-image: linear-gradient(rgba(72,78,225, 1),rgba(71,21,124, 1));
	}
	//弹窗
	.popupTC {
	    box-sizing: border-box;
	    width: 594px;
	    border-radius: 20px;
	}
	.tcshow {
	    padding: 80px;
	}
	.hd-yzm-ben {
		border: 1px solid #f2531d;
	}
</style>
