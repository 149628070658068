import Vue from 'vue'
import App from './App.vue'
import './common/font/pf-m.css'
import './common/font/pf-b.css'
import wechat from './config/wechat'

import router from './router';
import store from './store/index';
import axios from 'axios';
import {post, refreshToken, fetch, uploadFile} from "./config/axios";
// import QQMapWX from '../public/qqmap-wx-jssdk.js'
import '@babel/polyfill';
import Es6Promise from 'es6-promise';
Es6Promise.polyfill();

Vue.prototype.$axios = axios;
Vue.prototype.$wechat = wechat;
// Vue.prototype.$QQMapWX = QQMapWX;

Vue.prototype.$get = fetch;
Vue.prototype.$post = post;
Vue.prototype.$getToken = refreshToken;
Vue.prototype.$uploadFile = uploadFile;

Vue.config.productionTip = false;


// let openId = store.state.user.openid;

router.beforeEach((to, form, next) => {

    let jump = /\/t\//;
    if(to.path == '/act/hd202009'|| to.path == '/act/dy202010' || to.path == '/act/dy20201119' || to.path == '/deposit') {
        next();
        return 
    }
    //pc识别
    let ua = navigator.userAgent.toLocaleLowerCase();
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
	if(!flag && !/(MicroMessenger)/i.test(ua)){
		//pc端操作（非手机端并且非微信浏览器端）
        if(to.path != '/home'){
            next({
                path: '/home'
            });
        }else{
            next();
        }
        return false;
    }else if(!/(MicroMessenger)/i.test(ua)){
        //移动端 非微信浏览器端
		if(to.path == "/act/livehd" && to.query.browser == undefined) {
			next({
			    path: '/act/livehd',
				query: {
					browser: true
				}
			});
		}else {
            if(to.path == '/home'){
                next();
            }else if(jump.test(to.path)){
                window.location.replace( '/html/mark-20220906.jpg');
            }else{
                next({
                    path: '/home'
                });
            }
		}
		return false;
    }else {
		if(to.path == '/home'){
		    next({
		        path: '/index'
		    });
		}
	}
    if(jump.test(to.path)){
        //中间跳转
        next();
        return;
    }

    //确认是微信登陆后跳转
    if(to.path == '/pact20230923') {
        next();
        return
    }

    //获取用户id和token
    let openId = store.getters['user/getOpenId'];
    //访问记录
    let pattern = /\/act\//;
    let patterntwo = /\/pact/;
    let pattern2 = /\/20200426bw/;
    let pattern4 = /\/20200426xc/;
    let pattern6 = /\/20200629c/;
    let pattern7 = /\/20200713c/;
    let pattern8 = /\/20200804c/;
    let pattern3 = /\/books\//;
    let pattern5 = /\/litter/;
    let backend = /\/backend\//;
    let blank = /\/middle/;
    let ve = /\/v\//;
    let pay = /\/payInfo/;
    let vl = /\/20201111vl/;
    let vl2 = /\/mini20201111vl/;
	let xny = /\/20201231xny/;
	let zgyd = /\/hd20210309yd/;
	let zbhb = /\/hd20210716hd/;
	let zbhb2 = /\/livehd/;
	let series = /\/series/;
    let no = 0;
    if(to.path != '/login' && to.path != '/author'){
        if(to.path == '/personal'){
            //访问个人中心
            store.commit('local/setType', 3);
        }else if(zbhb2.test(to.redirectedFrom) || zbhb.test(to.redirectedFrom) || zgyd.test(to.redirectedFrom) || xny.test(to.redirectedFrom) || pattern2.test(to.redirectedFrom) || pattern4.test(to.redirectedFrom) || pattern6.test(to.redirectedFrom) || pattern7.test(to.redirectedFrom) || pattern8.test(to.redirectedFrom)|| blank.test(to.redirectedFrom)|| pay.test(to.redirectedFrom) || backend.test(to.redirectedFrom) || vl.test(to.redirectedFrom) || vl2.test(to.redirectedFrom)|| patterntwo.test(to.redirectedFrom)){
            //特殊通过页面
            store.commit('local/setType', 7);
            no= 7;
        }else if(zbhb2.test(to.path) || zbhb.test(to.path) || zgyd.test(to.path) || pattern2.test(to.path) || pattern4.test(to.path) || pattern6.test(to.path) || pattern7.test(to.path) || pattern8.test(to.path)|| blank.test(to.path)|| pay.test(to.path) || backend.test(to.path) || vl.test(to.path) || vl2.test(to.path)  || xny.test(to.path) || patterntwo.test(to.path)){
            //特殊通过页面
            store.commit('local/setType', 7);
            let url = to.path+'?';
            if (to.query.alert != undefined){
                url += '&alert='+to.query.alert
            }
            if (to.query.invCode != undefined){
                url += '&invCode='+to.query.invCode
            }
            if (to.query.id != undefined){
                url += '&id=' + to.query.id
            }
            store.commit('local/setUrl', url);
            no= 7;
        }else  if(pattern3.test(to.path) || pattern5.test(to.path)){
            //电子页
            store.commit('local/setType', 7);
            let url = to.path+'?';
            if (to.query.icode != undefined){
                url += '&icode='+to.query.icode
            }
            if (to.query.local != undefined){
                url += '&local='+to.query.local
            }
            store.commit('local/setUrl', url);
            no= 7;
        }else if(to.path == '/series' && to.query.markId != undefined && to.query.provinceId != undefined && to.query.cityId != undefined) {
            store.commit('local/setType', 9);
            let url = to.path+'?markId='+to.query.markId;
            url += '&provinceId='+to.query.provinceId;
            url += '&cityId='+to.query.cityId;

            store.commit('local/setUrl', url);
            
            no= 7;
        }else if(to.path == '/index'){
            //购车主页
            store.commit('local/setType', 1);
        }else if(to.path == '/sp-index'){
            //特价车主页
            store.commit('local/setType', 2);
        }else if(pattern.test(to.path)){
            //优惠券活动页面
            store.commit('local/setType', 9);
            // store.commit('local/setUrl', '/act/20200426');
			store.commit('local/setUrl', to.path);
        }else if(ve.test(to.path)){
            //v系列页面
            store.commit('local/setType', 8);
            store.commit('local/setUrl', to.path);
        }
    }

    //路由拦截用户判断用户是否登陆过
    if ((openId) || to.path == '/author') {
        //视频论外直接跳过验证
        if(ve.test(to.path)){
            next();
            return;
        }
        //拥有openId（说明已经授权过）
        if (to.path == '/author') {
            //其他的部分不处理
            next();
            return;
        }
        let userId = store.getters['user/getUserId'];
        let custId  = store.getters['user/getCustId'];
        let frist2  = store.getters['user/getFirst2'];//临时过度

        if(frist2){
            //跳过登陆
            store.commit('user/setFirst2', false);
            store.commit('user/setFirst', false);
        }

        if(no == 7){
            //7的特殊跳过
            next();
            return;
        }

        //如果访问的是login || 优惠券访问 -不用做处理
        if (to.path == '/login' || to.path == '/index' || pattern.test(to.path)) {
            //其他的部分不处理
            next();
            return;
        }
        //获取token
        let token = store.getters['user/getToken'];
        //判断token是否存在
        if(token == '' || token == null){
            //不存在跳转到首页/特价车首页 /个人中心
            if(to.path == '/index' || to.path == '/personal' || to.path == '/sp-index'){
                next();
            }else{
                next('/index');
            }
            return;
        }
        //其他的部分不处理
        setTimeout(function(){
            next();
        }, 100)
        return;
    } else {
        //未拥有信息，并且不是授权页，强制跳转到授权页面，并且将请求封装
        if (to.query.code) {
            //有code的情况下，直取code，state和对应的页面值
            next({path: '/author', query: {code: to.query.code, state: to.query.state, path: encodeURI(to.path)}})
        } else {
            //无code的情况下，封装query请求为state和对应的页面值
            next({path: '/author', query: {state: JSON.stringify(to.query), path: encodeURI(to.path)}})
        }
        return;
    }
});

//后置钩子 用户配置wx配置
router.afterEach((to, from) => {
    if(to.name != 'author'){
        //如果是辅助登录页不访问这个，以下是需要wx配置的访问页面
        let pattern5 = /\/litter\//;
        let pay = /\/payInfo/;
        let pattern7 = /\/20200713c/;
        if(to.name == 'personal' || pattern5.test(to.path) || pay.test(to.path)|| pattern7.test(to.path) || to.name == 'hd20201111vl' || to.name == 'hd20201231xny' || to.name == 'index' || to.name == 'hd20210716hd'|| to.name == 'act20210929' || to.path == '/pact20230923'){
            let url = wechat.baseUrl + to.fullPath;
            Vue.prototype.$post('/getWXConfig', {
                pathUrl  : url,
            }).then((res) => {
                if(res.code == 200){
                    let data = res.data;
					console.log(data.signature);
                    wx.config({
                        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                        appId: wechat.appId, // 必填，公众号的唯一标识
                        timestamp: data.timestamp, // 必填，生成签名的时间戳
                        nonceStr: data.nonce_str, // 必填，生成签名的随机串
                        signature: data.signature,// 必填，签名
                        jsApiList: ['showMenuItems','hideAllNonBaseMenuItem', 'getLocation','updateAppMessageShareData', 'onMenuShareAppMessage', 'updateTimelineShareData', 'onMenuShareTimeline', 'chooseWXPay', 'closeWindow'], // 必填，需要使用的JS接口列表
                        openTagList: ['wx-open-launch-weapp']
                    });
                    wx.error(function(res){
                        console.log(res);
                        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
                    });
                }
            })
        }

        //判断获取企业别称,如果为null则获取新的
        if((to.name != 'login' && to.name != 'hd20201111vl' && to.name != 'index' ) && (store.getters['user/getCustMini'] == null || store.getters['user/getCustMini'] == 'null')){
            Vue.prototype.$post('/getCustMini', {
                custId : store.getters['user/getCustId']
            }).then((res) => {
                if(res.code == 200){
                    store.commit('user/setCustMini', res.data.mini);
                }
            });
        }
    }
});

new Vue({
    store,
    router,
	// QQMapWX,
    render: h => h(App)
}).$mount('#app')
