<template>
    <div class="index" id="index2">
        <div class="index-container">
            <div class="banner-container" id="banner-container">
                <van-swipe :autoplay="3000" :show-indicators="false" class="banner" >
                    <van-swipe-item v-for="(image, index) in banner" :key="index" @click="navToBannerUrl(index)">
                        <img :src="image.file">
                    </van-swipe-item>
                    <van-loading type="spinner" v-if="banner.length == 0"  class="imgload"/>
                </van-swipe>
            </div>
            <div class="middle-conainer" id="middle-box">
                <div class="middle-item">
                    <img :src="middleBanner[0]['VALUE']" @click="navToSeries(1)">
                </div>
                <div class="middle-item">
                    <img :src="middleBanner[1]['VALUE']" @click="navToSeries(2)">
                </div>
                <div class="middle-item">
                    <img :src="middleBanner[2]['VALUE']" @click="navToOther">
                </div>
            </div>

            <van-list
                    v-model="loading"
                    :finished="finished"
                    finished-text="没有更多了"
                    @load="onLoad"
                    class="info-container" id="info-box">
                <label  v-for="(item, index) in infoList" :key="index">
                    <div class="info-item" @click="navtoUrl(index)">
                        <img :src="item.COVER_FILE">
                    </div>
                </label>

            </van-list>
        </div>

        <van-popup class="cityChoose-popup" id="cityChoose-popup" v-model="showCityPopup" :close-on-click-overlay="false">
            <div class="cityChoose-title">
                城市选择
            </div>
            <div class="city-choose-btn">
                <div class="choose-btn" @click="showLocalPopup">
                    <img src="/images/index/icon_interfaces_map-marker.png" class="city-popup-icon city-point-icon">
                    <div>{{localVal}}</div>
                    <img src="/images/index/icon_arrow_chevron-right.png" class="city-popup-icon city-arrow-icon">
                </div>
            </div>
            <div class="submit-btn" @click="showCityPopup = false">
                我知道了
            </div>
        </van-popup>

        <van-popup v-model="showPicker" position="bottom" :close-on-click-overlay="false" safe-area-inset-bottom>
            <van-picker
                    title="请选择所在城市"
                    show-toolbar
                    :columns="columns"
                    value-key = "content"
                    @change="onLocalChange"
                    @cancel="showPicker = false"
                    @confirm="onLocalConfirm"
            ></van-picker>
        </van-popup>

        <van-overlay :show="loadShow"  class="overlay-div">
            <van-loading type="spinner"  class="load"/>
        </van-overlay>
    </div>
</template>

<script>
    import vue from 'vue';
    import {Button, Cell , Icon , Swipe, SwipeItem,  Popup, Picker, Dialog, List} from 'vant';

    vue.use(Button).use(Cell).use(Icon).use(Swipe).use(SwipeItem).use(Popup).use(Picker).use(Dialog).use(List);

    let citys = {};
    export default {
        name: 'car-brand',
        created: function(){
			//获取token
			let token = this.$store.getters['user/getToken'];


			var localVal = this.$store.getters['local/getLocal'];
			if(localVal != null && localVal != ''){
                this.localVal = localVal;
                this.localVals = [
                    {'id' : this.$store.getters['local/getProvinceId']},
                    {'id' : this.$store.getters['local/getCityId']}
                ];
            }

			//判断token是否存在
			if(token == '' || token == null){
			    //不存在手动获取
			    let data = {
			        'appId' :this.$wechat.appId,
			        'openId' : this.$store.getters['user/getOpenId'],
			    };
			    this.$getToken('/getToken', data).then((res) => {
			        if(res.code == 200){
						let that = this;
			            let token = res.data.token;
			            that.$store.commit('user/setToken', token);
						//有token执行下面方法
						setTimeout(function() {
							that.commCreated();
						},600);
			        }else{
			            window.console.log('验证失败');
			        }
			    })
			}
			else {
				this.commCreated();
			}

            this.getBannerInfo();

            //加载信息
            this.loadOrdList(this.pageIndex).then((data) => {
                this.infoList = data.list;
                this.pageIndex = data.page;
            });
        },
        data() {
            return {
                banner: [
                    {'file' : '/images/index/banner-2.jpg'},
                ],
                middleBanner: [
                    {VALUE : '/images/index/card-1.png'},
                    {VALUE : '/images/index/card-2.png'},
                    {VALUE : '/images/index/card-3.png'},
                ],
                infoList:[
                    '/images/index/list-1.jpg',
                    '/images/index/list-1.jpg',
                ],
                value: '',
                showPicker: false,
                showCityPopup: false,   //城市选择弹窗
                citys : citys,
                columns: [
                    {
                        values: Object.keys(citys),
                    },
                    {
                        values: '',
                        className: 'column2',
                        defaultIndex: 2
                    }
                ],
                localVal : "请选择所在城市",
                localVals : [],
                hasLocalCss : '',
                markVal : {
                    content : '请选择车辆品牌',
                    img : '/images/icon_pinpai-five.png'
                },
                markVals : [],
                hasMarkCss : '',
                loadShow : false,
                custMini : this.$store.getters['user/getCustMini'],
				firstCity : false, //自检中是否有省份城市
                loading: false,
                finished: true,
                pageIndex : 1,
                pageSize : 20,
            }
        },
        methods: {
            onLoad() {
                // 异步更新数据更多数据
                if(!this.finished && !this.isLoading){
                    setTimeout(() => {
                        let pageIndex = this.pageIndex;
                        pageIndex++;
                        this.loadOrdList(pageIndex).then((data) => {
                            let list = this.list;
                            this.infoList = list.concat(data.list);
                            this.pageIndex = data.page;
                        });
                    }, 500);
                }
            },
            loadOrdList ($pageIndex){
                this.loading=true;
                this.isLoading = true;
                return new Promise((resolve) => {
                    let data = {
                        'appId' :this.$wechat.appId,
                        'openId' : this.$store.getters['user/getOpenId'],
                        'pageIndex' : $pageIndex,
                        'pageSize' : this.pageSize,
                    };
                    this.$post('/getNews', data).then((res) => {
                        if(res.code == 200){
                            let len = res.data.list.length;

                            resolve(res.data);

                            if(res.data['pageCount'] < res.data['pageSize']){
                                this.finished = true;
                            }else{
                                this.finished = false;
                            }
                        }
                        this.loading = false;
                        this.isLoading = false;
                    });
                })
            },
			//公共创建方法-新增方法
			commCreated() {
				let that = this;
				let cityList = "";
				if(that.$store.getters['local/getCityList'] != undefined && that.$store.getters['local/getCityList'] != '') {
					cityList = JSON.parse(that.$store.getters['local/getCityList']);
				}
				
				let timestamp = Date.now();
				let timeQuantum = 86400000;
				if(cityList != '') {
					timeQuantum = Number(timestamp) - Number(cityList.timestamp)
				}
				if(timeQuantum >= 86400000 || cityList == '') {
					//获取城市信息
					that.getCityList();
				}
				else {
					citys = cityList.data;
					let brandKey = [];
					if(that.$store.getters['local/getBrandKey'] != undefined && that.$store.getters['local/getBrandKey'] != null && this.$store.getters['local/getBrandKey'].length != 0) {
						brandKey = JSON.parse(that.$store.getters['local/getBrandKey']);
					}else {
						brandKey = [0,0];
					}
					that.citys = citys;
					that.columns = [
					    {
					        values: citys,
					        className: 'column1',
							defaultIndex: brandKey[0],
					    },
						{
							values: citys[brandKey[0]]['list'],
							className: 'column2',
							defaultIndex: brandKey[1],
						}
					];
					let brandLoacl = that.$store.getters['local/getBrandLocal'];
					if(brandLoacl.length > 0){
					    let brandKey = that.$store.getters['local/getBrandKey'];
					    that.onLocalConfirm(JSON.parse(brandLoacl), JSON.parse(brandKey));
					}
				}

                this.reloadUserInfo();
			},
            reloadUserInfo(){
                let that = this;
                var oldCustId = this.$store.getters['user/getCustId'];
                //重新获取用户信息状态
                this.$post('/getUserInfoByOpenId', {
                    openId: this.$store.getters['user/getOpenId']
                }).then((res) => {
                    if (res.code == 200) {
                        let data = res.data;
                        let custId = data.custId;
                        let userId = data.userId;
                        that.$store.commit('user/setCustMini', data['mini']);
                        //强行附着0
                        custId = custId == 0 ? 101088: custId;

                        //更新数据
                        this.$store.commit('user/setUserId', userId);
                        this.$store.commit('user/setCustId', custId);

                        if(oldCustId == 0){
                            //重启刷新(本地记录新个体重启）
                            window.location.reload();
                        }
                    } else {
                        alert(res.msg);
                    }
                });
            },
            //获取城市信息
            getCityList() {
                let that = this;
                that.$post('/getCityList', {
                }).then((res) => {
                    if(res.code == 200){
                        citys = res.data;
                        //创建一个时间戳
                        let time = Date.now();
                        let cityArr = {};
                        cityArr.data = citys;
                        cityArr.timestamp = time;
                        this.$store.commit('local/setCityList', JSON.stringify(cityArr));
                        that.citys = citys;
                        let brandKey = [];
                        if(this.$store.getters['local/getBrandKey'] != undefined && this.$store.getters['local/getBrandKey'] != null && this.$store.getters['local/getBrandKey'].length != 0) {
                            brandKey = JSON.parse(this.$store.getters['local/getBrandKey']);
                        }else {
                            brandKey = [0,0];
                        }
                        that.columns = [
                            {
                                values: citys,
                                className: 'column1',
                                defaultIndex: brandKey[0],
                            },
                            {
                                // values: citys[0]['list'],
                                values: citys[brandKey[0]]['list'],
                                className: 'column2',
                                defaultIndex: brandKey[1],
                            }
                        ];
                        let brandLoacl = this.$store.getters['local/getBrandLocal'];
                        if(brandLoacl.length > 0){
                            let brandKey = this.$store.getters['local/getBrandKey'];
                            this.onLocalConfirm(JSON.parse(brandLoacl), JSON.parse(brandKey));
                        }
                    }else{
                        Dialog.alert({
                            message: res.msg
                        }).then(() => {
                            // on close
                        });
                    }
                }).catch(
                    (e) =>{
                        window.console.log(e);
                    }
                );
            },
            /**
             * 获取用户企业展示图
             */
            getBannerInfo(){
                var that = this;
                //获取用户企业展示图
                that.$post('/getCustThreeBanner', {
                    'openId' : that.$store.getters['user/getOpenId'],
                }).then((res) => {
                    if(res.code == 200){
                        let banner = res.data.banner;
                        that.middleBanner = res.data.three;
                        if(banner.length>0){
                            let list = [];
                            let len = banner.length;
                            for(var i = 0; i < len; i++){
                                list.push(banner[i])
                            }
                            that.banner = list;
                        }else{
                            that.banner = [
                                '/images/banner1.jpg',
                            ]
                        }
                    }
                }).catch(
                    (e) =>{
                        window.console.log(e);
                    }
                );
            },
            //保存用户所在省份城市
			saveUserCity() {
				let provinceId = this.$store.getters['local/getProvinceId'];
				let cityId = this.$store.getters['local/getCityId'];
				let userId = this.$store.getters['user/getUserId'];
				console.log(provinceId,cityId,userId);
				this.$post('/saveUserFromCity', {
				    userId : userId,
				    provinceId :provinceId,
				    cityId : cityId
				}).then((res) => {
				    if(res.code == 200){
						
				    }else{
				        Dialog.alert({
				            message: res.msg
				        }).then(() => {
				            this.loadShow = false;
				            // on close
				        });
				    }
				}).catch(
				    (e) =>{
				        window.console.log(e);
				    }
				);
			},
			/**
             * 展示地址底部弹窗
             */
            showLocalPopup(){
                //如果存储里面有地理位置则弹选择城市
                let provinceId = this.$store.getters['local/getProvinceId'];
                let cityId = this.$store.getters['local/getCityId'];
                let local = this.$store.getters['local/getLocal'];
                // if(provinceId == null && cityId == null && local == null) {
                // 	this.getLocation();
                // }else {
                	this.$data.showPicker = true;
                // }
            },
            /**
             * 选择品牌
             */
            onMarkConfirm(index){
                this.markVals = this.markList[index];
                this.markVal['content'] = this.markVals['name'];
                this.markVal['img'] = this.markVals['file'];
                this.hasMarkCss = 'hasLocalCss';
                this.$data.showPicker2 = false;
            },
            /**
             * 地址选择变化
             */
            onLocalChange(picker) {
                let indexs = picker.getIndexes();
                picker.setColumnValues(1, this.citys[indexs[0]]['list']);
            },
            /**
             * 地址选择确认
             */
            onLocalConfirm(value, key){
                this.localVal = value[0]['content'] + '-' + value[1]['content'];
                this.localVals = value;
                let valStr = JSON.stringify(value);
                this.$store.commit('local/setBrandLocal', valStr);
                this.$store.commit('local/setBrandKey', JSON.stringify(key));
                this.hasLocalCss = "hasLocalCss";
                this.$data.showPicker = false;
				if(this.firstCity) {
					console.log(this.firstCity);
					this.saveUserCity();
				}
            },
            isCityShhow(){
                if(this.localVal == '请选择所在城市'){
                    this.showCityPopup = true;
                    return false;
                }else{
                    return true;
                }
            },
            /**
             * 跳转到车系页面
             */
            navToSeries(type){
                if(!this.isCityShhow()) return false;
                if(this.localVals.length <= 0 || (typeof this.localVals[0]['id'] == 'undefined') || (typeof this.localVals[1]['id'] == 'undefined')){
                    Dialog.alert({
                        message: '请选择所在城市'
                    }).then(() => {
                        // on close
                    });
                    return false;
                }
                let provinceId = this.localVals[0]['id'];
                let cityId = this.localVals[1]['id'];

                this.$store.commit('local/setProvinceId', provinceId);
                this.$store.commit('local/setCityId', cityId);
                this.$store.commit('local/setLocal', this.localVal);

                this.$router.push({
                    path: "/series",
                    query: {
                        type: type
                    }
                });
                return false;
            },
            /**
             * banner跳转
             */
            navToBannerUrl(index){
                if(!this.isCityShhow()) return false;
                var item = this.banner[index];

                if(item.fileurl != '' && item.fileurl != null){
                    window.location.href = item.fileurl;
                }else{
                }
            },
            /**
             * 跳转到其他页面
             */
            navToOther(){

            },
            /**
             * 访问其他网站
             * @param index
             */
            navtoUrl(index){
                var item = this.infoList[index];

                if(item.CONTENT != '' && item.CONTENT != null){
                    window.location.href = item.CONTENT;
                }else{
                }
            }
        }
    }
</script>

<style>
    /*主体*/
    #index2{
        width: 100%;
        padding-bottom: 100px;
    }
    #index2 .index-container{
        padding-top: 20px;
    }
    #banner-container{
        padding: 0 20px;
    }
    #index2 #banner-container .banner {
        width: 100%;
    }
    #index2 #banner-container .banner img{
        width: 100%;
    }
    #index2 #middle-box{
        display: flex;
        padding: 0 20px;
        padding-top: 40px;
        justify-content: space-between;
    }
    #index2 #middle-box .middle-item{
        width: 32%;
    }
    #index2 #middle-box .middle-item img{
        width: 100%;
    }

    #index2 #info-box{
        padding-top: 0;
    }
    #index2 #info-box .info-item{
        padding: 0 20px;
        padding-top: 46px;
        width: 100%;
    }
    #index2 #info-box .info-item img{
        width: 100%;
    }

    #index2 #cityChoose-popup{
        width: 80%;
        padding: 48px 0;
        padding-bottom: 10px;
        border-radius: 15px;
        font-size: 32px;
        color: #000;
    }
    #index2 #cityChoose-popup .city-choose-btn{
        padding: 40px 30px;
        padding-top: 56px;

    }
    #index2 #cityChoose-popup .choose-btn{
        background: rgba(245, 248, 255, 1);
        line-height: 88px;
        font-size: 28px;
        position: relative;
        border-radius: 15px;
        padding-left: 76px;
        text-align: left;
        color: rgba(105, 105, 105, 1);
    }
    #index2 #cityChoose-popup .city-choose-btn .city-popup-icon{
        position: absolute;
    }
    #index2 #cityChoose-popup .city-choose-btn .city-point-icon{
        left: 20px;
        top: 0;
        bottom: 0;
        margin: auto;
    }
    #index2 #cityChoose-popup .city-choose-btn .city-arrow-icon{
        right: 20px;
        top: 0;
        bottom: 0;
        margin: auto;
    }
    #index2 #cityChoose-popup .cityChoose-title{
        font-weight: bold;
    }
    #index2 #cityChoose-popup .submit-btn{
        color: #000;
        border-top: 1px solid rgba(204, 204, 204, 0.5);
        padding-top: 16px;
        line-height: 48px;
    }
</style>
