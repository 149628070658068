<template>
    <div class="order-list" id="handsel-order">
        <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="onLoad"
                class="hansel-list"
        >
<!--            <div class="item-container-out" v-for="(series ,index) in list" :key="index" >-->
            <div class="item-container-out" v-for="(item, index) in list " :key="index">
                <div class="box-1">
                    <div class="car-img">
                        <img class="img" :src="item.file" />
                    </div>
                    <div class="info-container">
                        <div class="info-item">
                            <div class="info-title">订单状态:</div>
                            <div class="info-content pay-content-type">已支付定金</div>
                        </div>

                        <div class="info-item">
                            <div class="info-title">定金金额:</div>
                            <div class="info-content  pay-content-type">{{item.DEPOSIT}}元</div>
                        </div>

                        <div class="info-item">
                            <div class="info-title">提交时间:</div>
                            <div class="info-content">{{item.CREATEDATE}}</div>
                        </div>
                    </div>
                </div>
                <div class="box-2">
                    <div class="info-item">
                        <div class="info-title">车型\车系</div>
                        <div class="info-content">{{item.MARKNAME}} {{item.CARTYPENAME}}  {{item.CONFIGINFO}}</div>
                    </div>
<!--                    <div class="info-item">-->
<!--                        <div class="info-title">外观\内饰</div>-->
<!--                        <div class="info-content">外白内黑</div>-->
<!--                    </div>-->
                    <div class="info-item">
                        <div class="info-title">所在单位</div>
                        <div class="info-content">{{item.CUST}}</div>
                    </div>
                    <div class="info-item">
                        <div class="info-title">姓名</div>
                        <div class="info-content">{{item.NAME}}</div>
                    </div>
                    <div class="info-item">
                        <div class="info-title">联系电话</div>
                        <div class="info-content">{{item.MOBILE}}</div>
                    </div>
                </div>
            </div>
        </van-list>
    </div>
</template>

<script>
    import vue from 'vue';
    import { Button, List, Dialog} from 'vant';

    vue.use(Button).use(List).use(Dialog);
    export default {
        name: 'order-list',
        data() {
            return {
                list: [],
                loading: false,
                finished: false,
                isLoading : false,
                test : true,
                pageIndex : 1,
                pageSize : 7
            }
        },
        created: function(){
            //加载列表
            this.loadOrdList(this.pageIndex).then((data) => {
                this.list = data.list;
                this.pageIndex = data.page;
            });
        },
        methods: {
            onLoad() {
                // 异步更新数据更多数据
                if(!this.finished && !this.isLoading){
                    setTimeout(() => {
                        let pageIndex = this.pageIndex;
                        pageIndex++;
                        this.loadOrdList(pageIndex).then((data) => {
                            let list = this.list;
                            this.list = list.concat(data.list);
                            this.pageIndex = data.page;
                        });
                    }, 500);
                }
            },
            loadOrdList ($pageIndex){
                this.loading=true;
                this.isLoading = true;
                return new Promise((resolve) => {
                    let data = {
                        'appId' :this.$wechat.appId,
                        'openId' : this.$store.getters['user/getOpenId'],
                        'pageIndex' : $pageIndex,
                        'pageSize' : this.pageSize,
                    };
                    this.$post('/getDepositOrder', data).then((res) => {
                        if(res.code == 200){
                            resolve(res.data);
                            if(res.data['pageCount'] < res.data['pageSize']){
                                this.finished = true;
                            }else{
                                this.finished = false;
                            }
                        }
                        this.loading = false;
                        this.isLoading = false;
                    });
                })
            }
        }
    }
</script>

<style>
    /*车详情页*/
    #handsel-order {
        background: #eee;
        position: relative;
        padding-top: 50px;
        min-height: 100%;
    }

    #handsel-order .hansel-list{
        padding: 0 30px;
    }
    #handsel-order .item-container-out{
        width: 100%;
        background: #fff;
        padding: 30px 0;
        border-radius: 10px;
    }

    #handsel-order .item-container-out:first-child{
        margin-top: 0;
    }

    #handsel-order .item-container-out .box-1{
        border-bottom:  1px solid #eee;
        padding: 20px 20px;
        position: relative;
    }

    #handsel-order .item-container-out .box-1 .car-img{
        width: 300px;
        min-height: 185px;
        background: #f6f6f6;
    }

    #handsel-order .item-container-out .box-1 .info-container{
        width: 100%;
        position: absolute;;
        top: 0;
        left: 0;
        padding: 40px 20px;
        padding-left: 340px;
    }

    #handsel-order .item-container-out .box-1 .info-container .info-item{
        width: 100%;
        display: flex;
        line-height: 60px;
    }

    #handsel-order .item-container-out .box-1 .info-container .info-title{
        width: 50%;
        text-align: left;;
        color: #656565;
        font-size: 22px;
    }

    #handsel-order .item-container-out .box-1 .info-container .info-content{
        width: 50%;
        text-align: right;
        font-size: 22px;
        color: #333;
    }

    #handsel-order .item-container-out .box-1 .info-container .info-content.pay-content-type{
        font-weight: bold;
        font-size: 30px;
        color: #ff0000;
    }

    #handsel-order .item-container-out .box-2{
        padding: 0 30px;
    }

    #handsel-order .item-container-out .box-2 .info-item{
        width: 100%;
        display: flex;
        line-height: 70px;
    }

    #handsel-order .item-container-out .box-2 .info-item .info-title{
        width: 30%;
        text-align: left;
        font-size: 28px;
        color: #656565;
    }

    #handsel-order .item-container-out .box-2 .info-item .info-content{
        width: 70%;
        text-align: right;
        font-size: 28px;
        color: #333;
        font-weight: bold;
    }

</style>