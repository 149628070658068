<template>
    <div class="author">
    </div>
</template>

<script>
    export default {
        name: 'author',
        data() {
            return {}
        },
        created: function () {
            //触发 授权跳页
            let code = this.$route.query.code;    //授权code
            let state = this.$route.query.state;  //携带参数
            let redirectUri = typeof this.$route.query.path != 'undefined' ? encodeURI(this.$wechat.baseUrl + decodeURI(this.$route.query.path)) : ''; //想要访问的页面
            if (!code) {
                //请求获取链接并且重定向到微信授权页面
                if(process.env.NODE_ENV == 'development'){
                    //测试数据
                    this.$store.commit('user/setOpenId', 'ooxbOv4zUu-GzIZ3WR_cqm2REaZY');
					this.$store.commit('user/setUserId', 0);
                    this.$store.commit('user/setCustId', 101088);
                    let urlType = this.$store.getters['local/getType'];
                    if(urlType == 9){
                        window.location.href = this.$store.getters['local/getUrl'];
                    }else if(urlType == 8){
                        //v系列页面
                        window.location.href = this.$store.getters['local/getUrl'];
                    }else if(urlType == 7){
                        //特殊跳转页面
                        window.location.href = this.$store.getters['local/getUrl'];
                    }else{
                        window.location.href = "/index";
                    }
                } else if(process.env .NODE_ENV == 'production'){
                    let urlType = this.$store.getters['local/getType'];
                    if(urlType == 7){
                        //特殊跳转页面
                        redirectUri = encodeURI(this.$wechat.baseUrl + this.$store.getters['local/getUrl']);
                    }
                    let authUrl = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid='+this.$wechat.appId+'&redirect_uri='+redirectUri+'&response_type=code&scope=snsapi_userinfo&state='+window.btoa(state)+'#wechat_redirect';
                    window.location.href = authUrl;
                }
            } else {
                //请求后台获取用户信息并且存入，然后跳转到对应页面
                window.console.log('===========code===========' + code);
                this.$post('/v2/getUserInfoByCode', {
                    code: code,
                    appId: this.$wechat.appId
                }).then((res) => {
                    if(res.code == 200){
                        //去除后if内跳转无法生效
                        let that = this;
                        let data =res.data;
                        let custId = data.custId;
                        let userId = data.userId;

                        //更新数据
                        that.$store.commit('user/setOpenId', data.openid);
                        that.$store.commit('user/setUserId', userId);
                        that.$store.commit('user/setCustId', custId);
                        let urlType = this.$store.getters['local/getType'];
                        let url = this.$store.getters['local/getUrl'];
                        state =JSON.parse(window.atob(state));
                        if(urlType == 7){
                            //特殊跳转
                            this.$store.commit('user/setFirst', false);
                            this.$router.replace({
                                path : url ,
                                query : state
                            }).catch((e) => {
                                this.$router.replace({
                                    path : url ,
                                    query : state
                                });
                            });
                            return false;
                        }

                        if (custId == 0 || userId == 0) {
                            //无企业id/用户id，跳转到登录页
                            this.$store.commit('user/setFirst', false);
                            this.$router.replace({
                                path : '/index' ,
                                query : state
                            }).catch((e) => {
                                this.$router.replace({
                                    path : '/index' ,
                                    query : state
                                });
                            });
                        }else{
                            this.$store.commit('user/setFirst', false);
                            if(urlType == 3){
                                window.location.replace("/personal");
                            }else if(urlType == 2){
                                window.location.replace("/sp-index");
                            }else if(urlType == 9){
                                window.location.replace(this.$store.getters['local/getUrl']);
                            }else if(urlType == 8){
                                //v系列页面
                                window.location.replace(this.$store.getters['local/getUrl']);
                            }else{
                                window.location.replace("/index");
                            }
                        }
                    }else{
                        alert(res.msg);
                    }
                }).catch(
                    (e) =>{
                        window.console.log(e);
                    }
                )
            }
        },
        methods: {}
    }
</script>

<style>
    .author {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        padding: 0 45px;
    }
</style>
