<template>
	<div class="wrap">
		<img class="img" src="/images/act/hd/banner.jpg" alt="">
		<div class="page2 pos_rel">
			<img class="img" src="/images/act/hd/page2.jpg" alt="">
			<div class="page2-cen">
				<img class="img" src="/images/act/hd/hd1.png" alt="">
				<img class="img" src="/images/act/hd/hd2.png" alt="">
			</div>
			<video id="video1" controls>
				<source src="http://admin.yiquan.com.cn/v/666.mp4" type="video/mp4">
				您的浏览器不支持 HTML5 video 标签。
			</video>
		</div>
		<div class="page3 pos_rel">
			<img class="img" src="/images/act/hd/page3.jpg" alt="">
			<div class="carbtn">
				<img class="carbtn1 img" src="/images/act/hd/velite1.png" @click="click(1)" alt="">
				<img class="carbtn2 img" src="/images/act/hd/velite2.png" @click="click(2)" alt="">
			</div>
		</div>
		<!-- <div class="footerBtn" @click="click()">立即参与</div> -->
	</div>
</template>

<script>
	import vue from 'vue';
	import {Button, Dialog, List, Popup, Field, Toast} from 'vant';
	
	vue.use(Button, Dialog, Popup, List, Field, Toast);
	export default {
		name: 'minivlhd',
		data() {
			return {
				id: '',
			}
		},
		created() {
			let path = this.$route.path;
			let query  = this.$route.query.code;
			let openid = this.$route.query.miniOpenid;
			let length = window.location.href.length;
		},
		methods: {
			click:function(e) { 
				this.$data.id = e;
				let typename = "";
				let markid ="";
				let booksCarId = "";
				if(e == 1) {
					markid = 1047;
					typename = "微蓝6";
					booksCarId = 14;
				}else {
					markid = 1047;
					typename = "微蓝6 混动";
					booksCarId = 21;
				}
				// alert(1);
				wx.miniProgram.navigateTo({url: '/pages/car/page/carModel/carModel?markid='+markid+'&typename='+typename+'&booksCarId='+booksCarId});
			},
		}
	}
</script>

<style>
	.wrap {
		position: relative;
	}
	.img {
		width: 100%;
		height: auto;
		display: block;
	}
	#video1 {
		position: absolute;
		bottom: 70px;
		left: 50%;
		width: 698px;
		height: 370px;
		transform: translateX(-50%);
		background: #666;
	}
	.yhqpopup {
		background-color: transparent;
		width: 100%;
	}
	.popupClose {
		margin: 60px auto 0;
		width: 100px;
		height: 100px;
	}
	.pos_rel {
		position: relative;
	}
	.page {
		position: absolute;
	}
	.page2-cen {
		position: absolute;
		top: 0;
	}
	.carbtn {
		position: absolute;
		bottom: 130px;
		left: 0;
	}
	.carbtn1 {
		/* margin-bottom: 60px; */
	}
	.carbtn img {
		width: 100%;
	}
	.hdImg {
		width: 100%;
	}
	.footerBtn {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100px;
		line-height: 100px;
		text-align: center;
		font-size: 34px;
		color: #fff;
		/* background-image: linear-gradient(rgba(72,78,225, 1),rgba(51,20,197, 1)); */
		background-image: linear-gradient(rgba(72,78,225, 1),rgba(71,21,124, 1));
	}
	.popupTC {
		box-sizing: border-box;
		width: 594px;
		border-radius: 20px;
	}
	.tcshow {
		padding: 80px;
	}
	.hd-yzm-ben {
		border: 1px solid #f2531d;
	}
</style>
