<template>
    <div class="personal-info">

        <form @submit.prevent="submitForm">
            <div class="info-container">
                <div class="cell-container">
                    <van-field label="姓名:" placeholder="请输入姓名" v-model="dataForm.name"/>
                    <!-- <img src="/images/userinf_username.png"> -->
					<i class="confirm-icon userinf_username"></i>
                </div>
                <div class="cell-container">
                    <van-field label="地址:" placeholder="家庭地址" maxlength="200" v-model="dataForm.address"/>
                    <!-- <img src="/images/userinf_idcard.png"> -->
					<i class="confirm-icon userinf_idcard"></i>
                </div>
                <div class="cell-container">
                    <van-field label="身份证:" placeholder="3XXXXXXXXXX" maxlength="18" v-model="dataForm.cardID"/>
                    <!-- <img src="/images/userinf_idcard.png"> -->
					<i class="confirm-icon userinf_idcard"></i>
                </div>
                <div class="cell-container">
                    <van-field label="工号:" error-message="若无工号请勿填写" v-model="dataForm.code"/>
                    <!-- <img src="/images/userinf_workno.png"> -->
					<i class="confirm-icon userinf_workno"></i>
                </div>
                <div class="cell-container">
                    <van-field label="邮箱:" error-message="若无工作邮箱请勿填写" v-model="dataForm.mail"/>
                    <!-- <img src="/images/userinf_mail.png"> -->
					<i class="confirm-icon userinf_mail"></i>
                </div>
                <div class="cell-container">
                    <van-field label="电话:" value="" placeholder="请输入手机号" type="number" maxlength="11"  v-model="dataForm.mobile" readonly/>
                    <!-- <img src="/images/userinf_phone.png"> -->
					<i class="confirm-icon userinf_phone"></i>
                </div>
                <div class="cell-container" v-if="!dataForm.hasPhone">
                    <van-field label="验证码:" value="" placeholder="请输入验证码"  type="number" maxlength="6"  v-model="dataForm.token">
                        <van-button slot="button" size="small" type="info"  @click="getMobileToken()">{{tokenBtnMsg}}</van-button>
                    </van-field>
                    <img src="/images/lab_c.png">
                </div>
            </div>
            <div class="bottom-submit">
                <van-button type="info" size="large" native-type="submit" @click="saveSubmit()">保存</van-button>
            </div>
        </form>

        <van-overlay :show="loadShow"  class="overlay-div">
            <van-loading type="spinner"  class="load"/>
        </van-overlay>
    </div>
</template>

<script>
    import vue from 'vue';
    import { Button, Cell, Dialog} from 'vant';

    vue.use(Button).use(Cell).use(Dialog);
    export default {
        name: 'personal-info',
        created(){
            this.loadShow = true;

            let data = {
                'appId' :this.$wechat.appId,
                'openId' : this.$store.getters['user/getOpenId'],
            };
            this.$post('/getUserBase', data).then((res) => {
                if(res.code == 200){
                    let data = res.data;
                    window.console.log(res.data);
                    this.dataForm['name'] = data['name'];
                    this.dataForm['address'] = data['address'];
                    this.dataForm['cardID'] = data['idcard'];
                    this.dataForm['code'] = data['userno'];
                    this.dataForm['mail'] = data['mail'];
                    this.dataForm['mobile'] = data['mobile'];

                    if(data['mobile'] != ''){
                        this.dataForm['hasPhone'] = 1;
                    }else {
                        this.dataForm['hasPhone'] = 0;
                    }

                    this.loadShow = false;
                }else{
                    Dialog.alert({
                        message: res.msg,
                    }).then(() => {
                        // on close 跳转到首页
                        window.location.href = '/personal';
                    });
                }
            });
        },
        data() {
            return {
                dataForm : {
                    'name' : '',
                    'address' : '',
                    'cardID' : '',
                    'code' : '',
                    'mail' : '',
                    'mobile' : '',
                    'token' : '',
                    'hasPhone' : 1,
                },
                tokenBtnMsg : '获取验证码',
                deadline : 60,
                btnDisable : false,
                loadShow : false,
            }
        },
        methods: {

            /**
             * 获取手机验证码
             */
            getMobileToken: function () {
                if(this.btnDisable){
                    return false;
                }
                this.btnDisable = true;
                let mobile = this.dataForm.mobile;
                if(this.isPhone(mobile)){
                    this.alertWarnLv = 0;
                    this.$post('/userGetRand', {
                        openId : this.$store.getters['user/getOpenId'],
                        mobile :  mobile,
                    }).then((res) => {
                        if(res.code == 200){
                            //循环倒计时
                            this.tokenBtnMsg = this.deadline + 's';
                            this.int_btnClock = window.setInterval(() => {
                                if(this.deadline <= 0){
                                    window.clearInterval(this.int_btnClock);
                                    this.tokenBtnMsg = '获取验证码';
                                    this.deadline = 60;
                                    this.btnDisable = false;
                                    return;
                                }
                                this.deadline--;
                                this.tokenBtnMsg = this.deadline + 's';
                            },1000);
                        }else{
                            this.btnDisable = false;
                            this.alertWarnLv = 3;
                            this.alertWarnContent = res.msg;
                        }
                    }).catch(e => {
                        window.console.log(e);
                        this.btnDisable = false;
                        this.alertWarnLv = 3;
                        this.alertWarnContent = '网路异常请稍后重试';
                    });
                }else{
                    Dialog.alert({
                        message: '请输入正确的手机号'
                    }).then(() => {
                        // on close
                    });
                    this.btnDisable = false;
                }
                return false
            },
            /**
             * @returns {boolean}
             */
            submitForm(){
                return false;
            },
            /**
             * 上传数据
             * @returns {boolean}
             */
            saveSubmit(){
                let data = this.dataForm;
                if(data['name'] == ''){
                    Dialog.alert({
                        message: '请输入姓名'
                    }).then(() => {
                        // on close
                    });
                    return false;
                }
                if(data['cardID'] == '' || data['cardID'].length < 11){
                    Dialog.alert({
                        message: '请输入身份证'
                    }).then(() => {
                        // on close
                    });
                    return false;
                }
                if(data['mobile'] == '' || !this.isPhone(data['mobile'])){
                    Dialog.alert({
                        message: '请输入手机号'
                    }).then(() => {
                        // on close
                    });
                    return false;
                }
                if(!data['hasPhone'] && (data['token'] == '')){
                    Dialog.alert({
                        message: '请输入验证码'
                    }).then(() => {
                        // on close
                    });
                    return false;
                }

                data['openId'] = this.$store.getters['user/getOpenId'];

                //直接提交
                this.loadShow = true;
                this.$post('/updateUserBase', data).then((res)=>{
                    window.console.log(res);
                    if(res.code == 200){
                        Dialog.alert({
                            message: '信息修改成功',
                        }).then(() => {
                            // on close 刷新
                            window.location.reload();
                        });
                        this.loadShow = false;
                    }else{
                        Dialog.alert({
                            message: res.msg,
                        }).then(() => {
                            // on close 跳转到订单列表
                        });
                        this.loadShow = false;
                    }
                });

                return false;
            },
            /**
             * 检查字符串是否为合法手机号码
             */
            isPhone: function (str) {
                let reg = /^(1[3-9][0-9])[0-9]{8}$/;
                if (reg.test(str)) {
                    // console.log('手机号码格式输入正确');
                    return true;
                } else {
                    // console.log('请输入正确格式的手机号码');
                    return false;
                }
            },
        }
    }
</script>

<style>
    /*个人中心*/
    .personal-info {
        position: relative;
        background: white;
        height: 100%;
        /*font-family: "PingFangSC-Medium";*/
    }
    .personal-info form{
        position: relative;
        min-height: 100%;
    }
    .personal-info .info-container .cell-container{
        box-sizing: content-box;
        position: relative;
    }
    .personal-info .info-container .cell-container img{
        position: absolute;
        left: 40px;
        top: 26px;
        margin: auto 0;
        height: 36px;
        width: 42px;
    }
    .personal-info .info-container .van-cell{
        padding-left: 100px;
    }
    .personal-info .info-container .van-cell__title{
        text-align: left;
    }
    .personal-info .bottom-submit{
        position: absolute;
        width: 100%;
        bottom: 0;
    }
	/* 雪碧图 */
	.confirm-icon {
		display: inline-block;
		position: absolute;
		left: 40px;
		top: 26px;
		margin: auto 0;
		height: 36px;
		width: 42px;
	}
	.userinf_username {
		background: url("https://qn.yiquan.com.cn/web/common/confirm-icon.png") no-repeat 0 0;
		background-size: 250px 36px;
	}
	.userinf_idcard {
		background: url("https://qn.yiquan.com.cn/web/common/confirm-icon.png") no-repeat -52px 0;
		background-size: 250px 36px;
	}
	.userinf_workno {
		background: url("https://qn.yiquan.com.cn/web/common/confirm-icon.png") no-repeat -104px 0;
		background-size: 250px 36px;
	}
	.userinf_mail {
		background: url("https://qn.yiquan.com.cn/web/common/confirm-icon.png") no-repeat -156px 0;
		background-size: 250px 36px;
	}
	.userinf_phone {
		background: url("https://qn.yiquan.com.cn/web/common/confirm-icon.png") no-repeat -208px 0;
		background-size: 250px 36px;
	}
</style>