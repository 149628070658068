<template>
    <div class="feedback">
        <form @submit.prevent="submitForm">
            <div class="info-container">
                    <van-field
                            v-model="dataForm.content"
                            rows="2"
                            autosize
                            type="textarea"
                            maxlength="100"
                            placeholder="请输入留言"
                            border
                            show-word-limit
                    />
                <p class="warn-info">接受您的宝贵意见，为您提供更优质的服务</p>
            </div>

            <div class="bottom-submit">
                <van-button type="info" size="large" native-type="submit" @click="saveSubmit()">确认提交</van-button>
            </div>
        </form>

        <van-overlay :show="loadShow"  class="overlay-div">
            <van-loading type="spinner"  class="load"/>
        </van-overlay>
    </div>
</template>

<script>
    import vue from 'vue';
    import { Button, Field , Dialog} from 'vant';

    vue.use(Button).use(Field ).use(Dialog);
    export default {
        name: 'feedback',
        created(){
        },
        data() {
            return {
                dataForm : {
                    'content' : ''
                },
                loadShow : false,
            }
        },
        methods: {
            /**
             * @returns {boolean}
             */
            submitForm(){
                return false;
            },
            /**
             * 上传数据
             * @returns {boolean}
             */
            saveSubmit(){
                let data = this.dataForm;
                if(data['content'] == ''){
                    Dialog.alert({
                        message: '请输入反馈内容'
                    }).then(() => {
                        // on close
                    });
                    return false;
                }

                data['openId'] = this.$store.getters['user/getOpenId'];
                //直接提交
                this.loadShow = true;
                this.$post('/feedback', data).then((res)=>{
                    window.console.log(res);
                    if(res.code == 200){
                        Dialog.alert({
                            message: '反馈提交成功',
                        }).then(() => {
                            // on close 刷新
                            this.dataForm.content = '';
                        });
                        this.loadShow = false;
                    }else{
                        Dialog.alert({
                            message: res.msg,
                        }).then(() => {
                            // on close
                        });
                        this.loadShow = false;
                    }
                });

                return false;

            }
        }
    }
</script>

<style>
    /*个人中心*/
    .feedback {
        position: relative;
        background: white;
        height: 100%;
        /*font-family: "PingFangSC-Medium";*/
    }
    .feedback .warn-info{
        color: red;
        font-size: 22px;
        text-align: left;
        padding: 10px 10px;
    }
    .feedback form{
        position: relative;
        min-height: 100%;
    }
    .feedback .info-container{
        margin-top: 20px;
        padding: 0 20px;
    }
    .feedback .info-container .van-field{
        border: 1px solid #bfbfbf;
        border-radius: 20px;
    }
    .feedback .bottom-submit{
        position: absolute;
        width: 100%;
        bottom: 0;
    }
</style>