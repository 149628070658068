<template>
    <div class="order-detail">
        <div class="order-status order-bubble">
            <div class="order-type" @click="orderLineShow = true">
                订单状态
                <span class="type-info">{{process.statusText}}</span>
                <img   class="type-arrow" src="/images/icon/arrow.png">
            </div>
        </div>
        <div class="car-info order-bubble">
            <div class="item-title">车辆详情</div>
            <div class="img-container">
                <img class="item-img" :src="detail.file">
            </div>
            <div class="info-container">
                <div class="series-name">{{detail['markName']}} <span v-if="detail.year != ''">{{detail['year']}}款</span> {{detail['carTypeName']}} {{detail['configInfo']}}</div>
                <div class="guide-price">颜色: <span>{{detail.color}}</span></div>
                <div class="guide-price">指导价: <span>{{detail.guidePirce}}</span></div>
            </div>
        </div>
        <div class="order-info">
            <div class="line">
                <section class="info-item order-bubble" v-for="(item ,index) in infoList" :key="index">
                    <div class="item-title">{{item.content}}</div>
                    <div class="item-info"  v-for="(info ,index2) in item.list" :key="index2">
                        <div class="info-key">{{info.name}}</div>
                        <div class="info-val">{{info.val}}</div>
                    </div>
                </section>
                <section class="info-item order-bubble bank-info" v-if="loanFlag">
                    <div class="item-title">贷款详情</div>
                    <div class="item-info" @click="orderLoanShow = true">
                        <div class="info-key">贷款进度</div>
                        <div class="info-val bank-type">
                            {{loanInfo.line[0]['LOANFOW']}}
                            <img   class="type-arrow" src="/images/icon/arrow.png">
                        </div>
                    </div>
                    <div class="item-info">
                        <div class="info-key">贷款机构</div>
                        <div class="info-val">中国农业银行</div>
                    </div>
                    <div class="item-info">
                        <div class="info-key">贷款金额</div>
                        <div class="info-val">¥{{loanInfo.PIRCE}}</div>
                    </div>
                    <div class="item-info">
                        <div class="info-key">贷款期限</div>
                        <div class="info-val">{{loanInfo.YEAR}}年{{loanInfo.NUMBER}}期</div>
                    </div>
                    <div class="item-info">
                        <div class="info-key">业务专员</div>
                        <div class="info-val">{{loanInfo.HEADNAME}}</div>
                    </div>
                    <div class="item-info">
                        <div class="info-key">专员电话</div>
                        <div class="info-val">{{loanInfo.HEADMOBILE}}</div>
                    </div>
                </section>
            </div>
        </div>


        <van-popup class="order-line-popup" v-model="orderLineShow" position="bottom" :close-on-click-overlay="false" safe-area-inset-bottom>
            <div class="order-line-title">
                <div class="item-title">订单进度</div>
                <span class="close-btn" @click="orderLineShow = false">取消</span>
            </div>
            <div class="order-type-line">
                <div class="line-item"  v-for="(item ,index) in process.list" :key="index">
                    <div class="date">
                        <!--                                <div class="day">{{item.date}}</div>-->
                        <!--                                <div class="time">{{item.time}}</div>-->
                    </div>
                    <div class="ball active"  v-if="item.fin == 1">
                        <div class="ball-in"></div>
                    </div>
                    <div class="ball" v-else>
                        <div class="ball-in"></div>
                    </div>
                    <div class="msg">
                        {{item.operate}}
                    </div>
                    <div v-if="index + 1 < process.list.length" class="type-line"></div>
                </div>
            </div>
        </van-popup>

        <van-popup class="order-line-popup" v-model="orderLoanShow" position="bottom" :close-on-click-overlay="false" safe-area-inset-bottom>
            <div class="order-line-title">
                <div class="item-title">贷款进度</div>
                <span class="close-btn" @click="orderLoanShow = false">取消</span>
            </div>
            <div class="order-type-line" v-if="loanFlag != false && loanInfo != null">
                <div class="line-item"  v-for="(item ,index) in loanInfo.line" :key="index">
                    <div class="date">
                        <!--                                <div class="day">{{item.date}}</div>-->
                        <!--                                <div class="time">{{item.time}}</div>-->
                    </div>
                    <div class="ball active"  v-if="item.BANKPROCESSID == loanInfo.STATUS">
                        <div class="ball-in"></div>
                    </div>
                    <div class="ball" v-else>
                        <div class="ball-in"></div>
                    </div>
                    <div class="msg">
                        {{item.REMARK}}
                    </div>
                    <div v-if="index + 1 < loanInfo.line.length" class="type-line"></div>
                </div>
            </div>
        </van-popup>
        <van-overlay :show="loadShow" class="overlay-div">
            <van-loading type="spinner" class="load"/>
        </van-overlay>
    </div>
</template>

<script>
    import vue from 'vue';
    import {Button, Dialog, Popup} from 'vant';

    vue.use(Button).use(Dialog).use(Popup);
    export default {
        name: 'order-detail',
        data() {
            return {
                detail : {
                    'file' : '',
                    'custName' : '-',
                    'markName' : '-',
                    'year' : '',
                    'carTypeName' : '-',
                    'configInfo' : '-',
                    'mobile' : '-',
                    'name' : '-',
                },
                infoList : [],
                process : {
                    'statusText' : '-',
                    'list' : []
                },
                loadShow: false,
                orderLineShow : false,
                orderLoanShow : false,

                loanFlag : false,
                loanInfo : [],
            }
        },

        created: function () {
            this.loadShow = true;
            let query = this.$route.query;
            if (typeof query.orderId == 'undefined') {
                this.$router.replace({
                    path: '/orderList'
                });
                return false;
            }
            let orderId = query.orderId;

            let data = {
                'appId' :this.$wechat.appId,
                'openId' : this.$store.getters['user/getOpenId'],
                'orderId' : orderId
            };

            this.$post('/orderDetail', data).then((res) => {

                if(res.code == 200){
                    let data = res.data;
                    data.detail['guidePirce'] = this.numberFormat(data.detail['guidePirce']);
                    this.detail = data.detail;
                    this.infoList = data.infoList;
                    this.process = data.process;

                    this.loanFlag = data.loanFlag;
                    this.loanInfo = data.loanInfo;
                    this.loadShow = false;
                }else{
                    Dialog.alert({
                        message: res.msg,
                    }).then(() => {
                        // on close 跳转到首页
                        window.location.href = '/orderList';
                    });
                }
            });
        },
        methods: {
            numberFormat(value) {
                var param = {};
                var k = 10000,
                    sizes = ['', '万', '亿', '万亿'],
                    i;
                if(value < k){
                    param.value =value
                    param.unit=''
                }else{
                    i = Math.floor(Math.log(value) / Math.log(k));

                    param.value = ((value / Math.pow(k, i)));
                    param.unit = sizes[i];
                }
                // return param;
                return param.value+param.unit;
            }
        }
    }
</script>

<style>
    /*车详情页*/
    .order-detail {
        position: relative;
        background: #fdfdfd;
        padding: 80px 30px;
        background-image: url("/images/order/bg.png");
        background-size: 100% auto;
        background-repeat: no-repeat;
        background-position-y: -100px;
    }

    .order-detail img {
        width: 100%;
        height: 100%;
    }

    .order-detail .car-info {
        margin-top: 36px;
        position: relative;
        padding: 0 24px;
        min-height: 260px;
        padding-left: 300px;
        text-align: left;
    }

    .order-detail .car-info .item-title {
        height: 78px;
        line-height: 78px;
        font-size: 30px;
        font-weight: bold;
        border-bottom: solid 1px #eeeeee;
        padding: 0 30px;
    }

    .order-detail .car-info .img-container {
        position: absolute;
        left: 24px;
        width: 200px;
        max-height: 160px;
        top: 120px;
    }

    .order-detail .car-info .info-container{
        padding-left: 260px;
        padding-top: 30px;
        padding-bottom: 48px;
    }

    .order-detail .car-info .info-container .series-name {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-size: 30px;
        line-height: 40px;
        min-height: 80px;
        text-align: left;
        font-weight: bold;
        /*font-family: "PingFangSC-Bold";*/
    }

    .order-detail .car-info .info-container .guide-price {
        color: #979797;
        font-size: 28px;
        margin-top: 20px;
        text-align: left;
    }

    .order-detail .car-info .info-container .guide-price span{
        color: #4a4a4a;
        font-weight: bold;
    }

    .order-detail .order-bubble{
        padding: 0 30px;
        background: #fff;
        border-radius: 10px;
        box-shadow: 0 0 30px rgba(0,18,42, 0.08);
        margin-top: 36px;
    }

    .order-detail .order-status .order-type {
        padding: 49px 0;
        font-size: 30px;
        font-weight: bold;
        position: relative;
        text-align: left;
    }

    .order-detail .order-status .order-type .type-info {
        color: #1b8fef;
        position: absolute;
        right: 26px;
    }

    .order-detail .order-status .order-type .type-arrow{
        width: 12px;
        height: 22px;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
    }

    .order-detail .order-info {
        width: 100%;
        border: none;
    }

    .order-detail .order-info .line {
        width: 100%;
    }

    .order-detail .order-info .info-item {
        width: 100%;
        text-align: left;
        position: relative;
        padding: 0 ;
    }

    .order-detail .order-info .info-item .item-title {
        height: 78px;
        line-height: 78px;
        font-size: 30px;
        font-weight: bold;
        border-bottom: solid 1px #eeeeee;
        padding: 0 30px;
    }

    .order-detail .order-info .info-item .item-info {
        min-height: 78px;
        padding: 38px 30px;
        font-size: 24px;
    }

    .order-detail .order-info .info-item .item-info:last-child {
        border-bottom: none;
    }

    .order-detail .order-info .info-item .item-info .info-key {
        position: absolute;
        left: 30px;
        width: 120px;
        line-height: 30px;
    }

    .order-detail .order-info .info-item .item-info .info-val {
        padding-left: 140px;
        width: 100%;
        text-align: right;
        line-height: 30px;
        white-space: pre-wrap;
        font-weight: bold;
    }
    .order-detail .order-line-popup{
        height: 600px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }

    .order-detail .order-line-popup .order-line-title{
        padding-top: 32px;
        position: relative;
    }

    .order-detail .order-line-popup .order-line-title .item-title{
        color: #333333;
        font-size: 30px;
        font-weight: bold;
    }

    .order-detail .order-line-popup .order-line-title .close-btn{
        position: absolute;
        right: 30px;
        top: 30px;
    }

    .order-detail .order-line-popup .order-type-line {
        padding-top: 20px;
        padding-bottom: 34px;
        position: relative;
    }

    .order-detail .order-line-popup .order-type-line .line-item {
        margin-top: 60px;
        position: relative;
    }

    .order-detail .order-line-popup .order-type-line .line-item:first-child {
        margin-top: 0;
    }

    .order-detail .order-line-popup .order-type-line .date {
        position: absolute;
        width: 100px;
        padding-left: 30px;
        text-align: center;
    }

    .order-detail .order-line-popup .order-type-line .day {
        font-size: 22px;
        line-height: 36px;
        color: #666666;
    }

    .order-detail .order-line-popup  .order-type-line .time {
        font-size: 20px;
        line-height: 36px;
        color: #999999;
    }

    .order-detail .order-line-popup .order-type-line .msg {
        padding-left: 180px;
        padding-right: 30px;
        font-size: 26px;
        line-height: 36px;
        letter-spacing: 1px;
        color: #979797;
        padding-top: 18px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .order-detail .order-line-popup .order-type-line .ball {
        position: absolute;
        width: 20px;
        height: 20px;
        border: 2px solid #333333;
        border-radius: 50%;
        left: 130px;
        top: 26px;
    }

    .order-detail .order-line-popup .order-type-line .ball.active {
        left: 120px;
        top: 16px;
        width: 40px;
        height: 40px;
        border: 2px solid #1b8fef;
    }

    .order-detail .order-line-popup .order-type-line .ball.active .ball-in {
        background: #1b8fef;
        border-radius: 50%;
        width: 28px;
        height: 28px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }

    .order-detail .order-line-popup .order-type-line .type-line{
        border-left: 1px solid #e5e5e5;
        height: 52px;
        position: absolute;;
        left: 138px;
        top: 60px;
    }

    .order-detail .order-line-popup .order-type-line .type-line.active{
        opacity: 0;
    }

    .order-detail .car-info {
        padding: 0 ;
    }

    .order-detail .bank-info .bank-type{
        color: #1b8fef;
        position: relative;
        padding-right: 20px;
    }

    .order-detail .bank-info .bank-type .type-arrow{
        width: 12px;
        height: 22px;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        padding-bottom: 4px;
    }

</style>