<template>
    <div class="order-list">
        <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="onLoad"
        >
            <div class="item-container-out" v-for="(series ,index) in list" :key="index" >
                <label v-if="series.statusId != 0">
                    <div class="top-class" >
<!--                        <div class="deadline">申请时间: {{series.createDate}} </div>-->
                        <div class="stats_content">{{series.process.statusText}}</div>
                    </div>
                    <div class="item-container">
                        <div class="img-container">
                            <img class="item-img" :src="series.file">
                        </div>
                        <div class="info-container">
                            <div class="series-name">{{series['markName']}} <span v-if="typeof series.year != 'undefined' && series.year != '' && series.year != null">{{series['year']}}款</span> {{series['carTypeName']}} {{series['configInfo']}}</div>
                            <div class="money">指导价: <span>{{series.guidePirce}}</span></div>
                        </div>
                    </div>
                    <div class="bottom-container" >
                        <div v-if="series.statusId == 1" class="cancel-btn bottom-btn" size="small" @click="cancelOrder(index)">取消订单</div>
                        <div class="detail-btn bottom-btn" type="info" size="small"  @click="navToModel(index)">查看订单</div>
                    </div>
                </label>
            </div>
        </van-list>
    </div>
</template>

<script>
    import vue from 'vue';
    import { Button, List, Dialog} from 'vant';

    vue.use(Button).use(List).use(Dialog);
    export default {
        name: 'order-list',
        data() {
            return {
                list: [],
                loading: false,
                finished: false,
                isLoading : false,
                test : true,
                pageIndex : 1,
                pageSize : 7
            }
        },
        created: function(){
            //加载列表
            this.loadOrdList(this.pageIndex).then((data) => {
                this.list = data.list;
                this.pageIndex = data.page;
            });
        },
        methods: {
            onLoad() {
                // 异步更新数据更多数据
                if(!this.finished && !this.isLoading){
                    setTimeout(() => {
                        let pageIndex = this.pageIndex;
                        pageIndex++;
                        this.loadOrdList(pageIndex).then((data) => {
                            let list = this.list;
                            this.list = list.concat(data.list);
                            this.pageIndex = data.page;
                        });
                    }, 500);
                }
            },
            loadOrdList ($pageIndex){
                this.loading=true;
                this.isLoading = true;
                return new Promise((resolve) => {
                    let data = {
                        'appId' :this.$wechat.appId,
                        'openId' : this.$store.getters['user/getOpenId'],
                        'pageIndex' : $pageIndex,
                        'pageSize' : this.pageSize,
                    };
                    this.$post('/orderList', data).then((res) => {
                        if(res.code == 200){
                            let len = res.data.list.length;
                            if(len > 0){
                                for (var i = 0; i < len ; i++){
                                    res.data.list[i]['guidePirce'] = this.numberFormat(res.data.list[i]['guidePirce']);
                                }
                            }
                            resolve(res.data);
                            if(res.data['pageCount'] < res.data['pageSize']){
                                this.finished = true;
                            }else{
                                this.finished = false;
                            }
                        }
                        this.loading = false;
                        this.isLoading = false;
                    });
                })
            },
            navToModel(index){
                let item = this.list[index];
                let id = item['id'];
                this.$router.push({
                    path : 'orderDetail' ,
                    query:{
                        orderId : id
                    }})
            },
            cancelOrder(index){
                let that = this;
                let item = this.list[index];
                Dialog.confirm({
                    message: '确认取消该订单？',
                }).then(() => {
                    // on close
                    let data = {
                        'appId' :this.$wechat.appId,
                        'openId' : this.$store.getters['user/getOpenId'],
                        'orderId' : item['id']
                    };

                    this.$post('/orderCancel', data).then((res) => {
                        if(res.code == 200){
                            that.list[index]['statusId'] = 0;
                        }else{
                            Dialog.alert({
                                message: res.msg,
                            }).then(() => {
                                // on close
                            })
                        }
                    });
                }) .catch(() => {
                    // on cancel
                });

            },
            numberFormat(value) {
                var param = {};
                var k = 10000,
                    sizes = ['', '万', '亿', '万亿'],
                    i;
                if(value < k){
                    param.value =value
                    param.unit=''
                }else{
                    i = Math.floor(Math.log(value) / Math.log(k));

                    param.value = ((value / Math.pow(k, i)));
                    param.unit = sizes[i];
                }
                // return param;
                return param.value+param.unit;
            }
        }
    }
</script>

<style>
    /*车详情页*/
    .order-list {
        background: #f1f0f6;
        position: relative;
        padding-top: 50px;
        /*font-family: "PingFangSC-Medium";*/
    }
    .order-list .item-container-out{
        margin-top: 36px;
        width: 100%;
        border-bottom: 1px solid #e5e5e5;
        padding: 0 22px;
        position: relative;
        overflow: hidden;
        border-bottom: none;
    }
    .order-list .item-container{
        box-sizing: content-box;
        position: relative;
        padding: 30px 24px;
        padding-bottom: 0;
        min-height: 200px;
        padding-left: 300px;
        background: #fff;
    }
    .order-list .item-container .img-container{
        position: absolute;
        left: 0;
        top:  16px;
        width: 240px;
    }
    .order-list .item-container .img-container .item-img{
        width: 290px;
        height: 200px;
    }
    .order-list .item-container .info-container{

    }
    .order-list .item-container .info-container .series-name{
        overflow: hidden;
        text-overflow:ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-align: left;
        font-size: 28px;
        line-height: 34px;
        /*font-family: "PingFangSC-Bold";*/
    }
    .order-list .deadline,.order-list .item-container .info-container .money{
        color: #656565;
        font-size: 24px;
        margin-top: 22px;
        text-align: left;
    }
    .order-list .deadline{
        text-align: left;
        line-height: 70px;
        padding-left: 30px;
    }
    .order-list .item-container .info-container .money span{
        color: #656565;
        font-size: 30px;
    }
    .order-list .bottom-container{
        text-align: right;
        font-size: 22px;
        position: relative;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        background: #fff;
        height: 100px;
    }
    .order-list .stats_content{
        color: #ff0000;
        font-size: 28px;
        text-align: left;
        margin: auto 0;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 40px;
        line-height: 70px;
    }
    .order-list .bottom-container .detail-btn{
        color: #fff;
        font-size: 22px;
        line-height: 50px;
        position: absolute;
        top: 23px;
        bottom: 0;
        right: 30px;
        height: 57px;
        width: 140px;
        border-radius: 29px;
        background-image: linear-gradient(-51deg,
        #ff4200 0%,
        #ff2727 100%),
        linear-gradient(
                #ff0000,
                #ff0000);
        border: none;
    }

    .order-list .bottom-container .bottom-btn{
        text-align: center;
        line-height: 57px;
    }

    .order-list .bottom-container .cancel-btn{
        position: absolute;
        top: 23px;
        bottom: 0;
        right: 200px;
        height: 57px;
        width: 140px;
        border-radius: 29px;
        border: solid 1px #7d7d7d;
        color: #6f6f6f;
    }

    .order-list .top-class{
        width: 100%;
        height: 70px;
        background: #fff;
        position: relative;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }
</style>