<template>
    <div class="series" id="seriesScreen">
        <div class="series-top" id="series-top">
            <div class="top-box">
                <div class="top-1 screen-box">
                    <div class="box-item search-input-box">
                        <img class="screen-icon" :src="icon.screenIcon">
                        <input class="search-input" type="search" placeholder="Search" @search="searchSub">
                    </div>
                    <div class="box-item right-cancel-btn" @click="navToBack">取消</div>
                </div>
                <div class="top-2 btn-list">
                    <div class="btn-item" @click="showScreenChoose">{{screenChooseInfo}}<img class="arrow-btn" :src="icon.arrowDownBtn"></div>
                    <div class="btn-item" @click="showMarkChoose">{{markChooseInfo}}<img class="arrow-btn" :src="icon.arrowDownBtn"></div>
                </div>

                <div class="screen-list-alert" :class="screenShow ? 'alert-show' : ''" id="screen-list-alert">
                    <div class="title-box">选择价格
                        <img class="arrow-btn3" :src="icon.arrowDownBtn">
                        <img class="close-btn" :src="icon.closeIconBtn" @click="hiddenScreenChoose">
                    </div>
                    <div class="screen-list">
                        <div class="screen-item" v-for="(item, index) in screenChooseList" :key="index">
                            <div class="item-info"  :class="index == screenChooseKey ? 'choose': ''" @click="changeScreenChoose(index)">{{item.name}}</div>
                        </div>
                    </div>
                </div>
                <div class="mark-list-alert" :class="markListShow ? 'alert-show' : ''" id="mark-list-alert">
                    <div class="title-box">选择品牌
                        <img class="arrow-btn3" :src="icon.arrowDownBtn">
                        <img class="close-btn" :src="icon.closeIconBtn" @click="hiddenMarkChoose">
                    </div>
                    <div class="screen-list">
                        <div class="screen-item" v-for="(item, index) in markList" :key="index" @click="chooseMark(item.id)">
                            <div class="item-info"  :class="item.id == markId ? 'choose': ''">{{item.name}}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="shadow-over" :class="shadowShow ? 'show' : ''"></div>
        </div>

        <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                class="series-list"
        >
            <div class="item-container-out" v-for="(series ,index) in list" :key="index" >
                <div class="item-container-3" @click="navToModel(index)">
                    <div class="img-container">
                        <img class="item-img" :src="series.file">
                    </div>
                    <div class="info-container">
                        <div class="move-box">
                            <div class="series-name">
                                {{series.name}}
                                <div class="tag-list">
                                    <div class="tag-item" v-for="(tagItem ,index) in series.tagList" :key="index">{{tagItem}}</div>
                                </div>
                            </div>
                            <div class="guide-price" v-if="series['max_guide'] == series['min_guide']">指导价: {{series['min_guide']}}</div>
                            <div class="guide-price" v-else>官方指导价: {{series['min_guide']}}-{{series['max_guide']}}
                                <img class="c3-arrow" src="/images/icon/arrow.png">
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </van-list>

        <van-overlay :show="loadShow"  class="overlay-div">
            <van-loading type="spinner"  class="load"/>
        </van-overlay>
    </div>
</template>

<script>
    import vue from 'vue';
    import {Swipe, List, Popup, Dialog, Picker} from 'vant';

    vue.use(Swipe).use(List).use(Popup).use(Dialog).use(Picker);

    let citys = {};
    export default {
        name: 'car-series',
        data() {
            return {
                subCommData : {},
                newenergy: 1, //1新能源, 2油车
                icon: {
                    arrowDownBtn: '/images/index/icon_arrow_chevron-down.png',
                    screenIcon: '/images/index/screen-icon.png',
                    closeIconBtn: '/images/index/icon_interfaces_close.png'
                },
                list: [],
                loading: false,
                finished: true,
                loadShow: false,
                custMini : this.$store.getters['user/getCustMini'],
                markList: [
                    {'id': 1048, 'name': '别克'},
                ],
                markId : 0,
                markListShow: false,
                shadowShow: false,
                screenShow: false,
                screenChooseKey : 0,
                screenChooseList : [
                    {'name' : '不限', 'min': 0, 'max' : 0},
                    {'name' : '5万以内', 'min': 0, 'max' : 5},
                    {'name' : '5-10万', 'min': 5, 'max' : 10},
                    {'name' : '10-15万', 'min': 10, 'max' : 15},
                    {'name' : '15-20万', 'min': 15, 'max' : 20},
                    {'name' : '20-25万', 'min': 20, 'max' : 25},
                    {'name' : '25-35万', 'min': 25, 'max' : 35},
                    {'name' : '35-40万', 'min': 35, 'max' : 40},
                    {'name' : '40-50万', 'min': 40, 'max' : 50},
                    {'name' : '50-100万', 'min': 50, 'max' : 100},
                    {'name' : '100万以上', 'min': 100, 'max' : 1000},
                ],

                screenChooseInfo: '选择价格',
                markChooseInfo: '选择品牌',
                screenSub: '',
            }
        },
        created:function(){
            var that = this;
            this.loadShow = true;
            //判断
            let query = this.$route.query;
            if(typeof query.type == 'undefined'){
                this.$router.replace({
                    path : '/index'
                });
                return false;
            }
            that.newenergy = query.type;
            that.markId = query.markId;
            that.screenChooseKey = query.screenIndex;
            if(query.screenIndex != 0){
                that.screenChooseInfo = this.screenChooseList[that.screenChooseKey]['name'];
            }

            var filter = '*',filterArray = [];
            if(typeof query.filter != 'undefined'){
                filter = query.filter != '' ? query.filter : '*';
                if(filter !== '*'){
                    filterArray = filter.split(',');
                }
            }

            that.subCommData = {
                provinceId : this.$store.getters['local/getProvinceId'],
                cityId : this.$store.getters['local/getCityId'],
                custId : this.$store.getters['user/getCustId'],
                userId : this.$store.getters['user/getUserId']
            };

            if(typeof query.provinceId != 'undefined' && typeof query.provinceId != 'undefined'){
                //有地址
                that.subCommData.provinceId = query.provinceId;
                that.subCommData.cityId = query.cityId;
                this.$store.commit('local/setProvinceId', that.subCommData.provinceId);
                this.$store.commit('local/setCityId', that.subCommData.cityId);

                //更新数据
                let data = {
                    'appId' :this.$wechat.appId,
                    'openId' : this.$store.getters['user/getOpenId'],
                };
                that.$getToken('/getToken', data).then((res) => {
                    if(res.code == 200){
                        let token = res.data.token;
                        that.$store.commit('user/setToken', token);
                        //有token执行下面方法
                        setTimeout(function() {
                            that.getMarkInfo();
                        },600);
                    }else{
                        window.console.log('验证失败');
                    }
                })
            }else{
                //无城市信息
                if((typeof that.subCommData.provinceId == 'undefined' || that.subCommData.provinceId == 0 || that.subCommData.provinceId == null) || (typeof that.subCommData.cityId == 'undefined' || that.subCommData.cityId == 0 || that.subCommData.cityId == null)){
                    this.$router.replace({
                        path : '/index'
                    });
                    return false;
                }
            }

            that.getMarkInfo();

        },
        mounted: function(){
            let query = this.$route.query;

            if(query.screenChoose == 1){
                $('.search-input').focus();
            }
        },
        methods: {
            /**
             * 异步渲染品牌
             */
            getMarkInfo(){
                var that = this;
                return new Promise((resolve, reject) => {
                    this.$post('/getBrandList', {
                        provinceId : that.subCommData.provinceId,
                        cityId : that.subCommData.cityId,
                        custId : that.subCommData.custId,
                        userId : that.subCommData.userId,
                        newenergy : that.newenergy
                    }).then((res) => {
                        if(res.code == 200){
                            console.log(res);
                            this.markList = res.data;
                            if(res.data.length > 0){
                                if(this.markId == 0){
                                    this.markId = res.data[0]['id'];
                                    this.markChooseInfo = res.data[0]['name'];
                                }else{
                                    var list = this.markList;
                                    if(list.length >= 0){
                                        for (var i = 0; i < list.length; i++){
                                            var item = list[i];
                                            if(item.id == this.markId){
                                                this.markChooseInfo = item.name;
                                            }
                                        }
                                    }
                                }

                                that.getSereis(that.subCommData);
                            }else{
                                this.loadShow = false;
                            }
                        }else{
                            Dialog.alert({
                                message: res.msg
                            }).then(() => {
                                // on close
                                this.$router.back();
                            });
                        }
                    });
                });
            },
            getSereis(data){
                var that = this;
                var filter = '*',filterArray = [];

                var priceRange = '';
                var rangeItem = that.screenChooseList[that.screenChooseKey];
                if(rangeItem['max']!= 0 != rangeItem['min'] ){
                    priceRange = rangeItem['min']*10000 + '-' + rangeItem['max']*10000;
                }

                this.$post('/getSearchCar', {
                    markId : this.markId,
                    provinceId : data.provinceId,
                    cityId : data.cityId,
                    custId : data.custId,
                    userId : data.userId,
                    newenergy : that.newenergy,
                    search : that.screenSub,
                    priceRange : priceRange

                }).then((res) => {
                    if(res.code == 200){
                        let list = res.data['list'],len = list.length, showList = [];
                        if(len > 0){
                            for (let i = 0; i < len; i++){
                                var item = list[i];
                                if(filter !== '*'){
                                    if(this.IsInArray(filterArray, item['name'])){
                                        item = this.itemChange(item);
                                        showList.push(item);
                                    }
                                }else{
                                    item = this.itemChange(item);
                                    showList.push(item);
                                }
                            }
                        }
                        this.list = showList;
                        this.banner = res.data['banner'];
                        this.loadShow = false;
                        this.shadowShow = false;
                        this.markListShow = false;
                        this.screenShow = false;
                    }else{
                        Dialog.alert({
                            message: res.msg
                        }).then(() => {
                            // on close
                            this.$router.back();
                        });
                    }
                });
            },
            navToModel(index){
                let item = this.list[index]['name'].trim();
                let name = escape(item);

                this.$router.replace({
                    path : 'model' ,
                    query:{
                        markId : this.markId,
                        type : name
                    }})
            },
            navToBack(){
                this.$router.go(-1); //返回
            },
            IsInArray(arr,val){
                var testStr=','+arr.join(",")+",";
                return testStr.indexOf(","+val+",")!=-1;
            },
            itemChange(item){
                if(item['ACTIVITYTYPE'] == 5){
                    item['sub'] = this.numberFormat2(item['sub']);
                    item['max_sub'] = this.numberFormat2(item['max_sub']);
                    item['min_sub'] = this.numberFormat2(item['min_sub']);
                    item['max_guide'] = this.numberFormat(item['max_guide']);
                    item['min_guide'] = this.numberFormat(item['min_guide']);
                }else{
                    item['sub'] = this.numberFormat(item['sub']);
                    item['max_sub'] = this.numberFormat(item['max_sub']);
                    item['min_sub'] = this.numberFormat(item['min_sub']);
                    item['max_guide'] = this.numberFormat(item['max_guide']);
                    item['min_guide'] = this.numberFormat(item['min_guide']);
                }
                var  tagList = [];
                if(this.custMini == '农业银行'){
                    tagList.push('中国农业银行特约客户价');
                    tagList.push('至高立减'+item['max_sub']+'元');
                }else if(this.custMini == '一圈会员'){
                    tagList.push('一圈会员专属');
                    tagList.push('至高立减'+item['max_sub']+'元');
                }else{
                    tagList.push(this.custMini + '员工价');
                    tagList.push('至高立减'+item['max_sub']+'元');
                }

                item['tagList'] = tagList;

                return item;
            },
            showScreenChoose(){
                this.screenShow =true;
                this.shadowShow = true;
            },
            hiddenScreenChoose(){
                this.screenShow =false;
                this.shadowShow = false;
            },
            showMarkChoose(){
                this.markListShow =true;
                this.shadowShow = true;
            },
            hiddenMarkChoose(){
                this.markListShow =false;
                this.shadowShow = false;
            },
            changeScreenChoose(index){
                this.loadShow = true;
                this.screenChooseKey = index;
                this.shadowShow = !this.shadowShow;

                if(index == 0){
                    this.screenChooseInfo = '选择价格';
                }else{
                    this.screenChooseInfo = this.screenChooseList[index]['name'];
                }

                this.getSereis(this.subCommData);
            },
            chooseMark(markId){
                this.loadShow = true;
                this.markId = markId;
                var list = this.markList;
                if(list.length >= 0){
                    for (var i = 0; i < list.length; i++){
                        var item = list[i];
                        if(item.id == markId){
                            this.markChooseInfo = item.name;
                        }
                    }
                }
                this.getSereis(this.subCommData);
            },
            searchSub(){
                $("input[type=search]").blur();

                var screen = $('.search-input').val();
                console.log(screen);
                this.screenSub = screen;
                this.getSereis(this.subCommData);
            },
            numberFormat(value) {
                var param = {};
                var k = 10000,
                    sizes = ['', '万', '亿', '万亿'],
                    i;
                if(value < k){
                    param.value =value
                    param.unit=''
                }else{
                    i = Math.floor(Math.log(value) / Math.log(k));

                    param.value = ((value / Math.pow(k, i)));
                    param.unit = sizes[i];
                }
                // return param;
                return param.value+param.unit;
            },
            numberFormat2(value){
                var param = {};
                var k = 10000,
                    sizes = ['', '万', '亿', '万亿'],
                    i;
                if(value < k){
                    param.value =value
                    param.unit=''
                }else{
                    i = Math.floor(Math.log(value) / Math.log(k));

                    param.value = ((value / Math.pow(k, i)));
                    param.unit = sizes[i];
                }

                let reg = /^[0-9]/;
                param.value = param.value.toString();
                let len = param.value.length;
                let newValue = '';
                for (var i = 0; i < len ; i++){
                    let item = param.value.charAt(i);
                    if(i != 0){
                        item = item.replace(reg, 'x');
                    }
                    newValue += item;
                }

                // return param;
                return newValue+param.unit;
            },
        }
    }
</script>

<style>
    /*主体*/
    .series {
        background: #fff;
        height: 100%;
    }
    .series  .banner {
        width: 100%;
    }
    .series .banner img{
        width: 100%;
        padding: 0 20px;
    }
    /*导航*/
    #seriesScreen #series-top .top-1{
        padding-top: 20px;
    }
    #seriesScreen #series-top .top-box,#seriesScreen #series-top .top-1{
        z-index: 30;
        background: #fff;
        position: relative;
    }

    #seriesScreen .screen-box{
        display: flex;
        line-height: 60px;
        padding: 0 20px;
        justify-content: space-between;
    }
    #seriesScreen .screen-box .box-item{
        position: relative;
    }
    #seriesScreen .screen-box img{
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
    }

    #seriesScreen .screen-box .search-input-box{
        position: relative;
        height: 64px;
        line-height: 64px;
        background: rgba(118, 118, 128, 0.12);
        color: #696969;
        width: 100%;
        border-radius: 50px;
        z-index: 30;
    }

    #seriesScreen .screen-box .right-cancel-btn{
        white-space: nowrap;
        padding-left: 30px;
    }
    #seriesScreen .screen-box .search-input-box{
        text-align: left;
        padding-left: 76px;
    }
    #seriesScreen .screen-box .search-input-box .screen-icon{
        position: absolute;
        width: 30px;
        height: 30px;
        left: 32px;
        top: 0;
        bottom: 0;
        margin: auto;
    }
    #seriesScreen .screen-box .search-input-box input{
        background: transparent;
        border: none;
        width: 100%;
        padding-right: 20px;
        height: 64px;
        line-height: 64px;
    }
    #seriesScreen .screen-box .screen-btn{
        width: 28px;
        height: 28px;
        left: 0;
    }

    #seriesScreen #series-top .btn-list{
        text-align: left;
        padding: 0 20px;
        padding-top: 26px;
        padding-bottom: 12px;
    }
    #seriesScreen #series-top .btn-list .btn-item{
        display: inline-block;
        position: relative;
        padding-right: 68px;
        font-weight: bold;
        font-size: 32px;
    }
    #seriesScreen #series-top .btn-list .btn-item .arrow-btn{
        position: absolute;;
        right: 20px;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 48px;
        height: 48px;
    }

    #seriesScreen #screen-list-alert{
        position: absolute;
        background: #fff;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        z-index: 50;
        display: none;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
    }
    #seriesScreen #screen-list-alert.alert-show{
        display: block;
    }
    #seriesScreen #screen-list-alert .title-box{
        width: 100%;
        text-align: left;
        height: 88px;
        line-height: 88px;
        position: relative;
        padding: 0 20px;
        font-size: 32px;
    }
    #seriesScreen #screen-list-alert .title-box .arrow-btn3{
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        height: 48px;
        width: 48px;
    }
    #seriesScreen #screen-list-alert .title-box .close-btn{
        position: absolute;
        right: 20px;
        top: 0;
        bottom: 0;
        margin: auto;
        height: 48px;
        width: 48px;
    }
    #seriesScreen #screen-list-alert .screen-list{
        display: flex;
        flex-wrap: wrap;
        justify-content: start;
        align-content: center;
        padding: 0 10px;
        font-size: 24px;
    }
    #seriesScreen #screen-list-alert .screen-list .screen-item{
        width: 25%;
        padding: 0 10px;
        padding-bottom: 20px;
    }
    #seriesScreen #screen-list-alert .screen-list .screen-item .item-info{
        height: 64px;
        line-height: 64px;
        background: rgba(240, 243, 248, 1);
        border-radius: 6px;
    }
    #seriesScreen #screen-list-alert .screen-list .screen-item .item-info.choose{
        color: #000;
        background: rgba(231, 240, 255, 1);
        font-weight: bold;
    }

    #seriesScreen #mark-list-alert{
        position: absolute;
        background: #fff;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        z-index: 50;
        display: none;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
    }
    #seriesScreen #mark-list-alert.alert-show{
        display: block;
    }
    #seriesScreen #mark-list-alert .title-box{
        width: 100%;
        text-align: left;
        height: 88px;
        line-height: 88px;
        position: relative;
        padding: 0 20px;
        font-size: 32px;
    }
    #seriesScreen #mark-list-alert .title-box .arrow-btn3{
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        height: 48px;
        width: 48px;
    }
    #seriesScreen #mark-list-alert .title-box .close-btn{
        position: absolute;
        right: 20px;
        top: 0;
        bottom: 0;
        margin: auto;
        height: 48px;
        width: 48px;
    }
    #seriesScreen #mark-list-alert .screen-list{
        display: flex;
        flex-wrap: wrap;
        justify-content: start;
        align-content: center;
        padding: 0 10px;
        font-size: 24px;
    }
    #seriesScreen #mark-list-alert .screen-list .screen-item{
        width: 25%;
        padding: 0 10px;
        padding-bottom: 20px;
    }
    #seriesScreen #mark-list-alert .screen-list .screen-item .item-info{
        height: 64px;
        line-height: 64px;
        background: rgba(240, 243, 248, 1);
        border-radius: 6px;
    }
    #seriesScreen #mark-list-alert .screen-list .screen-item .item-info.choose{
        color: #000;
        background: rgba(231, 240, 255, 1);
        font-weight: bold;
    }

    #seriesScreen #series-top .shadow-over{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0,0,0,0.6);
        z-index: 20;
        display: none;
    }
    #seriesScreen #series-top .shadow-over.show{
        display: block;
    }
    /*列表主体*/
    /*模板3*/
    #seriesScreen .series-list .item-container-3{
        position: relative;
        padding: 0 30px;
        min-height: 260px;
    }
    #seriesScreen .series-list .item-container-3 .img-container{
        position: relative;
        width: 100%
    }
    #seriesScreen .series-list .item-container-3 .img-container .item-img{
        width: 100%;
        box-sizing: content-box;
    }
    #seriesScreen .series-list .item-container-3 .info-container{
        text-align: left;
        padding-top: 8px;
        padding-bottom: 34px;
        border-bottom: 1px solid #e8e8e8;
        position: relative;
    }
    #seriesScreen .series-list .item-container-3 .info-container .series-name{
        font-weight: bold;
        font-size: 36px;
        position: relative;
    }
    #seriesScreen .series-list .item-container-3 .info-container .tag-list{
        display: inline-flex;
        padding-left: 10px;
        vertical-align: top;
    }
    #seriesScreen .series-list .item-container-3 .info-container .tag-list .tag-item{
        display: inline-block;
        font-weight: normal;
        font-size: 16px;
        height: 36rpx;
        line-height: 24px;
        align-items: center;
        text-align: center;
        padding: 0 12px;
        color: #fff;
        background: linear-gradient(90deg, rgba(255, 176, 91, 1) 0%, rgba(255, 140, 82, 1) 100%);
        border-radius: 5px;
        top: 0;
        margin: auto;
        margin-left: 20px;
    }
    #seriesScreen .series-list .item-container-3 .info-container .tag-list .tag-item:nth-child(1){
        margin-left: auto;
    }
    #seriesScreen .series-list .item-container-3 .info-container .guide-price{
        padding-top: 24px;
        color: #696969;
        font-size: 24px;
        position: relative;
    }
    #seriesScreen .series-list .item-container-3 .info-container .c3-arrow{
        position: absolute;
        right: 0;
        top: auto;
        bottom: 0;
        margin: auto;
        height: 20px;
    }

    .overlay-div{
        position: fixed;
    }
    .overlay-div .load{
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
    .overlay-div .load .van-loading__spinner{
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }


    #seriesScreen #cityChoose-popup{
        width: 80%;
        padding: 48px 0;
        padding-bottom: 10px;
        border-radius: 15px;
        font-size: 32px;
        color: #000;
    }
    #seriesScreen #cityChoose-popup .city-choose-btn{
        padding: 40px 30px;
        padding-top: 56px;

    }
    #seriesScreen #cityChoose-popup .choose-btn{
        background: rgba(245, 248, 255, 1);
        line-height: 88px;
        font-size: 28px;
        position: relative;
        border-radius: 15px;
        padding-left: 76px;
        text-align: left;
        color: rgba(105, 105, 105, 1);
    }
    #seriesScreen #cityChoose-popup .city-choose-btn .city-popup-icon{
        position: absolute;
    }
    #seriesScreen #cityChoose-popup .city-choose-btn .city-point-icon{
        left: 20px;
        top: 0;
        bottom: 0;
        margin: auto;
    }
    #seriesScreen #cityChoose-popup .city-choose-btn .city-arrow-icon{
        right: 20px;
        top: 0;
        bottom: 0;
        margin: auto;
    }
    #seriesScreen #cityChoose-popup .cityChoose-title{
        font-weight: bold;
    }
    #seriesScreen #cityChoose-popup .submit-btn{
        color: #000;
        border-top: 1px solid rgba(204, 204, 204, 0.5);
        padding-top: 16px;
        line-height: 48px;
    }
</style>
