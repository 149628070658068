<template>
    <div class="personal-coupon">
        <van-tabs class="tab" v-model="active" title-active-color="#ff2a3c" title-inactive-color="#414141" @click="tabClick" >
            <van-tab>
                <div slot="title">
                    未使用({{unusedCount}})
                </div>
            </van-tab>
            <van-tab title="已使用"></van-tab>
            <van-tab title="已过期"> </van-tab>
        </van-tabs>
        <div class="empety-box" v-if="isEmpty">
            <img src="https://qn.yiquan.com.cn/web/common/quesheng.png">
        </div>
        <van-list  v-else
                v-model="loading"
                :finished="finished"
                @load="onLoad"
                finished-text="没有更多了" class="coupon-list">
            <div class="coupon-item" :class="'item-'+type" v-for="(item, index) in list" :key="index">
                <img class="bg" src="https://qn.yiquan.com.cn/web/common/coupon-bg.png"/>
                <div class="item-info">
                    <div class="left-box item-box">
                        <div class="money"><label v-if="item['type'] == 2">¥</label><span>{{item['show_info']}}</span></div>
                        <div class="small-title">{{item['short_name']}}</div>
                    </div>
                    <div class="right-box item-box" :class="'type-' + item['USEDTYPE']">
                        <div class="item-title">{{item['name']}}</div>
                        <div class="item-code" v-if="item['USEDTYPE'] == 2 || item['USEDTYPE'] == 0">核对码:{{item['CODE']}}</div>
                        <div class="item-desc">
                            <p class="remark">{{item['content']}}</p>
                            <p class="deadline">有效期<span v-if="item['start_time'] != 0 && item['start_time'] != undefined && item['start_time'] != null">{{item['start_date']}} - </span>{{item['end_date']}}</p>
                        </div>
                        <van-button v-if="type == 1 && item['USEDTYPE'] == 1" class="used-btn" color="linear-gradient(to right, #ff273f, #ff580f)" size="mini" round @click="navToIndex">立即使用</van-button>
                        <div v-else-if="type == 2" class="coupon-status">
                            <img src="https://qn.yiquan.com.cn/web/common/coupon-used.png">
                        </div>
                        <div v-else-if="type == 3" class="coupon-status">
                            <img src="https://qn.yiquan.com.cn/web/common/coupon-over.png">
                        </div>
                    </div>
                </div>
            </div>
        </van-list>
    </div>
</template>

<script>
    import vue from 'vue';
    import { Tab, Tabs, List, Dialog} from 'vant';

    vue.use(Tab).use(Tabs).use(List).use(Dialog);
    export default {
        name: 'coupon',
        created(){
            //加载列表
            this.firstLoad();
        },
        data() {
            return {
                active: 0,
                unusedCount : 0,
                isEmpty: false,
                type : 1,
                list : [],
                loading: false,
                finished: true,
                loadShow: false,
                pageIndex : 1,
                pageSize : 20
            }
        },
        methods: {
            firstLoad (){
                this.isEmpty = true;
                this.loadList(this.pageIndex).then((data) => {
                    this.list = data.list;
                    this.pageIndex = data.page;
                    if(this.type == 1){
                        if(data.allCount != ''){
                            this.unusedCount = data.allCount;
                        }else{
                            this.unusedCount = 0;
                        }
                    }
                    if(data.allCount > 0){
                        this.isEmpty = false;
                    }
                });
            },
            onLoad() {
                // 异步更新数据更多数据
                if(!this.finished && !this.isLoading){
                    setTimeout(() => {
                        let pageIndex = this.pageIndex;
                        pageIndex++;
                        this.loadList(pageIndex).then((data) => {
                            let list = this.list;
                            this.list = list.concat(data.list);
                            this.pageIndex = data.page;
                        });
                    }, 500);
                }
            },
            loadList (pageIndex){
                this.loading=true;
                this.isLoading = true;
                return new Promise((resolve) => {
                    let data = {
                        'appId' :this.$wechat.appId,
                        'openId' : this.$store.getters['user/getOpenId'],
                        'type' : this.type,
                        'pageIndex' : pageIndex,
                        'pageSize' : this.pageSize,
                    };
                    this.$post('/getMyCoupon', data).then((res) => {
                        if(res.code == 200){
                            resolve(res.data);
                            if(res.data['pageCount'] < res.data['pageSize']){
                                this.finished = true;
                            }else{
                                this.finished = false;
                            }
                        }
                        this.loading = false;
                        this.isLoading = false;
                    });
                })
            },
            tabClick(event){
                let type = event + 1;
                this.type = type;
                this.list = [];
                this.pageIndex = 1;
                this.firstLoad();
            },
            navToIndex(){
                this.$router.push({
                    'path' : '/index',
                })
            }
        }
    }
</script>

<style>
    .personal-coupon {
        background: #f6f6f6;
        position: relative;
        min-height: 100%;
        position: relative;
        /*font-family: "PingFangSC-Medium";*/
    }
    .personal-coupon .tab{
        position: fixed;
        top:  0;
        left: 0;
        z-index: 1;
        width: 100%;
    }
    .personal-coupon .coupon-list{
        padding-top: 100px;
        width: 100%;
    }
    .personal-coupon .coupon-list .coupon-item{
        width: 100%;
        padding: 18px 30px;
        position: relative;
    }
    .personal-coupon .coupon-item .bg{
        width: 100%;
    }
    .personal-coupon .coupon-item .item-info{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        padding: 36px 30px;
    }
    .personal-coupon .coupon-item .item-box{
        float: left;
        position: relative;
        height: 100%;
        top: 0;
    }
    .personal-coupon .coupon-item .left-box{
        width: 30%;
        color: #979797;
        margin-top: 65px;
        font-size: 23px;
    }
    .personal-coupon .coupon-item.item-1 .left-box{
        color: #ff293d;
    }
    .personal-coupon .coupon-item .left-box .money span{
        font-size: 40px;
        font-weight: bold;
    }
    .personal-coupon .coupon-item .left-box .small-title{
        margin-top: 10px;
    }
    .personal-coupon .coupon-item .right-box{
        width: 70%;
        font-size: 20px;
        color: #979797;
        padding-top: 50px;
        padding-left: 43px;
        /*padding-right: 30px;*/
        text-align: left;
        position: relative;
        padding-right: 140px;
    }
    .personal-coupon .coupon-item .right-box.type-2{
        padding-right: 30px;
        padding-top: 30px;
    }
    .personal-coupon .coupon-item .right-box .item-title{
        font-size: 24px;
        line-height: 34px;
        font-weight: bold;
        color: #3c3c3c;
        overflow: hidden;
        white-space: nowrap;
    }
    .personal-coupon .coupon-item .right-box .item-code{
        font-size: 24px;
        line-height: 45px;
        letter-spacing: 1px;
        color: #ff4800;
    }
    .personal-coupon .coupon-item .right-box .item-desc{
        margin-top: 10px;
        line-height: 28px;
        /*padding-right: 120px;*/
    }
    .personal-coupon .coupon-item .right-box .remark{
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    .personal-coupon .coupon-item .right-box .used-btn{
        position: absolute;
        right: 30px;
        top: 0;
        bottom: 0;
        margin: auto;
    }
    .personal-coupon .coupon-item .right-box .coupon-status{
        position: absolute;
        right: 30px;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 100px;
        height: 100px;
    }
    .personal-coupon .coupon-item .right-box .coupon-status img{
        width: 100%;
        height: 100%;
    }
    .personal-coupon .empety-box{
        width: 100%;
        position: absolute;
        top: 0;
    }
    .personal-coupon .empety-box img{
        width: 460px;
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
        top: 320px;
    }
</style>