<template>
    <div class="litter-list">
        <div class="car-list">
            <van-swipe class="my-swipe" indicator-color="white" vertical :loop="loop" ref="swipe">
                <van-swipe-item>
                    <img class="car-img" src='http://api.yiquan.com.cn/web/litter/pf/litter-2-1.jpg' align="middle">
                </van-swipe-item>
                <van-swipe-item>
                    <img class="car-img" src='http://api.yiquan.com.cn/web/litter/pf/litter-2.jpg' align="middle">
                </van-swipe-item>
                <van-swipe-item>
                    <img class="car-img" src='http://api.yiquan.com.cn/web/litter/pf/litter-3.jpg' align="middle">
                </van-swipe-item>
                <van-swipe-item>
                    <div class="btn-item btn-item-1">
                        <img class="car-img2" src='http://api.yiquan.com.cn/web/litter/jiantou-2.png' align="middle">
                        <img class="car-img3" src='http://api.yiquan.com.cn/web//litter/pf-btn-2.png' align="middle" @click="navTolist(1)">
                    </div>
                    <img class="car-img" src='http://api.yiquan.com.cn/web/litter/pf/litter-2-4.jpg' align="middle">
                </van-swipe-item>
                <van-swipe-item>
                    <div class="btn-item btn-item-2">
                        <img class="car-img2" src='http://api.yiquan.com.cn/web/litter/jiantou-2.png' align="middle">
                        <img class="car-img3" src='http://api.yiquan.com.cn/web//litter/pf-btn-2.png' align="middle" @click="navTolist(2)">
                    </div>
                    <img class="car-img" src='http://api.yiquan.com.cn/web/litter/pf/litter-2-5.jpg' align="middle">
                </van-swipe-item>
                <van-swipe-item>
                    <div class="btn-item btn-item-3">
                        <img class="car-img2" src='http://api.yiquan.com.cn/web/litter/jiantou-2.png' align="middle">
                        <img class="car-img3" src='http://api.yiquan.com.cn/web//litter/pf-btn-2.png' align="middle" @click="navTolist(3)">
                    </div>
                    <img class="car-img" src='http://api.yiquan.com.cn/web/litter/pf/litter-6.jpg' align="middle">
                </van-swipe-item>
                <template #indicator>
                    <div class="custom-indicator">
                    </div>
                </template>
            </van-swipe>
        </div>
    </div>
</template>

<script>
    import vue from 'vue';
    import {Button, Dialog,Swipe, SwipeItem } from 'vant';

    vue.use(Button).use(Dialog).use(Swipe).use(SwipeItem);
    export default {
        name: 'books-index',
        created: function(){
            let that = this;

            //用户企业对应是否相同
            this.$post('/userCustState', {
                openId : this.$store.getters['user/getOpenId'],
            }).then((res) => {
                if(res.code == 200){
                    let invCode = 'pfyh';
                    let data =  res.data;
                    if(data.id != 0){
                        //判断是否是宝武
                        if(data.code == invCode){
                            //无任何操作
                            return false;
                        }else{
                            // Dialog.confirm({
                            //     message: '该活动仅限宝武员工参加',
                            // }).then(() => {
                            //     // on cancel
                            //     window.location.href = '/index';
                            // })
                            // .catch(() => {
                            //     window.location.href = '/index';
                            // });
                        }
                    }else{
                        //直接绑定宝武
                        this.$post('/userBindCust', {
                            openId : this.$store.getters['user/getOpenId'],
                            code :  invCode,
                        }).then((res) => {
                            if(res.code == 200){
                                this.$store.commit('user/setCustId', 1058);
                            }
                        });
                    }
                }
            });

            wx.ready(function(){
                wx.hideAllNonBaseMenuItem();

                wx.showMenuItems({
                    menuList: ['menuItem:share:appMessage', 'menuItem:share:timeline'] // 要显示的菜单项，所有menu项见附录3
                });

                let baseUrl = that.$wechat.baseUrl;
                wx.updateAppMessageShareData({
                    title: '欢迎信', // 分享标题
                    desc: '欢迎浦发银行小伙伴加入一圈员工购车服务平台大家庭', // 分享描述
                    link: baseUrl + '/litter/topf' , // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: baseUrl + '/images/icon/share-icon-2.jpg', // 分享图标
                    success: function () {
                        // 设置成功
                    }
                });


                wx.updateTimelineShareData({
                    title: '这里有浦发银行正式在职员工购车专属优惠！！', // 分享标题
                    link: baseUrl + '/litter/topf' , // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: baseUrl + '/images/icon/share-icon-2.jpg', // 分享图标
                    success: function () {
                        // 设置成功
                    }
                });
            });
        },
        data() {
            return {
                loadShow : true,
                loop : false,
                buttonClass : '',
                listClass : '',
                type: 1,
            }
        },
        methods: {
            navTolist(booksMarkId){
                if(booksMarkId == 1 || booksMarkId == 4){
                    this.$router.push({
                        path: '/litter/carlist',
                        query: {
                            booksMarkId : booksMarkId,
                            type : 1,
                        }
                    })
                }else{
                    this.$router.push({
                        path: '/litter/carlist',
                        query: {
                            booksMarkId : booksMarkId,
                            type : 2,
                        }
                    })
                }
            },
        }
    }
</script>

<style>
    /*主体*/
    page{
        height: 100%;
    }
    .litter-list {
        background: #d4d4d4;
        height: 100%;
        width: 100%;
    }
    .litter-list .car-list{
        height: 100%;
        width: 100%;
        position: relative;
    }

    .litter-list .van-swipe{
        width: 100%;
        height: 100%;
    }

    .litter-list .van-swipe-item{
        width: 100%;
        height: 100%;
        text-align: center;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    .litter-list .car-img{
        width: 100%;
        vertical-align:middle;
    }

    .litter-list .left-box .left-button img{
        height: 110px;
        width: 60px;
        right: 18px;
        top:0;
        bottom: 0;
        margin: auto 0;
        position: absolute;

    }

    .litter-list .btn-item{
        position: absolute;
        height: 1200px;
        width: 100%;
    }

    .litter-list .btn-item .car-img2{
        position: absolute;
        top: 80%;
        left: 0;
        right: 0;
        margin: auto;
        width: 36px;
    }
    .litter-list .btn-item-1 .car-img2,.litter-list .btn-item-2 .car-img2{
        animation:iconmove2 2.8s infinite;
    }
    .litter-list .btn-item .car-img3{
        position: absolute;
        bottom: 5%;
        width: 615px;
        padding-left: 5px;
        left: 0;
        right: 0;
        margin: auto;
    }
    .litter-list .btn-item-3 .car-img3{
        top: 58%;
        bottom: auto;
    }
    .litter-list .btn-item-3 .car-img2{
        top: 50%;
        animation:iconmove3 2.8s infinite;
    }

    @keyframes iconmove2
    {
        0% {top:80%;}
        1% {top:80%;}
        49% {top:82%;}
        51% {top:82%;}
        99% {top:80%;}
        100% {top:80%;}
    }

    @-webkit-keyframes iconmove2 /*Safari and Chrome*/
    {
        0% {top:80%;}
        1% {top:80%;}
        49% {top:82%;}
        51% {top:82%;}
        99% {top:80%;}
        100% {top:80%;}
    }

    @keyframes iconmove3
    {
        0% {top:50%;}
        1% {top:50%;}
        49% {top:52%;}
        51% {top:52%;}
        99% {top:50%;}
        100% {top:50%;}
    }

    @-webkit-keyframes iconmove3 /*Safari and Chrome*/
    {
        0% {top:50%;}
        1% {top:50%;}
        49% {top:52%;}
        51% {top:52%;}
        99% {top:50%;}
        100% {top:50%;}
    }
</style>
