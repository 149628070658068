<template>
    <div class="order-loan">
        <div class="banner-container">
            <img class="order-banner" src="http://api.yiquan.com.cn/loan/banner-1.png">
        </div>
        <div class="order-middel">
            <div class="loan-input">
                <div class="loan-title">
                    <label class="loan-left-line"></label>贷多少
                </div>
                <div class="money-show">
                    <span class="money-show-icon">¥</span><input class="money-show-input" type="number" :value="showTrueMoney" :placeholder="'最多可贷款'+showMoney+'元'" @input="showMoneyChange">
                </div>
            </div>
            <div class="loan-goods">
                <div class="loan-title">
                    <label class="loan-left-line"></label>贷多久
                </div>
                <div class="loan-goods-list">
                    <label v-for="(item,index) in goodsList" :key="index">
                        <div class="loan-goods-item active" v-if="index == active">
                            <p class="year-box">{{item.YEAR}}年</p>
                            <p class="month-box">({{item.NUMBER}}期)</p>
                            <img class="loan-goods-choose" src='http://api.yiquan.com.cn/loan/choose-1.png'>
                        </div>
                        <div class="loan-goods-item" v-else @click="chooseGoodsItem(index)">
                            <p class="year-box">{{item.YEAR}}年</p>
                            <p class="month-box">({{item.NUMBER}}期)</p>
                        </div>
                    </label>
                </div>
                <div class="wran-info">
                    *收到您的贷款需求后农行服务专员会第一时间联系您;
                </div>
            </div>
        </div>
        <div class="order-bottom">
            <div class="order-bottom-btn" @click="goBack">获取方案</div>
        </div>

        <van-overlay :show="loadShow"  class="overlay-div">
            <van-loading type="spinner"  class="load"/>
        </van-overlay>
    </div>
</template>

<script>
    import vue from 'vue';
    import { Button, Cell, Dialog} from 'vant';

    vue.use(Button).use(Cell).use(Dialog);

    export default {
        name: 'order-l',
        data() {
            return {
                showMoney: 50000,
                showTrueMoney: '',
                loadShow: true,
                price : 0,
                goodsList : [],
                active : 0,
            }
        },
        created: function(){
            let query = this.$route.query;
            this.price = this.$store.getters['loan/getPrice'] + 0;
            if (typeof query.markId == 'undefined' || query.markId != 1054 || this.price == 0){
                Dialog.alert({
                    message: '暂无相关产品信息'
                }).then(() => {
                    // on close
                    this.$router.go(-1);
                });
            }

            this.$post('/getOrderLoanInfo', {
                markId : query.markId
            }).then((res) => {
                if(res.code == 200){
                    let item = res.data[0];
                    this.goodsList = item['goods'];
                    console.log(this.price);
                    this.showMoney = this.goodsList[0]['PERCENT'] * this.price / 100;
                }else{
                    Dialog.alert({
                        message: res.msg
                    }).then(() => {
                        // on close
                        this.$router.go(-1);
                    });
                }
            });
            this.loadShow = false;
        },
        methods: {
            goBack(){
                if(this.showTrueMoney != ''){
                    let item = this.goodsList[this.active];
                    item['PRICE'] = this.showTrueMoney;
                    this.$store.commit('loan/setGoodsInfo', item);
                }else{
                    this.$store.commit('loan/setGoodsInfo', []);
                }
                this.$router.go(-1);
            },
            chooseGoodsItem(index){
                let item = this.goodsList[index];
                this.showMoney = item['PERCENT'] * this.price / 100;
                this.showTrueMoney = '';
                this.active = index;
                this.$forceUpdate();
            },
            showMoneyChange(event){
                let price = parseInt(event.target.value - 0);
                this.showTrueMoney = price;
                if(price >= this.showMoney){
                    this.showTrueMoney = this.showMoney;
                    this.$forceUpdate();
                }else if(price < 0){
                    this.showTrueMoney = 1;
                    this.$forceUpdate();
                }else if(price == 0){
                    this.showTrueMoney = ' ';
                    this.$forceUpdate();
                }
            }
        }
    }
</script>

<style>
    /*贷款方案*/
    .order-loan {
        background: #fff;
        position: relative;
        padding-bottom: 100px;
    }

    .order-loan .banner-container .order-banner{
        width: 100%;
    }

    .order-loan .order-middel{
        display: block;
        padding: 0 30px;
        padding-top: 28px;
        text-align: left;
    }

    .order-loan .order-middel .loan-input{
        padding-top: 30px;
        padding-bottom: 40px;
    }

    .order-loan .order-middel .loan-input .money-show{
        position: relative;
        margin-top: 66px;
        padding-bottom: 20px    ;
    }

    .order-loan .order-middel .loan-input .money-show .money-show-icon{
        position: absolute;
        left: 0;
        top: 0;
        padding-top: 4px;
        line-height: 60px;
        margin: auto;
        font-size: 40px;
        color: #292929;
        justify-content: center;
    }

    .order-loan .order-middel .loan-input .money-show .money-show-input{
        border: none;
        padding-left: 40px;
        line-height: 60px;
        font-size: 40px;
        box-sizing: content-box;
    }

    .order-loan .order-middel .loan-title{
        font-weight: bold;
        font-size: 36px;
        color: #333333;
        position: relative;
        padding-left: 30px;
    }

    .order-loan .order-middel .loan-title .loan-left-line{
        width: 8px;
        height: 20px;
        background-color: #1b8fef;
        border-radius: 4px;
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
    }

    .order-loan .order-middel .loan-goods{
        padding-top: 32px;
        padding-bottom: 22px;
    }

    .order-loan .order-middel .loan-goods .loan-goods-list{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .order-loan .order-middel .loan-goods .loan-goods-item{
        width: 200px;
        height: 120px;
        text-align: center;
        padding-top: 34px;
        background-color: #f6f6f6;
        margin-top: 30px;
        border-radius: 10px;
        position: relative;
        overflow: hidden;
    }

    .order-loan .order-middel .loan-goods .loan-goods-item.active{
        background: #e9f5ff;
    }

    .order-loan .order-middel .loan-goods .loan-goods-item .year-box{
        font-size: 28px;
        color: #4a4a4a;
    }

    .order-loan .order-middel .loan-goods .loan-goods-item .month-box{
        font-size: 22px;
        color: #4a4a4a;
        padding-top: 8px;
    }

    .order-loan .order-middel .loan-goods .loan-goods-item .loan-goods-choose{
        position: absolute;
        right: 0;
        bottom: 0;
        height: 48px;
        width: 48px;
    }

    .order-loan .order-middel .loan-goods .wran-info{
        font-size: 24px;
        color: #ff4200;
        padding-top: 40px;
    }

    .order-loan .order-bottom{
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
    }

    .order-loan .order-bottom .order-bottom-btn{
        width: 100%;
        text-align: center;
        height: 100px;
        background-image: linear-gradient(-51deg,
        #ff4200 0%,
        #ff2727 100%),
        linear-gradient(
                #1b8fef,
                #1b8fef);
        font-size: 30px;
        line-height: 100px;
        color: #fff;
    }
</style>