/**
 * 用户共享信息
*/
const user = {
    namespaced: true,
    state : {
        token : '',
        openid : 0,
        userId : 0,
        custId : 0,
        custMini : null,
        first  : true,
        first2  : false,
    },
    mutations: {
        //存储token方法
        //设置token等于外部传递进来的值
        setToken(state, token) {
            state.token = token;
            sessionStorage.token = token //同步存储token至localStorage
        },
        setOpenId(state,openid){
            state.openid = openid;
            localStorage.openid = openid //同步存储token至localStorage
        },
        setUserId(state,userId){
            state.userId = userId;
            localStorage.userId = userId //同步存储token至localStorage
        },
        setCustId(state,custId){
            state.custId = custId;
            localStorage.custId = custId //同步存储token至localStorage
        },
        setFirst(state, first){
            state.first = first;
        },
        setFirst2(state, first2){
            state.first2 = first2;
            localStorage.first2 = first2 //同步存储first2至localStorage
        },
        setCustMini(state,custMini){
            state.custMini = custMini;
            sessionStorage.custMini = custMini //同步存储token至sessionStorage
        },
    },
    getters : {
        //获取token方法
        //判断是否有token,如果没有重新赋值，返回给state的token
        getToken(state) {
            if (!state.token) {
                state.token = sessionStorage.getItem('token');
            }
            return state.token
        },
        getOpenId(state){
            if (!state.openid) {
                state.openid = localStorage.getItem('openid');
            }
            return state.openid

        },
        getUserId(state){
            if (!state.userId) {
                state.userId = localStorage.getItem('userId');
                //返回为null的情况下 返回0
                state.userId = state.userId == null ? 0 : state.userId;
            }
            return state.userId;

        },
        getCustId(state){
            if (!state.custId) {
                state.custId = localStorage.getItem('custId');
                //返回为null的情况下 返回0
                state.custId = state.custId == null ? 0 : state.custId;
            }
            return state.custId;

        },
        getFirst2(state){
            if (!state.first2) {
                state.first2 = localStorage.getItem('first2');
            }
            return state.first2

        },
        getCustMini(state){
            if (state.custMini == null) {
                state.custMini = sessionStorage.getItem('custMini');
            }
            return state.custMini

        },
    },
    actions: {

    }
};

export default  user;