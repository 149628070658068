/**
 * 选择地址信息
*/
const local = {
    namespaced: true,
    state : {
        provinceId : 0,
        cityId : 0,
        type : 0, //1直购车 2特价车 3个人中心
        local : '',
        url : '',
        brandLocal: '',
        brandKey : '',
        alertFlagTime : 0,
    },
    mutations: {
		setCityList(state,cityList) {
			state.cityList = cityList;
			sessionStorage.cityList = cityList //同步存储cityList至sessionStorage
		},
        setProvinceId(state, provinceId) {
            state.provinceId = provinceId;
            localStorage.provinceId = provinceId //同步存储provinceId至sessionStorage
        },
        setCityId(state,cityId){
            state.cityId = cityId;
            localStorage.cityId = cityId //同步存储cityId至sessionStorage
        },
        setLocal(state,local){
            state.local = local;
            localStorage.local = local //同步存储local至sessionStorage
        },
        setType(state,type){
            state.type = type;
            localStorage.localtype = type //同步存储local至sessionStorage
        },
        setUrl(state,url){
            state.url = url;
            localStorage.localtypeurl = url //同步存储local至sessionStorage
        },
        setBrandLocal(state, brandLocal){
            state.brandLocal = brandLocal;
            localStorage.brandLocal = brandLocal; //同步存储brandLocal到LocalStorage
        },
        setBrandKey(state, brandKey){
            state.brandKey = brandKey;
            localStorage.brandKey = brandKey; //同步存储brandKey到LocalStorage
        },
        setAlertFlagTime(state, alertFlagTime){
            state.alertFlagTime = alertFlagTime;
            localStorage.alertFlagTime = alertFlagTime; //同步存储alertFlagTime到LocalStorage
        },

    },
    getters : {
		getCityList(state) {
		    if (!state.cityList) {
		        state.cityList = sessionStorage.getItem('cityList');
		    }
		    return state.cityList
		},
        getProvinceId(state) {
            if (!state.provinceId) {
                state.provinceId = localStorage.getItem('provinceId');
            }
            return state.provinceId
        },
        getCityId(state){
            if (!state.cityId) {
                state.cityId = localStorage.getItem('cityId');
            }
            return state.cityId
        },
        getLocal(state){
            if (!state.local) {
                state.local = localStorage.getItem('local');
            }
            return state.local
        },
        getType(state){
            if (!state.type) {
                state.type = localStorage.getItem('localtype');
            }
            return state.type
        },
        getUrl(state){
            if (!state.url) {
                state.url = localStorage.getItem('localtypeurl');
            }
            return state.url
        },
        getBrandLocal(state){
            if(state.brandLocal == ''){
                state.brandLocal = localStorage.getItem('brandLocal');
                if(state.brandLocal == undefined){
                    state.brandLocal = [];
                }
            }
            return state.brandLocal;
        },
        getBrandKey(state){
            if(state.brandKey == ''){
                state.brandKey = localStorage.getItem('brandKey');
                if(state.brandKey == undefined){
                    state.brandKey = [];
                }
            }
            return state.brandKey;
        },
        getAlertFlagTime(state){
            if(state.alertFlagTime == 0){
                state.alertFlagTime = localStorage.getItem('alertFlagTime');
                if(state.alertFlagTime == undefined){
                    state.alertFlagTime = 0;
                }
            }
            return state.alertFlagTime;
        }
    },
    actions: {

    }
};

export default  local;