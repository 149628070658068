<template>
	<div class="360car-con">
		<p>360度看车</p>
		<img id="car-img" src="https://qn.yiquan.com.cn/kdlk/XT5/1.jpg" alt="">
		<canvas id="canvas" @touchstart="doMouseDown" @tochend="tochend" @touchmove="doMouseMove"></canvas> 
	</div>
</template>

<script>
    import vue from 'vue';

	var canvas = document.getElementById("canvas"),
		DPR =  window.devicePixelRatio, //获取设备的物理像素比
		viewW = window.innerWidth,
		viewH = window.innerHeight,
		cansW = viewW*DPR,//放大canvas
		cansH = viewH*DPR,
		ctx = "",
		imgArr = [],//图片数组
		curDeg = 0,//代表当前显示的图片下标
		imgTotal = 36,//图片总数
		imgRatio = (447/1000), //图片高宽比
		imgW = viewW*1.5,//图宽
		imgH = imgW*imgRatio;//图高
	var started = false; 
	var startTime = 0; //开始时间
	var endTime = 0; //结束时间
	var distances = "";//移动的距离
	var timeDis = "";//时间差
	var speed = "";//速度
	
	var startX = "";//开始时的X轴
	//手指触摸起点
	var startPoint = 0,
	//手指离开终点
	tempPoint = 0,
	//滑动多长距离，这里取（canvas宽/图片总数的一半）
	//数值越大约灵敏
	distance = cansW/70;
	//重设canvas宽高 
	//显示的宽高
	// canvas.style.width = cansW + "px";
	// canvas.style.height = cansH + "px";
	//画布宽高
	// canvas.width = viewW;
	// canvas.height = 500;
	var width = "";
	var height = "";
    export default {
        name: 'car-360',
        created: function(){
            
        },
        data() {
            return {
                
            }
        },
		mounted() {
		    this.initCanvas()
		},
        methods: {
			initCanvas() {
				window.onload = function() {
					canvas = document.getElementById("canvas");
					console.log(canvas);
					ctx = canvas.getContext("2d");
					canvas.width = window.innerWidth;// window.innerWidth;  
					canvas.height = 300;//window.innerHeight; 
					// console.log(canvas.width);
					width = canvas.width;
					height = canvas.height;
					
					var carImg = document.getElementById("car-img");
					// console.log(carImg.height);
					var baseURL = "https://qn.yiquan.com.cn/kdlk/XT5/",
					imgURL ="",
					imgObj = null,
					imgIndex = 1;
					//loading
					for(var i = 1;i <= imgTotal;i++){
						if(i<10) {
							imgURL = baseURL + "" + i + ".jpg";
						}
						else {
							imgURL = baseURL + i + ".jpg";
						}
						imgObj = new Image();
						imgObj.src = imgURL;
						//将所有图片对象压入一个数组,方便调用
						imgArr.push(imgObj);
						imgObj.onload = function() {
							imgIndex ++;
							if(imgIndex > imgTotal) {
								$(".loading").hide();
								//默认图
								ctx.drawImage(imgArr[0], 0, 0, width, height); //处理图片
							}
						}
					}
					//手指触摸起点
					// var startPoint = 0,
					// //手指离开终点
					// tempPoint = 0,
					// //滑动多长距离，这里取（canvas宽/图片总数的一半）
					// //数值越大约灵敏
					// distance = cansW/70;
					
					// images[frame].onload = function() {
					// 	// this.redraw();
					// 	var imageObj = images[frame];
					// 	console.log(imageObj,width,height);
					// 	ctx.clearRect(0, 0, width, height); //清除画布内的内容
					// 	ctx.drawImage(imageObj, 0, 0, width, height); //处理图片
					// 	bar.style.display = 'none';
					// }
				}
			},
			/**
			 * @description: 获取相对坐标
			 * @param {type}
			 * @return:
			 */
			// absolutePoint(event) {
			// 	const touch = event.targetTouches[0]
			// 	const canvas = document.getElementById('canvas')
			// 	const react = canvas.getBoundingClientRect()
			// 	this.point = { x: touch.pageX - react.left, y: touch.pageY - react.top }
			// },
            doMouseDown(event) {
				// console.log(event);
				// this.absolutePoint(event)
				//记录起始触摸点
				startPoint = event.touches[0].clientX;
				startX = event.touches[0].clientX;
				// console.log(startPoint,startX);
				startTime = Date.now();
				//去掉默认事件，iPhone下可去除双击页面默认跳动(翻页)效果
				event.preventDefault();
            },
			tochend(event) {
				console.log(event);
				//移动端手指离开屏幕
				//移动距离
				distances = tempPoint - startX;
				
				endTime = Date.now();
				//时间差
				timeDis = endTime - startTime;
				//速度
				speed = (distances / timeDis);
				// console.log(distance);
				// console.log("移动距离："+distances+" 移动时间："+timeDis+" 速度："+speed);
				var time = 0;
				var timer = setInterval(function() {
					time ++;
					if(time == 7) {
						clearInterval(timer);
					}
					else {
						tempPoint = tempPoint + speed*time;
						// console.log(x - startX);
						if((tempPoint - startPoint) > distance) {
							drawImg(curDeg,"left");
							startPoint = tempPoint;
							// console.log(x+speed);
						} else if((tempPoint - startPoint) < -distance) {
							drawImg(curDeg,"right");
							startPoint = tempPoint;
							// x = x - speed*time;
							// console.log(x-speed);
						}
					}
				},18);
			},
            doMouseMove(event) {
            	tempPoint = event.touches[0].clientX;
            	//向右移动
            	if((tempPoint - startPoint) > distance) {
            		this.drawImg(curDeg,"left");
            		//符合距离条件移动后，将记录点设到手指最新位置
            		startPoint = tempPoint;
            	}else if((tempPoint - startPoint) < -distance) {//左
            		this.drawImg(curDeg,"right");
            		startPoint = tempPoint;
            	}
            	//禁止移动页面
            	event.preventDefault();
            }, 
            doMouseUp(event) {
            	console.log("mouse up now");  
            	var x = event.pageX;
            	var y = event.pageY;
            	var canvas = event.target;
            	var loc = this.getPointOnCanvas(canvas, x, y);  
            	if (started) {  
            		this.doMouseMove(event);  
            		startedX = -1;  
            		started = false;
            		newfor = true;
            		number = frame;
            	}
            },
            doMouseleave(event) {
            	// console.log("鼠标移出事件！");
            	if (started) {  
            		// doMouseMove(event);  
            		startedX = -1;  
            		started = false;
            		newfor = true;
            		number = frame;
            	}  
            },
            getPointOnCanvas(canvas, x, y) {
            	var bbox = canvas.getBoundingClientRect();  
            	return { x: x - bbox.left * (canvas.width / bbox.width),  
            			y: y - bbox.top * (canvas.height / bbox.height)  
            	};  
            },
            loaded() {
            	setTimeout( update, 1000/8);  
            },  
            redraw() {  
            	// var imageObj = document.createElement("img");
            	// var imageObj = new Image();
				console.log(images);
            	var imageObj = images[frame];
            	ctx.clearRect(0, 0, width, height); //清除画布内的内容
            	ctx.drawImage(imageObj, 0, 0, width, height); //处理图片
            },
            update() {
            	this.redraw();
            	frame++;
            	if (frame >= totalImages) frame = 1;
            	setTimeout( update, 1000/8);
            },
			//绘图
			//参数：图片对象下标，移动方向
			drawImg(n,type){
				if(type == "left"){
					if(curDeg > 0){
						curDeg--;
					}else{
						curDeg = imgTotal-1;
					}
				}else if(type == "right"){
					if(curDeg < imgTotal-1){
						curDeg++;
					}else{
						curDeg = 0;
					}
				}
				n = curDeg;
				console.log(ctx); 
				console.log(11111);
				ctx.clearRect(0,0,cansW,cansH);
				//参数：图片对象，X偏移量，Y偏移量，图宽，图高
				ctx.drawImage(imgArr[n],0,0,width,height);
				// ctx.drawImage(imgArr[n],-(imgW-viewW)*0.5,(viewH-imgH)*0.5,imgW,imgH);
			}
        }
    }
</script>

<style>
    /*主体*/
    #fullview_canvas {
		width: 100%;
		height: auto;
	}
	#car-img {
		width: 100%;
		height: auto;
		display: none;
	}
</style>
