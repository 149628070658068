<template>
    <div class="sp-series">
        <!--    头部banner主体-->
        <div class="top">
            <van-dropdown-menu class="search">
                <van-dropdown-item title="品牌" ref="carS" id="carS">
                    <ul class="Category-w" id="Categorytw">
                        <li>
                            <div v-if="'' === markIndex" class="mark-item active" >
                                <img  class="mark-img" src="/images/shouye.png">
                                <span>所有</span>
                            </div>
                            <div v-else class="mark-item"   @click="claerBtn()">
                                <img  class="mark-img" src="/images/shouye.png">
                                <span>所有</span>
                            </div>
                        </li>
                        <li v-for="(item, index) in markList" :key="index">
                            <div v-if="index === markIndex" class="mark-item active" >
                                <img  class="mark-img" :src="item.file">
                                <span>{{item.name}}</span>
                            </div>
                            <div v-else class="mark-item"   @click="getMarkchange(index)">
                                <img  class="mark-img" :src="item.file">
                                <span>{{item.name}}</span>
                            </div>
                        </li>
                    </ul>
                    <ul class="Category-t" :class="isShow" id="Categoryt">
                        <li class="name-item"  v-for="(item, index) in seriesList" :key="index">
                            <span v-if="index === seriesIndex" class="series-item active" >{{item.name}}</span>
                            <span v-else class="series-item"   @click="getSerieshange(index)">{{item.name}}</span>
                        </li>
                    </ul>
                </van-dropdown-item>
                <van-dropdown-item title="价格" ref="item" id="money">
                    <ul class="Sort-Sort" id="Sort-Sort">
                        <li v-for="(item, index) in limitList" :key="index">
                            <div v-if="limitMoneyIndex == index" class="active">{{item.value}}</div>
                            <div v-else @click="setLimitMoney" :data-id="index">{{item.value}}</div>
                        </li>
                        <div class="clear"></div>
                    </ul>
                </van-dropdown-item>
            </van-dropdown-menu>
            <van-swipe :autoplay="3000" indicator-color="#fff" class="banner">
                <van-swipe-item v-for="(image, index) in banner" :key="index">
                    <img :src="image['file']">
                </van-swipe-item>
            </van-swipe>
        </div>

        <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                class="series-list"
                @load="onLoad"
        >
            <div class="item-container-out" v-for="(series ,index) in list" :key="index">
                <div class="item-container" @click="navToDetail(index)">
                    <div class="img-container">
                        <img class="item-img" :src="series.file">
                        <span v-if="series.is_sold_out == 2" class="sold-out-span">已售罄</span>
                    </div>
                    <div class="info-container">
                        <div class="series-name series-name-1">{{series.mark_name}} {{series.type_name}}</div>
                        <div class="series-name">{{series.config_info}}</div>
                        <div class="guide-price">指导价: {{series['guide_price']}}</div>
                        <div class="current-price-container">
                            <div class="tag">员工价</div>
                            <span class="price">{{series['sub_price']}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </van-list>

        <van-overlay :show="loadShow" class="overlay-div">
            <van-loading type="spinner" class="load"/>
        </van-overlay>
    </div>
</template>

<script>
    import vue from 'vue';
    import {Swipe, List, Popup, Dialog, DropdownMenu, DropdownItem, TreeSelect} from 'vant';

    vue.use(Swipe).use(List).use(Popup).use(Dialog).use(DropdownMenu).use(DropdownItem).use(TreeSelect);

    export default {
        name: 'sp-series',
        data() {
            return {
                banner: [
                    {'file': '/images/tjc2.png'},
                ],
                list: [],
                loading: false,
                finished: false,
                loadShow: false,
                pageIndex: 1,
                pageSize: 10,
                markIndex : '',
                markList : [],
                seriesIndex : '',
                seriesList : [],
                limitMoneyIndex: 0,
                limitList: [
                    {key: "0,0", value: "不限"},
                    {key: "0,50000", value: "5万以下"},
                    {key: "50000,100000", value: "5-10万"},
                    {key: "100000,150000", value: "10-15万"},
                    {key: "150000,200000", value: "15-20万"},
                    {key: "200000,300000", value: "20-30万"},
                    {key: "300000,500000", value: "30-50万"},
                    {key: "500000,1000000", value: "50-100万"},
                    {key: "1000000,0", value: "100万以上"},
                ],
                isShow : '',
            }
        },
        created: function () {
            this.loadShow = true;
            //获取车型列表
            this.getFirstList();
            //获取品牌列表

            let provinceId = this.$store.getters['local/getProvinceId'];
            let cityId = this.$store.getters['local/getCityId'];
            this.loadShow = true;
            //获取品牌信息
            this.$post('/getSPMarkList', {
                custId : this.$store.getters['user/getCustId'],
                provinceId :provinceId,
                cityId : cityId
            }).then((res) => {
                if(res.code == 200){
                    this.markList = res.data;
                    this.loadShow = false;
                }else{
                    Dialog.alert({
                        message: res.msg
                    }).then(() => {
                        this.loadShow = false;
                        // on close
                    });
                }
            }).catch(
                (e) =>{
                    window.console.log(e);
                }
            );

        },
        methods: {
            onLoad() {
                /**
                 * 异步更新数据
                 */
                if (!this.finished && !this.isLoading) {
                    setTimeout(() => {
                        let pageIndex = this.pageIndex;
                        pageIndex++;
                        this.getList(pageIndex).then((data) => {
                            let list = this.list;
                            let addList = data.list;
                            if (data.pageCount > 0) {
                                for (let i = 0; i < data.pageCount; i++) {
                                    addList[i]['sub'] = this.numberFormat(addList[i]['guide_price'] - addList[i]['sub_price']);
                                    addList[i]['guide_price'] = this.numberFormat(addList[i]['guide_price']);
                                    addList[i]['sub_price'] = this.numberFormat(addList[i]['sub_price']);
                                }
                            }
                            this.list = list.concat(addList);
                            this.pageIndex = data.page;
                        });
                    }, 500);
                }
            },
            navToDetail(index) {
                let item = this.list[index];
                window.console.log(item);
                this.$router.push({
                    path: 'sp-detail',
                    query: {
                        markId: this.list[index]['markId'],
                        markTypeId: this.list[index]['marktype_id'],
                        id: this.list[index]['id'],
                        type: 4 //特价车类型
                    }
                })
            },
            getFirstList(){
                this.getList(1).then((data) => {
                    this.loadShow = false;
                    let list = data.list;
                    if (data.pageCount > 0) {
                        for (let i = 0; i < data.pageCount; i++) {
                            list[i]['sub'] = this.numberFormat(list[i]['guide_price'] - list[i]['sub_price']);
                            list[i]['guide_price'] = this.numberFormat(list[i]['guide_price']);
                            list[i]['sub_price'] = this.numberFormat(list[i]['sub_price']);
                        }
                    }
                    this.list = list;
                    this.pageIndex = data.page;
                });
            },
            getList(pageIndex) {
                this.loading = true;
                this.isLoading = true;
                return new Promise((resolve) => {
                    let provinceId = this.$store.getters['local/getProvinceId'];
                    let cityId = this.$store.getters['local/getCityId'];
                    let custId = this.$store.getters['user/getCustId'];

                    if ((typeof provinceId == 'undefined' || provinceId == 0 || provinceId == null) || (typeof cityId == 'undefined' || cityId == 0 || cityId == null)) {
                        this.$router.replace({
                            path: '/index'
                        });
                        return false;
                    }

                    let data = {
                        provinceId: provinceId,
                        cityId: cityId,
                        custId: custId,
                        pageIndex: pageIndex,
                        pageSize: this.pageSize,
                        limitMoney: this.limitList[this.limitMoneyIndex]['key'],
                    };

                    if(this.markIndex !== '' && this.seriesIndex !== ''){
                        data['markId'] = this.markList[this.markIndex]['id'];
                        data['typeName'] = this.seriesList[this.seriesIndex]['name'];
                    }

                    this.$post('/getSPCarList', data).then((res) => {
                        if (res.code == 200) {
                            resolve(res.data);
                            if (res.data['pageCount'] < res.data['pageSize']) {
                                this.finished = true;
                            }
                            this.loading = false;
                            this.isLoading = false;
                        } else {
                            Dialog.alert({
                                message: res.msg
                            }).then(() => {
                                // on close
                                this.$router.replace({
                                    path: '/sp-index'
                                });
                            });
                        }
                    });
                });
            },
            getMarkchange(markIndex){
                if (this.markIndex !== markIndex) {
                    this.markIndex = markIndex;
                    //请求获取车系
                    let markItem = this.markList[markIndex];
                    this.getSeriesList(markItem['id']);
                }
            },
            getSeriesList(markId){
                let data = {
                    provinceId : this.$store.getters['local/getProvinceId'],
                    cityId : this.$store.getters['local/getCityId'],
                    custId : this.$store.getters['user/getCustId'],
                    markId : markId
                };
                this.$post('/getSPCarSeries', data).then((res) => {
                    if(res.code == 200){
                        this.isShow = 'show';
                        this.seriesIndex = '';
                        this.seriesList = res.data['list'];
                    } else {
                        Dialog.alert({
                            message: res.msg
                        }).then(() => {
                            // on close
                        });
                    }
                });
            },
            getSerieshange(seriesIndex){
                if (this.seriesIndex !== seriesIndex) {
                    this.seriesIndex = seriesIndex;
                    this.loadShow = true;
                    this.getFirstList();
                    this.$refs.carS.toggle();
                }
            },
            setLimitMoney(e) {
                let limitMoneyIndex = e.target.dataset.id;
                if (this.limitMoneyIndex != limitMoneyIndex) {
                    this.limitMoneyIndex = limitMoneyIndex;
                    this.loadShow = true;
                    this.getFirstList();
                    this.$refs.item.toggle();
                }
            },
            numberFormat(value) {
                var param = {};
                var k = 10000,
                    sizes = ['', '万', '亿', '万亿'],
                    i;
                if (value < k) {
                    param.value = value
                    param.unit = ''
                } else {
                    i = Math.floor(Math.log(value) / Math.log(k));

                    param.value = ((value / Math.pow(k, i)));
                    param.unit = sizes[i];
                }
                // return param;
                return param.value + param.unit;
            },
            claerBtn(){
                if (this.markIndex !== '') {
                    this.markIndex = '';
                    this.isShow = '';
                    this.seriesIndex = '';
                    this.seriesList = [];
                    this.$refs.carS.toggle();
                    this.isLoading =false;
                    this.finished =false;
                    //更新列表
                    this.getFirstList();
                }
            }
        }
    }
</script>

<style>
    /*主体*/
    .sp-series {
        background: #fff;
        height: 100%;
        position: relative;
    }

    .sp-series .top {
        position: fixed;
        z-index: 20;
        background: #fff;
        width: 100%;
        top: 0;
        left: 0;
        height: 280px;
        border-bottom: 10px solid #e2f2ff;
        box-sizing: content-box;
    }

    .sp-series .banner {
        width: 100%;
        height: 200px;
    }

    .sp-series .banner img {
        height: 100%;
    }

    /*导航*/
    .sp-series .search{
        height: 80px;
    }

    .sp-series .search .van-dropdown-item__content{
        height: 71%;
        width: 100%;
        overflow: hidden;
    }

    .sp-series .search #money .van-dropdown-item__content{
        height: auto;
    }

    .sp-series .search .Category-w{
        padding-bottom: 20px;
        height: 100%;
        overflow: auto;
    }

    .sp-series .search .Category-w .mark-item{
        background: rgb(255, 255, 255);
        text-align: left;
        padding: 0 30px;
        padding-left: 200px;
        height: 140px;
        line-height: 140px;
        position: relative;
        border-bottom: 1px solid #e2e2e2;
    }

    .sp-series .search .Category-w .mark-item.active,.sp-series .search .Category-t .series-item.active{
        color: rgb(27, 143, 239);
    }

    .sp-series .search .Category-w .mark-img{
        height: 100px;
        width: 100px;
        position: absolute;
        left: 60px;
        top: 0;
        bottom: 0;
        margin: auto;
    }

    .sp-series .search .Category-t{
        background: white;
        padding-bottom: 20px;
        text-align: left;
        position: absolute;
        top: 0;
        left: 102%;
        width: 50%;
        height: 100%;
        overflow: auto;
        box-shadow: -2px 0px 10px #e2e2e2;
        z-index: 2;
        transition-duration: 0.4s;
    }

    .sp-series .search .Category-t.show{
        left: 50%;
    }

    .sp-series .search .Category-t .name-item{
        background: rgb(255, 255, 255);
        text-align: left;
        padding: 0 30px;
        padding-left: 60px;
        height: 140px;
        line-height: 140px;
        position: relative;
        border-bottom: 1px solid #e2e2e2;
    }

    .sp-series .search .Category-t .name-item.active{
        color: rgb(27, 143, 239);
    }

    .sp-series .search .Sort-Sort {
        background: #f4f4f4;
        left: 0;
        text-align: center;
        list-style-type: none;
        margin: 0 auto;
        padding: 20px 20px;
    }

    .sp-series .search .Sort-Sort ul {
        margin: 0 auto;
        width: 100%;
        /*display: flex;*/
        /*flex-direction: row;*/
        /*flex-wrap: wrap;*/
    }

    .sp-series .search .Sort-Sort > li {
        padding: 0;
        width: 30%;
        float: left;
        text-align: center;
        line-height: 2.4rem;
        background-color: #FFF;
        border-radius: 5px;
        border: solid 1px #eee;
        margin-top: 10px;
        margin-right: 20px;
        font-size: 28px;
        flex: auto; /*这是关键*/
    }

    .sp-series .search .Sort-Sort .active {
        color: rgb(27, 143, 239);
    }

    .sp-series .search .Sort-Sort .clear {
        height: 0;
        width: 0;
        clear: both;
    }

    /*列表主体*/
    .sp-series .series-list {
        padding-top: 280px;
        width: 100%;
        /*font-family: "PingFangSC-Medium";*/
    }

    .sp-series .series-list .item-container-out {
        width: 100%;
        border-bottom: 1px solid #e5e5e5;
    }

    .sp-series .series-list .item-container {
        margin-top: 36px;
        position: relative;
        padding: 0 24px;
        min-height: 260px;
        padding-left: 300px;
    }

    .sp-series .series-list .item-container .img-container {
        position: absolute;
        left: 24px;
        width: 240px;
    }

    .sp-series .series-list .item-container .img-container .item-img {
        width: 240px;
        height: 180px;
    }

    .sp-series .series-list .img-container .sold-out-span {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        color: #fff;
        background: rgba(70, 70, 70, 0.5);
        width: 200px;
        height: 80px;
        line-height: 80px;
        font-size: 30px;
        border-radius: 30px;
        letter-spacing: 6px;
    }

    .sp-series .series-list .item-container .info-container .series-name {
        overflow: hidden;
        white-space: nowrap;
        font-size: 30px;
        line-height: 40px;
        text-align: left;
        /*font-family: "PingFangSC-Bold";*/
    }
    .sp-series .series-list .item-container .info-container .series-name-1 {
        padding-top: 20px;
    }

    .sp-series .series-list .item-container .info-container .guide-price {
        color: #979797;
        font-size: 28px;
        margin-top: 30px;
        text-align: left;
    }

    .sp-series .series-list .info-container .current-price-container {
        position: relative;
        margin-top: 20px;
        text-align: left;
        padding-top: 0;
        padding-bottom: 30px;
    }

    .sp-series .series-list .info-container .current-price-container .tag {
        background: #f30700;
        color: #fff;
        display: inline-block;
        letter-spacing: 0;
        padding: 10px 18px;
        border-radius: 15px;
        font-size: 30px;
        left: 0;
    }

    .sp-series .series-list .info-container .current-price-container .price {
        color: #f93204;
        display: inline-block;
        /*font-family: "PingFangSC-Bold";*/
        letter-spacing: 0;
        padding: 10px 18px;
        border-radius: 15px;
        left: 0;
        font-size: 26px;
    }

    .overlay-div {
        position: fixed;
    }

    .overlay-div .load {
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .overlay-div .load .van-loading__spinner {
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
</style>
