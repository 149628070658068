<template class="act230923">
    <div class="con" id="act230923">
        <div class="bg">
            <img class="img" src="https://qn.yiquan.com.cn/act/20231115/banner2-1.jpg" />
        </div>
        <div class="bg bg-bottom">
            <div class="inpu-form">
                <div class="item-input-item">
                    <div class="right-box right-box-2">
                        <input v-model="orderFormObj.name" :rules="[{ required: true, message: '姓名' }]" placeholder="请输入姓名">
                    </div>
                </div>
                <div class="item-input-item">
                    <div class="right-box right-box-2">
                        <input  type="number" v-model="orderFormObj.mobile" :rules="[{ required: true, message: '手机号' }]" placeholder="请输入电话">
                    </div>
                </div>
                <div class="item-input-item">
                    <div class="right-box right-box-2">
                        <div class="item-car item-alert-btn" @click="alertCar">{{orderFormObj.car}}</div>
                        <img class="arrow-icon" src="https://qn.yiquan.com.cn/act/20230914/btn-1.png" @click="alertCar" />
                    </div>
                </div>
                <div class="item-input-info">*留资成功后可至活动现场领取礼品</div>
            </div>
            <div class="submit-btn" @click="submitInfo">
                <img class="img" src="https://qn.yiquan.com.cn/act/20230914/banner2-subbtn.png" />
            </div>
        </div>
<!--        <div class="bg" @click="navToLive">-->
<!--            <img class="img" src="https://qn.yiquan.com.cn/act/20230914/banner2-1-1.jpg" />-->
<!--        </div>-->
        <div class="bg" @click="navToIndex">
            <img class="img" src="https://qn.yiquan.com.cn/act/20231115/banner2-navbtn.jpg"/>
        </div>
        <div class="bg" style="margin-top: -1px">
            <img class="img" src="https://qn.yiquan.com.cn/act/20231115/banner2-2-1.jpg" />
            <img class="img" src="https://qn.yiquan.com.cn/act/20231115/banner2-2-2.jpg" />
            <img class="img" src="https://qn.yiquan.com.cn/act/20231115/banner2-2-3.jpg" />
        </div>
        <!--        提示弹窗-->
        <van-popup v-model="alertShow" :style="{ 'background-color': 'transparent'}">
            <!--提交订单弹窗-->
            <div class="popup-box"  >
                <img class="img" src="https://qn.yiquan.com.cn/act/20230914/alert-3.jpg" />
                <div class="close-btn"  @click="alertShow = false">我知道了</div>
                <div class="close-box">
                    <img class="img" src="https://qn.yiquan.com.cn/act/20230914/close.png"  @click="alertShow= false"/>
                </div>
            </div>
        </van-popup>


        <!-- 地区选择 --->
        <van-popup v-model="localPicker" position="bottom" :close-on-click-overlay="false" safe-area-inset-bottom>
            <van-picker
                    title="请选择所在城市"
                    show-toolbar
                    :columns="columns"
                    value-key="content"
                    @cancel="localPicker = false"
                    @confirm="onLocalConfirm"
            ></van-picker>
        </van-popup>

        <!-- 车型选择 --->
        <van-popup v-model="carPicker" position="bottom" :close-on-click-overlay="false" safe-area-inset-bottom>
            <van-picker
                    title="请选择品牌"
                    show-toolbar
                    :columns="columns2"
                    value-key="content"
                    @cancel="carPicker = false"
                    @confirm="onCarConfirm"
            ></van-picker>
        </van-popup>
    </div>
</template>

<script>
    import vue from 'vue';
    import {
        Button,
        Dialog,
        List,
        Popup,
        Field,
        Toast,
        AddressEdit
    } from 'vant';

    vue.use(Button, Dialog, Popup, List, Field, Toast, AddressEdit);
    export default {
        name: 'act230923',
        data() {
            return {
                orderFormObj: {
                    name: '',
                    mobile : '',
                    city : '选择城市',
                    car: '请选择意向品牌',
                },
                alertShow: false,
                showPicker: true,
                buttonLock : false,
                pageType : 1,

                localPicker: false,
                columns: ['北京', '上海', '广州'],
                carPicker: false,
                columns2: ['别克', '雪佛兰', '凯迪拉克', 'smart', '岚图', '问界', '智己', '猛士'],
            }
        },
        created() {},
        methods: {
            /**
             * 清空
             */
            clearTop: function() {
                setTimeout(function() {
                    var scrollHeight = document.documentElement.scrollTop || document.body.scrollTop || 0;
                    window.scrollTo(0, Math.max(scrollHeight - 1, 0));
                }, 100);
            },
            // 提交信息
            submitInfo() {
                let that = this;
                if (that.btnDisable) {
                    return false;
                }
                that.btnDisable = true;
                let name = that.orderFormObj.name;
                let mobile = that.orderFormObj.mobile;
                let city = '';
                let car = that.orderFormObj.car;
                if (name.length == 0 || name.length > 6) {
                    Dialog.alert({
                        message: "请输入姓名！"
                    });
                    that.btnDisable = false;
                    return false;
                } else if (mobile.length != 11) {
                    Dialog.alert({
                        message: "请正确输入手机号！"
                    });
                    that.btnDisable = false;
                    return false;
                }

                if(car == '请选择车型'){
                    Dialog.alert({
                        message: "请正确选择意向品牌！"
                    });
                    that.btnDisable = false;
                    return false;
                }

                // 提交收货信息  partyId：正式服3 测试服5
                that.$post("/submit0923Party", {
                    openId: that.$store.getters['user/getOpenId'],
                    NAME: name,
                    MOBILE: mobile,
                    CITY: city,
                    CAR: car,
                }).then((res) => {
                    if (res.code == 200) {
                        that.btnDisable = false;

                        that.alertShow = true;
                    } else {
                        that.btnDisable = false;
                        Dialog.alert({
                            message: res.msg
                        });
                    }
                })
            },
            //改变状态
            changeTo1(){
                this.$router.push(
                    {
                        path: '/pact20230509',
                    })
            },
            closeAlert: function(){

            },
            /**
             * 弹窗地区
             */
            alertLocal: function () {
                this.localPicker = true;
            },
            /**
             * 地址选择确认
             */
            onLocalConfirm(value, key) {
                this.orderFormObj.city = value;
                this.localPicker = false;
            },
            /**
             * 弹窗车系
             */
            alertCar: function () {
                this.carPicker = true;
            },
            /**
             * 车系选择确认
             */
            onCarConfirm(value, key) {
                this.orderFormObj.car = value;
                this.carPicker = false;
            },
            /**
             * 跳转到首页
             */
            navToIndex(){
                window.location.href = "/index";
            },
            /**
             * 跳转到首页
             */
            navToLive(){
                window.location.href = "https://live.photoplus.cn/live/50569816?accessFrom=qrcode#/live";
            },
        },
    }
</script>

<style>
    /*展示内容*/
    #act230923{
        background: #191919;
        position: relative;
    }

    #act230923 .bg{
        width: 100%;
        position: relative;
    }

    #act230923 .bg.bg-bottom{
        padding-bottom: 100px;
        position: absolute;
        top: 910px;
        left: 0;
        right: 0;
        width: 100%;
    }

    #act230923 .bg img{
        width: 100%;
    }

    #act230923 .bg .submit-btn{
        font-size: 30px;
        color: #fff;
        margin: auto;
        top: 600px;
        width: 233px;
        border-radius: 3px;
        overflow: hidden;
        margin-top: 32px;
    }

    #act230923 .bg .item-input-info{
        font-size: 18px;
        line-height: 43px;
        color: #555555;
        text-align: left;
        padding: 2px 0;
        padding-left: 100px;
    }

    #act230923 .popup-box{
        margin: auto;
        width: 600px;
        padding-bottom: 300px;
    }

    #act230923 .popup-box img{
        width: 100%;
    }

    #act230923 .popup-box .inpu-form{
        padding: 0 70px;
    }

    #act230923 .popup-box .close-btn{
        background-image: linear-gradient(
                #00a2b0,
                #00a2b0),
        linear-gradient(
                #49a3ff,
                #49a3ff);
        font-size: 32px;
        color: #fff;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        top: 600px;
        width: 400px;
        padding: 20px 0;
        border-radius: 15px;
        overflow: hidden;
    }

    #act230923 .popup-box .close-box{
        width: 80px;
        position: absolute;
        left: 0;
        right: 0;
        padding-top: 50px;
        margin: auto;
    }

    #act230923 .right-box{
        margin: auto;
        margin-top: 20px;
        padding: 0 120px;
    }

    #act230923 .right-box-2{
        margin-top: 15px;
        padding: 0 100px;
        position: relative;
    }

    #act230923 .right-box input,#act230923 .right-box .item-alert-btn{
        width: 100%;
        border: none;
        background-color: rgba(255, 255, 255, 0.15);
        border-radius: 3px;
        line-height: 52px;
        padding: 0 30px;
        font-size: 22px;
        text-align: left;
        color: #fff;
    }

    #act230923 .right-box .arrow-icon{
        position: absolute;
        top: 0;
        bottom: 0;
        right: 110px;
        width: 24px;
        height: 24px;
        margin: auto;
    }

    #act230923 .right-box input::-webkit-input-placeholder{
        letter-spacing: 0;
        color: #fff;
    }
</style>
