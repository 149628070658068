<template class="livehd">
	<div class="zbhd-con">
		<div class="zbhd-hb">
			<img class="zbhd-img" v-if="bgShow" src="https://qn.yiquan.com.cn/20210723/livehd-bg.jpg" />
			<!-- <img class="zbhd-hb-banner" src="http://qn.yiquan.com.cn/web/202106/7M-banner-five.jpg"/> -->
			<!-- <div class="zbhd-hb-btn" @click="getzbhd()"></div> -->
		</div>
		<!-- 登录弹窗 -->
		<van-popup v-model="logShow" class="popupTC zbhd-logoPopup" :close-on-click-overlay='false'>
			<div class="wrapper-alert zyq-hd-wrapper" @click.stop>
				<div class="log-block six-block zyq-hd-block">
					<!-- <div class="close-btn close-btn2" @click="clickClose()">x</div> -->
					<div class="title">
						<p>填写注册信息</p>
					</div>
					<form class="submit-container zyq-submit-container" @submit.prevent="submitCustCode">
						<div class="form-input mobile-container zyq-form-input" v-if="!mobileType">
							<input placeholder="邀请码" v-model="logFormObj.code" @blur="clearTop" />
							<img src="/images/act/six/six-act-u.png">
						</div>
						<div class="form-input mobile-container zyq-form-input">
							<input placeholder="手机号" v-model="logFormObj.mobile" maxlength="11" @blur="clearTop" />
							<img src="/images/lab_m.png">
						</div>
						<div class="form-input pwd-container zyq-form-input border0">
							<div class="zyq-yzm-box">
								<input placeholder="验证码" class="yzm-input" v-model="logFormObj.token" @blur="clearTop"
									maxlength="6" />
							</div>
							<img src="/images/lab_c-2.png">
							<div class="mobile-btn yzm-btn hd-yzm-ben" @click="getMobileToken()">{{tokenBtnMsg}}</div>
						</div>
						<div class="warn-info zyq-warn-info">
							<span v-if="alertWarnLv == 1">请输入正确的手机号</span>
							<span v-else-if="alertWarnLv == 2">验证码错误</span>
							<span v-else-if="alertWarnLv == 3">{{alertWarnContent}}</span>
							<span v-else-if="alertWarnLv == 4">邀请码错误</span>
							<span v-else-if="alertWarnLv == 5">您已参加本次活动！</span>
							<span v-else-if="alertWarnLv == 6">请输入姓名</span>
						</div>
						<!-- <div class="zyq-color-red">注：收到您的预约信息后会有销售主动联系您；</div> -->

						<van-button class="zyq-submit-btn" round type="info" size="large" native-type="submit">
							<span>确认</span>
						</van-button>
					</form>
				</div>
			</div>
		</van-popup>
	</div>
</template>

<script>
	import vue from 'vue';
	import {
		Button,
		Dialog,
		List,
		Popup,
		Field,
		Toast,
		AddressEdit
	} from 'vant';

	vue.use(Button, Dialog, Popup, List, Field, Toast, AddressEdit);
	import vueSeamlessScroll from 'vue-seamless-scroll'
	let citys = {};
	export default {
		name: 'vlhd',
		components: {
			vueSeamlessScroll
		},
		data() {
			return {
				logShow: false, //登录弹窗
				tokenBtnMsg: '获取验证码',
				deadline: 60, //秒数
				btnDisable: false, //重复点击
				mobileType: false, //是否填写邀请码
				logFormObj: { //提交登录信息
					code: '',
					mobile: '',
					token: ''
				},
				alertWarnLv: 0, //提示等级 0:无提示 1：请输入正确的手机号 2：验证码错误 3：其他 4：邀请码错误 5:您已参加 6.姓名
				alertWarnContent: "", //其他提示信息
				cityWarnLv: 0, //地址信息提示 0：无提示 1、姓名不为空 2、手机号错误 3、城市为空 4、详细地址 5、邮编
				cityWarnContent: "", //地址信息提示信息
				columns: [{
						values: Object.keys(citys),
					},
					{
						values: '',
						className: 'column2',
						defaultIndex: 2
					}
				],
				bgShow: false,
			}
		},
		// 监听属性 类似于data概念
		computed: {
			defaultOption() {
				return {
					step: 0.4, // 数值越大速度滚动越快
					limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
					hoverStop: false, // 是否开启鼠标悬停stop
					direction: 1, // 0向下 1向上 2向左 3向右
					openWatch: true, // 开启数据实时监控刷新dom
					singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
					singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
					waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
				}
			}

		},
		created() {
			let that = this;
			let query = that.$route.query;
			console.log(query.browser);
			if(query.browser == true || query.browser == "true") {
				that.bgShow = true;
			}else {
				// 验证是否登录：如果未登录弹登录注册窗
				let openid = that.$store.getters['user/getOpenId'];
				let userId = that.$store.getters['user/getUserId'];
				let custId = that.$store.getters['user/getCustId'];
				// console.log(openid, userId, custId);
				if (openid != '' && userId != 0 && custId != 0) {
					// thata.logShow = true;
					//获取直播间地址
					this.getliveUrl();
				} else {
					if(userId == 0 && custId != 0) {
						that.mobileType = true;
						that.logShow = true;
					}else {
						that.logShow = true;
					}
				}
			}
		},
		methods: {
			// 页面接口
			getliveUrl() {
				this.$post('/partyGetLiveUrl', {
					openId: this.$store.getters['user/getOpenId'],
				}).then((res) => {
					if (res.code == 200) {
						window.location.href = res.data.url;
					} else {
						Dialog.confirm({
							title: '',
							message: res.msg
						});
					}
				}).catch(e => {
					
				});
			},
			// 登录相关接口
			/**
			 * 获取手机验证码
			 */
			getMobileToken: function() {
				if (this.btnDisable) {
					return false;
				}
				this.btnDisable = true;
				let mobile = this.logFormObj.mobile;
				if (mobile.length == 11) {
					this.alertWarnLv = 0;
					this.$post('/userGetRand', {
						openId: this.$store.getters['user/getOpenId'],
						mobile: mobile,
					}).then((res) => {
						if (res.code == 200) {
							//循环倒计时
							this.tokenBtnMsg = this.deadline + 's';
							this.int_btnClock = window.setInterval(() => {
								if (this.deadline <= 0) {
									window.clearInterval(this.int_btnClock);
									this.tokenBtnMsg = '获取验证码';
									this.deadline = 60;
									this.btnDisable = false;
									return;
								}
								this.deadline--;
								this.tokenBtnMsg = this.deadline + 's';
							}, 1000);
						} else {
							this.btnDisable = false;
							this.alertWarnLv = 3;
							this.alertWarnContent = res.msg;
						}
					}).catch(e => {
						this.btnDisable = false;
						this.alertWarnLv = 3;
						this.alertWarnContent = '网路异常请稍后重试';
					});
				} else {
					this.alertWarnLv = 1;
					this.btnDisable = false;
				}
				return false
			},
			/**
			 * 提交邀请码
			 * @returns {boolean}
			 */
			submitCustCode: function() {
				let id = this.id;
				let code = this.$data.logFormObj.code;
				let mobile = this.logFormObj.mobile;
				let token = this.logFormObj.token;

				if (mobile == '') {
					this.alertWarnLv = 1;
					return false
				}
				if (token == '') {
					this.alertWarnLv = 2;
					return false
				}
				let mobileType = this.mobileType;
				if (mobileType) {
					let id = this.id;
					this.submitBingMobile(id);
					return false;
				}

				if (code == '') {
					this.alertWarnLv = 4;
					return false
				}
				//请求后端判断邀请码是否正确
				// this.$data.logShow = false;
				this.$post('/userBindCust', {
					openId: this.$store.getters['user/getOpenId'],
					code: code,
				}).then((res) => {
					let that = this;
					if (res.code == 200) {
						that.$data.alertWarnLv = 0;
						// that.$data.alertWarnContent = res.msg;
						let id = this.id;
						this.mobileType = true;

						that.submitBingMobile();
					} else {
						that.$data.alertWarnLv = 3;
						that.$data.alertWarnContent = res.msg;
					}
				});

			},
			/**
			 *  提交手机号
			 * @returns {boolean}
			 */
			submitBingMobile: function() {
				//请求绑定
				// let code = this.logFormObj.code;
				let mobile = this.logFormObj.mobile;
				let token = this.logFormObj.token;
				let url = '/userBindMobile'; //注册
				// if(code == '') {
				//     this.alertWarnLv = 4;
				//     return false;
				// }
				// else {
				if (mobile.length == 11) {
					//判断验证码
					if (token == '' || typeof token == 'undefined') {
						this.alertWarnLv = 3;
						this.alertWarnContent = '请输入验证码';
						return false;
					}

					this.alertWarnLv = 0;
					this.$post(url, {
						openId: this.$store.getters['user/getOpenId'],
						code: token,
						mobile: mobile,
					}).then((res) => {
						if (res.code == 200) {
							//更新数据
							let custId = res.data.custId;
							let userId = res.data.userId;
							this.$store.commit('user/setUserId', userId);
							this.$store.commit('user/setCustId', custId);

							this.logShow = false;
							this.logFormObj = {
								mobile: '',
								token: '',
								code: ''
							};
							//获取直播间地址并跳转
							this.getliveUrl();
						} else {
							this.alertWarnLv = 3;
							this.alertWarnContent = res.msg;
						}
					})
				} else {
					this.alertWarnLv = 1;
					this.btnDisable = false;
				}
				return false;
				// }

			},
			/**
			 * ，关闭登录弹窗
			 */
			clickClose: function() {
				this.logShow = false;
				this.logFormObj = {
					mobile: '',
					token: '',
					code: ''
				};
			},
			/**
			 * 清空
			 */
			clearTop: function() {
				setTimeout(function() {
					var scrollHeight = document.documentElement.scrollTop || document.body.scrollTop || 0;
					window.scrollTo(0, Math.max(scrollHeight - 1, 0));
				}, 100);
			},
			
			// 返回直播
			linkHref() {
				// window.location.href = e;
				this.$router.go(-1); //返回
				// 在需要的地方加入下面这行代码
				wx.closeWindow();
			},
			// 提交信息
			submit(e) {
				let that = this;
				if (e == 1) {
					if (that.btnDisable) {
						return false;
					}
					that.btnDisable = true;
					let name = that.cityObj.name;
					let mobile = that.cityObj.mobile;
					let city = that.cityObj.city;
					let address = that.cityObj.address;
					let code = that.cityObj.code;
					if (name.length == 0 || name.length > 6) {
						Dialog.confirm({
							title: '',
							message: "请正确输入姓名！"
						});
						that.btnDisable = false;
						return false;
					} else if (mobile.length != 11) {
						Dialog.confirm({
							title: '',
							message: "请正确输入手机号！"
						});
						that.btnDisable = false;
						return false;
					} else if (city.length == 0) {
						Dialog.confirm({
							title: '',
							message: "请选择城市！"
						});
						that.btnDisable = false;
						return false;
					} else if (address.length == 0) {
						Dialog.confirm({
							title: '',
							message: "请填写详细地址！"
						});
						that.btnDisable = false;
						return false;
					}

					// 提交收货信息  partyId：正式服3 测试服5
					that.$post("/partyUpdateGiftOrderInfo", {
						openId: that.$store.getters['user/getOpenId'],
						partyId: 3,
						orderId: that.orderId,
						name: name,
						mobile: mobile,
						city: city,
						address: address,
						code: code,
					}).then((res) => {
						console.log(res);
						if (res.code == 200) {
							let data = res.data;
							that.show = false;
							let time = setTimeout(function() {
								that.showSubmitType = true;
								clearTimeout(time);
							}, 450);
							that.btnDisable = false;
						} else {
							that.btnDisable = false;
							Dialog.confirm({
								title: '',
								message: res.msg
							});
						}
					})
				} else {
					that.partyGetGiftOrderList();
					that.showSubmitType = false;
					let time = setTimeout(function() {
						that.showPrize = true;
						clearTimeout(time);
					}, 450);
				}
			},
			
			
			
		}
	}
</script>

<style>
	/* 直播活动样式 */
	.bgshow {
		
	}
	.zbhd-img {
		width: 100%;
		height: 100%;
	}
	.livehd {
		/* background-color: #aa1626; */
		background-color: #eee;
	}
	.zbhd-hb-banner {
		width: 100%;
	}
	/* body{
		background-color: #aa1626;
	} */
	img[src=""], img:not([src]) {
		opacity: 0;
	}
	.zbhd-con,
	.zbhd-hb {
		width: 100%;
		height: 100%;
	}

	.zbhd-hb {
		position: relative;
		/* background-color: #aa1626; */
		background-color: #eee;
	}

	.zbhd-hb-img {
		display: block;
		width: 100%;
		height: auto;
		background-color: #aa1626;
	}

	.zbhd-hb-btn {
		position: absolute;
		top: 750px;
		left: 50%;
		width: 200px;
		height: 200px;
		transform: translateX(-50%);
	}

	/* 红包弹窗样式 */
	.hbPopup {
		position: relative;
		/* display: none; */
	}

	.hbPopup-bg {
		position: fixed;
		top: 0;
		left: 0;
		display: none;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, .75);
	}

	.hbPopup-cont {
		position: fixed;
		top: 50%;
		left: 50%;
		padding: 246px 0 0;
		width: 605px;
		height: 538px;
		background: url(https://qn.yiquan.com.cn/20210723/zbhd-hb-bg.png) no-repeat;
		background-size: cover;
		transform: translateX(-50%) translateY(-50%) scale(0, 0);
		box-sizing: border-box;
		transition: all .5s;
	}

	.hbPopup-cont-text-box {
		position: relative;
		margin: 0 auto;
		width: 553px;
		height: 148px;
		background-color: #fff;
		border-radius: 10px;
	}

	.hbPopup-cont-text-box::after {
		content: "";
		position: absolute;
		top: 80px;
		left: -13.5px;
		width: 27px;
		height: 27px;
		background-color: #fe9b56;
		border-radius: 0 50% 50% 0;
	}

	.hbPopup-cont-text-box::before {
		content: "";
		position: absolute;
		top: 80px;
		right: -13.5px;
		width: 27px;
		height: 27px;
		background-color: #fe9956;
		border-radius: 50% 0 0 50%;
	}

	.hbPopup-cont-text {
		margin: 0 30px;
		padding: 30px 0 0;
		/* display: flex;
		align-items: center; */
		width: calc(100% - 60px);
		height: calc(94px);
		line-height: 64px;
		font-size: 24px;
		color: #e7351a;
		box-sizing: border-box;
		border-bottom: 1px dashed #dedede;
	}

	.hbPopup-cont-text img {
		padding-right: 10px;
		width: 250px;
		height: 35px;
		vertical-align: sub;
	}

	.hbPopup-cont-header {
		display: block;
		font-family: "黑体";
		font-size: 36px;
		font-weight: 600;
	}

	.hbPopup-cont-header-title {
		margin: 20px 0;
		display: block;
		font-family: "黑体";
		text-align: center;
		font-size: 30px;
		font-weight: 600;
	}

	.hbPopup-cont-time {
		margin: 0 30px;
		width: 100%;
		height: 54px;
		font-size: 20px;
		line-height: 54px;
		text-align: left;
	}

	.hbPopup-cont-btn {
		margin: 22px auto 0;
		width: 553px;
		height: 94px;
		line-height: 94px;
		font-size: 39px;
		color: #ffedd6;
		background: url(https://qn.yiquan.com.cn/20210723/zbhd-hb-ben-bg.png) no-repeat;
		background-size: 100%;
	}

	/* 弹出 */
	.popup-show {
		display: block;
	}

	.popup-cont-show {
		transform: translateX(-50%) translateY(-50%) scale(1, 1);
	}

	/* 信息弹窗 */
	.messagepopup {
		position: relative;
	}

	/* .messagepopup .messagepopup-bg {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, .75);
	}

	.messagepopup .messagepopup-cont {
		position: fixed;
		bottom: 0;
		left: 50%;
		padding: 0 20px;
		width: 100%;
		height: 800px;
		background-color: #fff;
		transform: translateX(-50%);
		box-sizing: border-box;
		transition: all .5s;
		border-radius: 20px 20px 0 0;
	} */

	.messagepopup-cont-header {
		margin: 14px 0 0;
		width: 100%;
		height: 80px;
		font-size: 36px;
		line-height: 80px;
		border-bottom: 1px solid #ddd;
	}

	.messagepopup-cont {
		border-top: 10px solid #f3f3f3;
	}

	.messagepopup-cont-li {
		padding: 0 20px;
		width: 100%;
		/* height: 86px; */
		line-height: 86px;
		display: flex;
		justify-content: flex-start;
		border-bottom: 1px solid #ddd;
	}

	.messagepopup-cont label {
		display: inline-block;
		width: 200px;
		text-align: left;
	}

	.messagepopup-cont textarea {
		width: calc(100% - 220px);
		height: 120px;
		line-height: 60px;
	}

	.messagepopup-cont input,
	.messagepopup-cont textarea {
		width: calc(100% - 220px);
		color: #a4a4a4;
		resize: none;
		border: none;
		outline: none;
	}

	.messagepopup-input,
	.messagepopup-textarea {
		color: #a4a4a4;
	}

	.messagepopup-xz {
		position: relative;
		width: calc(100% - 220px);
		text-align: left;
	}

	.messagepopup-xz::after {
		content: ">";
		position: absolute;
		top: 0;
		right: 0;
	}

	.messagepopup-cont-tag {
		padding: 20px 20px;
		font-size: 24px;
		line-height: 40px;
		text-align: left;
	}

	.messagepopup-cont-btn {
		margin: 0 auto;
		width: calc(100% - 40px);
		height: 88px;
		font-size: 32px;
		line-height: 88px;
		color: #fff;
		background-color: #48a3ff;
		border-radius: 10px;
	}

	/* 提交成功弹窗 */
	.submitTypePopup,
	.submitTypePopup img {
		width: 100%;
	}

	.submitTypePopup-header {
		margin: 40px 0;
		font-size: 38px;
		color: #333;
		font-weight: 600;
	}

	.submitTypePopup-text {
		margin: 0 0 120px 0;
		font-size: 28px;
		color: #999999;
	}

	.submitTypePopup-btn {
		margin: 0 auto 40px;
		width: 300px;
		height: 80px;
		line-height: 80px;
		text-align: center;
		font-size: 28px;
		color: #4d2ebe;
		border: 1px solid #4d2ebe;
		border-radius: 50px;
	}

	/* 中奖弹窗 */
	.prizePopup {
		/* position: relative; */
	}

	.prizePopup .prizePopup-img {
		margin: 0 auto;
		width: 204px;
		height: 55px;
	}

	.prizePopup-bottom {
		position: relative;
		margin-top: -160px;
		/* margin-top: -100px; */
	}

	.prizePopup-bottom2 {
		position: relative;
		/* margin-top: -160px; */
		margin-top: -95px;
	}

	.prizePopup-bottom .prizePopup-bg {
		width: 100%;
	}
	.prizePopup-cont {
		position: relative;
	}
	.prizePopup-cont,
	.prizePopup-bottom-cont {
		margin: 114px auto 0;
		padding: 26px 50px;
		width: 540px;
		height: 390px;
		border-radius: 20px;
		box-sizing: border-box;
		background-image: linear-gradient(to right, #f6dcc1, #ebb48e);
		overflow: hidden;
	}
	.prizePopup-cont2 {
		padding: 26px;
	}

	.prizePopup-cont p {
		margin: 15px 0 0;
		text-align: left;
		font-size: 24px;
		line-height: 34px;
		color: #2b2b2b;
	}

	.prizePopup-bottom-cont {
		position: absolute;
		top: 50px;
		/* top: 20px; */
		left: 50%;
		transform: translateX(-50%);
		overflow-y: hidden;
	}

	.prizePopup-bottom-cont2 {
		position: absolute;
		/* top: 50px; */
		top: 15px;
		left: 50%;
		transform: translateX(-50%);
		overflow-y: hidden;
	}

	.prizePopup-bottom-cont .item {}

	.prizePopup-bottom-cont li {
		text-align: left;
		line-height: 50px;
		font-size: 24px;
		color: #000;
	}

	.prizePopup-cont-btn {
		position: absolute;
		bottom: 130px;
		left: 50%;
		margin: 10px 0 0;
		display: inline-block;
		width: 160px;
		height: 46px;
		line-height: 46px;
		font-size: 24px;
		color: #111;
		border-radius: 20px;
		border: 1px solid #111;
		transform: translateX(-50%);
		z-index: 100;
	}
	.prizePopup-cont-btn2 {
		bottom: 50px;
	}

	#mesBox {
		position: absolute;
		top: 0;
	}

	.anim {
		transition: all 1s;
	}

	/* 登录弹窗 */
	.popupTC {
		box-sizing: border-box;
		width: 594px;
		border-radius: 20px;
	}

	.zbhd-logoPopup .mobile-btn {
		color: #9e1524 !important;
		border: 1px solid #9e1524 !important;
	}

	.zbhd-logoPopup .van-button--info {
		background-color: #9e1524;
		border: 1px solid #9e1524;
	}

	input::placeholder,
	textarea::placeholder {
		color: #a4a4a4;
	}

	/* 直播活动样式 */
</style>
