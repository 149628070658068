<template>
    <div class="brand">
        <form @submit.prevent="submitForm">
            <!--头部banner主体-->
            <van-swipe :autoplay="3000" :show-indicators="false" class="banner" >
                <van-swipe-item v-for="(image, index) in banner" :key="index">
                    <img :src="image">
                </van-swipe-item>
				<van-loading type="spinner" v-if="banner.length == 0"  class="imgload"/>
            </van-swipe>
            <div class="container-center">
                <div class="reminder-info2"></div>
                <div class="cell-container area-cell" :class="hasLocalCss">
                    <van-cell is-link @click="showLocalPopup" :value="localVal"/>
                    <i class="index-icon icon_area-five"></i>
                    <!-- <img src="/images/icon_area-five.png"> -->
                </div>
                <div class="cell-container car-cell" :class="hasMarkCss">
                    <van-cell is-link @click="showBrandPopup" :value="markVal.content"/>
                    <!-- <img :src="markVal.img"> -->
                    <i class="index-icon icon_pinpai-five"></i>
                </div>
            </div>

            <van-button class="submit-btn five-btn" type="info" size="large">确定</van-button>
        </form>
        <van-popup v-model="showPicker" position="bottom" :close-on-click-overlay="false" safe-area-inset-bottom>
            <van-picker
                    title="请选择所在城市"
                    show-toolbar
                    :columns="columns"
                    value-key = "content"
                    @change="onLocalChange"
                    @cancel="showPicker = false"
                    @confirm="onLocalConfirm"
            ></van-picker>
        </van-popup>
		
        <van-popup class="brand-popup" v-model="showPicker2" position="bottom" :close-on-click-overlay="false" safe-area-inset-bottom>
            <div class="brand-title">
                选择品牌
                <span class="close-btn" @click="showPicker2 = false">取消</span>
            </div>
            <ul class="brand-list">
                <li v-for="(item, index) in  markList" :key="index" class="brand-item" @click="onMarkConfirm(index)"><span>{{item.name}}</span><img :src="item.file"></li>
            </ul>
        </van-popup>

        <van-overlay :show="loadShow"  class="overlay-div">
            <van-loading type="spinner"  class="load"/>
        </van-overlay>
    </div>
</template>

<script>
    import vue from 'vue';
    import {Button, Cell , Icon , Swipe, SwipeItem,  Popup, Picker, Dialog} from 'vant';

    vue.use(Button).use(Cell).use(Icon).use(Swipe).use(SwipeItem).use(Popup).use(Picker).use(Dialog);

    let citys = {};
    export default {
        name: 'car-brand',
        created: function(){
			//获取token
			let token = this.$store.getters['user/getToken'];
			//判断token是否存在
			if(token == '' || token == null){
			    //不存在手动获取
			    let data = {
			        'appId' :this.$wechat.appId,
			        'openId' : this.$store.getters['user/getOpenId'],
			    };
			    this.$getToken('/getToken', data).then((res) => {
			        if(res.code == 200){
						let that = this;
			            let token = res.data.token;
			            that.$store.commit('user/setToken', token);
						//有token执行下面方法
						setTimeout(function() {
							that.commCreated();
						},600);
			        }else{
			            window.console.log('验证失败');
			        }
			    })
			}
			else {
				this.commCreated();
			}

			var custId = this.$store.getters['user/getCustId'];
			if(custId == 0){
                //重新获取用户信息状态
                this.$post('/getUserInfoByOpenId', {
                    openId: this.$store.getters['user/getOpenId']
                }).then((res) => {
                    if (res.code == 200) {
                        let data = res.data;
                        let custId = data.custId;
                        let userId = data.userId;
                        //强行附着0
                        custId = custId == 0 ? 101088: custId;

                        //更新数据
                        this.$store.commit('user/setUserId', userId);
                        this.$store.commit('user/setCustId', custId);

                        //重启刷新
                        window.location.reload();
                    } else {
                        alert(res.msg);
                    }
                });
            }
        },
        data() {
            return {
                banner: [],
                value: '',
                showPicker: false,
                showPicker2 : false,
                citys : citys,
                columns: [
                    {
                        values: Object.keys(citys),
                    },
                    {
                        values: '',
                        className: 'column2',
                        defaultIndex: 2
                    }
                ],
                localVal : "请选择所在城市",
                localVals : [],
                hasLocalCss : '',
                markList : [],
                markVal : {
                    content : '请选择车辆品牌',
                    img : '/images/icon_pinpai-five.png'
                },
                markVals : [],
                hasMarkCss : '',
                loadShow : false,
                custMini : this.$store.getters['user/getCustMini'],
				firstCity : false, //自检中是否有省份城市
            }
        },
        methods: {
			//公共创建方法-新增方法
			commCreated() {
				let that = this;
				let cityList = "";
				if(that.$store.getters['local/getCityList'] != undefined && that.$store.getters['local/getCityList'] != '') {
					cityList = JSON.parse(that.$store.getters['local/getCityList']);
				}
				
				let timestamp = Date.now();
				let timeQuantum = 86400000;
				if(cityList != '') {
					timeQuantum = Number(timestamp) - Number(cityList.timestamp)
				}
				if(timeQuantum >= 86400000 || cityList == '') {
					//获取城市信息
					that.getCityList();
				}
				else {
					citys = cityList.data;
					let brandKey = [];
					if(that.$store.getters['local/getBrandKey'] != undefined && that.$store.getters['local/getBrandKey'] != null && this.$store.getters['local/getBrandKey'].length != 0) {
						brandKey = JSON.parse(that.$store.getters['local/getBrandKey']);
					}else {
						brandKey = [0,0];
					}
					that.citys = citys;
					that.columns = [
					    {
					        values: citys,
					        className: 'column1',
							defaultIndex: brandKey[0],
					    },
						{
							values: citys[brandKey[0]]['list'],
							className: 'column2',
							defaultIndex: brandKey[1],
						}
					];
					let brandLoacl = that.$store.getters['local/getBrandLocal'];
					if(brandLoacl.length > 0){
					    let brandKey = that.$store.getters['local/getBrandKey'];
					    that.onLocalConfirm(JSON.parse(brandLoacl), JSON.parse(brandKey));
					}
				}
				
				//获取用户企业展示图
				that.$post('/v2/getUserCustBanner', {
				    'openId' : that.$store.getters['user/getOpenId'],
				    'type' : 1,
				}).then((res) => {
				    if(res.code == 200){
				        let banner = res.data;
				        if(banner.length>0){
				            let list = [];
				            let len = banner.length;
				            for(var i = 0; i < len; i++){
				                list.push(banner[i]['file'])
				            }
				            that.banner = list;
				        }else{
				            that.banner = [
				                '/images/banner1.jpg',
				            ]
				        }
				    }
				}).catch(
				    (e) =>{
				        window.console.log(e);
				    }
				);
				//用户自检
				that.$post('/userSelfCheck', {
				    'openId' : that.$store.getters['user/getOpenId'],
				}).then((res) => {
				    if(res.code == 200){
				        if(res.data.error){
				            // localStorage.clear();
				            // sessionStorage.clear();
				            //强刷新
				            // window.location.href = location.href+'?time='+((new Date()).getTime());
				        }else{
				            that.$store.commit('user/setCustMini', res.data['mini']);
							if(res.data.province == 0 || res.data.city == 0) {
								that.$data.firstCity = true;
							}
				        }
				    }
				})
			},
			//获取城市信息
			getCityList() {
				let that = this;
				that.$post('/getCityList', {
				}).then((res) => {
				    if(res.code == 200){
				        citys = res.data;
						//创建一个时间戳
						let time = Date.now();
						let cityArr = {};
						cityArr.data = citys;
						cityArr.timestamp = time;
						this.$store.commit('local/setCityList', JSON.stringify(cityArr));
				        that.citys = citys;
						let brandKey = [];
						if(this.$store.getters['local/getBrandKey'] != undefined && this.$store.getters['local/getBrandKey'] != null && this.$store.getters['local/getBrandKey'].length != 0) {
							brandKey = JSON.parse(this.$store.getters['local/getBrandKey']);
						}else {
							brandKey = [0,0];
						}
				        that.columns = [
				            {
				                values: citys,
				                className: 'column1',
								defaultIndex: brandKey[0],
				            },
							{
								// values: citys[0]['list'],
								values: citys[brandKey[0]]['list'],
								className: 'column2',
								defaultIndex: brandKey[1],
							}
				        ];
				        let brandLoacl = this.$store.getters['local/getBrandLocal'];
				        if(brandLoacl.length > 0){
				            let brandKey = this.$store.getters['local/getBrandKey'];
				            this.onLocalConfirm(JSON.parse(brandLoacl), JSON.parse(brandKey));
				        }
				    }else{
				        Dialog.alert({
				            message: res.msg
				        }).then(() => {
				            // on close
				        });
				    }
				}).catch(
				    (e) =>{
				        window.console.log(e);
				    }
				);
			},
			//获取经纬度坐标
			getLocation: function() {
				let that = this;
				//获取地理位置
				wx.ready(function () {
					wx.checkJsApi({
						jsApiList: [
							'getLocation'
						],
						success: function (res) {
							if (res.checkResult.getLocation == false) {
								alert('你的微信版本太低，不支持微信JS接口，请升级到最新的微信版本！');
								return;
							}
						}
					}); 
					wx.error(function(res){
						that.$data.showPicker = true;
					});
					wx.getLocation({
						success: function (res) {
							var lat = res.latitude;
							var lon = res.longitude;
							var key = "25ZBZ-OIU6D-5CL4J-HQTJM-QITQO-YPFYM";
								// o6KgywD65MUvDIL7pX9G6jLHUhnI
							that.getCoderInfo(lat,lon);
						},
						cancel: function (res) {
							//当无法获取当前位置是执行 弹窗城市地区
							that.$data.showPicker = true;
						},
						fail: function (res) {
							//当无法获取当前位置是执行 弹窗城市地区
							that.$data.showPicker = true;
						}
					});
				});
			},
			//通过经纬度获取位置信息
			getCoderInfo: function(lat,lon) {
				this.$post('/getCoderInfo', {
					location: lat+','+lon
				}).then((res) => {
					let that = this;
					let data = res.data;
				    if(res.code == 200){
				        let province = data.address_component.province;
						let city = data.address_component.city;
						let district = data.address_component.district;
			
						let provinces = ['上海市','北京市','天津市'];
			            let citys = ['上海市','北京市','天津市','杭州市','宁波市','温州市','湖州市','嘉兴市','绍兴市','金华市','衢州市','舟山市','台州市','丽水市','重庆市'];
						for(var i =0;i<provinces.length;i++) {
							if(province == provinces[i]) {
								province = province.split("市")[0];
								break;
							}
							else {
								province = province.split("省")[0];
							}
						}
						
						for(var v = 0; v < citys.length; v++) {
							if(city == citys[v]) {
								break;
							}else {
								city = city.split("市")[0];
							}
						}
						that.localVal = province+"-"+city;
						that.getLocalId(city);
					}else{
				        Dialog.alert({
				            message: res.msg
				        }).then(() => {
				            // on close
				        });
					}
					
				}).catch(
				    (e) =>{
						//当无法获取当前位置是执行 弹窗城市地区
						this.$data.showPicker = true;
				        window.console.log(e);
				    }
				);
			},
			//获取地址匹配Id信息
			getLocalId(cityName) {
				this.$post('/getLocalId', {
					cityName: cityName
				}).then((res) => {
					let data = res.data;
					if(res.code == 200){
						//http://192.168.20.190:8080/act/20201111vl
						if(data.provincesId == 0 || data.cityId == 0) {
							this.$data.showPicker = true;
							return false;
						}
						let provinceId = data.provincesId;
						let cityId = data.cityId;
						let loc = this.citys;
						let localVal = this.localVal;
						let xhprovince = 0;
						let xhcity = 0;
						let valStr = [];
						let brandkey = [];
						for(var i = 0; i < loc.length; i++) {
							if(provinceId == loc[i].id) {
								// valStr = JSON.stringify(loc[i]);
								valStr = loc[i];
								xhprovince = i;
							}
						}
						let localVals = [];
						localVals.push(valStr);
						for(var o = 0; o < valStr["list"].length; o++) {
							if(cityId == valStr["list"][o].id) {
								localVals.push(valStr["list"][o]);
								xhcity = o;
							}
						}
						this.localVals = localVals;
						brandkey = [xhprovince, xhcity];
						this.$store.commit('local/setProvinceId', provinceId);
						this.$store.commit('local/setCityId', cityId);
						this.$store.commit('local/setLocal', localVal);
						this.$store.commit('local/setBrandLocal', JSON.stringify(localVals));
						this.$store.commit('local/setBrandKey', JSON.stringify(brandkey));
						//获取最新的地址
						//this.getCityList();
					}else{
						Dialog.alert({
							message: res.msg
						}).then(() => {
							// on close
						});
					}
				}).catch(
					(e) =>{
						//当无法获取当前位置是执行 弹窗城市地区
						this.$data.showPicker = true;
						window.console.log(e);
					}
				);
			},
            //保存用户所在省份城市
			saveUserCity() {
				let provinceId = this.$store.getters['local/getProvinceId'];
				let cityId = this.$store.getters['local/getCityId'];
				let userId = this.$store.getters['user/getUserId'];
				console.log(provinceId,cityId,userId);
				this.$post('/saveUserFromCity', {
				    userId : userId,
				    provinceId :provinceId,
				    cityId : cityId
				}).then((res) => {
				    if(res.code == 200){
						
				    }else{
				        Dialog.alert({
				            message: res.msg
				        }).then(() => {
				            this.loadShow = false;
				            // on close
				        });
				    }
				}).catch(
				    (e) =>{
				        window.console.log(e);
				    }
				);
			},
			/**
             * 展示地址底部弹窗
             */
            showLocalPopup(){
                //如果存储里面有地理位置则弹选择城市
                let provinceId = this.$store.getters['local/getProvinceId'];
                let cityId = this.$store.getters['local/getCityId'];
                let local = this.$store.getters['local/getLocal'];
                // if(provinceId == null && cityId == null && local == null) {
                // 	this.getLocation();
                // }else {
                	this.$data.showPicker = true;
                // }
            },
            /**
             * 品牌弹窗
             */
            showBrandPopup(){
                if(this.localVals.length <= 0 || (typeof this.localVals[0]['id'] == 'undefined') || (typeof this.localVals[1]['id'] == 'undefined')){
                    Dialog.alert({
                        message: '请选择所在城市'
                    }).then(() => {
                        // on close
                    });
                    return false;
                }
                let provinceId = this.localVals[0]['id'];
                let cityId = this.localVals[1]['id'];
                this.loadShow = true
                //获取品牌信息
                this.$post('/getMarkList', {
                    custId : this.$store.getters['user/getCustId'],
                    provinceId :provinceId,
                    cityId : cityId
                }).then((res) => {
                    if(res.code == 200){
                        this.markList = res.data;
                        this.loadShow = false;
                        this.$data.showPicker2 = true;
                    }else{
                        Dialog.alert({
                            message: res.msg
                        }).then(() => {
                            this.loadShow = false;
                            // on close
                        });
                    }
                }).catch(
                    (e) =>{
                        window.console.log(e);
                    }
                );
            },
            /**
             * 选择品牌
             */
            onMarkConfirm(index){
                this.markVals = this.markList[index];
                this.markVal['content'] = this.markVals['name'];
                this.markVal['img'] = this.markVals['file'];
                this.hasMarkCss = 'hasLocalCss';
                this.$data.showPicker2 = false;
            },
            /**
             * 地址选择变化
             */
            onLocalChange(picker) {
                let indexs = picker.getIndexes();
                picker.setColumnValues(1, this.citys[indexs[0]]['list']);
            },
            /**
             * 地址选择确认
             */
            onLocalConfirm(value, key){
                this.localVal = value[0]['content'] + '-' + value[1]['content'];
                this.localVals = value;
                let valStr = JSON.stringify(value);
                this.$store.commit('local/setBrandLocal', valStr);
                this.$store.commit('local/setBrandKey', JSON.stringify(key));
                this.hasLocalCss = "hasLocalCss";
                this.$data.showPicker = false;
				if(this.firstCity) {
					console.log(this.firstCity);
					this.saveUserCity();
				}
            },
            /**
             * 提交表单
             * @returns {boolean}
             */
            submitForm(){
                if(this.localVals.length <= 0 || (typeof this.localVals[0]['id'] == 'undefined') || (typeof this.localVals[1]['id'] == 'undefined')){
                    Dialog.alert({
                        message: '请选择所在城市'
                    }).then(() => {
                        // on close
                    });
                    return false;
                }
                if(this.markVals.length <= 0 || (typeof this.markVals['id'] == 'undefined')){
                    Dialog.alert({
                        message: '请选择品牌'
                    }).then(() => {
                        // on close
                    });
                    return false;
                }
                let provinceId = this.localVals[0]['id'];
                let cityId = this.localVals[1]['id'];
                let markId = this.markVals['id'];

                this.$store.commit('local/setProvinceId', provinceId);
                this.$store.commit('local/setCityId', cityId);
                this.$store.commit('local/setLocal', this.localVal);

                this.$router.push({
                    path: "/series",
                    query: {
                        markId : markId
                    }
                });
                return false;
            }
        }
    }
</script>

<style>
    /*主体*/
    .brand {
        background: #fff;
        height: 100%;
    }
    .brand span{
        margin: auto;
    }
    .brand  .banner {
        width: 100%;
    }
    .brand .banner img{
        width: 100%;
    }
    .brand .container-center{
        width: 100%;
        padding: 0 56px;
        margin-top: 100px;
        color: #999;
    }
    .brand .container-center .reminder-info{
		position: relative;
		display: block;
        /*font-family: "PingFangSC-Medium";*/
        font-size: 30px;
        font-weight: normal;
        font-stretch: normal;
        text-align: left;
        /* color: #f93204; */
		color: #ff4800;
        margin-bottom: 40px;
		z-index: 1;
        height: 30px;
    }
    .brand .container-center .reminder-info2{
        position: relative;
        display: block;
        /*font-family: "PingFangSC-Medium";*/
        font-size: 30px;
        font-weight: normal;
        font-stretch: normal;
        text-align: left;
        /* color: #f93204; */
        color: #ff4800;
        margin-bottom: 40px;
        z-index: 1;
    }
	.brand .container-center .reminder-info::before {
		content: "";
		position: absolute;
		left: 0;
		bottom: 0;
		display: block;
		width: 80%;
		height: 10px;
		background-color: #ffe7e7;
		z-index: -1;
	}
    .brand .container-center .cell-container{
        height: 100px;
        border-bottom: 1px solid #e7e7e7;
        border-top: 1px solid #e7e7e7;
        box-sizing: content-box;
        position: relative;
    }
    .brand .container-center .area-cell{
        border-bottom: none;
    }
    .brand .container-center .van-cell{
        height: 100px;
        padding-left: 100px;
    }
    .brand .container-center .van-cell .van-cell__value{
        color: #979797;
        margin: auto;
    }
    .brand .cell-container .van-cell__right-icon{
        margin: auto;
    }
    .brand .container-center .hasLocalCss .van-cell__value{
        color: #000;
    }
    .brand .container-center .cell-container img{
        position: absolute;
        left: 20px;
        top: 0;
        bottom: 0;
        margin: auto 0;
        height: 42px;
        width: 42px;
    }
    .brand .submit-btn{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 105px;
        line-height: 105px;
    }
    /*选择品牌弹窗*/
    .brand-popup{
        padding-bottom: 12px;
        max-height: 100%;
    }
    .brand-popup .brand-title{
        height: 82px;
        line-height: 82px;
        color: #4e4a49;
        text-align: center;
        font-style: 30px;
        border-bottom: 1px solid #e7e7e7;
    }
    .brand-popup .brand-title .close-btn{
        position: absolute;
        color: #000;
        font-style: 24px;
        right: 18px;
    }

    .brand  .brand-list{
        height: 980px;
        overflow-y: scroll;
        overflow-scrolling: touch;
    }
    .brand-list .brand-item{
        position: relative;
        height: 150px;
        width: 100%;
        border-bottom: 1px solid #e7e7e7;
        line-height: 150px;
        text-align: left;
        padding-left: 180px;
    }
    .brand-list .brand-item img{
        width: 100px;
        height: 100px;
        position: absolute;
        left: 36px;
        top: 0;
        bottom: 0;
        margin: auto 0;
    }
    .overlay-div{
        position: fixed;
    }
    .overlay-div .load{
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
    .overlay-div .load .van-loading__spinner{
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    /*five*/
    .brand .submit-btn.five-btn{
        background-image: linear-gradient(-51deg,
        #ff1243 0%,
        #eb0031 100%),
        linear-gradient(
                #eb0031,
                #eb0031);
        box-shadow: 0px 11px 40px 0px rgba(229, 26, 4, 0.38);
        border: none;
        position: relative;
        margin-top: 120px;
        border-radius: 50px;
        width: 630px;
    }
	/* 后续追加 */
    .brand .banner {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 400px;
		background-color: #F7F7F7;
	}
	.van-swipe__track {
		display: flex;
	}
	.imgload {
		position: relative;
		top: 0;
		bottom: 0;
		left: 50%;
		right: 0;
		margin: auto;
		transform: translateX(-50%);
	}
	/* 雪碧图 */
	.index-icon {
		position: absolute;
		left: 20px;
		top: 0;
		bottom: 0;
		margin: auto 0;
		height: 42px;
		width: 42px;
	}
	.icon_area-five {
		background: url("https://qn.yiquan.com.cn/web/common/index-icon.png") no-repeat 0 0;
		background-size: 92px 41px;
	}
	.icon_pinpai-five {
		background: url("https://qn.yiquan.com.cn/web/common/index-icon.png") no-repeat -52px 0;
		background-size: 92px 41px;
	}
</style>
