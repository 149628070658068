<template class="act221012">
    <div class="con" id="act221012">
        <div class="bg">
            <img class="img" src="https://qn.yiquan.com.cn/act/20221012/1019/all-page.jpg" />
        </div>
    </div>
</template>

<script>
    import vue from 'vue';
    import {
        Button,
        Dialog,
        List,
        Popup,
        Field,
        Toast,
        AddressEdit
    } from 'vant';

    vue.use(Button, Dialog, Popup, List, Field, Toast, AddressEdit);
    export default {
        name: 'act221012',
        data() {
            return {
                orderFormObj: {
                    name: '',
                    mobile : ''
                },
                showPicker: true,
                buttonLock : false,
                pageType : 2
            }
        },
        created() {
            let that = this;
        },
        methods: {
            /**
             * 清空
             */
            clearTop: function() {
                setTimeout(function() {
                    var scrollHeight = document.documentElement.scrollTop || document.body.scrollTop || 0;
                    window.scrollTo(0, Math.max(scrollHeight - 1, 0));
                }, 100);
            },
        },
    }
</script>

<style>
    /*展示内容*/
    #act221012{
    }

    #act221012 .bg{
        width: 100%;
        position: relative;
    }

    #act221012 .bg img{
        width: 100%;
    }
</style>
