<template>
	<div class="dycontent">
		<img src="/images/act/hd/index.jpg" alt="">
	</div>
</template>

<script>
	export default {
		name: 'dyhd',
		data() {
			return {
				
			}
		},
		methods: {
			
		}
	}
</script>

<style>
	.dycontent img {
		width: 100%;
		height: auto;
	}
</style>
