<template>
    <div class="sp-detail">
        <!--    头部banner主体-->
        <van-swipe :autoplay="3000" indicator-color="#656565" class="banner">
            <van-swipe-item v-for="(image, index) in info.banner" :key="index">
                <img :src="image.file">
            </van-swipe-item>
        </van-swipe>
        <div class="car-info">
            <p class="car-name">{{info['mark_name']}} <span v-if="info.year != '' && info.year != null ">{{info['year']}}款</span> {{info['type_name']}} {{info['config_info']}}</p>
             <p class="guide-price">厂商指导价: <span> {{info['guide_price']}}</span></p>
        </div>
        
        <label v-if="info['showSubCard']">
            <div class="price-info">
                <img class="card-bg" :src="cardBg">
                <label>
                    <div class="down-price">立减：{{info['sub']}}</div>
                    <div class="current-price">¥: <span>{{info['sub_price']}}</span></div>
                </label>
            </div>
        </label>
        <label v-else>
            <div class="price-info" v-if="cardBg != ''">
                <img class="card-bg" :src="cardBg">
                <label v-if="info['CHANGEPRICE'] == 1">
                    <div class="current-price changeInfo"> <span>{{info['CHANGEINFO']}}</span></div>
                </label>
                <div class="other-gift-info" v-if="info['OTHERINFO'] != '' && info['OTHERINFO'] != null">
                    <label v-if="info.OTHERINFO == '此优惠价格已包含国补'">
                    </label>
                    <label v-else>
                        <img class="other-img" src="/images/car/gift-2.png">
                        <p class="other-info-content">{{info['OTHERINFO']}}</p>
                    </label>
                </div>
            </div>
        </label>




        <div class="ensure-info">
            <div class="title">一圈保证</div>
            <img class="ensure-contnet" src="/images/yqtBz1.png">
        </div>
        <div class="other-info">
            <van-tabs v-model="active" color="#1b8fef" title-active-color="#1b8fef">
                <van-tab title="政策详情"><span class="base-info" v-html="info.content.policy"></span></van-tab>
<!--    series?markId=2017            <van-tab title="购车流程"><span class="base-info" v-html="info.content.process"></span></van-tab>-->
<!--                <van-tab title="车型配置"><span class="base-info" v-html="info.content.config"></span></van-tab>-->
            </van-tabs>
        </div>
        <div class="buy-btn" v-if="info.is_sold_out == 1">
            <van-button type="info" size="noraml" @click="navToBuy">我要购车</van-button>
        </div>
        <div class="buy-btn buy-btn-soldout" v-else-if="info.is_sold_out == 2">
<!--            <van-button type="info" size="noraml" @click="navToBuy">已售罄</van-button>-->
            <van-button type="default" size="noraml" disabled >已售罄</van-button>
        </div>

        <van-overlay :show="loadShow" class="overlay-div">
            <van-loading type="spinner" class="load"/>
        </van-overlay>
        <!--        <link rel="stylesheet" type="text/css" href="http://car.yiquanvip.com/jslib/img/xiangqing.css"/>-->
        <link rel="stylesheet" type="text/css" href="http://api.yiquantuan.cn/jslib/img/xiangqing.css"/>
    </div>
</template>

<script>
    import vue from 'vue';
    import {Swipe, List, Button, Tab, Tabs, Dialog} from 'vant';

    vue.use(Swipe).use(List).use(Button).use(Tab).use(Tabs).use(Dialog);

    export default {
        name: 'sp-detail',
        data() {
            return {
                info: {
                    banner: [
                        {file : '/images/goods-big3.jpg'},
                    ],
                    mark_name : '-',
                    year : '',
                    type_name : '-',
                    config_info : '-',
                    content : {
                        config : '',
                        policy : '',
                        process : ''
                    }
                },
                active: 0,
                loadShow: false,
                cardBg: ''
            }
        },
        created: function () {
            this.loadShow = true;

            let query = this.$route.query;
            if (typeof query.markId == 'undefined' || typeof query.markTypeId == 'undefined' || typeof query.id == 'undefined') {
                this.$router.replace({
                    path: '/index'
                });
                return false;
            }

            let markId = query.markId;
            // let markTypeId = query.markTypeId;
            let typeId = query.id;
            let provinceId = this.$store.getters['local/getProvinceId'];
            let cityId = this.$store.getters['local/getCityId'];
            let custId = this.$store.getters['user/getCustId'];
            let userId = this.$store.getters['user/getUserId'];

            if ((typeof provinceId == 'undefined' || provinceId == 0 || provinceId == null) || (typeof cityId == 'undefined' || cityId == 0 || cityId == null)) {
                this.$router.replace({
                    path: '/index'
                });
                return false;
            }

            this.$post('getCarInfo', {
                provinceId: provinceId,
                cityId: cityId,
                custId: custId,
                markId: markId,
                typeId: typeId,
                userId: userId,
                type  : query.type
            }).then((res) => {
                if (res.code == 200) {
                    let data = res.data;
                    data['sub'] = this.numberFormat(data['guide_price'] - data['sub_price']);
                    data['guide_price'] = this.numberFormat(data['guide_price']);
                    data['sub_price'] = this.numberFormat(data['sub_price']);
                    console.log(data);
                    if(data.showSubCard == 0){
                        this.cardBg = data.bannerFile;
                    }else{
                        if(custId == 101088){
                            this.cardBg = 'http://qn.yiquan.com.cn/web/spring2021/diban3.png';
                        }else{
                            this.cardBg = 'http://qn.yiquan.com.cn/web/spring2021/diban2.png';
                        }
                    }
                    this.info =data;
                    this.loadShow = false;
                } else {
                    Dialog.alert({
                        message: res.msg
                    }).then(() => {
                    });
                }
            });
        },
        methods: {
            navToBuy() {
                this.$router.push({
                    path: 'selection',
                    query:{
                        markId : this.$route.query.markId,
                        markTypeId: this.$route.query.markTypeId,
                        id: this.$route.query.id,
                    }
                })
            },
            numberFormat(value) {
                var param = {};
                var k = 10000,
                    sizes = ['', '万', '亿', '万亿'],
                    i;
                if(value < k){
                    param.value =value;
                    param.unit=''
                }else{
                    i = Math.floor(Math.log(value) / Math.log(k));

                    param.value = ((value / Math.pow(k, i)));
                    param.unit = sizes[i];
                }
                // return param;
                return param.value+param.unit;
            }
        }
    }
</script>

<style>
    /*车详情页*/
    .sp-detail {
        background: #fff;
        position: relative;
        padding-bottom: 130px;
    }

    .sp-detail .banner {
        width: 100%;
    }

    .sp-detail .banner img {
        width: 100%;
    }

    .sp-detail .car-info {
        width: 100%;
        padding: 0 30px;
    }

    .sp-detail .car-info .car-name {
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        color: #000;
        /*font-family: "PingFangSC-Bold";*/
    }

    .sp-detail .car-info .guide-price {
        color: #858585;
        font-size: 28px;
        margin-top: 30px;
        text-align: left;
    }

    .sp-detail .car-info .guide-price>span{
        color: #F00;
    }

    .sp-detail .price-info {
        margin-top: 20px;
        width: 100%;
        padding: 0 30px;
        color: #fff;
        position: relative;
    }

    .sp-detail .price-info .card-bg {
        width: 100%;
    }

    .sp-detail .price-info .down-price {
        position: absolute;
        /*font-family: "PingFangSC-Medium";*/
        top: 52px;
        left: 190px;
    }

    .sp-detail .price-info .current-price {
        position: absolute;
        /*font-family: "PingFangSC-Bold";*/
        top: 168px;
        left: 80px;
    }

    .sp-detail  .price-info .current-price span {
        font-size: 50px;
    }

    .sp-detail .ensure-info {
        width: 100%;
    }

    .sp-detail  .ensure-info .title {
        background-color: #f2f9ff;
        /*font-family: "PingFangSC-Bold";*/
        font-size: 30px;
        font-weight: normal;
        font-stretch: normal;
        color: #4a4a4a;
        padding: 30px;
        text-align: left;
    }

    .sp-detail .ensure-info .ensure-contnet {
        width: 100%;
    }

    .sp-detail .other-info {
        border-top: 10px solid #f2f9ff;
        width: 100%;
    }

    .sp-detail .other-info .base-info {
        width: 100%;
        display: block;
        line-height: normal;
        text-align: left;
        padding: 10px;

    }

    .sp-detail .other-info .base-info>div{
        margin: 0!important;
    }

    .sp-detail .other-info .base-info img {
        width: 100%;
    }

    .sp-detail .other-info .base-info img, .other-info .buy-img {
        width: 100%;
    }

    .sp-detail .buy-btn {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 20;
    }

    .sp-detail .buy-btn .van-button {
        width: 100%;
    }

    .sp-detail .buy-btn.buy-btn-soldout{
        background: gray;
    }

    .sp-detail .overlay-div {
        position: fixed;
    }

    .sp-detail .overlay-div .load {
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .sp-detail .overlay-div .load .van-loading__spinner {
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
</style>