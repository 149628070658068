<template>
	<div class="model">
		<!--    头部banner主体-->
		<div class="model-banner">
			<van-swipe :autoplay="3000" v-if="car3D.has3D == false || car360 == false" indicator-color="#fff"
				class="banner">
				<van-swipe-item>
					<img :src="series.file">
				</van-swipe-item>
			</van-swipe>
			<div class="car3D" v-else>
				<!-- <img class="car3D-icon" src="http://qn.yiquan.com.cn/web/common/360.png" alt=""> -->
				<img class="car3D-icon" src="http://qn.yiquan.com.cn/web/common/360-icon.png" alt="">
				<img id="car-img" :src="imgsrc" alt="">
				<canvas id="canvas" style="width:100%" @touchstart="doMouseDown" @tochend="tochend"
					@touchmove="doMouseMove"></canvas>
			</div>
		</div>
		<div class="series-info">
			<div class="series-name">{{series.name}}</div>
			<label v-if="series['showSubPrice']">
				<div class="guide-price">指导价:
					<span v-if="series.min_guide == series.max_guide">{{series.min_guide}}</span>
					<span v-else>{{series.min_guide}}-{{series.max_guide}}</span>
				</div>
				<div class="current-price-container" v-if="series.ACTIVITYTYPE != 1">
					<div class="price">
						<div class="price-title" v-if="custMini == '农业银行'">中国农业银行特约客户价</div>
						<div class="price-title" v-else-if="custMini ==  '一圈会员'">平台价</div>
						<div class="price-title" v-else>{{custMini}}员工价</div>

						<div class="price-num" v-if="changePrice == 1">{{changeInfo}}</div>
						<div class="price-num" v-else-if="series.min_sub == series.max_sub">{{series.max_sub}}</div>
						<div class="price-num" v-else>{{series.min_sub}}-{{series.max_sub}}</div>
					</div>
				</div>
			</label>
			<label v-else>
				<div class="current-price-container-2">
					<span class="price" v-if="changePrice == 1">{{custMini}}官方指导价: {{changeInfo}}</span>
					<span class="price" v-else-if="series.min_sub == series.max_sub">{{custMini}}官方指导价: {{series.min_guide}}</span>
					<span class="price" v-else>{{custMini}}官方指导价: {{series.min_guide}}-{{series.max_guide}}</span>
				</div>
			</label>
		</div>
		<div class="container-2">
			<ul class="nav-list">
				<li v-for="(nav, index) in  navList" :key="index" class="nav-item">
					<p v-if="index == navKey" class="active">{{nav}}</p>
					<p v-else @click="navKey = index">{{nav}}</p>
				</li>
			</ul>
			<van-list v-model="loading" :finished="finished" finished-text="没有更多了" class="model-list">
				<div class="item-container" v-for="(item, index) in list" :key="index">
					<label class="item-container-box3" v-if="item['showSubPrice']">
						<div class="show-box">
							<div class="model-name">
								<div>
									<span v-if="item.year != '' && item.year != null">{{item['year']}}款</span>{{item['config_info']}}<span class="color_red">{{item['config_info_tag']}}</span>
								</div>
								<div class="down-price-tag" v-if="item.sub != 0 && item.sub != null">
									<img class="down-img" src="http://qn.yiquan.com.cn/web/202401/series-down.png">
									<p>{{item.sub}}</p>
								</div>
							</div>
							<div class="price-box">
								<div class="guide-price">官方指导价: {{item['guide_price']}}</div>
							</div>
							<van-button plain hairline type="info" size="small" class="more-btn-2 other-box-btn" @click="navToDetail(index)">{{item['sub_price']}}</van-button>
							<img class="icb2-arrow" src="/images/icon/arrow.png">
						</div>
					</label>
					<label class="item-container-box2" v-else>
						<div class="show-box">
							<div class="model-name">
								<span v-if="item.year != '' && item.year != null">{{item['year']}}款</span>{{item['config_info']}}<span class="color_red">{{item['config_info_tag']}}</span>
							</div>
							<div class="price-box">
								<div class="guide-price">官方指导价: {{item['guide_price']}}</div>
							</div>
							<van-button plain hairline type="info" size="small" class="more-btn-2 other-box-btn" @click="navToDetail(index)">优惠政策</van-button>
							<img class="icb2-arrow" src="/images/icon/arrow.png">
						</div>
					</label>
				</div>
			</van-list>
		</div>

		<van-overlay :show="alertShow">
			<div class="wrapper" @click.stop>
				<div class="alertInfo">
					<label class="a2">
						<img class="TY-alertInfo" src="http://api.yiquan.com.cn/uploads/tanchuang4.png">
						<img class="close-btn" src="/images/car/close-btn-2.png" @click="alertShow=false">
					</label>
				</div>
			</div>
		</van-overlay>

		<van-overlay :show="alertShow2">
			<div class="wrapper" @click.stop>
				<div class="alertInfo alertInfo2">
					<label class="a2">
						<img class="TY-alertInfo" src="http://api.yiquan.com.cn/web/litter/pf/pfAlert-2.png">
						<img class="close-btn" src="/images/car/close-btn-3.png" @click="alertShow2Close">
					</label>
				</div>
			</div>
		</van-overlay>

		<van-overlay :show="loadShow" class="overlay-div">
			<van-loading type="spinner" class="load" />
		</van-overlay>
	</div>
</template>

<script>
	import vue from 'vue';
	import {
		Swipe,
		List,
		Button,
		Dialog
	} from 'vant';

	vue.use(Swipe).use(List).use(Button).use(Dialog);
	// 3D 公共变量
	var canvas = document.getElementById("canvas"),
		DPR = window.devicePixelRatio, //获取设备的物理像素比
		viewW = window.innerWidth,
		viewH = viewW * 0.6,
		cansW = viewW * DPR, //放大canvas
		cansH = viewH * DPR,
		ctx = "",
		imgArr = [], //图片数组
		curDeg = 0, //代表当前显示的图片下标
		imgTotal = 36; //图片总数
	var started = false;
	var startTime = 0; //开始时间
	var endTime = 0; //结束时间
	var distances = ""; //移动的距离
	var timeDis = ""; //时间差
	var speed = ""; //速度

	var startX = ""; //开始时的X轴
	//手指触摸起点
	var startPoint = 0,
		//手指离开终点
		tempPoint = 0,
		//滑动多长距离，这里取（canvas宽/图片总数的一半）
		//数值越大约灵敏
		distance = cansW / 70;
	var width = "";
	var height = "";
	var baseURL = ""; //图片前缀
	export default {
		name: 'car-model',
		data() {
			return {
				navList: [
					"全部车型",
				],
				navKey: 0,
				list: [],
				series: {
					img: '/images/series-1.jpg',
				},
				loading: false,
				finished: true,
				loadShow: false,
				alertInfo: '',
				alertShow: false,
				alertShow2: false,
				custMini: this.$store.getters['user/getCustMini'],
				changePrice: 0,
				changeInfo: '',
				imgsrc: "", //360 首张图片地址
				car360: true, //是否显示360
				car3D: {},
				markId: "",
			}
		},
		created: function() {
			//360 重置公共变量
			ctx = "",
				imgArr = [], //图片数组
				curDeg = 0, //代表当前显示的图片下标
				imgTotal = 36; //图片总数
			// started = false; 
			startTime = 0; //开始时间
			endTime = 0; //结束时间
			distances = ""; //移动的距离
			timeDis = ""; //时间差
			speed = ""; //速度

			startX = ""; //开始时的X轴
			//手指触摸起点
			startPoint = 0,
				//手指离开终点
				tempPoint = 0;
			let query = this.$route.query;
			if (typeof query.markId == 'undefined' || typeof query.type == 'undefined') {
				this.$router.replace({
					path: '/index'
				});
				return false;
			}

			let custId = this.$store.getters['user/getCustId'];
			if (typeof query.alert != 'undefined') {
				this.alertInfo = query.alert;
				if (query.alert == 'TYalert' && custId == 1058) {
					this.alertShow = true;
				}
				if (query.alert == 'TYalert2' && custId == 1082) {
					let time = this.$store.getters['local/getAlertFlagTime'];
					if (time == 0 || new Date(parseInt(time)).toDateString() != new Date().toDateString()) {
						this.alertShow2 = true;
					}
				}
			} else {
				this.loadShow = true;
			}
			let markId = query.markId;
			this.markId = query.markId;
			let provinceId = this.$store.getters['local/getProvinceId'];
			let cityId = this.$store.getters['local/getCityId'];
			let userId = this.$store.getters['user/getUserId'];
			let type = unescape(query.type);

			if ((typeof provinceId == 'undefined' || provinceId == 0 || provinceId == null) || (typeof cityId ==
					'undefined' || cityId == 0 || cityId == null)) {
				this.$router.replace({
					path: '/index'
				});
				return false;
			}

			this.$post('/getCarModel', {
				markId: markId,
				provinceId: provinceId,
				cityId: cityId,
				custId: custId,
				userId: userId,
				typeName: encodeURIComponent(type)
			}).then((res) => {
				if (res.code == 200) {
					let data = res.data;
					let car360 = res.data['car3D'];
					let list = res.data['list'];
					let info = res.data['info'];

					let len = list.length;

					info['max_guide'] = 0;
					info['min_guide'] = 0;
					info['max_sub'] = 0;
					info['min_sub'] = 0;
					if (len > 0) {
						for (let i = 0; i < len; i++) {
							if (info['ACTIVITYTYPE'] == 5) {
								list[i]['guide_price'] = parseFloat(list[i]['guide_price']);
								list[i]['sub_price'] = parseFloat(list[i]['sub_price']);
								list[i]['sub'] = this.numberFormat2(list[i]['guide_price'] - list[i][
									'sub_price'
								]);
								
								if(list[i]['config_info'].indexOf("【特价】") != -1 ) {
									list[i]['config_info'] = list[i]['config_info'].split('【特价】')[0];
									list[i]['config_info_tag'] = '【特价】';
								}
								
								if (list[i]['guide_price'] > info['max_guide']) {
									info['max_guide'] = list[i]['guide_price'];
								}
								if (list[i]['guide_price'] < info['min_guide'] || info['min_guide'] == 0) {
									info['min_guide'] = list[i]['guide_price'];
								}
								list[i]['guide_price'] = this.numberFormat2(list[i]['guide_price']);

								if (list[i]['sub_price'] > info['max_sub']) {
									info['max_sub'] = list[i]['sub_price'];
								}
								if (list[i]['sub_price'] < info['min_sub'] || info['min_sub'] == 0) {
									info['min_sub'] = list[i]['sub_price'];
								}
								list[i]['sub_price'] = this.numberFormat2(list[i]['sub_price']);
							} else {
								list[i]['guide_price'] = parseFloat(list[i]['guide_price']);
								list[i]['sub_price'] = parseFloat(list[i]['sub_price']);
								list[i]['sub'] = this.numberFormat(list[i]['guide_price'] - list[i][
									'sub_price']);
									
 								if(list[i]['config_info'].indexOf("【特价】") != -1 ) {
									list[i]['config_info'] = list[i]['config_info'].split('【特价】')[0];
									list[i]['config_info_tag'] = '【特价】';
								}
								
								if (list[i]['guide_price'] > info['max_guide']) {
									info['max_guide'] = list[i]['guide_price'];
								}
								if (list[i]['guide_price'] < info['min_guide'] || info['min_guide'] == 0) {
									info['min_guide'] = list[i]['guide_price'];
								}
								list[i]['guide_price'] = this.numberFormat(list[i]['guide_price']);

								if (list[i]['sub_price'] > info['max_sub']) {
									info['max_sub'] = list[i]['sub_price'];
								}
								if (list[i]['sub_price'] < info['min_sub'] || info['min_sub'] == 0) {
									info['min_sub'] = list[i]['sub_price'];
								}
								list[i]['sub_price'] = this.numberFormat(list[i]['sub_price']);
							}
							if (list[i]['CHANGEPRICE'] == 1) {
								this.changePrice = 1;
								this.changeInfo = list[i]['CHANGEINFO'];
							}
						}
					}

					if (info['ACTIVITYTYPE'] == 5) {
						info['max_guide'] = this.numberFormat(info['max_guide']);
						info['min_guide'] = this.numberFormat(info['min_guide']);
						info['max_sub'] = this.numberFormat2(info['max_sub']);
						info['min_sub'] = this.numberFormat2(info['min_sub']);
						info['sub'] = this.numberFormat2(info['sub']);
					} else {
						info['max_guide'] = this.numberFormat(info['max_guide']);
						info['min_guide'] = this.numberFormat(info['min_guide']);
						info['max_sub'] = this.numberFormat(info['max_sub']);
						info['min_sub'] = this.numberFormat(info['min_sub']);
						info['sub'] = this.numberFormat(info['sub']);
					}
					this.list = list;
					this.series = info;
					this.car3D = car360;
					this.loadShow = false;
					//3D看车
					if (data.car3D.has3D) {
						imgTotal = data.car3D.info.NUM;
						distance = cansW / imgTotal;
						baseURL = data.car3D.info.prefixPath;
						this.imgsrc = data.car3D.info.prefixPath + '/1.jpg';
						this.initCanvas(this.markId);
					} else {
						this.car360 = false;
					}
				} else {
					Dialog.alert({
						message: res.msg
					}).then(() => {
						// on close
						this.$router.replace({
							path: '/index'
						});
					});
				}
			});
		},
		methods: {
			onLoad() {
				/**
				 * 异步更新数据
				 */
				setTimeout(() => {
					for (let i = 0; i < 10; i++) {
						this.list.push(this.list.length + 1);
					}
					// 加载状态结束
					this.loading = false;

					// 数据全部加载完成
					if (this.list.length >= 40) {
						this.finished = true;
					}
				}, 500);
			},
			navToDetail(index) {
				this.$router.push({
					path: 'detail',
					query: {
						markId: this.$route.query.markId,
						markTypeId: this.list[index]['marktype_id'],
						id: this.list[index]['id'],
					},
				})
			},
			numberFormat(value) {
				var param = {};
				var k = 10000,
					sizes = ['', '万', '亿', '万亿'],
					i;
				if (value < k) {
					param.value = value
					param.unit = ''
				} else {
					i = Math.floor(Math.log(value) / Math.log(k));

					param.value = ((value / Math.pow(k, i)));
					param.unit = sizes[i];
				}
				// return param;
				return param.value + param.unit;
			},
			alertShow2Close() {
				this.alertShow2 = false;
				this.$store.commit('local/setAlertFlagTime', new Date().getTime());
			},
			numberFormat2(value) {
				var param = {};
				var k = 10000,
					sizes = ['', '万', '亿', '万亿'],
					i;
				if (value < k) {
					param.value = value
					param.unit = ''
				} else {
					i = Math.floor(Math.log(value) / Math.log(k));

					param.value = ((value / Math.pow(k, i)));
					param.unit = sizes[i];
				}

				let reg = /^[0-9]/;
				param.value = param.value.toString();
				let len = param.value.length;
				let newValue = '';
				for (var i = 0; i < len; i++) {
					let item = param.value.charAt(i);
					if (i != 0) {
						item = item.replace(reg, 'x');
					}
					newValue += item;
				}

				// return param;
				return newValue + param.unit;
			},
			//3D看车
			initCanvas(markId) {
				canvas = document.getElementById("canvas");
				ctx = canvas.getContext("2d");
				canvas.width = window.innerWidth * DPR; // window.innerWidth;  
				canvas.height = canvas.width * 0.6; //window.innerHeight; 
				width = canvas.width;
				height = canvas.height;

				var carImg = document.getElementById("car-img"),
					imgURL = "",
					imgObj = null,
					imgIndex = 0;
				carImg.onerror = () => { // 图片加载错误时的替换图片
					this.car360 = false;
				};
				//loading
				for (var i = 1; i < imgTotal + 1; i++) {
					imgURL = baseURL + "/" + i + ".jpg";
					imgObj = new Image();
					imgObj.src = imgURL;
					//将所有图片对象压入一个数组,方便调用
					imgArr.push(imgObj);
					imgObj.onload = function() {
						imgIndex++;
						if (imgIndex == imgTotal) {
							// if(viewW > 750) {
							// 	this.redraw();
							// }
							//默认图
							ctx.drawImage(imgArr[0], 0, 0, width, height); //处理图片
							if (markId == '1047') {
								var carMb = new Image();
								carMb.src = "https://qn.yiquan.com.cn/bk/car-mb.png";
								carMb.onload = function() {
									ctx.drawImage(carMb, 0, 0, width, height); //处理图片
								}
							}
						}
					}
					// if(i == imgTotal-1) {
					// 	//默认第一张图
					// 	this.drawImg(0);
					// 	break;
					// }
				}
			},
			doMouseDown(event) {
				//记录起始触摸点
				//记录起始触摸点
				startPoint = event.touches[0].clientX;
				startX = event.touches[0].clientX;
				startTime = Date.now();
				//去掉默认事件，iPhone下可去除双击页面默认跳动(翻页)效果
				event.preventDefault();
			},
			tochend(event) {
				//移动端手指离开屏幕
				//移动距离
				distances = tempPoint - startX;
				endTime = Date.now();
				//时间差
				timeDis = endTime - startTime;
				//速度
				speed = (distances / timeDis);
				var time = 0;
				var timer = setInterval(function() {
					time++;
					if (time == 7) {
						clearInterval(timer);
					} else {
						tempPoint = tempPoint + speed * time;
						if ((tempPoint - startPoint) > distance) {
							drawImg(curDeg, "left");
							startPoint = tempPoint;
						} else if ((tempPoint - startPoint) < -distance) {
							drawImg(curDeg, "right");
							startPoint = tempPoint;
						}
					}
				}, 18);
			},
			doMouseMove(event) {
				tempPoint = event.touches[0].clientX;
				//向右移动
				if ((tempPoint - startPoint) > distance) {
					this.drawImg(curDeg, "left");
					//符合距离条件移动后，将记录点设到手指最新位置
					startPoint = tempPoint;
				} else if ((tempPoint - startPoint) < -distance) { //左
					this.drawImg(curDeg, "right");
					startPoint = tempPoint;
				}
				//禁止移动页面
				event.preventDefault();
			},
			doMouseUp(event) {
				var x = event.pageX;
				var y = event.pageY;
				var canvas = event.target;
				var loc = this.getPointOnCanvas(canvas, x, y);
				if (started) {
					this.doMouseMove(event);
					startedX = -1;
					started = false;
					newfor = true;
					number = frame;
				}
			},
			doMouseleave(event) {
				if (started) {
					startedX = -1;
					started = false;
					newfor = true;
					number = frame;
				}
			},
			getPointOnCanvas(canvas, x, y) {
				var bbox = canvas.getBoundingClientRect();
				return {
					x: x - bbox.left * (canvas.width / bbox.width),
					y: y - bbox.top * (canvas.height / bbox.height)
				};
			},
			//绘图
			//参数：图片对象下标，移动方向
			drawImg(n, type) {
				if (type == "left") {
					if (curDeg > 0) {
						curDeg--;
					} else {
						curDeg = imgTotal - 1;
					}
				} else if (type == "right") {
					if (curDeg < imgTotal - 1) {
						curDeg++;
					} else {
						curDeg = 0;
					}
				}
				n = curDeg;
				ctx.clearRect(0, 0, cansW, cansH);
				//参数：图片对象，X偏移量，Y偏移量，图宽，图高
				ctx.drawImage(imgArr[n], 0, 0, width, height);
				var markId = this.markId;
				if (markId == "1047") {
					var carMb = new Image();
					carMb.src = "https://qn.yiquan.com.cn/bk/car-mb.png";
					ctx.drawImage(carMb, 0, 0, width, height); //加载图片蒙版
				}
			}
		}
	}
</script>

<style>
	/*头部车系西信息*/
	.model {
		background: #fff;
		height: 100%;
	}

	.model .banner {
		width: 100%;
	}

	.model .banner img {
		width: 100%;
	}

	.series-info {
		padding: 0 28px;
		padding-bottom: 20px;
		position: relative;
	}

	.series-info .series-name {
		overflow: hidden;
		white-space: nowrap;
		font-size: 30px;
		line-height: 32px;
		text-align: left;
		font-weight: bold;
		/*font-family: "PingFangSC-Bold";*/
	}

	.series-info .guide-price {
		color: #979797;
		font-size: 24px;
		margin-top: 30px;
		text-align: left;
	}

	.series-info .guide-price span {
		text-decoration: line-through;
	}

	/*模板1*/
	.series-info .current-price-container {
		margin-top: 20px;
		text-align: left;
		line-height: 26px;
		/*padding-left: 40px;*/
	}

	.series-info .current-price-container .tag {
		color: #fff;
		display: inline-block;
		letter-spacing: 0;
		/*padding: 10px 18px;*/
		padding-right: 18px;
		border-radius: 10px;
		left: 0;
		font-size: 26px;
	}

	.series-info .current-price-container .tag .tag-img {
		height: 44px;
		position: absolute;
		top: 0;
		left: 0;
		padding-top: 4px;
	}

	.series-info .current-price-container .tag .tag-img-icon {
		height: 44px;
		line-height: 44px;
		/*color: #ffffff;*/
		font-size: 22px;
		font-weight: bold;
		color: #ff0007;
		/*background-image: linear-gradient(-56deg,*/
		/*#ff6000 0%,*/
		/*#ff0000 100%),*/
		/*linear-gradient(*/
		/*        #ff3036,*/
		/*        #ff3036);*/
		/*background-blend-mode: normal,*/
		/*normal;*/
		/*box-shadow: 0px 4px 1px 0px rgba(243, 7, 0, 0.2);*/
		border-radius: 6px;
		padding: 0 8px;
		letter-spacing: 1px;
	}

	.series-info .current-price-container .price {
		color: #ff0007;
		font-weight: bold;
		display: inline-block;
		letter-spacing: 0;
		padding-left: 0;
		border-radius: 15px;
		font-size: 26px;
		position: absolute;
		right: 60px	;
			top: 0;
		margin: auto;
		text-align: right;
	}
	.series-info .current-price-container .price .price-title{
		font-size: 24px;
		line-height: 26px;
		color: #696969;
		padding-top: 14px;
	}
	.series-info .current-price-container .price .price-num{
		padding-top: 20px;
	}

	/*模板2*/
	.series-info .current-price-container-2 {
		position: relative;
		margin-top: 20px;
		text-align: left;
		line-height: 26px;
		/*padding-left: 40px;*/
	}

	.series-info .current-price-container-2 .tag {
		color: #fff;
		display: inline-block;
		letter-spacing: 0;
		/*padding: 10px 18px;*/
		padding-right: 18px;
		border-radius: 10px;
		left: 0;
		font-size: 26px;
	}

	.series-info .current-price-container-2 .tag .tag-img {
		height: 44px;
		position: absolute;
		top: 0;
		left: 0;
		padding-top: 4px;
	}

	.series-info .current-price-container-2 .tag .tag-img-icon {
		height: 44px;
		line-height: 44px;
		/*color: #ffffff;*/
		font-size: 22px;
		font-weight: bold;
		color: #ff0007;
		/*background-image: linear-gradient(-56deg,*/
		/*#ff6000 0%,*/
		/*#ff0000 100%),*/
		/*linear-gradient(*/
		/*        #ff3036,*/
		/*        #ff3036);*/
		/*background-blend-mode: normal,*/
		/*normal;*/
		/*box-shadow: 0px 4px 1px 0px rgba(243, 7, 0, 0.2);*/
		border-radius: 6px;
		padding: 0 8px;
		letter-spacing: 1px;
	}

	.series-info .current-price-container-2 .price {
		color: #333333;
		display: inline-block;
		letter-spacing: 0;
		padding: 10px 18px;
		padding-left: 0;
		border-radius: 15px;
		left: 0;
		font-size: 24px;
	}
	/*列表主体*/
	.container-2 {
		width: 100%;
		/*font-family: "PingFangSC-Medium";*/
	}

	.container-2 .nav-list {
		background: #f6f6f6;
		display: flex;
		white-space: nowrap;
		overflow-x: scroll;
		overflow-y: hidden;
		padding: 28px 30px;
		font-size: 28px;
		font-family: 黑体;
	}

	.container-2 .nav-list .nav-item p {
		display: inline-block;
		padding: 0 30px;
	}

	.container-2 .nav-list .nav-item .active {
		color: #333333;
		width: 100%;
		border-left: 5px solid #333333;
		padding-left: 25px;
		font-size: 30px;
		line-height: 30px;
		font-weight: bold;
	}

	.container-2 .model-list {
		padding: 0 30px;
	}

	.container-2 .model-list .item-container {
		padding-top: 42px;
		padding-bottom: 42px;
		border-bottom: 1px solid #e5e5e5;
		box-sizing: border-box;
	}

	.container-2 .model-list .item-container .model-name {
		text-align: left;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		color: #4a4a4a;
		font-size: 30px;
		line-height: 32px;
		font-weight: bold;
		font-family: 微软雅黑;
	}

	.container-2 .model-list .item-container .price-box {
		display: flex;
		height: 58px;
		padding: 14px 0;
		position: relative;
	}

	.container-2 .model-list .item-container .guide-price {
		color: #656565;
		font-size: 24px;
		/*font-family: "PingFangSC-Medium";*/
	}

	.container-2 .model-list .item-container .current-price {
		color: #f93204;
		font-weight: bold;
		font-size: 32px;
		position: absolute;
		right: 10px;
		/*font-family: "PingFangSC-Bold";*/
	}

	.container-2 .model-list .item-container .current-price2 {
		position: absolute;
		right: 10px;
		letter-spacing: 0px;
		color: #f30700;
		font-size: 30px;
	}

	.container-2 .model-list .item-container .other-box {
		display: flex;
		line-height: 50px;
		height: 50px;
		position: relative;
		margin-top: 26px;
	}

	.container-2 .model-list .item-container .other-box .down-price-box{
		/*background: #f2f9ff;*/
		/* background: rgba(255, 97, 13, 0.1); */
		background-image: url("https://qn.yiquan.com.cn/web/spring2021/jiagediban.png");
		background-repeat: no-repeat;
		background-size: 100% 100%;
		/*color: #1b8fef;*/
		/* color: #ff0000; */
		color: #b9946e;
		/* border-radius: 6px; */
		position: relative;
		padding-left: 54px;
		padding-right: 24px;
		/*font-family: "PingFangSC-Bold";*/
		font-size: 24px;
		margin-right: 14px;
	}
	.container-2 .model-list .item-container .other-box .down-price-box1 {
		background-image:url();
		padding-left: 20px;
		padding-right: 20px;
		background-color: #fff;
		border: 1px solid #b9946e;
		border-radius: 10px 0 10px 0;
	}
	.container-2 .item-container .other-box .down-price-box .down-img {
		height: 46px;
		width: 46px;
		position: absolute;
		left: 0;
		padding: 10px;
		padding-left: 20px;
	}

	.container-2 .item-container .other-box .down-price-box .down-img2 {
		height: 24px;
		width: 24px;
		top: 12px;
		left: 18px;
	}

	.container-2 .item-container .other-box .other-info {
		display: inline-block;
		position: relative;
		padding-left: 60px;
		padding-right: 14px;
		font-size: 24px;
		color: #ff0000;
		background: #ffe5e5;
		border-radius: 6px;
		letter-spacing: 0px;
	}

	.container-2 .item-container .other-box .other-img {
		position: absolute;
		width: 32px;
		height: 32px;
		left: 14px;
		top: 0;
		bottom: 4px;
		margin: auto;
	}

	.container-2 .item-container .other-box .more-btn {
		position: absolute;
		right: 0;
		color: #fff;
		background-color: #f30700;
		border-radius: 50px;
		font-size: 22px;
		padding: 0 20px;
	}

	.container-2 .item-container .other-box .van-button--plain.van-button--info {
		border: none;
	}

	.container-2 .item-container .other-box .more-btn::after {
		border-radius: 99px;
	}

	.container-2 .model-list .item-container .item-container-box2{}
	.container-2 .model-list .item-container .item-container-box2 .show-box{
		position: relative;
	}
	.container-2 .model-list .item-container .item-container-box2 .show-box .other-box-btn{
		position: absolute;
		bottom: 4px;
		right: 0;
		color: #ff0000;
		background-color: transparent;
		font-size: 24px;
		line-height: 24px;
		padding: 0 20px;
		height: 24px;
	}
	.container-2 .model-list .item-container .item-container-box2 .show-box .van-button--plain.van-button--info {
		border: none;
		padding-right: 26px;
	}
	.container-2 .model-list .item-container .item-container-box2 .show-box .other-box-btn::after {
		border-radius: 99px;
		border: none;
	}
	.container-2 .model-list .item-container .item-container-box2 .price-box{
		padding-top: 44px;
		height: auto;
		padding-bottom: 0;
	}
	.container-2 .model-list .item-container .item-container-box2 .guide-price{
		box-sizing: content-box;
	}
	.container-2 .model-list .item-container .item-container-box2 .icb2-arrow{
		position: absolute;
		height: 22px;
		bottom: 5px;
		right: 0;
	}

	.container-2 .model-list .item-container .item-container-box3{}
	.container-2 .model-list .item-container .item-container-box3 .show-box{
		position: relative;
	}
	.container-2 .model-list .item-container .item-container-box3 .show-box .other-box-btn{
		position: absolute;
		bottom: 4px;
		right: 0;
		color: #ff0000;
		background-color: transparent;
		font-size: 36px;
		line-height: 24px;
		padding: 0 20px;
		height: 24px;
		font-weight: bold;
	}
	.container-2 .model-list .item-container .item-container-box3 .show-box .van-button--plain.van-button--info {
		border: none;
		padding-right: 26px;
	}
	.container-2 .model-list .item-container .item-container-box3 .show-box .other-box-btn::after {
		border-radius: 99px;
		border: none;
	}
	.container-2 .model-list .item-container .item-container-box3 .price-box{
		padding-top: 44px;
		height: auto;
		padding-bottom: 0;
	}
	.container-2 .model-list .item-container .item-container-box3 .guide-price{
		box-sizing: content-box;
	}
	.container-2 .model-list .item-container .item-container-box3 .icb2-arrow{
		position: absolute;
		height: 22px;
		bottom: 5px;
		right: 0;
	}
	.container-2 .model-list .item-container .item-container-box3 .model-name{
		font-size: 36px;
		display: flex;
	}
	.container-2 .model-list .item-container .item-container-box3 .guide-price{
		font-size: 26px;
	}

	.container-2 .model-list .item-container .item-container-box3 .down-price-tag{
		/*background-image: url("https://qn.yiquan.com.cn/web/spring2021/jiagediban.png");*/
		/*background-repeat: no-repeat;*/
		/*background-size: 100% 100%;*/
		background: rgba(255, 61, 8, 0.08);
		color: #ff3d08;
		position: relative;
		padding-left: 24px;
		padding-right: 8px;
		font-size: 22px;
		margin-left: 14px;
	}
	.container-2 .model-list .item-container .item-container-box3 .down-price-tag .down-img {
		height: 20px;
		width: 20px;
		position: absolute;
		left: 4px;
		top: 0;
		bottom: 0;
		margin: auto;
	}

	.overlay-div {
		position: fixed;
	}

	.overlay-div .load {
		position: absolute;
		margin: auto;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}

	.overlay-div .load .van-loading__spinner {
		position: absolute;
		margin: auto;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}



	/*============弹窗===================================*/

	.model .wrapper {
		padding: 0;
	}

	.model .alertInfo .TY-alertInfo {
		width: 100%;
		padding-top: 40px;
	}

	.model .alertInfo .close-btn {
		margin-top: 80px;
		width: 40px;
		height: 40px;
	}

	.model .alertInfo2 .TY-alertInfo {
		padding-top: 0;
	}

	.model .alertInfo .close-btn {
		width: 60px;
		height: 60px;
	}

	.model-banner {
		width: 100%;
		min-height: 450px;
		display: flex;
		align-items: flex-end;
		justify-content: center;
	}

	.model .car3D-icon {
		position: absolute;
		top: 50px;
		right: 34px;
		width: 90px;
		height: auto;
	}
	.color_red {
		color: #f00;
	}
</style>
