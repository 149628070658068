<template>
	<img class="dyimg" src="/images/act/hd/eleven-end.jpg" alt="">
</template>

<script>
	export default {
		name: 'dyhd',
		data() {
			return {
				
			}
		},
		methods: {
			
		}
	}
</script>

<style>
	.dyimg {
		display: block;
		width: 100%;
		height: auto;
	}
</style>
