<template>
    <div class="series">
        <!--    头部banner主体-->
        <van-swipe :autoplay="3000" indicator-color="#fff" class="banner">
            <van-swipe-item v-for="(image, index) in banner" :key="index">
                <img :src="image['file']">
            </van-swipe-item>
			<van-loading type="spinner" v-if="banner.length == 0"  class="imgload"/>
        </van-swipe>

        <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                class="series-list"
        >
            <div class="item-container-out" v-for="(series ,index) in list" :key="index" >
                <label v-if="series.ACTIVITYTYPE == 1">
                    <div class="item-container" @click="navToModel(index)">
                        <div class="img-container">
                            <div class="down-tag" v-if="series.SUBPLICY != 0 && series.SUBPLICY != '员工特价'">
                                <img src="/images/icon/series-show-icon2.png" class="down-tag-img down-tag-img-po">
                                <div class="down-info down-info2">
                                    <p class="bt-info">补贴点数</p>
                                    <p class="down-num">{{series.SUBPLICY}}</p>
                                </div>
                            </div>
                            <div class="down-tag" v-else-if="series.SUBPLICY != 0 && series.SUBPLICY == '员工特价' && series['min_sub'] == series['max_sub'] && series['min_sub'] != 0">
                                <img src="/images/icon/series-show-icon2.png" class="down-tag-img down-tag-img-po">
                                <div class="down-info down-info2">
                                    <p class="bt-info">补贴金额</p>
                                    <p class="down-num">{{series['min_sub']}}元</p>
                                </div>
                            </div>
                            <img class="item-img" :src="series.file">
                        </div>
                        <div class="info-container">
                            <div class="series-name">{{series.name}}</div>
                            <div class="guide-price">厂商指导价: {{series['min_guide']}}-{{series['max_guide']}}</div>
                            <div class="current-price-container  current-price-container2 tag-2" >
                                <img class="tag-img" src="/images/tag/tag-2.png">
                                <span class="price" v-if="series['min_sub'] == series['max_sub']">{{series['min_sub']}}元</span>
                                <span class="price" v-else>{{series['min_sub']}}-{{series['max_sub']}}元</span>
                            </div>
                        </div>
                    </div>
                </label>
                <label v-else-if="series['showSubPrice']">
                    <!--追加仅限102047&10246&10249-->
                    <div class="item-container-3" @click="navToModel(index)">
                        <div class="img-container">
                            <img class="item-img" :src="series.file">
                        </div>
                        <div class="info-container">
                            <div class="move-box">
                                <div class="series-name">
                                    {{series.name}}
                                    <div class="cust-info-show">
                                        <label v-if="custMini == '农业银行'">中国农业银行特约客户价</label>
                                        <label v-else-if="custMini ==  '一圈会员'">平台价</label>
                                        <label v-else>{{custMini}}员工价</label>
                                        <img class="c3-arrow" src="/images/icon/arrow.png">
                                    </div>
                                </div>
                                <div class="guide-price" v-if="series['max_guide'] == series['min_guide']">指导价: {{series['min_guide']}}</div>
                                <div class="guide-price" v-else>官方指导价: {{series['min_guide']}}-{{series['max_guide']}}</div>

                                <label v-if="series['CHANGEPRICE'] == 1">
                                    <div class="price">{{series['CHANGEINFO']}}</div>
                                </label>
                                <label v-else>
                                    <div class="price" v-if="series['min_sub'] == series['max_sub']">{{series['min_sub']}}</div>
                                    <div class="price" v-else>{{series['min_sub']}}-{{series['max_sub']}}</div>
                                </label>
                            </div>
                        </div>
                    </div>
                </label>
                <label v-else>
                    <div class="item-container-2" @click="navToModel(index)">
                        <div class="img-container">
                            <label v-if="series['CHANGEPRICE'] != 1">
                                <div class="down-tag" v-if="series.ACTIVITYTYPE == 5">
                                    <img src="/images/icon/series-show-icon5.png" class="down-tag-img">
                                </div>
                                <div class="down-tag" v-else-if="series.sub != 0 && series.sub != null">
                                    <!--                                    <img src="/images/icon/series-show-icon1-five.png" class="down-tag-img down-tag-img-po">-->
                                    <img src="http://qn.yiquan.com.cn/web/spring2021/jiaobiao.png" class="down-tag-img down-tag-img-po">
                                    <!--                                <img src="/images/icon/series-show-icon1.png" class="down-tag-img down-tag-img-po">-->
                                    <div class="down-info">
                                        <p>最高立减</p>
                                        <p class="down-num">{{series.sub}}</p>
                                    </div>
                                </div>
                            </label>
                            <img class="item-img" :src="series.file">
                        </div>
                        <div class="info-container">
                            <div class="move-box">
                                <div class="series-name">{{series.name}}</div>
                                <div class="guide-price" v-if="series['max_guide'] == series['min_guide']">指导价: {{series['min_guide']}}</div>
                                <div class="guide-price" v-else>官方指导价: {{series['min_guide']}}-{{series['max_guide']}}</div>
                                <img class="c2-arrow" src="/images/icon/arrow.png">
                            </div>
                        </div>
                    </div>
                </label>

            </div>
        </van-list>

        <van-overlay :show="loadShow"  class="overlay-div">
            <van-loading type="spinner"  class="load"/>
        </van-overlay>
    </div>
</template>

<script>
    import vue from 'vue';
    import {Swipe, List, Popup, Dialog} from 'vant';

    vue.use(Swipe).use(List).use(Popup).use(Dialog);

    export default {
        name: 'car-series',
        data() {
            return {
                banner: [
                    '/images/banner201808_1.png',
                    '/images/banner201808_1.png',
                ],
                list: [],
                loading: false,
                finished: true,
                loadShow: false,
                custMini : this.$store.getters['user/getCustMini'],
                markId : 0,
            }
        },
        created:function(){
            this.loadShow = true;
            let query = this.$route.query;
            if(typeof query.markId == 'undefined'){
                this.$router.replace({
                    path : '/index'
                });
                return false;
            }

            var filter = '*',filterArray = [];
            if(typeof query.filter != 'undefined'){
                filter = query.filter != '' ? query.filter : '*';
                if(filter !== '*'){
                    filterArray = filter.split(',');
                }
            }

            let markId = query.markId;
            this.markId = markId;
            let provinceId = this.$store.getters['local/getProvinceId'];
            let cityId = this.$store.getters['local/getCityId'];
            let custId = this.$store.getters['user/getCustId'];
            let userId = this.$store.getters['user/getUserId'];

            if(typeof query.provinceId != 'undefined' && typeof query.provinceId != 'undefined'){
                let that = this;

                provinceId = query.provinceId;
                cityId = query.cityId;
                this.$store.commit('local/setProvinceId', provinceId);
                this.$store.commit('local/setCityId', cityId);

                let data = {
                    'appId' :this.$wechat.appId,
                    'openId' : this.$store.getters['user/getOpenId'],
                };
                that.$getToken('/getToken', data).then((res) => {
                    if(res.code == 200){
                        let token = res.data.token;
                        that.$store.commit('user/setToken', token);
                        //有token执行下面方法
                        setTimeout(function() {
                            that.$post('/getCarSeries', {
                                markId : markId,
                                provinceId : provinceId,
                                cityId : cityId,
                                custId : custId,
                                userId : userId
                            }).then((res) => {
                                if(res.code == 200){
                                    let list = res.data['list'],len = list.length, showList = [];
                                    if(len > 0){
                                        for (let i = 0; i < len; i++){
                                            var item = list[i];
                                            if(filter !== '*'){
                                                if(that.IsInArray(filterArray, item['name'])){
                                                    item = that.itemChange(item);
                                                    showList.push(item);
                                                }
                                            }else{
                                                item = that.itemChange(item);
                                                showList.push(item);
                                            }
                                        }
                                    }
                                    that.list = showList;
                                    that.banner = res.data['banner'];
                                    that.loadShow = false;
                                }else{
                                    Dialog.alert({
                                        message: res.msg
                                    }).then(() => {
                                        // on close
                                        that.$router.back();
                                    });
                                }
                            });
                        },600);
                    }else{
                        window.console.log('验证失败');
                    }
                })
            }else{
                if((typeof provinceId == 'undefined' || provinceId == 0 || provinceId == null) || (typeof cityId == 'undefined' || cityId == 0 || cityId == null)){
                    this.$router.replace({
                        path : '/index'
                    });
                    return false;
                }

                this.$post('/getCarSeries', {
                markId : markId,
                provinceId : provinceId,
                cityId : cityId,
                custId : custId,
                userId : userId
            }).then((res) => {
                if(res.code == 200){
                    let list = res.data['list'],len = list.length, showList = [];
                    if(len > 0){
                        for (let i = 0; i < len; i++){
                            var item = list[i];
                            if(filter !== '*'){
                                if(this.IsInArray(filterArray, item['name'])){
                                    item = this.itemChange(item);
                                    showList.push(item);
                                }
                            }else{
                                item = this.itemChange(item);
                                showList.push(item);
                            }
                        }
                    }
                    this.list = showList;
                    this.banner = res.data['banner'];
                    this.loadShow = false;
                }else{
                    Dialog.alert({
                        message: res.msg
                    }).then(() => {
                        // on close
                        this.$router.back();
                    });
                }
            });
            }

        },
        methods: {
            onLoad() {
                /**
                 * 异步更新数据
                 */
                setTimeout(() => {
                    for (let i = 0; i < 10; i++) {
                        this.list.push(this.list.length + 1);
                    }
                    // 加载状态结束
                    this.loading = false;

                    // 数据全部加载完成
                    if (this.list.length >= 40) {
                        this.finished = true;
                    }
                }, 500);
            },
            navToModel(index){
                let item = this.list[index]['name'].trim();
                let name = escape(item);

                this.$router.push({
                    path : 'model' ,
                    query:{
                        markId : this.$route.query.markId,
                        type : name
                    }})
            },
            numberFormat(value) {
                var param = {};
                var k = 10000,
                    sizes = ['', '万', '亿', '万亿'],
                    i;
                if(value < k){
                    param.value =value
                    param.unit=''
                }else{
                    i = Math.floor(Math.log(value) / Math.log(k));

                    param.value = ((value / Math.pow(k, i)));
                    param.unit = sizes[i];
                }
                // return param;
                return param.value+param.unit;
            },
            numberFormat2(value){
                var param = {};
                var k = 10000,
                    sizes = ['', '万', '亿', '万亿'],
                    i;
                if(value < k){
                    param.value =value
                    param.unit=''
                }else{
                    i = Math.floor(Math.log(value) / Math.log(k));

                    param.value = ((value / Math.pow(k, i)));
                    param.unit = sizes[i];
                }

                let reg = /^[0-9]/;
                param.value = param.value.toString();
                let len = param.value.length;
                let newValue = '';
                for (var i = 0; i < len ; i++){
                    let item = param.value.charAt(i);
                    if(i != 0){
                        item = item.replace(reg, 'x');
                    }
                    newValue += item;
                }

                // return param;
                return newValue+param.unit;
            },
            IsInArray(arr,val){
                var testStr=','+arr.join(",")+",";
                return testStr.indexOf(","+val+",")!=-1;
            },
            itemChange(item){
                if(item['ACTIVITYTYPE'] == 5){
                    item['sub'] = this.numberFormat2(item['sub']);
                    item['max_sub'] = this.numberFormat2(item['max_sub']);
                    item['min_sub'] = this.numberFormat2(item['min_sub']);
                    item['max_guide'] = this.numberFormat(item['max_guide']);
                    item['min_guide'] = this.numberFormat(item['min_guide']);
                }else{
                    item['sub'] = this.numberFormat(item['sub']);
                    item['max_sub'] = this.numberFormat(item['max_sub']);
                    item['min_sub'] = this.numberFormat(item['min_sub']);
                    item['max_guide'] = this.numberFormat(item['max_guide']);
                    item['min_guide'] = this.numberFormat(item['min_guide']);
                }

                return item;
            }
        }
    }
</script>

<style>
    /*主体*/
    .series {
        background: #fff;
        height: 100%;
    }
    .series  .banner {
        width: 100%;
    }
    .series .banner img{
        width: 100%;
    }
    /*列表主体*/
    /*模板1*/
    .series-list {
        width: 100%;
        background: #fff;
        /*font-family: "PingFangSC-Medium";*/
    }
    .series-list .item-container-out{
        width: 100%;
    }
    .series-list .item-container{
        margin-top: 36px;
        position: relative;
        padding: 0 24px;
        min-height: 260px;
        padding-left: 350px;
        padding-top: 12px;
        border-bottom: 1px solid #e5e5e5;
    }
    .series-list .item-container .img-container{
        position: absolute;
        left: 24px;
        top: 0;
        width: 240px;
    }
    .series-list .item-container .img-container .item-img{
        width: 260px;
        height: 179px;
        padding-top: 38px;
        box-sizing: content-box;
    }
    .series-list .img-container .down-tag{
        position: absolute;
        color: #fff;
        /*font-family: "PingFangSC-Medium";*/
        display: inline-block;
        border-top-left-radius: 15%;
        border-top-right-radius: 15%;
        border-bottom-right-radius: 50%;
        border-bottom-left-radius: 50%;
        font-size: 24px;
        top: -10px;
        left: 0;
    }
    .series-list .img-container .down-tag .down-num{
        margin-top: 8px;
        font-weight: bold;
    }
    .series-list .img-container .down-tag .down-tag-img{
        width: 110px;
    }
    .series-list .img-container .down-tag .down-info{
        padding-top: 22px;
        padding-bottom: 20px;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        color: #fff0d7;
    }
    .series-list .img-container .down-tag .down-info2{
        padding-top: 32px;
    }

    .series-list .img-container .down-tag .down-info2 .down-num{
        margin: 10px 0;
    }

    .series-list .img-container .down-tag .down-info .bt-info{
        font-size: 20px;
    }
    .series-list .item-container .info-container{}

    .series-list .item-container .info-container.info-container-noSubPirce{
        padding: 5% 0;
    }
    .series-list .item-container .info-container.info-container-noSubPirce .move-box{
        padding: 10% 0;
    }

    .series-list .item-container .info-container .series-name{
        overflow: hidden;
        white-space: nowrap;
        font-size: 32px;
        line-height: 40px;
        text-align: left;
        color: #4a4a4a;
        font-weight: bold;
    }
    .series-list .item-container .info-container .guide-price{
        color: #696969;
        font-size: 28px;
        margin-top: 20px;
        text-align: left;
    }
    .series-list .info-container .current-price-container{
        position: relative;
        /*margin-top: 20px;*/
        text-align: left;
        padding-top: 60px;
    }

    .series-list .info-container .current-price-container2{
        padding-left: 100px;
        padding-top: 0;
        margin-top: 20px;
    }
    .series-list .info-container .current-price-container .tag{
        background: #f30700;
        color: #fff;
        display: inline-block;
        letter-spacing: 0;
        padding: 10px 18px;
        border-radius: 15px;
        font-size: 30px;
        left: 0;
    }
    .series-list .info-container .current-price-container .tag-2{
         background: #f30700;
         color: #fff;
         display: inline-block;
         letter-spacing: 0;
         padding: 10px 18px;
         border-radius: 15px;
         left: 0;
        font-size: 26px ;
     }

    .series-list .info-container .current-price-container .tag-img{
        height: 46px;
        top: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        position: absolute;
        padding-top: 4px;
    }

    .series-list .info-container .current-price-container .tag-img-icon{
        height: 46px;
        line-height: 46px;
        top: 20px;
        left: 0;
        margin: auto;
        position: absolute;
        /*color: #ffffff;*/
        /* color: #fff0d7; */
		color: #b9946e;
        font-size: 22px;
        font-weight: bold;
        /*background-image: linear-gradient(-56deg,*/
        /*#ff6000 0%,*/
        /*#ff0000 100%),*/
        /*linear-gradient(*/
        /*        #ff3036,*/
        /*        #ff3036);*/
        /*background-blend-mode: normal,*/
        /*normal;*/
        /* box-shadow: 0px 4px 1px 0px rgba(243, 7, 0, 0.2); */
        /* border-radius: 6px; */
        padding: 0 8px;
        letter-spacing: 1px;
        background-image: url("http://qn.yiquan.com.cn/web/spring2021/danweibeijing.png");
        background-repeat:no-repeat;
        background-size: 100% 100%;
    }
    .series-list .info-container .current-price-container .price{
        color: #f93204;
        display: inline-block;
        font-weight: bold;
        letter-spacing: 0;
        padding: 10px 18px;
        border-radius: 15px;
        left: 0;
        font-size: 32px;
        line-height: 52px;
        white-space: nowrap;
        padding-left: 0;
    }
    .series-list .info-container .current-price-container.tag-2{
        font-size: 28px;
        line-height: 36px;
        letter-spacing: 0px;
        color: #f30700;
    }

    /*模板2*/
    .series-list .item-container-2{
        position: relative;
        padding: 0 30px;
        min-height: 260px;
    }
    .series-list .item-container-2 .img-container{
        position: relative;
        width: 100%
    }
    .series-list .item-container-2 .img-container .item-img{
        width: 100%;
        box-sizing: content-box;
    }
    .series-list .item-container-2 .info-container{
        text-align: left;
        padding-top: 8px;
        padding-bottom: 34px;
        padding-left: 60px;
        padding-right: 60px;
        border-bottom: 1px solid #e5e5e5;
        position: relative;
    }
    .series-list .item-container-2 .info-container .series-name{
        font-weight: bold;
        font-size: 36px;
    }
    .series-list .item-container-2 .info-container .guide-price{
        padding-top: 24px;
        color: #696969;
        font-size: 24px;
    }
    .series-list .item-container-2 .info-container .c2-arrow{
        position: absolute;
        right: 60px;
        bottom: 38px;
        height: 20px;
    }

    /*模板3*/
    .series-list .item-container-3{
        position: relative;
        padding: 0 30px;
        min-height: 260px;
    }
    .series-list .item-container-3 .img-container{
        position: relative;
        width: 100%
    }
    .series-list .item-container-3 .img-container .item-img{
        width: 100%;
        box-sizing: content-box;
    }
    .series-list .item-container-3 .info-container{
        text-align: left;
        padding-top: 8px;
        padding-bottom: 34px;
        border-bottom: 1px solid #e8e8e8;
        position: relative;
    }
    .series-list .item-container-3 .info-container .series-name{
        font-weight: bold;
        font-size: 36px;
        position: relative;
    }
    .series-list .item-container-3 .info-container .guide-price{
        padding-top: 24px;
        color: #696969;
        font-size: 24px;
    }
    .series-list .item-container-3 .info-container .price{
        color: #ff3d08;
        font-size: 24px;
        position: absolute;
        right: 0;
        bottom: 34px;
    }
    .series-list .item-container-3 .info-container .cust-info-show{
        font-weight: normal;
        font-size: 22px;
        height: 36rpx;
        display: flex;
        text-align: right;
        position: absolute;
        right: 0;
        top: 2px;
        bottom: 0;
        margin: auto;
        align-items: center;
        padding-right: 18px;
        color: #696969;
    }
    .series-list .item-container-3 .info-container .c3-arrow{
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        height: 20px;
    }

    .overlay-div{
        position: fixed;
    }
    .overlay-div .load{
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
    .overlay-div .load .van-loading__spinner{
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
</style>
