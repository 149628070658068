<template>
    <div class="books-index">
        <div class="books-list">
            <div class="books-mark-item" @click="navTolist(1)">
                <img class="one" src="/images/book/b-bk.png">
                <div class="bg bk"></div>
            </div>
            <div class="books-mark-item" @click="navTolist(3)">
                <img class="one"  src="/images/book/b-bk.png">
                <div class="bg kdlk"></div>
            </div>
            <div class="books-mark-item" @click="navTolist(2)">
                <img class="one" src="/images/book/b-bk.png">
                <div class="bg xfl"></div>
            </div>
            <div class="books-mark-item" @click="navTolist(4)">
                <img class="one" src="/images/book/b-xny.png">
                <div class="bg xny"></div>
            </div>
        </div>

        <van-overlay :show="show">
            <div class="wrapper" @click.stop>
                <div class="order-box">
                    <div class="order-title">
                        <img class="order-title-img" src="/images/book/b-banner-1.png">
                    </div>
                    <div class="order-form">
                        <form  @submit.prevent="submitForm">
                            <div class="cell-container">
                                <van-field v-model="dataForm.branch" placeholder="所属单位" />
                            </div>
                            <div class="cell-container">
                                <van-field v-model="dataForm.name" placeholder="姓名" />
                            </div>
                            <div class="cell-container">
                                <van-field v-model="dataForm.mobile" placeholder="手机号码" />
                            </div>
                            <div class="cell-container token-cell">
                                <van-field v-model="dataForm.rand" placeholder="验证码" maxlength="6"/>
                                <div class="getToken" @click="getMobileToken()">{{tokenBtnMsg}}</div>
                                <div class="warn-info">
                                    <span v-if="alertWarnLv == 1">请输入正确的手机号</span>
                                    <span v-else-if="alertWarnLv == 2">验证码错误</span>
                                    <span v-else-if="alertWarnLv == 3">{{alertWarnContent}}</span>
                                </div>
                            </div>
                            <div class="bottom-submit">
                                <van-button size="large" native-type="submit" @click="saveSubmit()">签到</van-button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </van-overlay>

        <van-popup class="branch-popup" v-model="popupShow" round  position="bottom"  :close-on-click-overlay="false" safe-area-inset-bottom>
            <div class="branch-title">
                所属单位
                <span class="close-btn" @click="popupShow = false">取消</span>
            </div>
            <ul class="branch-list">
                <li v-for="(item, index) in  branchList" :key="index" class="branch-item" @click="onBranchConfrim(item)">
                    <span>{{item}}</span>
                </li>
            </ul>
        </van-popup>

        <div class="orientation-box">
            <div class="orientation">请锁定屏幕<br>以竖屏方式浏览</div>
        </div>

        <van-overlay :show="loadShow" class="overlay-div">
            <van-loading type="spinner" class="load"/>
        </van-overlay>
    </div>
</template>

<script>
    import vue from 'vue';
    import {Button, Dialog, Overlay, Field, Popup, Cell, CellGroup} from 'vant';

    vue.use(Button, Dialog, Overlay, Popup, Cell, CellGroup, Field );
    export default {
        name: 'books-index',
        created: function(){
            //用户企业对应是否相同
            this.$post('/userCustState', {
                openId : this.$store.getters['user/getOpenId'],
            }).then((res) => {
                if(res.code == 200){
                    let invCode = 'Test200916';
                    let data =  res.data;
                    if(data.id != 0){
                        //判断是否是宝武
                        if(data.code == invCode){
                            //无任何操作
                            return false;
                        }
                    }else{
                        //直接绑定宝武
                        this.$post('/userBindCust', {
                            openId : this.$store.getters['user/getOpenId'],
                            code :  invCode,
                        }).then((res) => {
                            if(res.code == 200){
                                this.$store.commit('user/setCustId', 101087);
                            }
                        });
                    }
                }
            });

        },
        data() {
            return {
                loadShow : false,
                popupShow: false,
                show : false,
                tokenBtnMsg : '获取验证码',
                deadline : 60,
                btnDisable : false,
                alertWarnLv: 0, //提示等级 0:无提示 1：请输入正确的手机号 2：验证码错误 3：其他
                alertWarnContent : '',
                dataForm: {
                    branch: '',
                    name: '',
                    mobile : '',
                    rand : '',
                },
                branchTitle: '所属单位',
                hasBranch : '',
                branchList: [
                    '单位1', '单位2', '单位3', '单位4', '单位5', '单位6', '单位7'
                ],
            }
        },
        methods: {
            submitForm(){
                return false;
            },
            saveSubmit(){
                //请求绑定
                let branch = this.dataForm.branch;
                let name = this.dataForm.name;
                let mobile = this.dataForm.mobile;
                let code = this.dataForm.rand;

                if(branch == ''){
                    //判断所属单位
                    this.alertWarnLv = 3;
                    this.alertWarnContent = '请选择所属单位';
                    return false;
                }else if(name == ''){
                    //判断姓名
                    this.alertWarnLv = 3;
                    this.alertWarnContent = '请填写姓名';
                    return false;
                }else if(mobile == '' || !this.isPhone(mobile)){
                    //判断手机号
                    this.alertWarnLv = 1;
                    return false;
                }else if(code == '' || typeof code == 'undefined'){
                    //判断验证码
                    this.alertWarnLv = 3;
                    this.alertWarnContent = '请输入验证码';
                    return false;
                }

                let data = this.dataForm;
                data['openId'] =  this.$store.getters['user/getOpenId'];
                data['partyCode'] =  'bwjnh';

                this.$post('/submitPartyBook', data).then((res) => {
                    if(res.code == 200){
                        Dialog.alert({
                            message: '签到成功'
                        }).then(() => {
                            // on close
                            this.show= false;
                        });
                    }else{
                        Dialog.alert({
                            message: res.msg
                        }).then(() => {
                            // on close
                        });
                    }
                });
            },
            onBranchConfrim(branch){
                this.dataForm.branch = branch;
                this.branchTitle = branch;
                this.hasBranch = 'hasBranch';
                this.popupShow = false;
            },
            navTolist(booksMarkId){
                if(booksMarkId == 1 || booksMarkId == 4){
                    this.$router.push({
                        path: '/books/carlist',
                        query: {
                            booksMarkId : booksMarkId,
                            type : 1,
                        }
                    })
                }else{
                    this.$router.push({
                        path: '/books/carlist',
                        query: {
                            booksMarkId : booksMarkId,
                            type : 2,
                        }
                    })
                }
            },
            alertBranchInfo(){
                this.popupShow = true;
            },
            /**
             * 获取手机验证码
             */
            getMobileToken: function () {
                if(this.btnDisable){
                    return false;
                }
                this.btnDisable = true;
                let mobile = this.dataForm.mobile;
                if(this.isPhone(mobile)){
                    this.alertWarnLv = 0;
                    this.$post('/userGetBooksRand', {
                        openId : this.$store.getters['user/getOpenId'],
                        mobile :  mobile,
                    }).then((res) => {
                        if(res.code == 200){
                            //循环倒计时
                            this.tokenBtnMsg = this.deadline + 's';
                            this.int_btnClock = window.setInterval(() => {
                                if(this.deadline <= 0){
                                    window.clearInterval(this.int_btnClock);
                                    this.tokenBtnMsg = '获取验证码';
                                    this.deadline = 60;
                                    this.btnDisable = false;
                                    return;
                                }
                                this.deadline--;
                                this.tokenBtnMsg = this.deadline + 's';
                            },1000);
                        }else{
                            this.btnDisable = false;
                            this.alertWarnLv = 3;
                            this.alertWarnContent = res.msg;
                        }
                    }).catch(e => {
                        window.console.log(e);
                        this.btnDisable = false;
                        this.alertWarnLv = 3;
                        this.alertWarnContent = '网路异常请稍后重试';
                    });
                }else{
                    this.alertWarnLv = 1;
                    this.btnDisable = false;
                }
                return false
            },
            // 检查字符串是否为合法手机号码
            isPhone: function (str) {
                let reg = /^(1[3-9][0-9])[0-9]{8}$/;
                if (reg.test(str)) {
                    // console.log('手机号码格式输入正确');
                    return true;
                } else {
                    // console.log('请输入正确格式的手机号码');
                    return false;
                }
            },
        }
    }
</script>

<style>
    /*主体*/
    page{
        height: 100%;
    }
    .books-index {
        background: #262728;
        height: 100%;
        width: 100%;
    }
    .books-index .books-list{
        height: 100%;
        width: 100%;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
    }
    .books-index .books-list .books-mark-item{
        width: 100%;
        overflow: hidden;
        position: relative;
    }
    .books-index .books-list .books-mark-item img{
        width: 100%;
    }
    .books-index .books-list .books-mark-item .one{
        opacity: 0;
    }
    .books-index .books-list .books-mark-item .bg{
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
    }
    .books-index .books-list .books-mark-item .bg.bk{
        background-image: url("/images/book/b-bk.png");
        background-size: 100% auto;
        background-position:center;
    }
    .books-index .books-list .books-mark-item .bg.xfl{
         background-image: url("/images/book/b-xfl.png");
         background-size: 100% auto;
        background-position:center;
     }
    .books-index .books-list .books-mark-item .bg.kdlk{
          background-image: url("/images/book/b-kdlk.png");
          background-size: 100% auto;
        background-position:center;
    }
    .books-index .books-list .books-mark-item .bg.xny{
          background-image: url("/images/book/b-xny.png");
          background-size: 100% auto;
        background-position:center;
    }
    .books-index .wrapper{
        padding: 0 95px;
    }
    .books-index .wrapper .order-box{
        background: #fff;
        width: 100%;
        position: relative;
        border-radius: 20px;
        overflow: hidden;
        padding-bottom: 42px;
    }

    .books-index .wrapper .order-box .order-title{
        width: 100%;
    }

    .books-index .wrapper .order-box .order-title img{
        width: 100%;
    }

    .books-index .wrapper .order-box .order-form{
        padding: 60px 36px;
        padding-bottom: 0;
    }

    .books-index .wrapper .order-box .order-form .cell-container{
        position: relative;
        padding-top: 32px;
    }

    .books-index .wrapper .order-box .order-form .cell-container .van-cell{
        border: solid 1px rgba(160,160,160,.8);
    }

    .books-index .wrapper .order-box .order-form .cell-container:first-child{
        padding-top: 0;
    }

    .books-index .wrapper .order-box .order-form .cell-container.token-cell .van-cell{
        width: 60%;
    }

    .books-index .wrapper .order-box .order-form .cell-container.token-cell .getToken{
        color: #fff;
        display: inline-block;
        background-color: #352c2b;
        border-radius: 10px;
        position: absolute;
        right: 0;
        top: 32px;
        height: 76px;
        line-height: 76px;
        font-size: 24px;
        padding: 0 20px;
        box-shadow: none;
        width: 166px;
    }

    .books-index .wrapper .order-box .order-form .cell-container .van-cell.van-cell--clickable{
        border-radius: 10px;
        border: solid 1px #a0a0a0;
        padding: 0 25px;
        font-size: 26px;
        height: 76px;
        line-height: 76px;
        text-align: left;
        color: #999999;
    }

    .books-index .wrapper .order-box .order-form .warn-info{
        text-align: left;
        font-size: 26px;
        color: #f93204;
        height: 50px;
        line-height: 50px;
        padding-left: 60px;
    }
    
    .books-index .wrapper .order-box .order-form .cell-container.hasBranch .van-cell.van-cell--clickable{
        color: #000;
    }
    
    .books-index .wrapper .order-box .order-form .cell-container .van-cell.van-cell--clickable .van-icon{
        margin: auto 0;
        margin-left: 5px;
    }

    .books-index .wrapper .order-box .order-form .cell-container .van-field{
        border-radius: 10px;
        border: solid 1px #a0a0a0;
        padding: 0 25px;
        font-size: 26px;
        height: 76px;
        line-height: 76px;
    }

    .books-index .wrapper .order-box .order-form .cell-container .cell-title{
        text-align: left;
        color: #4a4a4a;
        font-size: 24px;
    }

    .books-index .wrapper .order-box .order-form .bottom-submit{
        margin-top: 64px;
    }

    .books-index .wrapper .order-box .order-form .bottom-submit .van-button{
        border: none;
    }

    .books-index .wrapper .order-box .order-form .bottom-submit .van-button{
        height: 78px;
        line-height: 78px;
        color: #fff;
        font-size: 28px;
        background-image: linear-gradient(90deg,
        #aa8f62 0%,
        #d8c08c 100%);
        box-shadow: 0px 6px 27px 0px
        rgba(59, 48, 51, 0.35);
        border-radius: 10px;
    }

    /*选择部门弹窗*/
    .books-index .branch-popup{
        padding-bottom: 12px;
        padding-top: 8px;
        max-height: 100%;
    }

    .books-index .branch-popup .branch-title{
        height: 82px;
        line-height: 82px;
        color: #4e4a49;
        text-align: center;
        border-bottom: 1px solid #e7e7e7;
    }

    .books-index .branch-popup .branch-title .close-btn{
        position: absolute;
        color: #000;
        right: 18px;
    }

    .books-index .branch-popup .branch-list{
        height: 980px;
        overflow-y: scroll;
        overflow-scrolling: touch;
    }

    .books-index .branch-popup .branch-list .branch-item{
        height: 120px;
        line-height: 120px;
        text-align: center;
        font-size: 40px;
        border-bottom: 1px solid #e7e7e7;
    }

    .books-index .orientation {
        display: none;
    }

    @media screen and (min-width: 420px) and (orientation: landscape){
        .books-index .orientation-box {
            position: absolute;
            background-color: #000!important;
            height: 100%;
            width: 100%;
            top: 0;
        }

        .books-index .orientation::before{
            background: url('/images/icon-bg-b-width.png') no-repeat center 40% #000;
            background-size: 100%;
            bottom: 100%;
            content: "";
            height: 60px;
            left: 50%;
            position: absolute;
            -webkit-transform: translate(-50%,-10px);
            transform: translate(-50%,-10px);
            width: 110px;
        }

        .books-index .orientation {
            color: #fff;
            display: block;
            font-size: 18px;
            line-height: 24px;
            position: absolute;
            text-align: center;
            top: 50%;
            width: 100%;
        }
    }
</style>
