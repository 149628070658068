<template>
    <div class="books-neo">
        <div class="books-list">
            <div v-for="(item, index) in  markList" :key="index" class="books-mark-item" @click="naToSeries(index)">
                <img class="one" :src="item.file">
            </div>
        </div>

        <!-- 登录弹窗 -->
        <van-popup v-model="logShow" class="popupTC logoPopup" :close-on-click-overlay='false'>
            <div class="wrapper-alert zyq-hd-wrapper" @click.stop>
                <div class="log-block six-block zyq-hd-block">
                    <!-- <div class="close-btn close-btn2" @click="clickClose()">x</div> -->
                    <div class="title">
                        <p>填写注册信息</p>
                    </div>
                    <form class="submit-container zyq-submit-container" @submit.prevent="submitCustCode">
                        <div class="form-input mobile-container zyq-form-input" v-if="!mobileType">
                            <input placeholder="邀请码" v-model="logFormObj.code" @blur="clearTop" />
                            <img src="/images/act/six/six-act-u.png">
                        </div>
                        <div class="form-input mobile-container zyq-form-input">
                            <input placeholder="手机号" v-model="logFormObj.mobile" maxlength="11" @blur="clearTop" />
                            <img src="/images/lab_m.png">
                        </div>
                        <div class="form-input pwd-container zyq-form-input border0">
                            <div class="zyq-yzm-box">
                                <input placeholder="验证码" class="yzm-input" v-model="logFormObj.token" @blur="clearTop"
                                       maxlength="6" />
                            </div>
                            <img src="/images/lab_c-2.png">
                            <div class="mobile-btn yzm-btn hd-yzm-ben" @click="getMobileToken()">{{tokenBtnMsg}}</div>
                        </div>
                        <div class="warn-info zyq-warn-info">
                            <span v-if="alertWarnLv == 1">请输入正确的手机号</span>
                            <span v-else-if="alertWarnLv == 2">验证码错误</span>
                            <span v-else-if="alertWarnLv == 3">{{alertWarnContent}}</span>
                            <span v-else-if="alertWarnLv == 4">邀请码错误</span>
                            <span v-else-if="alertWarnLv == 5">您已参加本次活动！</span>
                            <span v-else-if="alertWarnLv == 6">请输入姓名</span>
                        </div>
                        <!-- <div class="zyq-color-red">注：收到您的预约信息后会有销售主动联系您；</div> -->

                        <van-button class="zyq-submit-btn" round type="info" size="large" native-type="submit">
                            <span>确认</span>
                        </van-button>
                    </form>
                </div>
            </div>
        </van-popup>

        <div class="orientation-box">
            <div class="orientation">请锁定屏幕<br>以竖屏方式浏览</div>
        </div>

        <van-overlay :show="loadShow" class="overlay-div">
            <van-loading type="spinner" class="load"/>
        </van-overlay>
    </div>
</template>

<script>
    import vue from 'vue';
    import {Button, Dialog, Overlay, Field, Popup, Cell, CellGroup} from 'vant';

    vue.use(Button, Dialog, Overlay, Popup, Cell, CellGroup, Field );
    export default {
        name: 'books-neo',
        created: function(){
            var that = this;
            this.getNeoList();

            // 验证是否登录：如果未登录弹登录注册窗
            let openid = that.$store.getters['user/getOpenId'];
            let userId = that.$store.getters['user/getUserId'];
            let custId = that.$store.getters['user/getCustId'];

            if (openid != '' && userId != 0 && custId != 0) {
                // console.log('用户登陆成功')
            } else {
                if(userId == 0 && custId != 0) {
                    that.mobileType = true;
                    that.logShow = true;
                }else {
                    that.logShow = true;
                }
            }
        },
        data() {
            return {
                logShow: false, //登录弹窗
                tokenBtnMsg: '获取验证码',
                deadline: 60, //秒数
                btnDisable: false, //重复点击
                mobileType: false, //是否填写邀请码
                logFormObj: { //提交登录信息
                    code: '',
                    mobile: '',
                    token: ''
                },
                alertWarnLv: 0, //提示等级 0:无提示 1：请输入正确的手机号 2：验证码错误 3：其他 4：邀请码错误 5:您已参加 6.姓名
                alertWarnContent: "", //其他提示信息
                markList : [],
            }
        },
        methods: {
            getNeoList(){
                this.loadShow = true;
                //获取品牌信息
                this.$post('/bookNeoList').then((res) => {
                    if(res.code == 200){
                        this.markList = res.data;
                        this.loadShow = false;
                    }else{
                        Dialog.alert({
                            message: res.msg
                        }).then(() => {
                            this.loadShow = false;
                            // on close
                        });
                    }
                }).catch(
                    (e) =>{
                        window.console.log(e);
                    }
                );
            },
            naToSeries(index){
                var item = this.markList[index];
                this.$router.push({
                    path: "/series",
                    query: {
                        markId : item.MARKID,
                        filter : item.SERIES
                    }
                });
            },
            // 登录相关接口
            /**
             * 获取手机验证码
             */
            getMobileToken: function() {
                if (this.btnDisable) {
                    return false;
                }
                this.btnDisable = true;
                let mobile = this.logFormObj.mobile;
                if (mobile.length == 11) {
                    this.alertWarnLv = 0;
                    this.$post('/userGetRand', {
                        openId: this.$store.getters['user/getOpenId'],
                        mobile: mobile,
                    }).then((res) => {
                        if (res.code == 200) {
                            //循环倒计时
                            this.tokenBtnMsg = this.deadline + 's';
                            this.int_btnClock = window.setInterval(() => {
                                if (this.deadline <= 0) {
                                    window.clearInterval(this.int_btnClock);
                                    this.tokenBtnMsg = '获取验证码';
                                    this.deadline = 60;
                                    this.btnDisable = false;
                                    return;
                                }
                                this.deadline--;
                                this.tokenBtnMsg = this.deadline + 's';
                            }, 1000);
                        } else {
                            this.btnDisable = false;
                            this.alertWarnLv = 3;
                            this.alertWarnContent = res.msg;
                        }
                    }).catch(e => {
                        this.btnDisable = false;
                        this.alertWarnLv = 3;
                        this.alertWarnContent = '网路异常请稍后重试';
                    });
                } else {
                    this.alertWarnLv = 1;
                    this.btnDisable = false;
                }
                return false
            },
            /**
             * 提交邀请码
             * @returns {boolean}
             */
            submitCustCode: function() {
                let id = this.id;
                let code = this.$data.logFormObj.code;
                let mobile = this.logFormObj.mobile;
                let token = this.logFormObj.token;

                if (mobile == '') {
                    this.alertWarnLv = 1;
                    return false
                }
                if (token == '') {
                    this.alertWarnLv = 2;
                    return false
                }
                let mobileType = this.mobileType;
                if (mobileType) {
                    let id = this.id;
                    this.submitBingMobile(id);
                    return false;
                }

                if (code == '') {
                    this.alertWarnLv = 4;
                    return false
                }
                //请求后端判断邀请码是否正确
                // this.$data.logShow = false;
                this.$post('/userBindCust', {
                    openId: this.$store.getters['user/getOpenId'],
                    code: code,
                }).then((res) => {
                    let that = this;
                    if (res.code == 200) {
                        that.$data.alertWarnLv = 0;
                        // that.$data.alertWarnContent = res.msg;
                        let id = this.id;
                        this.mobileType = true;

                        that.submitBingMobile();
                    } else {
                        that.$data.alertWarnLv = 3;
                        that.$data.alertWarnContent = res.msg;
                    }
                });

            },
            /**
             *  提交手机号
             * @returns {boolean}
             */
            submitBingMobile: function() {
                //请求绑定
                // let code = this.logFormObj.code;
                let mobile = this.logFormObj.mobile;
                let token = this.logFormObj.token;
                let url = '/userBindMobile'; //注册
                // if(code == '') {
                //     this.alertWarnLv = 4;
                //     return false;
                // }
                // else {
                if (mobile.length == 11) {
                    //判断验证码
                    if (token == '' || typeof token == 'undefined') {
                        this.alertWarnLv = 3;
                        this.alertWarnContent = '请输入验证码';
                        return false;
                    }

                    this.alertWarnLv = 0;
                    this.$post(url, {
                        openId: this.$store.getters['user/getOpenId'],
                        code: token,
                        mobile: mobile,
                    }).then((res) => {
                        if (res.code == 200) {
                            //更新数据
                            let custId = res.data.custId;
                            let userId = res.data.userId;
                            this.$store.commit('user/setUserId', userId);
                            this.$store.commit('user/setCustId', custId);

                            this.logShow = false;
                            this.logFormObj = {
                                mobile: '',
                                token: '',
                                code: ''
                            };
                        } else {
                            this.alertWarnLv = 3;
                            this.alertWarnContent = res.msg;
                        }
                    })
                } else {
                    this.alertWarnLv = 1;
                    this.btnDisable = false;
                }
                return false;
                // }

            },
            /**
             * ，关闭登录弹窗
             */
            clickClose: function() {
                this.logShow = false;
                this.logFormObj = {
                    mobile: '',
                    token: '',
                    code: ''
                };
            },
            /**
             * 清空
             */
            clearTop: function() {
                setTimeout(function() {
                    var scrollHeight = document.documentElement.scrollTop || document.body.scrollTop || 0;
                    window.scrollTo(0, Math.max(scrollHeight - 1, 0));
                }, 100);
            },
            // 提交信息
            submit(e) {
                let that = this;
                if (e == 1) {
                    if (that.btnDisable) {
                        return false;
                    }
                    that.btnDisable = true;
                    let name = that.cityObj.name;
                    let mobile = that.cityObj.mobile;
                    let city = that.cityObj.city;
                    let address = that.cityObj.address;
                    let code = that.cityObj.code;
                    if (name.length == 0 || name.length > 6) {
                        Dialog.confirm({
                            title: '',
                            message: "请正确输入姓名！"
                        });
                        that.btnDisable = false;
                        return false;
                    } else if (mobile.length != 11) {
                        Dialog.confirm({
                            title: '',
                            message: "请正确输入手机号！"
                        });
                        that.btnDisable = false;
                        return false;
                    } else if (city.length == 0) {
                        Dialog.confirm({
                            title: '',
                            message: "请选择城市！"
                        });
                        that.btnDisable = false;
                        return false;
                    } else if (address.length == 0) {
                        Dialog.confirm({
                            title: '',
                            message: "请填写详细地址！"
                        });
                        that.btnDisable = false;
                        return false;
                    }

                    // 提交收货信息  partyId：正式服3 测试服5
                    that.$post("/partyUpdateGiftOrderInfo", {
                        openId: that.$store.getters['user/getOpenId'],
                        partyId: 3,
                        orderId: that.orderId,
                        name: name,
                        mobile: mobile,
                        city: city,
                        address: address,
                        code: code,
                    }).then((res) => {
                        console.log(res);
                        if (res.code == 200) {
                            let data = res.data;
                            that.show = false;
                            let time = setTimeout(function() {
                                that.showSubmitType = true;
                                clearTimeout(time);
                            }, 450);
                            that.btnDisable = false;
                        } else {
                            that.btnDisable = false;
                            Dialog.confirm({
                                title: '',
                                message: res.msg
                            });
                        }
                    })
                } else {
                    that.partyGetGiftOrderList();
                    that.showSubmitType = false;
                    let time = setTimeout(function() {
                        that.showPrize = true;
                        clearTimeout(time);
                    }, 450);
                }
            },
        }
    }
</script>

<style>
    /*主体*/
    page{
        height: 100%;
    }
    .books-neo {
        background: #262728;
        width: 100%;
    }
    .books-neo .books-list{
        width: 100%;
    }
    .books-neo .books-list .books-mark-item{
        width: 100%;
        position: relative;
    }
    .books-neo .books-list .books-mark-item img{
        width: 100%;
    }
    .books-neo .books-list .books-mark-item .bg{
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
    }
    .books-neo .wrapper{
        padding: 0 95px;
    }
    .books-neo .wrapper .order-box{
        background: #fff;
        width: 100%;
        position: relative;
        border-radius: 20px;
        overflow: hidden;
        padding-bottom: 42px;
    }

    .books-neo .wrapper .order-box .order-title{
        width: 100%;
    }

    .books-neo .wrapper .order-box .order-title img{
        width: 100%;
    }

    .books-neo .wrapper .order-box .order-form{
        padding: 60px 36px;
        padding-bottom: 0;
    }

    .books-neo .wrapper .order-box .order-form .cell-container{
        position: relative;
        padding-top: 32px;
    }

    .books-neo .wrapper .order-box .order-form .cell-container .van-cell{
        border: solid 1px rgba(160,160,160,.8);
    }

    .books-neo .wrapper .order-box .order-form .cell-container:first-child{
        padding-top: 0;
    }

    .books-neo .wrapper .order-box .order-form .cell-container.token-cell .van-cell{
        width: 60%;
    }

    .books-neo .wrapper .order-box .order-form .cell-container.token-cell .getToken{
        color: #fff;
        display: inline-block;
        background-color: #352c2b;
        border-radius: 10px;
        position: absolute;
        right: 0;
        top: 32px;
        height: 76px;
        line-height: 76px;
        font-size: 24px;
        padding: 0 20px;
        box-shadow: none;
        width: 166px;
    }

    .books-neo .wrapper .order-box .order-form .cell-container .van-cell.van-cell--clickable{
        border-radius: 10px;
        border: solid 1px #a0a0a0;
        padding: 0 25px;
        font-size: 26px;
        height: 76px;
        line-height: 76px;
        text-align: left;
        color: #999999;
    }

    .books-neo .wrapper .order-box .order-form .warn-info{
        text-align: left;
        font-size: 26px;
        color: #f93204;
        height: 50px;
        line-height: 50px;
        padding-left: 60px;
    }
    
    .books-neo .wrapper .order-box .order-form .cell-container.hasBranch .van-cell.van-cell--clickable{
        color: #000;
    }
    
    .books-neo .wrapper .order-box .order-form .cell-container .van-cell.van-cell--clickable .van-icon{
        margin: auto 0;
        margin-left: 5px;
    }

    .books-neo .wrapper .order-box .order-form .cell-container .van-field{
        border-radius: 10px;
        border: solid 1px #a0a0a0;
        padding: 0 25px;
        font-size: 26px;
        height: 76px;
        line-height: 76px;
    }

    .books-neo .wrapper .order-box .order-form .cell-container .cell-title{
        text-align: left;
        color: #4a4a4a;
        font-size: 24px;
    }

    .books-neo .wrapper .order-box .order-form .bottom-submit{
        margin-top: 64px;
    }

    .books-neo .wrapper .order-box .order-form .bottom-submit .van-button{
        border: none;
    }

    .books-neo .wrapper .order-box .order-form .bottom-submit .van-button{
        height: 78px;
        line-height: 78px;
        color: #fff;
        font-size: 28px;
        background-image: linear-gradient(90deg,
        #aa8f62 0%,
        #d8c08c 100%);
        box-shadow: 0px 6px 27px 0px
        rgba(59, 48, 51, 0.35);
        border-radius: 10px;
    }

    @media screen and (min-width: 420px) and (orientation: landscape){
        .books-neo .orientation-box {
            position: absolute;
            background-color: #000!important;
            height: 100%;
            width: 100%;
            top: 0;
        }

        .books-neo .orientation::before{
            background: url('/images/icon-bg-b-width.png') no-repeat center 40% #000;
            background-size: 100%;
            bottom: 100%;
            content: "";
            height: 60px;
            left: 50%;
            position: absolute;
            -webkit-transform: translate(-50%,-10px);
            transform: translate(-50%,-10px);
            width: 110px;
        }

        .books-neo .orientation {
            color: #fff;
            display: block;
            font-size: 18px;
            line-height: 24px;
            position: absolute;
            text-align: center;
            top: 50%;
            width: 100%;
        }
    }
</style>
